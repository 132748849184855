import Dexie from 'dexie'
export const db = new Dexie('myDataBase');
db.version(1).stores({
    plans:'++id,_id,[pins],description,height,width,mediaType,nomMedia,updatedAt,createdAt,image'
})



/* 
const dbName = 'myDataBase';
const tableName = 'test';
const request = window.indexedDB.open(dbName);

request.onsuccess = (event) => {
  const db = event.target.result;

  const transaction = db.transaction([tableName], 'readonly');
  const objectStore = transaction.objectStore(tableName);

  const getAllRequest = objectStore.getAll();

  getAllRequest.onsuccess = (event) => {
    const data = event.target.result;
    console.log('Données récupérées :', data);
  };

  getAllRequest.onerror = (event) => {
    console.error('Erreur lors de la récupération des données :', event.target.error);
  };
};

request.onerror = (event) => {
  console.error('Erreur lors de l\'ouverture de la base de données :', event.target.error);
}; */
//recupération donner
/* const request = window.indexedDB.open(dbName);

request.onsuccess = (event) => {
  const db = event.target.result;
  console.log(db);
  // Vous pouvez maintenant interagir avec la base de données ici
};

request.onerror = (event) => {
  console.error('Erreur lors de l\'ouverture de la base de données :', event.target.error);
}; */
//pour supprimer une base de donner

/* const request = window.indexedDB.deleteDatabase('myDataBase');
request.onsuccess = (event) => {
  console.log('Base de données supprimée avec succès.');
};

request.onerror = (event) => {
  console.error('Erreur lors de la suppression de la base de données :', event.target.error);
}; */