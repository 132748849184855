import React, { useState, useEffect } from 'react'
import InformationGeneral from '../rapport/component/InformationGeneral'
import axios from 'axios'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { getDataPresence } from '../../../helpers/suivi/suivi'
import { useToasts } from 'react-toast-notifications'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import Header_small_screen from '../../../components/navBar/Header_small_screen'
const CarteAdminEtGen = () => {
  const { addToast } = useToasts()
  const navigate = useNavigate()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [administratif, setAdministratif] = useState('')
  const [wait, setWait] = useState(false)
  const [generalite, setGeneralite] = useState('')
  const [dataPresence, setDataPresence] = useState([])
  const [id, setId] = useState('')
  useEffect(() => {
    setWait(true)
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/crDocs?planning=${infoChantier.planning}`,
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data[0]) {
          setAdministratif(
            res.data.data[0].administratif ? res.data.data[0].administratif : ''
          )
          setGeneralite(
            res.data.data[0].generalite ? res.data.data[0].generalite : ''
          )
          setId(res.data.data[0]._id ? res.data.data[0]._id : '')
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getDataPresence(infoChantier.planning, infoUser.token, '')
      .then((e) => {
        if (e.responsables) {
          /* getnew table */
          const contacts = e.responsables.reduce((accumulator, responsable) => {
            //console.log(responsable);
            const user = {}
            user._id = responsable._id ? responsable._id : '---'
            user.name = responsable.name ? responsable.name : 'Non défini'
            user.lastname = responsable.lastname
              ? responsable.lastname
              : 'Non défini'
            user.email = responsable.email ? responsable.email : 'Non défini'
            user.telephone = responsable.telephone
              ? responsable.telephone
              : 'Non défini'
            user.present = responsable.statut
              ? responsable.statut.present
              : true
            user.lot = responsable.lot ? responsable.lot : ''
            user.retard = false
            user.absent = false
            user.convoque =
              responsable.role.roleType === 'ent'
                ? responsable.statut
                  ? responsable.statut.convoque
                  : true
                : responsable.statut
                ? responsable.statut.convoque
                : false
            user.diffusionPresence = responsable.statut
              ? responsable.statut.diffusionPresence
              : true
            user.intervenant = responsable._id ? responsable._id : 'Non défini'
            user.role = responsable.role.roleType
              ? responsable.role.roleType
              : 'Non défini'
            user.entreprise = responsable.entrepriseName
              ? responsable.entrepriseName
              : responsable.entreprise
              ? responsable.entreprise
              : 'Non défini'
            user.roleName = responsable.role?.roleName
              ? responsable.role.roleName
              : 'Non défini'
            const exist = accumulator.find((res) => res._id === responsable._id)
            if (!exist) {
              accumulator.push(user)
            }
            return accumulator
          }, [])
          setDataPresence(contacts)
        }
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: false,
        })
      })
      .finally((res) => {
        setWait(false)
      })
  }, [])
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="suivi" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
                    <Header_small_screen />
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#94A3B8',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                  / Suivi opérationnel
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  {' '}
                  / Information générale
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/SuiviOperationnel')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Information générale
                </span>
              </span>
            </div>
            {/*debut profil */}
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div className="col-lg-6" style={{ marginTop: '20px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '20px 20px',
                        backgroundColor: '#FFFFFF',
                        boxShadow:
                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                        borderRadius: '8px',
                        height: '166px',
                        border: '1px solid #E2E8F0',
                      }}>
                      <span
                        style={{
                          color: ' #334155',
                          fontSize: '20px',
                          fontWeight: '600',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <span
                          style={{
                            width: '70%',
                            display: 'flex',
                            flexWrap: 'nowrap',
                            overflow: 'hidden',
                          }}>
                          Administratif et Généralités
                        </span>
                      </span>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          padding: '0px',
                          alignItems: 'flex-start',
                          marginTop: '24px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <span></span>
                          <div>
                            <span
                              style={{
                                color: '#1E293B',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}></span>{' '}
                            <br />
                            <span
                              style={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '400',
                              }}></span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <div></div>
                          <div>
                            <span
                              style={{
                                color: '#1E293B',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}></span>{' '}
                            <br />
                            <span
                              style={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '400',
                              }}></span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <div></div>
                          <div>
                            <span
                              style={{
                                color: '#1E293B',
                                fontSize: '14px',
                                fontWeight: '600',
                              }}></span>{' '}
                            <br />
                            <span
                              style={{
                                color: '#64748B',
                                fontSize: '14px',
                                fontWeight: '400',
                              }}></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <InformationGeneral
                administratif={administratif}
                setAdministratif={setAdministratif}
                generalite={generalite}
                setGeneralite={setGeneralite}
                intervenant={dataPresence}
                isSuiviOp={true}
                setWait={setWait}
                id={id}
              />
            </div>
            {/* Fin profil */}
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default CarteAdminEtGen
