import React, { useState, useEffect } from 'react'
import Menu from '../../components/navBar/Menu'
import Header from '../../components/navBar/Header'
import { getChantier, addChantier } from '../../helpers/acceuil/acceuil'
import { ReactComponent as Clock } from '../../assets/icons/clock.svg'
import { ReactComponent as ClockRed } from '../../assets/icons/clockRed.svg'
import { ReactComponent as FullStar } from '../../assets/icons/etoilePlein.svg'
import { ReactComponent as Star } from '../../assets/icons/etoileVide.svg'
import plan from '../../assets/images/sooda_logo/planning.jpg'
import { useNavigate } from 'react-router-dom'
import PageNotFound from '../../components/404/PageNotFound'
import LoaderFixed from '../../components/loader/LoaderFixed'
const Acceuil = () => {
  const navigate = useNavigate()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [adresse, setAdresse] = useState('')
  const [titre, setTitre] = useState('')
  const [photo, setPhoto] = useState()
  const [client, setClient] = useState('')
  const [data, setData] = useState([])
  const [bool, setBool] = useState(true)
  const [wait, setWait] = useState(false)
  const [nbJours, setNbJours] = useState(0)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
/*   const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
 */
  const [item, setItem] = useState('trier')
  const [item1, setItem1] = useState(false)
  const [nbInactif, setNumberInactif] = useState([])
  const [inactif, setInactif] = useState(0)
  const [nbActif, setNumberActif] = useState([])
  const [actif, setActif] = useState(0)
  const [nbTermine, setNumberTermine] = useState([])
  const [termine, setTermine] = useState(0)
  const [accessUser, setAccessUser] = useState(
    infoUser.user?.acces ? infoUser.user.acces : []
  )
  const totalJours = () => {
    const exist = data.filter((e) => {
      if (!e.intervenants) {
        return false
      }
      return e.intervenants.intervenants.find(
        (tb) => tb.intervenant == infoUser.user._id
      )
    })
    const total = exist.reduce((acc, value) => {
      if (value.dureeChantier) {
        return acc + value.dureeChantier
      } else {
        return acc + 0
      }
    }, 0)
    return total
  }
  const filter = (value) => {
    const exist = value.filter((e) => e.intervenant == infoUser.user._id)
    if (exist.length > 0) return true
    return false
  }
  const handleSubmit = () => {
    addChantier(adresse, titre, photo, client).then((e) => {
      setBool(!bool)
    })
  }
  const accessFilter = (arr, _id) => {
    const value = arr.find((obj) => obj.chantierId._id == _id)
    if (value.roles) {
      return { values: value.roles, bool: true }
    } else {
      return { bool: false }
    }
  }

  useEffect(() => {
    setNbJours(totalJours())
  }, [data])
  //recupération des chantiers
  useEffect(() => {
    setWait(true)
    getChantier(infoUser.token)
      .then((e) => {
        if (e.data.chantiers) {
          //setNbJours(e.data.nbJour)
          setData(e.data.chantiers)
          setNumberInactif(
            e.data.chantiers.filter(
              (el) =>
                el.intervenants &&
                filter(el.intervenants?.intervenants) &&
                el.statut == 'Inactif'
            )
          )
          setNumberActif(
            e.data.chantiers.filter(
              (el) =>
                el.intervenants &&
                filter(el.intervenants?.intervenants) &&
                el.statut == 'Actif'
            )
          )
          setNumberTermine(
            e.data.chantiers.filter(
              (el) =>
                el.intervenants &&
                filter(el.intervenants?.intervenants) &&
                el.statut == 'Terminé'
            )
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally((res) => {
        setWait(false)
      })
  }, [bool])
  useEffect(() => {
    setInactif(nbInactif.length)
    setActif(nbActif.length)
    setTermine(nbTermine.length)
  }, [nbInactif, nbActif, nbTermine])
  return (
    <>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={false} />
      </div>
      <div class="page-wrapper" style={{ padding: '20px 20px' }}>
        <div class="page-content-tab">
          <div style={{ width: '100%' }}>
            <div class="row" style={{ width: '99%', margin: 'auto' }}>
              <div
                class="col-sm-12"
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                }}>
                <div
                  class="page-title-box d-flex"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <div style={{ marginLeft: '2%' }}>
                    <h4 class="page-title">Accueil</h4>
                  </div>
                  <div class="float-end">
                    {infoUser?.user?.role?.roleType == 'admin' && (
                      <>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            border: 'none',
                            color: 'white',
                            padding: '12px 24px',
                          }}>
                          + Nouveau chantier
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="row">
              <div class="col-lg-12">
                <div class="d-flex justify-content-between mb-3">
                  <div class="align-self-center">
                    {(infoUser?.user?.role?.roleType == 'admin' ||
                      infoUser?.user?.role?.roleType == 'opc') && (
                      <>
                        <button
                          type="button"
                          class="btn btn-de-success btn-sm"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalRequest"
                          disabled={true}
                          style={{ cursor: 'not-allowed' }}
                        >
                          <i
                            data-feather="download"
                            class="align-self-center icon-xs me-2"
                          ></i>
                          Nouveau chantier
                        </button>
                      </>
                    )}

                    <div
                      class="modal fade"
                      id="exampleModalRequest"
                      tabindex="-1"
                      aria-labelledby="exampleModalRequestLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h6
                              class="modal-title m-0"
                              id="exampleModalRequestLabel"
                            >
                              Ajouter un nouveau chantier
                            </h6>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form
                            onSubmit={(e) => {
                              e.target.reset()
                              e.preventDefault()
                              handleSubmit()
                            }}
                          >
                            <div class="modal-body">
                              <div class="mb-2">
                                <label for="cryptocurrency">Adresse</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setAdresse(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                              <div class="mb-2">
                                <label for="cryptocurrency">Nom chantier</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setTitre(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                              <div class="mb-2">
                                <label for="cryptocurrency">Client</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setClient(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                              <div class=" mb-2">
                                <label for="toaddress">Photo</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => {
                                    e.preventDefault()
                                    setPhoto(e.target.files[0])
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                class="btn btn-de-success btn-sm"
                                type="submit"
                              >
                                Enregistrer
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="media">
                    <img
                      src={plan}
                      class="me-2 thumb-sm align-self-center rounded-circle"
                      alt="..."
                    />
                    <div class="media-body align-self-center">
                      <p class="mb-1 text-muted">Jours plannifiés</p>
                      <h5 class="mt-0 text-dark mb-1">
                        {nbJours ? nbJours : 0} jours
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className="row"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0px 20px',
                marginTop: '15px',
              }}>
              <div
                style={{
                  padding: '8px 12px',
                  border: '1px solid #CBD5E1',
                  borderRadius: '8px',
                  width: '79px',
                  backgroundColor: 'white',
                }}>
                <span style={{ display: 'flex', marginLeft: '15px' }}>
                  <div
                    className="form-check form-switch"
                    onClick={(el) => {
                      setItem1(!item1)
                      item1 ? setItem('trier') : setItem('normale')
                    }}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={item1}
                    />
                  </div>{' '}
                </span>
              </div>
            </div>
            {item == 'normale' && (
              <>
                <div class="row" style={{ marginTop: '10px' }}>
                  {data &&
                    data.map((e) => {
                      if (
                        e.intervenants &&
                        filter(e.intervenants?.intervenants)
                      ) {
                        return (
                          <>
                            <div
                              class="col-lg-4"
                              style={{ cursor: 'pointer' }}
                              onClick={(el) => {
                                el.preventDefault()
                                const result = accessFilter(accessUser, e._id)
                                if (accessUser.length > 0 && result.bool) {
                                  infoUser.user.role = result.values
                                  localStorage.setItem(
                                    'users',
                                    JSON.stringify(infoUser)
                                  )
                                  localStorage.removeItem('infoChantiers')
                                  localStorage.setItem(
                                    'infoChantiers',
                                    JSON.stringify(e)
                                  )
                                  sessionStorage.setItem('zoomPlanning', 'mois')
                                  localStorage.setItem('ltp',JSON.stringify(false))

                                  // On récupère la valeur de la formule
                                  const formule_opc = infoUser?.user?.formule
                                  if(formule_opc === "opclte" ) {
                                    navigate('/planning_GestionTache') 
                                  }else{
                                    navigate('/Tableau_de_bord') 
                                  }
                                  // Redirection si l'utilisateur est un OPCLTE ou pas
                                  // '/Tableau_de_bord'
                                  // '/planning_GestionTache'
                                }
                              }}>
                              <div
                                class="card "
                                style={{
                                  borderRadius: '8px',
                                  boxShadow:
                                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                                  padding: '16px 24px',
                                  margin: '10px 10px',
                                }}>
                                <div class="card-header">
                                  <div class="row">
                                    <div class="col-10">
                                      <div class="media">
                                        <div class="media-body align-self-center">
                                          <span
                                            style={{
                                              color: '#475569',
                                              fontWeight: '400',
                                              fontSize: '14px',
                                              lineHeight: '24px',
                                            }}>
                                            Client :
                                          </span>{' '}
                                          <span
                                            style={{
                                              color: '#6366F1',
                                              fontWeight: '600',
                                              fontSize: '14px',
                                              lineHeight: '24px',
                                            }}>
                                            {e.client ? e.client : <>...</>}
                                          </span>{' '}
                                          <br />
                                        </div>
                                      </div>
                                    </div>
                                    {e.statut ? (
                                      e.statut == 'Actif' ? (
                                        <>
                                          <div class="col-2">
                                            <span class="badge bg-soft-success">
                                              Actif
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}

                                    {e.statut ? (
                                      e.statut == 'Inactif' ? (
                                        <>
                                          <div class="col-2">
                                            <span class="badge bg-soft-danger">
                                              Inactif
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                    {e.statut ? (
                                      e.statut == 'Terminé' ? (
                                        <>
                                          <div class="col-2">
                                            <span class="badge bg-soft-danger">
                                              Terminé
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div
                                    className="row"
                                    style={{ marginTop: '5px' }}>
                                    <span
                                      style={{
                                        color: '#334155',
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        lineHeight: '24px',
                                      }}>
                                      {e.titre}
                                    </span>
                                    <p class="fw-semibold mb-0">
                                      <br />
                                      <Clock />{' '}
                                      <span style={{ color: '#64748B' }}>
                                        Début:{' '}
                                        {(e.planningDiffuse) ? (e.dateDebut ? e.dateDebut : "---") : '---'}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div class="card-body">
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}>
                                    <div>
                                      <span
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          padding: '6px 12px',
                                          justifyContent: 'center',
                                          backgroundColor: ' #FFF1F2',
                                          borderRadius: '4px',
                                          gap: '8px',
                                        }}>
                                        {' '}
                                        <ClockRed />{' '}
                                        <span style={{ color: '#FB7185' }}>
                                          Fin :{' '}
                                          {(e.planningDiffuse)
                                            ? (e.dateFin ? e.dateFin : "---")
                                            : 'Aucun planning diffusé'}
                                        </span>
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          padding: '6px 12px',
                                          justifyContent: 'center',
                                        }}>
                                        {e.etoile > 4 ? <FullStar /> : <Star />}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    })}
                </div>
              </>
            )}
            {item == 'trier' && (
              <>
                <div class="row" style={{ marginTop: '10px' }}>
                  <div className="col-lg-4" style={{ padding: '10px 20px' }}>
                    <span
                      style={{
                        color: '#475569',
                        fontSize: '18px',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '4px solid #64748B',
                        paddingBottom: '17px',
                      }}>
                      {' '}
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#64748B',
                          marginRight: '10px',
                          marginTop: '2px',
                        }}></div>
                      Inactif{' '}
                      <div
                        style={{
                          width: '28px',
                          height: '28px',
                          borderRadius: '50%',
                          backgroundColor: '#E2E8F0',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '14px',
                          marginLeft: '10px',
                        }}>
                        {inactif ? inactif : 0}
                      </div>{' '}
                    </span>
                    <div className="colonneChantier">
                      {data &&
                        data.map((e) => {
                          if (
                            e.intervenants &&
                            e.statut == 'Inactif' &&
                            filter(e.intervenants?.intervenants)
                          ) {
                            return (
                              <>
                                <div
                                  class="card "
                                  style={{
                                    borderRadius: '8px',
                                    boxShadow:
                                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                                    padding: '16px 24px',
                                    marginTop: '10px',
                                    width: '100%',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(el) => {
                                    el.preventDefault()
                                    const result = accessFilter(
                                      accessUser,
                                      e._id
                                    )
                                    if (accessUser.length > 0 && result.bool) {
                                      infoUser.user.role = result.values
                                      localStorage.setItem(
                                        'users',
                                        JSON.stringify(infoUser)
                                      )
                                      localStorage.removeItem('infoChantiers')
                                      localStorage.setItem(
                                        'infoChantiers',
                                        JSON.stringify(e)
                                      )
                                      sessionStorage.setItem(
                                        'zoomPlanning',
                                        'mois'
                                      )

                                      // On récupère la valeur de la formule
                                      const formule_opc = infoUser?.user?.formule
                                      if(formule_opc === "opclte" ) {
                                        navigate('/planning_GestionTache') 
                                      }else{
                                        navigate('/Tableau_de_bord') 
                                      }
                                      // Redirection si l'utilisateur est un OPCLTE ou pas
                                      // '/Tableau_de_bord'
                                      // '/planning_GestionTache'
                                    }
                                  }}>
                                  <div class="card-header">
                                    <div class="row">
                                      <div class="col-10">
                                        <div class="media">
                                          <div class="media-body align-self-center">
                                            <span
                                              style={{
                                                color: '#475569',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                              }}>
                                              Client :
                                            </span>{' '}
                                            <span
                                              style={{
                                                color: '#6366F1',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                              }}>
                                              {e.client ? e.client : <>...</>}
                                            </span>{' '}
                                            <br />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{ marginTop: '5px' }}>
                                      <span
                                        style={{
                                          color: '#334155',
                                          fontSize: '16px',
                                          fontWeight: '600',
                                          lineHeight: '24px',
                                        }}>
                                        {e.titre ? e.titre : 'Non défini'}
                                      </span>
                                      <p class="fw-semibold mb-0">
                                        <br />
                                        <Clock />{' '}
                                        <span style={{ color: '#64748B' }}>
                                          Début:{' '}
                                          {(e.planningDiffuse) ? (e.dateDebut ? e.dateDebut : "---") : '---'}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}>
                                      <div>
                                        <span
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            padding: '6px 12px',
                                            justifyContent: 'center',
                                            backgroundColor: ' #FFF1F2',
                                            borderRadius: '4px',
                                            gap: '8px',
                                          }}>
                                          {' '}
                                          <ClockRed />{' '}
                                          <span style={{ color: '#FB7185' }}>
                                            Fin :{' '}
                                            {(e.planningDiffuse) ? (e.dateFin ? e.dateFin : "---") : 'Aucun planning diffusé'}
                                          </span>
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            padding: '6px 12px',
                                            justifyContent: 'center',
                                          }}>
                                          <FullStar />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        })}
                    </div>
                  </div>
                  <div className="col-lg-4" style={{ padding: '10px 20px' }}>
                    <span
                      style={{
                        color: '#475569',
                        fontSize: '18px',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '4px solid #6366F1',
                        paddingBottom: '17px',
                      }}>
                      {' '}
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#6366F1',
                          marginRight: '10px',
                          marginTop: '2px',
                        }}></div>
                      Actif{' '}
                      <div
                        style={{
                          width: '28px',
                          height: '28px',
                          borderRadius: '50%',
                          backgroundColor: '#E2E8F0',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '14px',
                          marginLeft: '10px',
                        }}>
                        {actif ? actif : 0}
                      </div>{' '}
                    </span>
                    <div className="colonneChantier">
                      {data &&
                        data.map((e) => {
                          if (
                            e.intervenants &&
                            e.statut == 'Actif' &&
                            filter(e.intervenants?.intervenants)
                          ) {
                            return (
                              <>
                                <div
                                  class="card"
                                  style={{
                                    borderRadius: '8px',
                                    boxShadow:
                                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                                    padding: '16px 24px',
                                    marginTop: '10px',
                                    width: '100%',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(el) => {
                                    el.preventDefault()
                                    const result = accessFilter(
                                      accessUser,
                                      e._id
                                    )
                                    if (accessUser.length > 0 && result.bool) {
                                      infoUser.user.role = result.values
                                      localStorage.setItem(
                                        'users',
                                        JSON.stringify(infoUser)
                                      )
                                      localStorage.removeItem('infoChantiers')
                                      localStorage.setItem(
                                        'infoChantiers',
                                        JSON.stringify(e)
                                      )
                                      sessionStorage.setItem(
                                        'zoomPlanning',
                                        'mois'
                                      )

                                      // On récupère la valeur de la formule
                                      const formule_opc = infoUser?.user?.formule
                                      if(formule_opc === "opclte" ) {
                                        navigate('/planning_GestionTache') 
                                      }else{
                                        navigate('/Tableau_de_bord') 
                                      }
                                      // Redirection si l'utilisateur est un OPCLTE ou pas
                                      // '/Tableau_de_bord'
                                      // '/planning_GestionTache'
                                    }
                                  }}>
                                  <div class="card-header">
                                    <div class="row">
                                      <div class="col-10">
                                        <div class="media">
                                          <div class="media-body align-self-center">
                                            <span
                                              style={{
                                                color: '#475569',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                              }}>
                                              Client :
                                            </span>{' '}
                                            <span
                                              style={{
                                                color: '#6366F1',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                              }}>
                                              {e.client ? e.client : <>...</>}
                                            </span>{' '}
                                            <br />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{ marginTop: '5px' }}>
                                      <span
                                        style={{
                                          color: '#334155',
                                          fontSize: '16px',
                                          fontWeight: '600',
                                          lineHeight: '24px',
                                        }}>
                                        {e.titre ? e.titre : 'Non défini'}
                                      </span>
                                      <p class="fw-semibold mb-0">
                                        <br />
                                        <Clock />{' '}
                                        <span style={{ color: '#64748B' }}>
                                          Début:{' '}
                                          {(e.planningDiffuse) ? (e.dateDebut? e.dateDebut : "---") : '---'}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}>
                                      <div>
                                        <span
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            padding: '6px 12px',
                                            justifyContent: 'center',
                                            backgroundColor: ' #FFF1F2',
                                            borderRadius: '4px',
                                            gap: '8px',
                                          }}>
                                          {' '}
                                          <ClockRed />{' '}
                                          <span style={{ color: '#FB7185' }}>
                                            Fin :{' '}
                                            {(e.planningDiffuse) ? (e.dateFin ? e.dateFin : "---"): 'Aucun planning diffusé'}
                                          </span>
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            padding: '6px 12px',
                                            justifyContent: 'center',
                                          }}>
                                          <FullStar />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        })}
                    </div>
                  </div>
                  <div className="col-lg-4" style={{ padding: '10px 20px' }}>
                    <span
                      style={{
                        color: '#475569',
                        fontSize: '18px',
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '4px solid #2DD4BF',
                        paddingBottom: '17px',
                      }}>
                      {' '}
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#2DD4BF',
                          marginRight: '10px',
                          marginTop: '2px',
                        }}></div>
                      Terminé{' '}
                      <div
                        style={{
                          width: '28px',
                          height: '28px',
                          borderRadius: '50%',
                          backgroundColor: '#E2E8F0',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '14px',
                          marginLeft: '10px',
                        }}>
                        {termine ? termine : 0}
                      </div>{' '}
                    </span>
                    <div className="colonneChantier">
                      {data &&
                        data.map((e) => {
                          if (
                            e.intervenants &&
                            e.statut == 'Terminé' &&
                            filter(e.intervenants?.intervenants)
                          ) {
                            return (
                              <>
                                <div
                                  class="card "
                                  style={{
                                    borderRadius: '8px',
                                    boxShadow:
                                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                                    padding: '16px 24px',
                                    marginTop: '10px',
                                    width: '100%',
                                    cursor: 'pointer',
                                  }}
                                  onClick={(el) => {
                                    el.preventDefault()
                                    const result = accessFilter(
                                      accessUser,
                                      e._id
                                    )
                                    if (accessUser.length > 0 && result.bool) {
                                      infoUser.user.role = result.values
                                      localStorage.setItem(
                                        'users',
                                        JSON.stringify(infoUser)
                                      )
                                      localStorage.removeItem('infoChantiers')
                                      localStorage.setItem(
                                        'infoChantiers',
                                        JSON.stringify(e)
                                      )
                                      sessionStorage.setItem(
                                        'zoomPlanning',
                                        'mois'
                                      )

                                      // On récupère la valeur de la formule
                                      const formule_opc = infoUser?.user?.formule
                                      if(formule_opc === "opclte" ) {
                                        navigate('/planning_GestionTache') 
                                      }else{
                                        navigate('/Tableau_de_bord') 
                                      }
                                      // Redirection si l'utilisateur est un OPCLTE ou pas
                                      // '/Tableau_de_bord'
                                      // '/planning_GestionTache'
                                    }
                                  }}>
                                  <div class="card-header">
                                    <div class="row">
                                      <div class="col-10">
                                        <div class="media">
                                          <div class="media-body align-self-center">
                                            <span
                                              style={{
                                                color: '#475569',
                                                fontWeight: '400',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                              }}>
                                              Client :
                                            </span>{' '}
                                            <span
                                              style={{
                                                color: '#6366F1',
                                                fontWeight: '600',
                                                fontSize: '14px',
                                                lineHeight: '24px',
                                              }}>
                                              {e.client ? e.client : <>...</>}
                                            </span>{' '}
                                            <br />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="row"
                                      style={{ marginTop: '5px' }}>
                                      <span
                                        style={{
                                          color: '#334155',
                                          fontSize: '16px',
                                          fontWeight: '600',
                                          lineHeight: '24px',
                                        }}>
                                        {e.titre ? e.titre : 'Non défini'}
                                      </span>
                                      <p class="fw-semibold mb-0">
                                        <br />
                                        <Clock />{' '}
                                        <span style={{ color: '#64748B' }}>
                                          Début:{' '}
                                          {(e.planningDiffuse) ? (e.dateDebut ? e.dateDebut : "---") : '---'}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="card-body">
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}>
                                      <div>
                                        <span
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            padding: '6px 12px',
                                            justifyContent: 'center',
                                            backgroundColor: ' #FFF1F2',
                                            borderRadius: '4px',
                                            gap: '8px',
                                          }}>
                                          {' '}
                                          <ClockRed />{' '}
                                          <span style={{ color: '#FB7185' }}>
                                            Fin :{' '}
                                            {(e.planningDiffuse) ? (e.dateFin ? e.dateFin : "---") : 'Aucun planning diffusé'}
                                          </span>
                                        </span>
                                      </div>
                                      <div>
                                        <span
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            padding: '6px 12px',
                                            justifyContent: 'center',
                                          }}>
                                          <FullStar />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          }
                        })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Acceuil
