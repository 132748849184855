import axios from 'axios'

export const getAllEvent = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const resultat = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/events?planning=${id}&limit=200000000`, config)
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        return err.response.data.data
      })
    return resultat
  } catch (err) {
    alert('Le serveur ne répond pas')
    return { message: 'erreur' }
  }
}
export const updateEvent = async (id,data,token,planning) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    try {
      const resultat = await axios
        .patch(`${process.env.REACT_APP_URL}/api/v1/events/${id}?planning=${planning}`,{...data}, config)
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          return err.response.data.data
        })
      return resultat
    } catch (err) {
      alert('Le serveur ne répond pas')
      return { message: 'erreur' }
    }
  }


export const delEvent = async (id, token,planning) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    try {
      const resultat = await axios
        .delete(`${process.env.REACT_APP_URL}/api/v1/events/${id}?planning=${planning}`, config)
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          return err.response.data.data
        })
      return resultat
    } catch (err) {
      alert('Le serveur ne répond pas')
      return { message: 'erreur' }
    }
  }

export const getAllZone = async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    try {
      const resultat = await axios
        .get(`${process.env.REACT_APP_URL}/api/v1/zones?planning=${id}&archive=false&limit=10000&sort=index`, config)
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          return err.response.data.data
        })
      return resultat
    } catch (err) {
      alert('Le serveur ne répond pas')
      return { message: 'erreur' }
    }
  }
  export const UpdateZone = async (id,data,token,planning) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    try {
      const resultat = await axios
        .patch(`${process.env.REACT_APP_URL}/api/v1/zones/${id}?planning=${planning}`,{
          label:data
        }, config)
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          return err.response.data.data
        })
      return resultat
    } catch (err) {
      alert('Le serveur ne répond pas')
      return { message: 'erreur' }
    }
  }
  export const DeleteZone = async (id,token,planning) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    try {
      const resultat = await axios
        .patch(`${process.env.REACT_APP_URL}/api/v1/zones/${id}?planning=${planning}`,{
         archive:true
        }, config)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response.data.data
        })
      return resultat
    } catch (err) {
      alert('Le serveur ne répond pas')
      return { message: 'erreur' }
    }
  }


  export const getAllLotGlissant = async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    try {
      const resultat = await axios
        .get(`${process.env.REACT_APP_URL}/api/v1/lots/getFormatedLotToLastPlanner?planning=${id}`, config)
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          return err.response.data.data
        })
      return resultat
    } catch (err) {
      alert('Le serveur ne répond pas')
      return { message: 'erreur' }
    }
  }
  export const getAllCarteGlissant = async (id, token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  
    try {
      const resultat = await axios
        .get(`${process.env.REACT_APP_URL}/api/v1/cartes/getFormatedCarteToLastPlanner?planning=${id}`, config)
        .then((res) => {
          return res.data.data
        })
        .catch((err) => {
          return err.response.data.data
        })
      return resultat
    } catch (err) {
      alert('Le serveur ne répond pas')
      return { message: 'erreur' }
    }
  }


  
export const addEvent = async (id, data, token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const resultat = await axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/events?planning=${planning}`,
        { ...data,planning:id },
        config
      )
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        return err.response.data.data
      })
    return resultat
  } catch (err) {
    alert('Le serveur ne répond pas')
    return { message: 'erreur' }
  }
}

export const addZone = async (id, data, token,index) => {
  console.log(data)
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const resultat = await axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/zones`,
        { label: data, planning: id,index:index },
        config
      )
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        return err.response.data.data
      })
    return resultat
  } catch (err) {
    alert('Le serveur ne répond pas')
    return { message: 'erreur' }
  }
}

export const shareEvent = async (id,data,garde,destinataire, token,planning) => {
  const config = {
    headers: {
      //'Content-Type': 'text/html',
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const resultat = await axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/lastPlanner/partager?planning=${planning}`,
        { html:JSON.stringify(data),pageDeGarde:JSON.stringify(garde),destinataires:destinataire,planning:id },
        config
      )
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        return err.response.data.data
      })
    return resultat
  } catch (err) {
    alert('Le serveur ne répond pas')
    return { message: 'erreur' }
  }
}
