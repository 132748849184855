import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { ReactTabulator } from 'react-tabulator'
import PageNotFound from '../../../components/404/PageNotFound'
import {
  updateData,
  getAllData,
  delData,
  addData,
} from '../../../helpers/suivi/suivi'
import trash from '../../../assets/images/Kolos_logo/trash.png'
import Multiselect from 'multiselect-react-dropdown'
import { getAllEntreprise } from '../../../helpers/rapport/rapport'
import axios from 'axios'
import { Button, Upload } from 'antd'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { searchOptimizedData } from '../../../helpers/recherche/recherche'
const Newpassation = () => {
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  const [dataPassation, setDataPassation] = useState([])
  const [dataModalEntreprise, setDataModalEntreprise] = useState({})
  const [dataModalLocalisation, setDataModalLocalisation] = useState({})
  const [dataModalAction, setDataModalAction] = useState({})
  const [dataMaj, setDataMaj] = useState()
  const [imageAction, setImageAction] = useState([])
  const [dataLot, setDataLot] = useState([])
  const [entSelected, setEntSelected] = useState([])
  const [locSelected, setLocSelected] = useState([])
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState(false)
  const [dataTag, setDataTag] = useState([])

  //Boutton de suppression
  const button = (delFunction) => (cell) => {
    const delIcons = document.createElement('img')
    delIcons.src = trash
    delIcons.style = 'width:24px;height:24px'

    //create delete button
    var delBt = document.createElement('button')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.type = 'button'
    delBt.appendChild(delIcons)
    delBt.classList.add('btn')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)
    return buttonHolder
  }
  const passationEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    updateData(data, `fichesPassation`, infoUser.token, infoChantier.planning)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }
  const delPassation = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'fichesPassation',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
      setWait(false)
    } catch (err) {
      addToast("Une erreur s'est produit.", {
        appearance: 'error',
        autoDismiss: true,
      })
      setWait(false)
    }
  }
  const addPassationRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      { planning: infoChantier.planning },
      `fichesPassation`,
      'rien',
      infoUser.token
    )
      .then((e) => {
        console.log(e)
        return e
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
        return
      })
      .finally((res) => {
        //setWait(false)
        setBool(!bool)
      })
    //setDataPassation([{ ...result }, ...dataPassation])
  }
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }

  //Mise à jour ligne
  const maj = (cell, info) => {
    console.log(cell.getValue())
    cell.setValue(info)
  }

  const majPhoto = (cell, info) => {
    console.log(cell.getValue())
    cell.getRow().update(info)
  }

  const modalAction = (value) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-download')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAction')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      setDataMaj(cell)
      setDataModalAction(cell.getData())
      setImageAction(null)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }

  //Modal document
  const modalEntreprise = (value) => (cell) => {
    const data = cell.getData()
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-list')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEntreprise')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      console.log(dataLot)
      //setEntSelected([])
      setEntSelected(
        data.entreprises?.length > 0
          ? data.entreprises
          : [{ nom: 'Sélectionner des entreprises' }]
      )
      setDataModalEntreprise(cell.getData())
      setDataMaj(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.style = 'margin-left:12px'
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  const modalLocalisation = (value) => (cell) => {
    const data = cell.getData()
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-map-pin')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalLocalisation')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      setLocSelected(
        data.localisation?.length > 0
          ? data.localisation
          : [{ value: 'Sélectionner localisation' }]
      )
      setDataModalLocalisation(cell.getData())
      setDataMaj(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.style = 'margin-left:12px'
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }

  const colonnePassation = [
    {
      title: 'Masquer',
      width: 110,
      field: 'masquer',
      formatter: checkbox,
    },
    {
      title: 'Entreprises',
      width: 110,
      field: 'entreprises',
      formatter: modalEntreprise(),
    },
    {
      title: 'Localisation',
      field: 'localisation',
      width: 120,
      formatter: modalLocalisation(),
    },
    { title: 'Description', field: 'description', formatter: simpleInput },
    {
      title: 'Photo',
      width: 120,
      formatter: modalAction(),
    },
    {
      title: 'Validation',
      field: 'validation',
      width: 110,
      formatter: checkbox,
    },
    {
      title: 'Impact',
      field: 'impact',
      formatter: simpleInput,
    },
    { title: 'Commentaires', field: 'commentaires', formatter: simpleInput },
    { title: 'Solutions', field: 'solutions', formatter: simpleInput },
    {
      title: 'Supprimer',
      field: 'functionButton',
      width: 100,
      formatter: button(delPassation),
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  useEffect(() => {
    setWait(true)
    getAllData(
      `planning=${infoChantier.planning}&sort=value`,
      'zonesFiche',
      infoUser.token
    )
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setDataTag(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    getAllData(
      `planning=${infoChantier.planning}`,
      'fichesPassation',
      infoUser.token
    )
      .then((res) => {
        console.log(res.data)
        if (res.data) {
          setDataPassation(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally((res) => {
        setWait(false)
      })
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/lots/entreprises?planning=${infoChantier.planning}&deselect[ne]=true`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data) {
          console.log(res.data.data)
          setDataLot(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [bool])
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="rapport" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#94A3B8',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                  / Rapports
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  {' '}
                  / Nouvelle Fiche de passation
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Rapport_CR')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Nouvelle fiche de passation
                </span>
              </span>
            </div>
          </div>
          <div
            className="container-fluid"
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: '8px',
              boxShadow:
                '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
              padding: '16px 0px 24px',
            }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 0px 0px 40px',
              }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                  }}>
                  Passation
                </span>
                <span
                  style={{
                    backgroundColor: '#E0E7FF',
                    borderRadius: '24px',
                    padding: '6px 12px',
                    color: '#64748B',
                    fontSize: '14px',
                    fontWeight: '600',
                    marginLeft: '8px',
                    maxHeight: '32px',
                    width: 'auto',
                  }}>
                  {dataPassation.length > 0 ? dataPassation.length : '0'} tâches
                </span>
              </div>
              <div>
                <span
                  style={{
                    padding: '8px 12px 8px 12px',
                    backgroundColor: 'rgba(226, 232, 240, 0.6)',
                    borderRadius: '8px',
                    height: '40px',
                  }}>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#818CF8"
                      style={{ width: '18px', height: '18px' }}>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    placeholder="Recherche"
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                    }}
                    onChange={(ev) => {
                      ev.preventDefault()
                      ev.stopPropagation()
                      const newData = searchOptimizedData(
                        ev.target.value,
                        dataPassation
                      )
                      setDataPassation(newData)
                    }}
                  />
                </span>
                <button
                  className="btn"
                  style={{
                    backgroundColor: '#2DD4BF',
                    color: '#F8FAFC',
                    marginLeft: '24px',
                    marginRight: '20px',
                  }}
                  onClick={(e) => addPassationRow(e)}>
                  + Nouvelle tâche
                </button>
                <button
                  className="btn"
                  style={{
                    backgroundColor: '#4F46E5',
                    color: '#F8FAFC',
                    marginLeft: '24px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setWait(true)
                    axios
                      .get(
                        `${process.env.REACT_APP_URL}/api/v1/fiches/ficheDePassationToPDF?planning=${infoChantier.planning}`,
                        {
                          headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${infoUser.token}`,
                          },
                        }
                      )
                      .then((res) => {
                        addToast('Fiche de passation créé avec succès.', {
                          appearance: 'success',
                          autoDismiss: true,
                        })
                        localStorage.setItem('typeRapport','passation')
                        navigate('/Rapport_CR')
                      })
                      .catch((err) => {
                        console.log(err)
                        addToast("Une erreur s'est produit.", {
                          appearance: 'error',
                          autoDismiss: true,
                        })
                      })
                      .finally((res) => {
                        setWait(false)
                      })
                  }}>
                  Valider
                </button>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                borderTop: 'solid #E2E8F0 1px',
                marginTop: '16px',
              }}>
              <ReactTabulator
                data={dataPassation}
                columns={colonnePassation}
                layout={'fitColumns'}
                events={{ cellEdited: passationEdited }}
                options={{
                  locale: true,
                  pagination: 'local',
                  paginationSize: 5,
                  langs: {
                    fr: {
                      pagination: {
                        first: 'Premier',
                        first_title: 'Première',
                        last: 'Dernier',
                        last_title: 'Dernière',
                        prev: 'Précédent',
                        prev_title: 'Précédente',
                        next: 'Suivant',
                        next_title: 'Page suivante',
                        page_size: 'Taille de page',
                        page_title: 'Afficher la page',
                        all: 'Tous',
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Modal multiselect entreprise */}
      <div class="modal" id="myModalEntreprise">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Liste entreprise
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>

            <div class="modal-body">
              <Multiselect
                displayValue="nom"
                options={dataLot}
                placeholder=""
                onRemove={function noRefCheck(e) {
                  console.log(e)
                  setEntSelected(
                    e.filter((el) => el.nom !== 'Sélectionner des entreprises')
                  )
                  //setEntSelected(entSelected.filter((el)=>el.nom!=="Sélectionner des entreprises"))
                }}
                onSelect={function noRefCheck(e) {
                  console.log(e)
                  setEntSelected(
                    e.filter((el) => el.nom !== 'Sélectionner des entreprises')
                  )
                }}
                selectedValues={entSelected}
              />
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                style={{
                  color: 'white',
                  backgroundColor: '#4F46E5',
                  borderRadius: '8px',
                }}
                data-bs-dismiss="modal"
                onClick={(ev) => {
                  ev.preventDefault()
                  axios
                    .patch(
                      `${process.env.REACT_APP_URL}/api/v1/fichesPassation/${dataModalEntreprise._id}?planning=${infoChantier.planning}`,
                      { entreprises: entSelected },
                      {
                        headers: {
                          Authorization: `Bearer ${infoUser.token}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log(res.data.data)
                      if (res.data.data) {
                        dataMaj.getRow().update(res.data.data)
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                      addToast("Une erreur s'est produit.", {
                        appearance: 'error',
                        autoDismiss: true,
                      })
                    })
                }}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal multiselect Localisation */}
      <div class="modal" id="myModalLocalisation">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Localisation
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>

            <div class="modal-body">
              <Multiselect
                displayValue="value"
                options={dataTag}
                placeholder=""
                onRemove={function noRefCheck(e) {
                  console.log(e)
                  setLocSelected(
                    e.filter((el) => el.value !== 'Sélectionner localisation')
                  )
                }}
                onSelect={function noRefCheck(e) {
                  console.log(e)
                  setLocSelected(
                    e.filter((el) => el.value !== 'Sélectionner localisation')
                  )
                }}
                selectedValues={locSelected}
              />
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                style={{
                  color: 'white',
                  backgroundColor: '#4F46E5',
                  borderRadius: '8px',
                }}
                data-bs-dismiss="modal"
                onClick={(ev) => {
                  ev.preventDefault()
                  axios
                    .patch(
                      `${process.env.REACT_APP_URL}/api/v1/fichesPassation/${dataModalLocalisation._id}?planning=${infoChantier.planning}`,
                      { localisation: locSelected },
                      {
                        headers: {
                          Authorization: `Bearer ${infoUser.token}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log(res)
                      if (res.data.data) {
                        dataMaj.getRow().update(res.data.data)
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                      addToast("Une erreur s'est produit.", {
                        appearance: 'error',
                        autoDismiss: true,
                      })
                    })
                }}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Action */}
      <div class="modal fade" id="myModalAction">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Importer des photos
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.target.reset()
                addToast('Enregistrement en cours.', {
                  appearance: 'success',
                  autoDismiss: true,
                })
                console.log(imageAction)
                const formData = new FormData()
                imageAction.map((item) => {
                  formData.append('item', item.originFileObj)
                })
                axios
                  .patch(
                    `${process.env.REACT_APP_URL}/api/v1/fichesPassation/${dataModalAction._id}?planning=${infoChantier.planning}`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${infoUser.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    addToast('Enregistrement avec succès.', {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    console.log(res)
                    if (res.data.data) {
                      console.log(res.data.data)
                      majPhoto(dataMaj, res.data.data)
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  })
              }}>
              {' '}
              <div class="modal-body">
                {dataModalAction?.actionPhoto?.length < 4 ? (
                  <>
                    <div class="mb-3 mt-3">
                      <label for="comment">
                        (4 images max, Taille 1Mo max, type:".png,.jpeg,.jpg"):
                      </label>
                      <Upload.Dragger
                        multiple={true}
                        maxCount={4}
                        beforeUpload={(files) => {
                          return false
                        }}
                        showUploadList={{ showRemoveIcon: true }}
                        accept=".png,.jpeg,.jpg"
                        listType="picture"
                        onChange={(ev) => {
                          console.log(ev.fileList)
                          setImageAction(ev.fileList)
                        }}>
                        <Button>Parcourir Fichier</Button>
                      </Upload.Dragger>
                    </div>
                  </>
                ) : (
                  <>
                    <h4
                      class="modal-title"
                      style={{ color: '#334155', textAlign: 'center' }}>
                      Vous avez atteint la limite des photos à importer (4
                      images)
                    </h4>
                  </>
                )}
                {dataModalAction?.actionPhoto?.length > 0 && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        padding: '12px 12px',
                        height: '300px',
                        overflowY: 'scroll',
                      }}>
                      {dataModalAction.actionPhoto.map((el) => {
                        return (
                          <>
                            <div
                              className="container-fluid"
                              id={`${el._id}`}
                              style={{ position: 'relative' }}>
                              <img
                                src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                alt=""
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  marginTop: '5px',
                                }}
                              />
                              <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image =
                                      dataModalAction.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalAction._id,
                                      },
                                      'fichesPassation',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      console.log(res)
                                      if (res.data) {
                                        dataMaj.getRow().update(res.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                  disabled={
                    dataModalAction?.actionPhoto?.length < 4 ? false : true
                  }>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default Newpassation
