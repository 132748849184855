import React, { useState, useEffect, useRef } from 'react'
import Menu from '../../../../components/navBar/Menu'
import Header from '../../../../components/navBar/Header'
import { useToasts } from 'react-toast-notifications'
import { updateData } from '../../../../helpers/suivi/suivi'
import { fabric } from 'fabric'
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
const AnnotationPlan = ({ setAnnotation, plan, data, cell, setWait }) => {
  const { editor, onReady } = useFabricJSEditor()
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [brush, setBrush] = useState(10)
  const [color, setColor] = useState('#E60505')
  const [cropImage, setCropImage] = useState(true)
  const [isDessiner, setIsDessiner] = useState(true)
  const [listAnnotation,setListAnnotation] = useState([])
  const history = []
  const afficherAnnotationsDepuisBaseDeDonnees = (data) => {
    data.forEach((item) => {
      let objet
      switch (item.type) {
        case 'path':
          objet = new fabric.Path(item.path ? item.path : [], {
            left: item.left ? item.left : 0,
            top: item.top ? item.top : 0,
            stroke: item.stroke ? item.stroke : 'red',
            strokeWidth: item.strokeWidth ? item.strokeWidth : 10,
            strokeLineCap: item.strokeLineCap ? item.strokeLineCap : 'round',
            strokeLineJoin: item.strokeLineJoin ? item.strokeLineJoin : 'round',
            strokeMiterLimit: item.strokeLimit ? item.strokeLimit : 10,
            zoomX: item.zoomX ? item.zoomX : 0,
            zoomY: item.zoomY ? item.zoomY : 0,
            fill: item.fill ? item.fill : 'rgba(255, 255, 255, 0.0)',
            angle: item.angle ? item.angle : 0,
          })
          editor?.canvas.add(objet)
          break
        case 'circle':
          objet = new fabric.Circle({
            radius: item.radius ? item.radius : 20,
            left: item.left ? item.left : 0,
            top: item.top ? item.top : 0,
            fill: item.fill ? item.fill : 'rgba(255, 255, 255, 0.0)',
            zoomX: item.zoomX ? item.zoomX : 0,
            zoomY: item.zoomY ? item.zoomY : 0,
            scaleX: item.scaleX ? item.scaleX : 0,
            scaleY: item.scaleY ? item.scaleY : 0,
            stroke: item.stroke ? item.stroke : 0,
            strokeWidth: item.strokeWidth ? item.strokeWidth : 10,
            angle: item.angle ? item.angle : 0,
          })
          editor?.canvas.add(objet)
          break
        case 'rect':
          objet = new fabric.Rect({
            width: item.width ? item.width : 0,
            height: item.height ? item.height : 0,
            scaleX: item.scaleX ? item.scaleX : 0,
            scaleY: item.scaleY ? item.scaleY : 0,
            fill: item.fill ? item.fill : 'rgba(255, 255, 255, 0.0)',
            stroke: item.stroke ? item.stroke : 'red',
            left: item.left ? item.left : 0,
            top: item.top ? item.top : 0,
            zoomX: item.zoomX ? item.zoomX : 0,
            zoomY: item.zoomY ? item.zoomY : 0,
            strokeWidth: item.strokeWidth ? item.strokeWidth : 10,
            angle: item.angle ? item.angle : 0,
          })
          editor?.canvas.add(objet)
          break
        case 'line':
          objet = new fabric.Line(
            [
              item.x1 ? item.x1 : 0,
              item.y1 ? item.y1 : 0,
              item.x2 ? item.x2 : 0,
              item.y2 ? item.y2 : 0,
            ],
            {
              left: item.left ? item.left : 0,
              top: item.top ? item.top : 0,
              stroke: item.stroke ? item.stroke : 'red',
              strokeWidth: item.strokeWidth ? item.strokeWidth : 10,
              zoomX: item.zoomX ? item.zoomX : 0,
              zoomY: item.zoomY ? item.zoomY : 0,
              scaleX: item.scaleX ? item.scaleX : 0,
              scaleY: item.scaleY ? item.scaleY : 0,
              width: item.width ? item.width : 0,
              height: item.height ? item.height : 0,
              angle: item.angle ? item.angle : 0,
            }
          )
          editor?.canvas.add(objet)
          break
        case 'textbox':
          objet = new fabric.Textbox(item.text ? item.text : '', {
            left: item.left ? item.left : 0,
            top: item.top ? item.top : 0,
            width: item.width ? item.width : 0,
            height: item.height ? item.height : 0,
            fill: item.fill ? item.fill : 'rgba(255, 255, 255, 0.0)',
            zoomX: item.zoomX ? item.zoomX : 0,
            zoomY: item.zoomY ? item.zoomY : 0,
            scaleX: item.scaleX ? item.scaleX : 0,
            scaleY: item.scaleY ? item.scaleY : 0,
            fontSize: item.fontSize ? item.fontSize : 16,
            textLines: item.textLines ? item.textLines : [],
            dinamicMinWidth: item.dinamicMinWidth ? item.dinamicMinWidth : 0,
            angle: item.angle ? item.angle : 0,
          })
          editor?.canvas.add(objet)
          break
        default:
          // Gérer le cas par défaut si nécessaire
          break
      }

      // Ajouter l'objet au canvas
    })
  }
  const addBackground = (data) => {
    if (!editor || !fabric || !data) {
      return
    }

    fabric.Image.fromURL(
      `${process.env.REACT_APP_URL}/api/v1/ressources/plan/?planName=${plan.nomMedia}&accessToken=${infoUser.token}&planning=${infoChantier.planning}`,
      (image) => {
        editor.canvas.setBackgroundImage(
          image,
          editor.canvas.renderAll.bind(editor.canvas)
        )
      }
    )
    //editor.canvas.setWidth(800)
    editor.canvas.setHeight(1000)
  }
  const toggleDraw = (value) => {
    editor.canvas.isDrawingMode = value
  }
  const undo = () => {
    if (editor.canvas._objects.length > 0) {
      history.push(editor.canvas._objects.pop())
    }
    editor.canvas.renderAll()
  }
  const removeSelectedObject = () => {
    editor.canvas.remove(editor.canvas.getActiveObject())
  }
  const onAddCircle = () => {
    //editor?.addCircle()
    const cercle = new fabric.Circle({
      radius: 200,
      top: 100,
      left: 100,
      fill: 'rgba(255, 255, 255, 0.0)',
      stroke: color,
      strokeWidth: 10,
    })
    editor?.canvas.add(cercle)
  }
  const onAddLigne = () => {
    const line = new fabric.Line([400, 400, 200, 50], {
      // [x1, y1, x2, y2] définit les points de début et de fin de la ligne
      stroke: color, // Couleur de la ligne
      strokeWidth: 10, // Largeur de la ligne
      selectable: true, // Permet de sélectionner la ligne
      // Autres propriétés personnalisées...
    })

    editor?.canvas.add(line)
    //editor.addLine()
  }
  const onAddRectangle = () => {
    //editor?.addRectangle()
    const rect = new fabric.Rect({
      width: 400,
      height: 200,
      fill: 'rgba(255, 255, 255, 0.0)',
      stroke: color,
      strokeWidth: 10,
      top: 100,
      left: 100,
    })
    editor?.canvas.add(rect)
  }
  const addText = () => {
    //editor.addText('insérer texte')
    const texte = new fabric.Textbox('Insérer texte', {
      left: 100,
      top: 100,
      fill: color,
      fontSize: 80,
      width: 400,
      height: 200,
    })
    editor?.canvas.add(texte)
  }

  useEffect(() => {
    if (!editor || !fabric) {
      return
    }
    editor.canvas.freeDrawingBrush.color = color
    editor.canvas.freeDrawingBrush.width = brush
    editor.setStrokeColor(color)
  }, [color, brush])
  useEffect(() => {
    if (!editor || !fabric) {
      return
    }

    if (cropImage) {
      editor.canvas.__eventListeners = {}
      return
    }

    if (!editor.canvas.__eventListeners['mouse:wheel']) {
      editor.canvas.on('mouse:wheel', function (opt) {
        var delta = opt.e.deltaY
        var zoom = editor.canvas.getZoom()
        zoom *= 0.999 ** delta
        if (zoom > 20) zoom = 20
        if (zoom < 0.01) zoom = 0.01
        editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
        opt.e.preventDefault()
        opt.e.stopPropagation()
      })
    }

    if (!editor.canvas.__eventListeners['mouse:down']) {
      editor.canvas.on('mouse:down', function (opt) {
        var evt = opt.e
        if (evt.ctrlKey === true) {
          this.isDragging = true
          this.selection = false
          this.lastPosX = evt.clientX
          this.lastPosY = evt.clientY
        }
      })
    }

    if (!editor.canvas.__eventListeners['mouse:move']) {
      editor.canvas.on('mouse:move', function (opt) {
        if (this.isDragging) {
          var e = opt.e
          var vpt = this.viewportTransform
          vpt[4] += e.clientX - this.lastPosX
          vpt[5] += e.clientY - this.lastPosY
          this.requestRenderAll()
          this.lastPosX = e.clientX
          this.lastPosY = e.clientY
        }
      })
    }

    if (!editor.canvas.__eventListeners['mouse:up']) {
      editor.canvas.on('mouse:up', function (opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform)
        this.isDragging = false
        this.selection = true
      })
    }

    editor.canvas.renderAll()
  }, [editor])
  useEffect(() => {
    if (!editor || !fabric) {
      return
    }
    //editor.canvas.setHeight(500)
    //editor.canvas.setWidth(500)
    addBackground(plan)
    editor.canvas.renderAll()
  }, [editor?.canvas.backgroundImage, plan])
  useEffect(() => {
    //fromSvg()
    if (editor) {
      editor.canvas.clear()
      afficherAnnotationsDepuisBaseDeDonnees(listAnnotation)
    }
  }, [listAnnotation])
  useEffect(()=>{
    setListAnnotation(data)
  },[])
  return (
    <>
      <div
        className="container-fluid"
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          height: '100%',
          width: '100%',
          backgroundColor: '#F8FAFC',
          zIndex: '2222',
          padding: '10px',
        }}>
        <div className="d-flex flex-column">
          <Menu bool={true} active="rapport" />
          <Header bool={true} />
        </div>
        <div className="page-wrapper">
          <div className="page-content-tab">
            <div className="container-fluid">
              <div className="page-title-box">
                <div className="float-end"></div>
                <span style={{ marginLeft: '10px' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#94A3B8"
                    style={{ width: '16px', height: '16px' }}>
                    <path
                      fillRule="evenodd"
                      d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                      clipRule="evenodd"
                    />
                  </svg>{' '}
                  <span
                    style={{
                      color: '#94A3B8',
                      fontSize: '14px',
                      fontWeight: '400',
                    }}>
                    / Rapports / Rapport d'avancement
                  </span>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    {' '}
                    / Annotation
                  </span>
                </span>{' '}
                <br />
                <span
                  style={{
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <span
                    style={{
                      backgroundColor: '#E2E8F0',
                      padding: '2px',
                      borderRadius: '6px',
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#6366F1"
                      style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={(ev) => {
                        setAnnotation(false)
                      }}>
                      <path
                        fillRule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '20px',
                      fontWeight: '600',
                      marginLeft: '10px',
                    }}>
                    {' '}
                    Annotation
                  </span>
                  <div
                    class="form-floating"
                    style={{ width: '200px', marginLeft: '1vw' }}></div>
                </span>
              </div>
              <div className="container-fluid">
                <div
                  className="container-fluid"
                  style={{
                    backgroundColor: '#F8FAFC',
                    borderBottom: 'solid #D9D9D9 1.5px',
                    marginBottom: '20px',
                  }}>
                  <div style={{ display: 'flex' }}>
                    <h4
                      className="page-title"
                      style={{
                        color: '#475569',
                        fontWeight: '600',
                        fontSize: '20px',
                        lineHeight: '24px',
                      }}>
                      {plan?.description ? plan.description : '---'}
                    </h4>
                  </div>
                </div>
                {/* <div
                  className="container-fluid"
                  style={{ display: 'flex', justifyContent: 'end',alignItems:'center'}}>
                  <button
                    className="btn"
                    style={{
                      backgroundColor: '#2DD4BF',
                      color: '#F8FAFC',
                      padding: '2px 2px',
                    }}
                    onClick={(ev) => {
                      canvasRef.current.undo()
                    }}>
                    <i
                      className="ti ti-corner-up-left"
                      style={{ fontSize: '32px' }}></i>
                  </button>
                  <input
                    className="form-control"
                    type="number"
                    style={{ marginLeft: '20px', width: '100px' }}
                    min={1}
                    max={15}
                    value={brush}
                    onChange={(ev) => {
                      ev.preventDefault()
                      if (ev.target.value > 0 && ev.target.value < 16) {
                        setBrush(ev.target.value)
                      }
                    }}
                  />

                  <input
                    className="form-control"
                    type="color"
                    style={{
                      marginLeft: '20px',
                      //marginTop: '10px',
                      width: '50px',
                    }}
                    value={color}
                    onChange={(ev) => {
                      ev.preventDefault()
                      setColor(ev.target.value)
                    }}
                  />
                  <button
                    className="btn"
                    style={{
                      backgroundColor: '#4F46E5',
                      color: '#F8FAFC',
                      marginLeft: '24px',
                    }}
                    onClick={(ev) => {
                      ev.preventDefault()
                      setWait(true)
                      const canvasData = canvasRef.current.getSaveData()
                      const data = cell.getData()
                      data.draws = JSON.parse(canvasData)
                      console.log(
                        'Image avec les annotations (base64) :',
                        canvasData
                      )
                      updateData(
                        data,
                        'avancements',
                        infoUser.token,
                        infoChantier.planning
                      )
                        .then((res) => {
                          console.log(res)
                          addToast('Enregistrement avec succès.', {
                            appearance: 'success',
                            autoDismiss: true,
                          })
                        })
                        .catch((err) => {
                          console.log(err)
                          addToast("Une erreur s'est produit", {
                            appearance: 'error',
                            autoDismiss: true,
                          })
                        })
                        .finally((res) => {
                          setWait(false)
                        })
                    }}>
                    Enregistrer
                  </button>
                </div> */}
                {/* <div
                  className="container-fluid"
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '70%',
                    marginTop: '20px',
                    borderTop: 'solid gray 1px',
                    borderBottom: 'solid gray 1px',
                    overflow: 'auto',
                    textAlign: 'center',
                  }}>
                  <CanvasDraw
                    ref={canvasRef}
                    className='cible'
                    saveData={JSON.stringify(data)}
                    brushColor={color}
                    canvasWidth={plan.width}
                    canvasHeight={plan.height}
                    brushRadius={brush}
                    lazyRadius={2}
                    onChange={(ev) => {}}
                    enablePanAndZoom={true}
                    mouseZoomFactor={0.01}
                    imgSrc={`${process.env.REACT_APP_URL}/api/v1/ressources/plan/?planName=${plan.nomMedia}&accessToken=${infoUser.token}&planning=${infoChantier.planning}`}
                    style={{margin:'auto',cursor:'none'}}
                  />
                </div> */}
                <div className="container-fluid">
                  <div
                    className="container-fluid"
                    style={{
                      display: 'flex',
                      marginBottom: '12px',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}>
                    <button
                      data-bs-toggle="dropdown"
                      className="btn"
                      style={{
                        backgroundColor: '#2DD4BF',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        padding: '5px 5px',
                      }}
                      disabled={
                        !cropImage == false && !isDessiner == false
                          ? false
                          : true
                      }>
                      + Formes
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1">
                      <li onClick={onAddCircle}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Cercle
                        </a>
                      </li>
                      <li onClick={onAddLigne}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Ligne
                        </a>
                      </li>
                      <li onClick={onAddRectangle}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Rectangle
                        </a>
                      </li>
                    </ul>
                    <button
                      onClick={addText}
                      className="btn"
                      style={{
                        backgroundColor: '#2DD4BF',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        padding: '5px 5px',
                      }}
                      disabled={
                        !cropImage == false && !isDessiner == false
                          ? false
                          : true
                      }>
                      Texte
                    </button>
                    <button
                      onClick={(ev) => {
                        ev.preventDefault()
                        setIsDessiner(false)
                        toggleDraw(true)
                      }}
                      className="btn"
                      style={{
                        backgroundColor: '#2DD4BF',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        padding: '5px 5px',
                      }}
                      disabled={!cropImage}>
                      <i
                        className="ti ti-brush"
                        style={{ fontSize: '16px' }}></i>
                    </button>
                    <button
                      onClick={undo}
                      className="btn"
                      style={{
                        backgroundColor: '#2DD4BF',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        padding: '5px 5px',
                      }}
                      disabled={!cropImage}>
                      <i
                        className="ti ti-corner-up-left"
                        style={{ fontSize: '16px' }}></i>
                    </button>
                    <button
                      onClick={(ev) => {
                        ev.preventDefault()
                        setCropImage(true)
                        setIsDessiner(true)
                        toggleDraw(false)
                      }}
                      className="btn"
                      style={{
                        backgroundColor: '#2DD4BF',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        padding: '5px 5px',
                      }}>
                      <i
                        className="ti ti-hand-finger"
                        style={{ fontSize: '16px' }}></i>
                    </button>
                    <button
                      onClick={(ev) => {
                        ev.preventDefault()
                        setCropImage(!cropImage)
                      }}
                      className="btn"
                      style={{
                        backgroundColor: '#2DD4BF',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        padding: '5px 5px',
                      }}
                      disabled={!isDessiner == false ? false : true}>
                      ZOOM
                    </button>
                    <button
                      onClick={removeSelectedObject}
                      className="btn"
                      style={{
                        backgroundColor: 'red',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        padding: '5px 5px',
                      }}
                      disabled={!cropImage}>
                      Supprimer
                    </button>
                    <input
                      className="form-control"
                      type="number"
                      style={{ marginLeft: '20px', width: '100px' }}
                      min={5}
                      max={30}
                      value={brush}
                      onChange={(ev) => {
                        ev.preventDefault()
                        if (ev.target.value > 0 && ev.target.value < 31) {
                          setBrush(ev.target.value)
                        }
                      }}
                      disabled={!cropImage}
                    />
                    <input
                      className="form-control"
                      type="color"
                      style={{
                        marginLeft: '20px',
                        width: '50px',
                      }}
                      value={color}
                      onChange={(ev) => {
                        ev.preventDefault()
                        setColor(ev.target.value)
                      }}
                      disabled={!cropImage}
                    />
                    <button
                      className="btn"
                      style={{
                        backgroundColor: '#4F46E5',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        padding: '5px 5px',
                      }}
                      onClick={(ev) => {
                        ev.preventDefault()
                        const dataShape = editor?.canvas.getObjects()
                        ev.preventDefault()
                        setWait(true)
                        const data = cell.getData()
                        data.shapes = dataShape
                        cell.getRow().update(data)
                        updateData(
                          data,
                          'avancements',
                          infoUser.token,
                          infoChantier.planning
                        )
                          .then((res) => {
                            console.log(res)
                            addToast('Enregistrement avec succès.', {
                              appearance: 'success',
                              autoDismiss: true,
                            })
                          })
                          .catch((err) => {
                            console.log(err)
                            addToast("Une erreur s'est produit", {
                              appearance: 'error',
                              autoDismiss: true,
                            })
                          })
                          .finally((res) => {
                            setWait(false)
                          })
                      }}>
                      Enregistrer
                    </button>
                  </div>
                  <div
                    style={{
                      border: `1px ${!cropImage ? 'dotted' : 'solid'} black`,
                      width: '100%',
                      height: '800px',
                      margin: 'auto',
                      cursor:'pointer'
                    }}>
                    <FabricJSCanvas
                      className="sample-canvas"
                      onReady={onReady}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnnotationPlan
