import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch,useSelector } from 'react-redux';
const AddArticle = ({ id,article}) => {
  const dispatch = useDispatch()
  const bool = useSelector((state)=>state.bool.bool)
  const [bools,setBools] = useState(true)
  const [tache, setTache] = useState([]);
  const [tacheArticle,setTacheArticle] = useState([])
  const [newTache,setNewTache] =useState([])
  const infoChantiers = localStorage.getItem('infoChantiers');
  const infoChantier = JSON.parse(infoChantiers);
  const  infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/taches/simple?planning=${infoChantier.planning}&fille=true&sort=id,text&insideArticle=false`,{
          headers: {
            Authorization: `Bearer ${infoUser.token}`
          },
        }
      )
      .then((res) => {
        setTache(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [bool,bools]);
  const saveArticle = () => {
     axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches`,{article:article._id,taches:newTache},{
      headers: {
        Authorization: `Bearer ${infoUser.token}`
      },
    }).then((e)=>{
         dispatch({type:"bool/modifier",payload:!bool})
         setBools(!bools)
         setNewTache([])
     }).catch((e)=>{
         dispatch({type:"bool/modifier",payload:!bool})
         setNewTache([])
     })
  }
  return (
    <div
      class="modal fade modal-dialog-scrollable"
      id={id}
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Ajouter des taches dans l'article "{article.titre}" 
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={(e)=>{
            e.preventDefault()
            e.target.reset()
            saveArticle()
          }}>
            <div class="modal-body">
              <h5> <u>Selectionner les nouveaux taches</u> </h5>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Taches</th>
                    <th scope="col">Assigner</th>
                  </tr>
                </thead>
                <tbody>
                 {
                    tache.map((e)=>{
                        return (
                            <>
                            <tr>
                                <td>{e.id}</td>
                                <td>{e.text}</td>
                                <td> <input type="checkbox" onChange={(event)=>{
                                    if(event.target.checked){
                                        newTache.push(e)
                                    }else{
                                        setNewTache(newTache.filter((element)=> element.id != e.id))
                                    }
                                }}/></td>
                            </tr>
                            </>
                        )
                    })
                 }
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-dark" data-bs-dismiss="modal">
                Fermer
              </button>
              <button type="submit" class="btn btn-success" data-bs-dismiss="modal" >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddArticle;
