import React, { useState, useEffect } from 'react'
import { getAllData, updateData } from '../../../../helpers/suivi/suivi'
import { ReactTabulator } from 'react-tabulator'
import { Button, Upload } from 'antd'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'
import '../tabulatorVisible.scss'
import { searchOptimizedData } from '../../../../helpers/recherche/recherche'
const InformationGeneral = ({
  administratif,
  setAdministratif,
  generalite,
  setGeneralite,
  intervenant,
  isSuiviOp,
  setWait,
  id,
}) => {
  const { addToast } = useToasts()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [item, setItem] = useState('infoGenerale')
  const [bool, setBool] = useState(false)
  const [bool1, setBool1] = useState(false)
  const [dataLot, setDataLot] = useState([])
  const [dataItemperie, setDataIntemperie] = useState([])
  const [dataModalAction, setDataModalAction] = useState({})
  const [imageAction, setImageAction] = useState([])
  const [dataMaj, setDataMaj] = useState()
  const [intervenants, setIntervenants] = useState(
    intervenant ? intervenant : []
  )
  //Modal document
  const modalAction = (value) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-download')
    //create delete button
    const plusIcon = document.createElement('i')
    plusIcon.type = 'icon'
    plusIcon.classList.add('ti')
    plusIcon.classList.add('ti-plus')
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAction')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px;margin-right:10px'
    modalBt.addEventListener('click', function () {
      setDataModalAction(cell.getData())
      setImageAction([])
      setDataMaj(cell)
    })
    var modalListImage = document.createElement('button')
    modalListImage.type = 'button'
    modalListImage.setAttribute('data-bs-toggle', 'modal')
    modalListImage.setAttribute('data-bs-target', '#myModalListImage')
    modalListImage.classList.add('btn')
    modalListImage.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalListImage.appendChild(plusIcon)
    modalListImage.addEventListener('click', function () {
      setDataModalAction(cell.getData())
      setImageAction([])
      setDataMaj(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.style =
      'display:flex;align-items:center'
    buttonHolder.appendChild(modalBt)
    buttonHolder.appendChild(modalListImage)
    return buttonHolder
  }

  //format date colonne
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  //champ text modifiable
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  //champ text disabled
  const simpleInputDisabled = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.disabled = true
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  //champ select
  const selectInput = (cell) => {
    const data = cell.getData()
    const ul = document.createElement('ul')
    ul.className = 'dropdown-menu'
    ul.style = 'background-color:white;z-index:9999'
    if (intervenants && intervenants.length > 0) {
      intervenants.map((el) => {
        if (el.role != 'ent') {
          const li = document.createElement('li')
          li.className = 'dropdown-item'
          li.innerText = `${el.name ? el.name : ''} ${
            el.lastname ? el.lastname : ''
          } (${el.roleName ? el.roleName : 'Simple utillisateur'})`
          li.addEventListener('click', function (e) {
            e.preventDefault()
            data.MOAemisPar = { _id: el._id }
            updateData(
              data,
              'lotsInfoGenerale',
              infoUser.token,
              infoChantier.planning
            )
              .then((res) => {
                setBool(!bool)
              })
              .catch((err) => {
                console.log(err)
                setBool(!bool)
              })
          })
          ul.appendChild(li)
        }
      })
    }
    const btn = document.createElement('button')
    btn.className = 'btn'
    btn.type = 'button'
    btn.style =
      'background-color:white;width:200px;display:flex;justify-content:space-between'
    btn.addEventListener('click', function (e) {
      e.preventDefault()
    })
    btn.setAttribute('data-bs-toggle', 'dropdown')
    const span = document.createElement('span')
    span.innerText = `${
      data.MOAemisPar?._id ? data.MOAemisPar?.name && data.MOAemisPar.name : ''
    } ${
      data.MOAemisPar?._id
        ? data.MOAemisPar?.lastname && data.MOAemisPar.lastname
        : 'Non défini'
    }`
    const icon = document.createElement('i')
    icon.className = 'ti ti-selector'
    btn.appendChild(span)
    btn.appendChild(icon)
    const container = document.createElement('div')
    container.className = 'dropdown dropstart'
    container.style = 'position:relative;z-index:999'
    container.appendChild(btn)
    container.appendChild(ul)
    return container
  }
  //champ select
  const selectInputIntemprerie = (cell) => {
    const data = cell.getData()
    const ul = document.createElement('ul')
    ul.className = 'dropdown-menu'
    ul.style = 'background-color:white;z-index:9999'
    if (intervenants && intervenants.length > 0) {
      intervenants.map((el) => {
        const li = document.createElement('li')
        li.className = 'dropdown-item'
        li.innerText = `${el.name ? el.name : ''} ${
          el.lastname ? el.lastname : ''
        } (${el.roleName})`
        li.addEventListener('click', function (e) {
          e.preventDefault()
          data.demandePar = { _id: el._id }
          updateData(
            data,
            'suiviIntemperies',
            infoUser.token,
            infoChantier.planning
          )
            .then((res) => {
              setBool1(!bool1)
            })
            .catch((err) => {
              console.log(err)
              setBool1(!bool1)
            })
        })
        ul.appendChild(li)
      })
    }
    const btn = document.createElement('button')
    btn.className = 'btn'
    btn.type = 'button'
    btn.style =
      'background-color:white;width:200px;display:flex;justify-content:space-between'
    btn.addEventListener('click', function (e) {
      e.preventDefault()
    })
    btn.setAttribute('data-bs-toggle', 'dropdown')
    const span = document.createElement('span')
    span.innerText = `${
      data.demandePar?._id ? data.demandePar?.name && data.demandePar.name : ''
    } ${
      data.demandePar?._id
        ? data.demandePar?.lastname && data.demandePar.lastname
        : 'Non défini'
    }`
    const icon = document.createElement('i')
    icon.className = 'ti ti-selector'
    btn.appendChild(span)
    btn.appendChild(icon)
    const container = document.createElement('div')
    container.className = 'dropdown dropend'
    container.style = 'position:relative;z-index:99999'
    container.appendChild(btn)
    container.appendChild(ul)
    return container
  }
  //format toggle switch
  const checkbox = (cell) => {
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch  mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }

  //modification Marche
  const marcheEdited = async (cell) => {
    console.log(cell.getData())
    const data = cell.getData()
    updateData(data, 'lotsInfoGenerale', infoUser.token, infoChantier.planning)
      .then((res) => {
        //setBool(!bool)
      })
      .catch((err) => {
        console.log(err)
        setBool(!bool)
      })
  }

  const inspectionEdited = async (cell) => {
    const data = cell.getData()
    updateData(data, 'lotsInfoGenerale', infoUser.token, infoChantier.planning)
      .then((res) => {
        //setBool(!bool)
      })
      .catch((err) => {
        console.log(err)
        setBool(!bool)
      })
  }
  const intemprerieEdited = async (cell) => {
    const data = cell.getData()
    updateData(data, 'suiviIntemperies', infoUser.token, infoChantier.planning)
      .then((res) => {
        //setBool1(!bool1)
      })
      .catch((err) => {
        console.log(err)
        setBool1(!bool1)
      })
  }
  //Colonne Marche / OS / Avenant
  const colonneMarche = [
    {
      title: 'Lot',
      field: 'titre',
      width: 500,
      formatter: simpleInputDisabled,
    },
    {
      title: 'Date prévisionnel',
      field: 'MOADatePrevisionnel',
      formatter: date,
    },
    { title: "Date d'éxécution", field: 'MOADateExecution', formatter: date },
    { title: 'Emis par', field: 'MOAemisPar', formatter: selectInput },
  ]
  //Colonne Inspection
  const colonneInspection = [
    {
      title: 'Lot',
      field: 'titre',
      width: 500,
      formatter: simpleInputDisabled,
    },
    {
      title: 'Date prévisionnel',
      field: 'ICDatePrevisionnel',
      formatter: date,
    },
    { title: "Date d'éxécution", field: 'ICDateExecution', formatter: date },
  ]
  //Colonne Marche / OS / Avenant
  const colonneIntemperie = [
    {
      title: 'Demandé par',
      field: '',
      width: 250,
      resizable: false,
      formatter: selectInputIntemprerie,
    },
    {
      title: 'Ouvrage impactés',
      width: 250,
      field: 'ouvrageImpacte',
      resizable: false,
      formatter: simpleInput,
    },
    { title: 'Date', field: 'date', formatter: date, resizable: false },
    {
      title: 'Evénements',
      field: 'evenement',
      formatter: simpleInput,
      resizable: false,
    },
    {
      title: 'Justificatif météo fourni',
      formatter: modalAction('action'),
      resizable: false,
    },
    {
      title: 'Accord MOE / OPC',
      width: 100,
      field: 'accordMOEOuOpc',
      resizable: false,
      formatter: checkbox,
    },
    {
      title: 'Nombre de jours',
      field: 'nombreJours',
      formatter: simpleInput,
      resizable: false,
    },
  ]
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}&fields=ICDatePrevisionnel,MOADateExecution,MOADatePrevisionnel,MOAemisPar,titre,_id,ICDateExecution`,
      'lots/simple',
      infoUser.token
    ).then((e) => {
      if (e.data) {
        setDataLot(e.data)
      }
    })
  }, [bool])
  useEffect(() => {
    setIntervenants(intervenant)
  }, [intervenant])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}`,
      'suiviIntemperies',
      infoUser.token
    )
      .then((res) => {
        setDataIntemperie(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [bool1])
  return (
    <>
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <nav>
            <div class="wrapper" style={{ marginTop: '20px' }}>
              <div
                className="item"
                onClick={(e) => {
                  e.preventDefault()
                  setItem('infoGenerale')
                }}
                style={{
                  borderLeft: '1px solid #CBD5E1',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                }}>
                <span
                  style={
                    item == 'infoGenerale'
                      ? {
                          color: '#F8FAFC',
                          fontSize: '16px',
                          fontWeight: '400',
                          backgroundColor: '#6366F1',
                          padding: '8px 24px',
                          borderRadius: '8px',
                          marginLeft: '5px',
                        }
                      : {
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }
                  }>
                  Information générale
                </span>
              </div>
              <div
                className="item"
                onClick={(e) => {
                  e.preventDefault()
                  setItem('Marche')
                }}>
                <span
                  style={
                    item == 'Marche'
                      ? {
                          color: '#F8FAFC',
                          fontSize: '16px',
                          fontWeight: '400',
                          backgroundColor: '#6366F1',
                          padding: '8px 24px',
                          borderRadius: '8px',
                          marginRight: '15px',
                        }
                      : {
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }
                  }>
                  Marché / OS / Avenants
                </span>
              </div>
              <div
                className="item"
                onClick={(e) => {
                  e.preventDefault()
                  setItem('inspection')
                }}>
                <span
                  style={
                    item == 'inspection'
                      ? {
                          color: '#F8FAFC',
                          fontSize: '16px',
                          fontWeight: '400',
                          backgroundColor: '#6366F1',
                          padding: '8px 24px',
                          borderRadius: '8px',
                          marginRight: '15px',
                        }
                      : {
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }
                  }>
                  Inspections communes
                </span>
              </div>
              <div
                className="item"
                onClick={(e) => {
                  e.preventDefault()
                  setItem('intemperie')
                }}
                style={{
                  borderRight: '1px solid #CBD5E1',
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                }}>
                <span
                  style={
                    item == 'intemperie'
                      ? {
                          color: '#F8FAFC',
                          fontSize: '16px',
                          fontWeight: '400',
                          backgroundColor: '#6366F1',
                          padding: '8px 24px',
                          borderRadius: '8px',
                          marginRight: '15px',
                        }
                      : {
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }
                  }>
                  Suivi des intempéries
                </span>
              </div>
            </div>
          </nav>
        </div>
        <div style={{ width: '100%' }}>
          {item == 'infoGenerale' && (
            <>
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  marginTop: '22px',
                  padding: '32px 32px',
                }}>
                <div className="row">
                  <div className="mb-3">
                    <label style={{ fontSize: '16px', fontWeight: '600' }}>
                      {' '}
                      Administratifs
                    </label>
                    <textarea
                      class="form-control"
                      rows="8"
                      id="message"
                      defaultValue={administratif}
                      onChange={(e) => {
                        setAdministratif(e.target.value)
                      }}></textarea>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3">
                    <label style={{ fontSize: '16px', fontWeight: '600' }}>
                      {' '}
                      Généralités
                    </label>
                    <textarea
                      class="form-control"
                      rows="8"
                      id="message"
                      defaultValue={generalite}
                      onChange={(e) => {
                        setGeneralite(e.target.value)
                      }}></textarea>
                  </div>
                </div>
                {isSuiviOp && (
                  <>
                    <div
                      className="container-fluid"
                      style={{ display: 'flex', justifyContent: 'end' }}>
                      <button
                        className="btn"
                        style={{
                          color: 'white',
                          backgroundColor: '#4F46E5',
                          borderRadius: '8px',
                        }}
                        onClick={(ev) => {
                          ev.preventDefault()
                          axios
                            .patch(
                              `${process.env.REACT_APP_URL}/api/v1/crDocs/${id}?planning=${infoChantier.planning}`,
                              {
                                administratif: administratif,
                                generalite: generalite,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${infoUser.token}`,
                                },
                              }
                            )
                            .then((res) => {
                              addToast('Enregistrement réussi.', {
                                appearance: 'success',
                                autoDismiss: true,
                              })
                            })
                            .catch((err) => {
                              console.log(err)
                              addToast("Une erreur s'est produit.", {
                                appearance: 'error',
                                autoDismiss: true,
                              })
                            })
                        }}>
                        Enregistrer
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {item == 'Marche' && (
            <>
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  padding: '16px 0px 24px',
                  marginTop: '22px',
                }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 0px 0px 40px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}>
                      Marché / OS / Avenants
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        padding: '8px 12px 8px 12px',
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        borderRadius: '8px',
                        height: '40px',
                        marginRight: '20px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#818CF8"
                          style={{
                            width: '18px',
                            height: '18px',
                          }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        placeholder="Recherche"
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        onChange={(ev) => {
                          ev.preventDefault()
                          ev.stopPropagation()
                          const newData = searchOptimizedData(
                            ev.target.value,
                            dataLot
                          )
                          setDataLot(newData)
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div
                  className="cibleTabulatorCr"
                  style={{
                    width: '100%',
                    borderTop: 'solid #E2E8F0 1px',
                    marginTop: '16px',
                  }}>
                  <ReactTabulator
                    data={dataLot ? dataLot : []}
                    columns={colonneMarche}
                    layout={'fitColumns'}
                    events={{
                      cellEdited: marcheEdited,
                    }}
                    options={{
                      locale: true,
                      pagination: 'local',
                      paginationSize: 5,
                      langs: {
                        fr: {
                          pagination: {
                            first: 'Premier',
                            first_title: 'Première',
                            last: 'Dernier',
                            last_title: 'Dernière',
                            prev: 'Précédent',
                            prev_title: 'Précédente',
                            next: 'Suivant',
                            next_title: 'Page suivante',
                            page_size: 'Taille de page',
                            page_title: 'Afficher la page',
                            all: 'Tous',
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {item == 'inspection' && (
            <>
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  padding: '16px 0px 24px',
                  marginTop: '22px',
                }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 0px 0px 40px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}>
                      Inspections communes
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        padding: '8px 12px 8px 12px',
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        borderRadius: '8px',
                        height: '40px',
                        marginRight: '20px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#818CF8"
                          style={{
                            width: '18px',
                            height: '18px',
                          }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        placeholder="Recherche"
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        onChange={(ev) => {
                          ev.preventDefault()
                          ev.stopPropagation()
                          const newData = searchOptimizedData(
                            ev.target.value,
                            dataLot
                          )
                          setDataLot(newData)
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid #E2E8F0 1px',
                    marginTop: '16px',
                  }}>
                  <ReactTabulator
                    data={dataLot ? dataLot : []}
                    columns={colonneInspection}
                    layout={'fitColumns'}
                    events={{
                      cellEdited: inspectionEdited,
                    }}
                    options={{
                      locale: true,
                      pagination: 'local',
                      paginationSize: 5,
                      langs: {
                        fr: {
                          pagination: {
                            first: 'Premier',
                            first_title: 'Première',
                            last: 'Dernier',
                            last_title: 'Dernière',
                            prev: 'Précédent',
                            prev_title: 'Précédente',
                            next: 'Suivant',
                            next_title: 'Page suivante',
                            page_size: 'Taille de page',
                            page_title: 'Afficher la page',
                            all: 'Tous',
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {item == 'intemperie' && (
            <>
              <div
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  padding: '16px 0px 24px',
                  marginTop: '22px',
                }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 0px 0px 40px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}>
                      Suivi des intempéries
                    </span>
                    {/* <span
                      style={{
                        backgroundColor: '#E0E7FF',
                        borderRadius: '24px',
                        padding: '6px 12px',
                        color: '#64748B',
                        fontSize: '14px',
                        fontWeight: '600',
                        marginLeft: '8px',
                        maxHeight: '32px',
                        width: 'auto',
                      }}>
                      0 tâches
                    </span> */}
                  </div>
                  <div>
                    <span
                      style={{
                        padding: '8px 12px 8px 12px',
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        borderRadius: '8px',
                        height: '40px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#818CF8"
                          style={{
                            width: '18px',
                            height: '18px',
                          }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        placeholder="Recherche"
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        onChange={(ev) => {
                          ev.preventDefault()
                          ev.stopPropagation()
                          const newData = searchOptimizedData(
                            ev.target.value,
                            dataItemperie
                          )
                          setDataIntemperie(newData)
                        }}
                      />
                    </span>
                    <button
                      className="btn"
                      style={{
                        backgroundColor: '#4F46E5',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                      }}
                      onClick={(ev) => {
                        ev.preventDefault()
                        axios
                          .post(
                            `${process.env.REACT_APP_URL}/api/v1/suiviIntemperies?planning=${infoChantier.planning}`,
                            {
                              ouvrageImpacte: '',
                              nombreJours: 0,
                              evenement: '',
                              planning: infoChantier.planning,
                              accordMOEOuOpc: false,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${infoUser.token}`,
                              },
                            }
                          )
                          .then((res) => {
                            setBool1(!bool1)
                          })
                          .catch((err) => {
                            console.log(err)
                            setBool1(!bool1)
                          })
                      }}>
                      + Nouvelle tâche
                    </button>
                  </div>
                </div>
                <div
                  className="cibleTabulatorCrIntemperie"
                  style={{
                    width: '100%',
                    borderTop: 'solid #E2E8F0 1px',
                    marginTop: '16px',
                  }}>
                  <ReactTabulator
                    data={dataItemperie ? dataItemperie : []}
                    columns={colonneIntemperie}
                    layout={'fitColumns'}
                    events={{
                      cellEdited: intemprerieEdited,
                    }}
                    options={{
                      locale: true,
                      pagination: 'local',
                      paginationSize: 5,
                      langs: {
                        fr: {
                          pagination: {
                            first: 'Premier',
                            first_title: 'Première',
                            last: 'Dernier',
                            last_title: 'Dernière',
                            prev: 'Précédent',
                            prev_title: 'Précédente',
                            next: 'Suivant',
                            next_title: 'Page suivante',
                            page_size: 'Taille de page',
                            page_title: 'Afficher la page',
                            all: 'Tous',
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Modal Action */}
      <div
        class="modal fade"
        id="myModalAction"
        style={{ position: 'absolute',left:'-5%' }}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Importer des images ou PDF
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form onSubmit={(e) => {}}>
              {' '}
              <div class="modal-body">
                {dataModalAction?.justificationMeteo?.length < 4 ? (
                  <>
                    <div class="mb-3 mt-3">
                      <label for="comment">
                        (4 fichier max, Taille 10Mo max,
                        type:".png,.jpeg,.jpg,.pdf"):
                      </label>
                      <Upload.Dragger
                        multiple={true}
                        maxCount={4}
                        beforeUpload={(files) => {
                          return false
                        }}
                        showUploadList={{ showRemoveIcon: true }}
                        accept=".png,.jpeg,.jpg"
                        listType="picture"
                        onChange={(ev) => {
                          setImageAction(ev.fileList)
                        }}>
                        <Button>Parcourir Fichier</Button>
                      </Upload.Dragger>
                    </div>
                  </>
                ) : (
                  <>
                    <h4
                      class="modal-title"
                      style={{ color: '#334155', textAlign: 'center' }}>
                      Vous avez atteint la limite des photos à importer (4
                      images)
                    </h4>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    addToast('Enregistrement en cours.', {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    const formData = new FormData()
                    imageAction.map((item) => {
                      formData.append('item', item.originFileObj)
                    })
                    axios
                      .patch(
                        `${process.env.REACT_APP_URL}/api/v1/suiviIntemperies/${dataModalAction._id}?planning=${infoChantier.planning}`,
                        formData,
                        {
                          headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${infoUser.token}`,
                          },
                        }
                      )
                      .then((res) => {
                        if(res.data.data){
                          dataMaj.getRow().update(res.data.data)
                          addToast('Enregistrement avec succès.', {
                            appearance: 'success',
                            autoDismiss: true,
                          })
                        }else{
                          addToast("Une erreur s'est produit.", {
                            appearance: 'error',
                            autoDismiss: true,
                          })
                        }
                       
                      })
                      .catch((err) => {
                        console.log(err)
                        addToast("Une erreur s'est produit.", {
                          appearance: 'error',
                          autoDismiss: true,
                        })
                      })
                  }}
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                  disabled={
                    dataModalAction?.justificationMeteo?.length < 4
                      ? false
                      : true
                  }>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal" id="myModalListImage" style={{ position: 'absolute',left:'-5%' }}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h5 class="modal-title" style={{ color: '#334155' }}>
                Suivi des intempéries
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
              }}>
              <div class="modal-body">
                <div className="mb-3">
                  <label htmlFor="">Justification:</label>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalAction?.justificationMeteo?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalAction.justificationMeteo.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const config = {
                                      headers: {
                                        Authorization: `Bearer ${infoUser.token}`,
                                      },
                                    }
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image =
                                      dataModalAction.justificationMeteo
                                    axios
                                      .patch(
                                        `${process.env.REACT_APP_URL}/api/v1/suiviIntemperies/${dataModalAction._id}?planning=${infoChantier.planning}`,
                                        {
                                          justificationMeteo: image.filter(
                                            (img) => img._id != el._id
                                          ),
                                        },
                                        config
                                      )
                                      .then((res) => {
                                        if(res.data){
                                          dataMaj.getRow().update(res.data)
                                          addToast('Enregistrement avec succès.', {
                                            appearance: 'success',
                                            autoDismiss: true,
                                          })
                                        }else{
                                          addToast("Une erreur s'est produit.", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                      .catch((err) => {
                                        console.log(err)
                                        addToast("Une erreur s'est produit.", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default InformationGeneral
