import React from 'react'
import { ReactTabulator } from 'react-tabulator'
import { updateFavori } from '../../../../helpers/planning/favori/favori'
import Modal from './Modal'
const Favori2 = ({ allTask, allLot,bool,setBool }) => {
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)

  const checkbox = (cell) => {
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      updateFavori(
        false,
        cell.getData()._id,
        infoUser.token,
        infoChantier.planning
      ).then((res) => {
        console.log(res)
        setBool(!bool)
      })
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch  mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.disabled =true
    view.style = 'background-color:rgba(226, 232, 240, 0.4)'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.style='background-color:white'
    container.appendChild(view)
    return container
  }
  const modal = (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', `#favori${cell.getData()._id}`)
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.disabled = true
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;overflow:visible'
    input.defaultValue = cell.getValue()
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  const progress = (cell) => {
    const data = cell.getData()
    const div = document.createElement('span')
    div.style = `position:absolute;left:0;top:0;bottom:0;background-color:#4F46E5;width:${
      data.progress ? data.progress * 100 : 0
    }%;border-radius:20px;text-align:center;color:white`
    div.innerText = `${data.progress ? (data.progress * 100).toFixed(0) : 0}%`
    const avancement = document.createElement('div')
    avancement.style =
      'width:100%;position:relative;height:17px;border-radius:20px;background-color:#C7D2FE'
    avancement.appendChild(div)
    const view = document.createElement('div')
    view.style = ' padding:10px 0px'
    view.appendChild(avancement)
    return view
  }
  const colonneFavori = [
    { title: 'J.C.', width: 100, field: 'isCritical', formatter: checkbox },
    { title: 'Tâches', field: 'text',formatter:simpleInput },
    { title: 'Date début', field: 'start_date', formatter: date },
    { title: 'Date fin', field: 'end_date', formatter: date },
    { title: 'Avancement', formatter: progress },
    { title: 'Details', width: 100, formatter: modal },
  ]

  return (
    <>
      <div
        style={{
          width: '100%',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          boxShadow:
            '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
          padding: '16px 4px 24px',
          marginTop: '22px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '12px 12px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: '#334155',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              Jalons Critiques
            </span>
            <span
              style={{
                backgroundColor: '#E0E7FF',
                borderRadius: '24px',
                padding: '6px 12px',
                color: '#64748B',
                fontSize: '14px',
                fontWeight: '600',
                marginLeft: '8px',
                maxHeight: '200px',
                width: 'auto',
              }}
            >
              {allTask.length > 0 ? allTask.length : '0'} Jalons critiques
            </span>
          </div>
          <div></div>
        </div>
        <div
          style={{
            width: '100%',
            borderTop: 'solid #E2E8F0 1px',
            marginTop: '16px',
          }}
        >
          <ReactTabulator
            data={allTask ? allTask : []}
            columns={colonneFavori}
            layout={'fitColumns'}
            options={{
              locale: true,
              pagination: 'local',
              paginationSize: 5,
              langs: {
                fr: {
                  pagination: {
                    first: 'Premier',
                    first_title: 'Première',
                    last: 'Dernier',
                    last_title: 'Dernière',
                    prev: 'Précédent',
                    prev_title: 'Précédente',
                    next: 'Suivant',
                    next_title: 'Page suivante',
                    page_size: 'Taille de page',
                    page_title: 'Afficher la page',
                    all: 'Tous',
                  },
                },
              },
            }}
          />
        </div>
      </div>
      {allTask &&
        allTask.map((e) => {
          if (e.isCritical) {
            return (
              <>
                <Modal
                  id={`favori${e._id}`}
                  data={e}
                  lot={allLot}
                  curentLot={e.lot}
                />
              </>
            )
          }
        })}
    </>
  )
}

export default Favori2
