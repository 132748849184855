let currentPopover = null
const showPopover = (e, text, scheduler) => {
  if (currentPopover) {
    // Masquez le popover existant
    document.body.removeChild(currentPopover)
    currentPopover = null
  }
  const span = document.createElement('span')
  span.innerHTML = text
  // Créez un élément div pour le popover
  var popover = document.createElement('div')
  var eventBox = e.target.getBoundingClientRect()
  popover.style =
    'padding:5px 10px;background-color:#fff;display:flex;align-items:center;justify-content:center;border-radius:8px'
  // Positionnez le popover à côté de la souris
  popover.style.position = 'fixed'
  popover.style.left = eventBox.x + 'px'
  popover.style.top = eventBox.y + 33 + 'px'
  popover.appendChild(span)
  // Ajoutez le popover à la page
  document.body.appendChild(popover)

  // Mettez à jour la variable pour suivre l'état actuel du popover
  currentPopover = popover

  // Supprimez le popover après un certain délai (par exemple, 3 secondes)
  setTimeout(function () {
    // Vérifiez si le popover actuel est toujours le même
    if (currentPopover === popover) {
      document.body.removeChild(popover)
      currentPopover = null
    }
  }, 1000)
}
export const initScheduler = (scheduler, id, data, lot, intervenants, zone) => {
  scheduler.clearAll()
  scheduler.resetLightbox()
  scheduler.config.details_on_dblclick = true
  scheduler.createTimelineView({
    name: 'timeline',
    fit_events: true,
    first_hour: 8,
    last_hour: 17,
    x_unit: 'day', // measuring unit of the X-Axis.
    x_date: '%D %j', // date format of the X-Axis
    x_step: 1, // X-Axis step in 'x_unit's
    x_size: 21, // X-Axis length specified as the total number of 'x_step's
    x_start: 0, // X-Axis offset in 'x_unit's
    x_length: 2, // number of 'x_step's that will be scrolled at a time
    y_unit: zone, // sections of the view (titles of Y-Axis),
    y_property: 'room_id', // mapped data property
    second_scale: {
      x_unit: 'week',
      x_date: '%d %F',
    },
    render: 'bar',
    resize: true,
    drag_mode: 'section', // view mode
  })

  scheduler.locale = {
    date: {
      month_full: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      month_short: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aoû',
        'Sep',
        'Oct',
        'Nov',
        'Déc',
      ],
      day_full: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ],
      day_short: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    },
    labels: {
      new_event: 'Nouvelle tâche',
      icon_save: 'Enregistrer',
      icon_cancel: 'Annuler',
      icon_details: 'Détails',
      icon_edit: 'Modifier',
      icon_delete: 'Effacer',
      confirm_closing: '', //Your changes will be lost, are your sure ?
      confirm_deleting:
        'La tâche sera effacée définitivement, êtes-vous sûr(e) ?',
      section_description: 'Description',
      section_time: 'Période',
      section_type: 'Type',
    },
  }
  scheduler.attachEvent('onMouseMove', function (id, e) {
    var event = scheduler.getEvent(id)
    if (event) {
      console.log('1')
      var description = event.text // Récupérez la description de l'événement
      if (description) {
        showPopover(e, description, scheduler) // Affichez le popover à côté de la tâche survolée
      }
    }
  })
  //scheduler.config.mark_now = true;
  scheduler.config.auto_end_date = true
  scheduler.locale.labels.timeline_tab = 'Glissant'
  scheduler.locale.labels.month_tab = 'Mois'
  scheduler.locale.labels.today_tab = "Aujourd'hui"
  scheduler.config.multisection = true
  scheduler.ignore_month = function (date) {
    if (date.getDay() == 0)
      //hides Saturdays and Sundays
      return true
  }
  scheduler.ignore_timeline = function (date) {
    if (date.getDay() == 0)
      //hides Saturdays and Sundays
      return true
  }

  scheduler.addMarkedTimespan({
    days: 6,
    zones: 'fullday',
    css: 'timeline_weekend',
  })
  scheduler.form_blocks['my_editor'] = {
    render: function (sns) {
      return `<button  type="button" class="btn" data-bs-toggle="modal" data-bs-target="#myModalAssigne"><svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      style="width:22px;height:22px">
      <path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" />
      <path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" />
    </svg>Assigner à des taches</button>`
    },
    set_value: function (node, value, ev) {
    },
    get_value: function (node, ev) {
      return 1
    },
    focus: function (node) {
      var input = node.querySelector("[name='text']")
      input.select()
      input.focus()
    },
  }
  scheduler.config.lightbox.sections = [
    {
      name: 'description',
      height: 150,
      map_to: 'text',
      type: 'textarea',
      focus: true,
    },
    { name: 'Période', height: 40, map_to: 'auto', type: 'calendar_time' },
    {
      name: 'Lots / <br>intervenants ',
      height: 40,
      map_to: 'lot',
      type: 'select',
      options: [
        {
          key: 0,
          label: '------------------------     LOTS    --------------------',
          disabled: true,
        },
        ...lot,
        {
          key: 0,
          label: '--------------   INTERVENANTS   -------------',
          disabled: true,
        },
        ...intervenants,
      ],
    },
    {
      name: 'Zone',
      height: 90,
      map_to: 'room_id',
      type: 'multiselect',
      options: zone,
    },
    { name: '', type: 'my_editor' },
  ]
  scheduler.templates.event_class = function (start, end, ev) {
    for (var i = 0; i < lot.length; i++) {
      if (lot[i].key === ev.lot) {
        ev.color = lot[i].color
      }
    }
  }
  scheduler.templates.lightbox_header = function (start, end, ev) {
    return scheduler.templates.event_bar_text(ev.start_date, ev.end_date, ev)
  }
  scheduler.templates.month_day = function (date) {
    var today = new Date()
    if (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    ) {
      return "<div class='dateNow'>" + date.getDate() + '</div>'
    } else {
      return '<div>' + date.getDate() + '</div>'
    }
  }
  scheduler.init(id, new Date(), 'timeline')
  scheduler.parse(data)
}
export const dhtmlxGantt = (gantt, id, data) => {
  gantt.clearAll()
  /* gantt.templates.link_class = function (link) {
    if (!gantt.config.show_links) {
      return 'hideLink'
    }
    return ''
  } */
  gantt.locale = {
    date: {
      month_full: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      month_short: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aoû',
        'Sep',
        'Oct',
        'Nov',
        'Déc',
      ],
      day_full: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ],
      day_short: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    },
    labels: {
      new_task: 'Nouvelle tâche',
      icon_save: 'Enregistrer',
      icon_cancel: 'Annuler',
      icon_details: 'Détails',
      icon_edit: 'Modifier',
      icon_delete: 'Effacer',
      confirm_closing: '', //Your changes will be lost, are your sure ?
      confirm_deleting:
        'La tâche sera effacée définitivement, êtes-vous sûr(e) ?',
      section_description: 'Description',
      section_time: 'Période',
      section_type: 'Type',
    },
  }

  //gantt.config.auto_scheduling = true;
  //gantt.config.auto_scheduling_strict = true;

  gantt.templates.task_text = function (start, end, task) {
    return (+task.progress * 100).toFixed(2) + '%'
  }

  //largeeur collonne gauche
  //gantt.config.grid_width =700 ;

  gantt.templates.rightside_text = function (start, end, task) {
    if (task.type == gantt.config.types.milestone) {
      return task.text
    }
    if (task.planned_end) {
      if (end.getTime() > task.planned_end.getTime()) {
        var overdue = Math.ceil(
          Math.abs(
            (end.getTime() - task.planned_end.getTime()) / (24 * 60 * 60 * 1000)
          )
        )
        var text =
          "<div class='text-danger ov1' > <span class='ov'>Retard: " +
          overdue +
          ' jour(s)</span></div>'
        return text
      }
    }
    return ''
  }
  gantt.templates.task_class = function (start, end, task) {
    if (task.planned_end) {
      var classes = ['has-baseline']
      if (end.getTime() > task.planned_end.getTime()) {
        classes.push('retard')
      }
      return classes.join(' ')
    }
  }

  //colonne gauche
  var formatter = gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'day',
    format: 'auto',
  })
  var linksFormatter = gantt.ext.formatters.linkFormatter({
    durationFormatter: formatter,
  })
  const editors = {
    text: { type: 'text', map_to: 'text' },
    end_date: { type: 'date', map_to: 'end_date' },
    start_date: { type: 'date', map_to: 'start_date' },
    duration: { type: 'number', map_to: 'duration' },
    predecessors: { type: 'predecessor', map_to: 'auto' },
    progress: {
      type: 'number',
      map_to: 'progress',
      min: 0,
      max: 1,
      step: 0.05,
    },
  }
  const dateFr = (value) => {
    var date = new Date(value)
    var day = date.getDate().toString().padStart(2, '0')
    var month = (date.getMonth() + 1).toString().padStart(2, '0')
    var year = date.getFullYear()
    var formattedDate = day + '/' + month + '/' + year
    return formattedDate
  }
  gantt.config.columns = [
    { name: 'id', label: 'ID', width: 80, tree: true, resize: true },
    {
      name: 'text',
      label: 'Nom',
      width: 100,
      editor: editors.text,
      resize: true,
    },
    {
      name: 'start_date',
      label: 'date debut',
      width: 120,
      editor: editors.start_date,
      width: 100,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" value="${dateFr(
          task.start_date
        )}"/>`
      },
    },
    {
      name: 'end_date',
      label: 'date fin',
      width: 100,
      editor: editors.end_date,
      width: 100,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" value="${dateFr(
          task.end_date
        )}"/>`
      },
    },
    {
      name: 'duration',
      label: 'Durée',
      width: 70,
      align: 'center',
      editor: editors.duration,
      resize: true,
    },
    {
      name: 'progress',
      label: 'Avancement',
      align: 'center',
      width: 150,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" min="0" max="1" step="0.05" value="${
          task.progress ? `${(task.progress * 100).toFixed(0)}%` : 0
        }" style="background-color:white;border:none" disabled/>`
      },
    },
    {
      name: 'predecessors',
      label: 'Prédécesseurs',
      align: 'center',
      editor: editors.predecessors,
      resize: true,
      template: function (task) {
        var links = task.$target
        var labels = []
        for (var i = 0; i < links.length; i++) {
          var link = gantt.getLink(links[i])
          labels.push(linksFormatter.format(link))
        }
        return labels.join(',')
      },
    },
    {
      name: 'checked',
      label: 'J.C.',
      align: 'center',
      template: function (task) {
        var checked = task.isCritical ? ' checked' : ''
        return (
          "<input class='gantt-checkbox-column' type='checkbox' name='test' id='test' value='1' " +
          checked +
          '/>'
        )
      },
    },
    { name: 'add', label: '+' },
    //{
    //   name:"Supprimer",label:"Suppr",align:"center",template:function(task){
    //     return`<button id='suppr' class='btn btn-outline-danger' onclick='gantt.deleteTask(${task.id})'><i class='ti ti-trash'></i></button>`
    //   }
    // },
  ]
  function deleteTask(id) {
    gantt.deleteTask(id)
  }
  var opts = [
    { key: 'task', label: 'Tâche' },
    { key: 'milestone', label: 'Jalon' },
  ]
  gantt.config.lightbox.sections = [
    {
      name: 'description',
      height: 70,
      map_to: 'text',
      type: 'textarea',
      focus: true,
    },
    { name: 'type', height: 50, type: 'select', map_to: 'type', options: opts },
    { name: 'time', type: 'duration', map_to: 'auto' },
  ]

  gantt.init(id)
  gantt.parse(data.data)
}
export const dhtmlxGantt02 = (gantt, id, data) => {
  gantt.clearAll()

  /* gantt.templates.link_class = function (link) {
    if (!gantt.config.show_links) {
      return 'hideLink'
    }
    return ''
  } */
  gantt.locale = {
    date: {
      month_full: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      month_short: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aoû',
        'Sep',
        'Oct',
        'Nov',
        'Déc',
      ],
      day_full: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ],
      day_short: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    },
    labels: {
      new_task: 'Nouvelle tâche',
      icon_save: 'Enregistrer',
      icon_cancel: 'Annuler',
      icon_details: 'Détails',
      icon_edit: 'Modifier',
      icon_delete: 'Effacer',
      confirm_closing: '', //Your changes will be lost, are your sure ?
      confirm_deleting:
        'La tâche sera effacée définitivement, êtes-vous sûr(e) ?',
      section_description: 'Description',
      section_time: 'Période',
      section_type: 'Type',
    },
  }

  //gantt.config.auto_scheduling = true;
  //gantt.config.auto_scheduling_strict = true;

  gantt.templates.task_text = function (start, end, task) {
    return (+task.progress * 100).toFixed(2) + '%'
  }

  //largeeur collonne gauche
  //gantt.config.grid_width =700 ;

  gantt.templates.rightside_text = function (start, end, task) {
    if (task.type == gantt.config.types.milestone) {
      return task.text
    }
    if (task.planned_end) {
      if (end.getTime() > task.planned_end.getTime()) {
        var overdue = Math.ceil(
          Math.abs(
            (end.getTime() - task.planned_end.getTime()) / (24 * 60 * 60 * 1000)
          )
        )
        var text =
          "<div class='text-danger ov1' > <span class='ov'>Retard: " +
          overdue +
          ' jour(s)</span></div>'
        return text
      }
    }
    return ''
  }
  gantt.templates.task_class = function (start, end, task) {
    if (task.planned_end) {
      var classes = ['has-baseline']
      if (end.getTime() > task.planned_end.getTime()) {
        classes.push('retard')
      }
      return classes.join(' ')
    }
  }

  //colonne gauche
  var formatter = gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'day',
    format: 'auto',
  })
  var linksFormatter = gantt.ext.formatters.linkFormatter({
    durationFormatter: formatter,
  })
  const editors = {
    text: { type: 'text', map_to: 'text' },
    end_date: { type: 'date', map_to: 'end_date' },
    start_date: { type: 'date', map_to: 'start_date' },
    duration: { type: 'number', map_to: 'duration' },
    predecessors: { type: 'predecessor', map_to: 'auto' },
    progress: {
      type: 'number',
      map_to: 'progress',
      min: 0,
      max: 1,
      step: 0.05,
    },
  }
  const dateFr = (value) => {
    var date = new Date(value)
    var day = date.getDate().toString().padStart(2, '0')
    var month = (date.getMonth() + 1).toString().padStart(2, '0')
    var year = date.getFullYear()
    var formattedDate = day + '/' + month + '/' + year
    return formattedDate
  }
  gantt.config.columns = [
    { name: 'id', label: 'ID', width: 80, tree: true, resize: true },
    {
      name: 'text',
      label: 'Nom',
      width: 100,
      editor: editors.text,
      resize: true,
    },
    {
      name: 'start_date',
      label: 'date debut',
      width: 120,
      editor: editors.start_date,
      width: 100,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" value="${dateFr(
          task.start_date
        )}"/>`
      },
    },
    {
      name: 'end_date',
      label: 'date fin',
      width: 100,
      editor: editors.end_date,
      width: 100,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" value="${dateFr(
          task.end_date
        )}"/>`
      },
    },
    {
      name: 'duration',
      label: 'Durée',
      width: 70,
      align: 'center',
      editor: editors.duration,
      resize: true,
    },
    /* {
      name: 'progress',
      label: 'Avancement',
      align: 'center',
      width: 150,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" min="0" max="1" step="0.05" value="${
          task.progress ? `${(task.progress * 100).toFixed(0)}%` : 0
        }" style="background-color:white;border:none" disabled/>`
      },
    },
     {
        name: 'predecessors',
        label: 'Prédécesseurs',
        align: 'center',
        editor: editors.predecessors,
        resize: true,
        template: function(task){
          var links = task.$target;
          var labels = [];
          for(var i = 0; i < links.length; i++){
              var link = gantt.getLink(links[i]);
              labels.push(linksFormatter.format(link)); 
          }
          return labels.join(",")
      }
      },
    {
      name: 'checked',
      label: 'J.C.',
      align: 'center',
      template: function (task) {
        var checked = task.isCritical ? ' checked' : ''
        return (
          "<input class='gantt-checkbox-column' type='checkbox' name='test' id='test' value='1' " +
          checked +
          '/>'
        )
      },
    },
    { name: 'add', label: '+' }, */
    //{
    //   name:"Supprimer",label:"Suppr",align:"center",template:function(task){
    //     return`<button id='suppr' class='btn btn-outline-danger' onclick='gantt.deleteTask(${task.id})'><i class='ti ti-trash'></i></button>`
    //   }
    // },
  ]
  function deleteTask(id) {
    gantt.deleteTask(id)
  }
  gantt.init(id)
  gantt.parse(data.data)
}
export const pointages = (gantt, id, data) => {
  //largeeur collonne gauche
  gantt.config.grid_width = 600
  //format date
  gantt.config.date_format = '%Y-%m-%d'
  //colonne gauche
  var formatter = gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'day',
    format: 'auto',
  })
  var linksFormatter = gantt.ext.formatters.linkFormatter({
    durationFormatter: formatter,
  })
  const editors = {
    text: { type: 'text', map_to: 'text' },
    end_date: { type: 'date', map_to: 'end_date' },
    start_date: { type: 'date', map_to: 'start_date' },
    duration: { type: 'number', map_to: 'duration' },
    predecessors: { type: 'predecessor', map_to: 'auto' },
  }
  const dateFr = (value) => {
    var date = new Date(value)
    var day = date.getDate().toString().padStart(2, '0')
    var month = (date.getMonth() + 1).toString().padStart(2, '0')
    var year = date.getFullYear()
    var formattedDate = day + '/' + month + '/' + year
    return formattedDate
  }
  gantt.config.columns = [
    { name: 'id', label: 'ID', width: 170, tree: true, resize: true },
    {
      name: 'text',
      label: 'Nom',
      width: 170,
      editor: editors.text,
      resize: true,
    },
    {
      name: 'start_date',
      label: 'date debut',
      editor: editors.start_date,
      width: 150,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" value="${dateFr(
          task.start_date
        )}"/>`
      },
    },
    {
      name: 'end_date',
      label: 'date fin',
      editor: editors.end_date,
      width: 150,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" value="${dateFr(
          task.end_date
        )}"/>`
      },
    },
    {
      name: 'duration',
      label: 'Durée',
      align: 'center',
      editor: editors.duration,
      resize: true,
    },
    {
      name: 'predecessors',
      label: 'Prédécesseurs',
      align: 'center',
      editor: editors.predecessors,
      resize: true,
      template: function (task) {
        var links = task.$target
        var labels = []
        for (var i = 0; i < links.length; i++) {
          var link = gantt.getLink(links[i])
          labels.push(linksFormatter.format(link))
        }
        return labels.join(', ')
      },
    },
  ]
  gantt.init(id)
  gantt.parse(data.data)
}

export const lastPlanner = (gantt, id, data) => {
  gantt.clearAll()
  gantt.locale = {
    date: {
      month_full: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      month_short: [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Aoû',
        'Sep',
        'Oct',
        'Nov',
        'Déc',
      ],
      day_full: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ],
      day_short: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    },
    labels: {
      new_task: 'Nouvelle tâche',
      icon_save: 'Enregistrer',
      icon_cancel: 'Annuler',
      icon_details: 'Détails',
      icon_edit: 'Modifier',
      icon_delete: 'Effacer',
      confirm_closing: '', //Your changes will be lost, are your sure ?
      confirm_deleting:
        'La tâche sera effacée définitivement, êtes-vous sûr(e) ?',
      section_description: 'Description',
      section_time: 'Période',
      section_type: 'Type',
    },
  }
  gantt.config.open_split_tasks = true
  //largeeur collonne gauche
  gantt.config.grid_width = 600

  //format date
  gantt.config.date_format = '%Y-%m-%d'

  //colonne gauche
  const editors = {
    text: { type: 'text', map_to: 'text' },
    end_date: { type: 'date', map_to: 'end_date' },
    start_date: { type: 'date', map_to: 'start_date' },
    duration: { type: 'number', map_to: 'duration' },
  }

  gantt.config.columns = [
    { name: 'text', label: 'Nom', tree: true, resize: true },
    {
      name: 'start_date',
      label: 'date debut réel',
      editor: editors.start_date,
      width: 150,
      resize: true,
    },
    {
      name: 'end_date',
      label: 'date fin réel',
      editor: editors.end_date,
      width: 150,
      resize: true,
    },
    {
      name: 'duration',
      label: 'Durée réel',
      align: 'center',
      editor: editors.duration,
      resize: true,
    },
    { name: 'add', label: '' },
  ]
  gantt.init(id)
  gantt.parse({ data: data, links: [] })
  //gantt.parse(data.data)
}

export const listColumns = (gantt) => {
  //colonne gauche
  var formatter = gantt.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'day',
    format: 'auto',
  })
  var linksFormatter = gantt.ext.formatters.linkFormatter({
    durationFormatter: formatter,
  })

  const editors = {
    text: { type: 'text', map_to: 'text' },
    end_date: { type: 'date', map_to: 'end_date' },
    start_date: { type: 'date', map_to: 'start_date' },
    duration: { type: 'number', map_to: 'duration' },
    predecessors: { type: 'predecessor', map_to: 'auto' },
  }
  const dateFr = (value) => {
    var date = new Date(value)
    var day = date.getDate().toString().padStart(2, '0')
    var month = (date.getMonth() + 1).toString().padStart(2, '0')
    var year = date.getFullYear()
    var formattedDate = day + '/' + month + '/' + year
    return formattedDate
  }
  gantt.config.columns = [
    { name: 'id', label: 'ID', width: 80, tree: true, resize: true },
    {
      name: 'text',
      label: 'Nom',
      width: 100,
      editor: editors.text,
      resize: true,
    },
    {
      name: 'start_date',
      label: 'date debut réel',
      width: 120,
      editor: editors.start_date,
      width: 100,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" value="${dateFr(
          task.start_date
        )}"/>`
      },
    },
    {
      name: 'end_date',
      label: 'date fin réel',
      width: 100,
      editor: editors.end_date,
      width: 100,
      resize: true,
      template: function (task) {
        return `<input type="text" class="form-control" value="${dateFr(
          task.end_date
        )}"/>`
      },
    },
    {
      name: 'duration',
      label: 'Durée réel',
      width: 70,
      align: 'center',
      editor: editors.duration,
      resize: true,
    },
    {
      name: 'predecessors',
      label: 'Predecessors',
      align: 'center',
      editor: editors.predecessors,
      resize: true,
      template: function (task) {
        var links = task.$target
        var labels = []
        for (var i = 0; i < links.length; i++) {
          var link = gantt.getLink(links[i])
          labels.push(linksFormatter.format(link))
        }
        return labels.join(',')
      },
    },
    {
      name: 'checked',
      label: 'Favori',
      align: 'center',
      template: function (task) {
        var checked = task.isCritical ? ' checked' : ''
        return (
          "<input class='gantt-checkbox-column' type='checkbox' name='test' id='test' value='1' " +
          checked +
          '/>'
        )
      },
    },
    { name: 'add', label: '' },
    {
      name: 'Supprimer',
      label: '',
      template: function (task) {
        return `<button id='suppr' class='btn btn-outline-danger' onclick='gantt.deleteTask(${task.id})'><i class='ti ti-trash'></i></button>`
      },
    },
  ]
  gantt.render()
}

export const dhtmlxGanttPointage = (gantt, id, data) => {
  gantt.templates.task_text = function (start, end, task) {
    return (+task.progress * 100).toFixed(2) + '%'
  }
  gantt.config.show_progress = true
  gantt.config.grid_width = 600
  gantt.config.date_format = '%Y-%m-%d'
  gantt.config.columns = [
    { name: 'text', label: 'Nom', width: 250, tree: true, resize: true },
    {
      name: 'start_date',
      label: 'Date Debut Réel',
      align: 'center',
      resize: true,
    },
    {
      name: 'end_date',
      label: 'Date Fin Prévu',
      align: 'center',
      resize: true,
    },
  ]
  gantt.init(id)
  gantt.parse(data.data)
}
