import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import PageNotFound from '../../../components/404/PageNotFound'
import ImageMarker from 'react-image-marker'
import { GeoAltFill } from 'react-bootstrap-icons'
import PinOffLine from './component/PinOffLine'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { searchOptimizedData } from '../../../helpers/recherche/recherche'
import axios from 'axios'
const OffLineWithPlan = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [isOnLine, setIsOnLine] = useState(navigator.onLine)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }
  //affichage liste plan
  const [showListPlan, setShowListPlan] = useState(true)
  const [showOnePlan, setShowOnePlan] = useState(false)
  const [listPlan, setListPlan] = useState([])
  const [widthPlan, setWidthPlan] = useState(100)
  const [markers, setMarkers] = useState([])
  const [idPlanSelected, setIdPlanSelected] = useState('')
  const [idPlanSelectedIndexedDB, setIdPlanSelectedIndexedDB] = useState()
  const [planSelected, setPanSelected] = useState()
  const [nomPlanSelected, setNomPlanSelected] = useState('')
  const [imagePlan, setImagePlan] = useState()
  const [recherche, setRecherche] = useState('')
  const [itemType, setItemType] = useState(1)
  const [allType, setAllType] = useState([])
  const nombrePins = (pins = []) => {
    return pins.filter((el) => el.archive == false)
  }
  const [allLot, setAllLot] = useState(
    localStorage.getItem('allLot')
      ? JSON.parse(localStorage.getItem('allLot'))
      : []
  )
  const [bool, setBool] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    const dbName = 'myDataBase'
    const tableName = 'plans'
    const request = window.indexedDB.open(dbName)
    request.onsuccess = (event) => {
      const db = event.target.result
      const transaction = db.transaction([tableName], 'readwrite')
      const objectStore = transaction.objectStore(tableName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = (event) => {
        const data = event.target.result
        if (data && data.length > 0) {
          setListPlan(data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)))
          setIsLoading(false)
        }
        setIsLoading(false)
      }

      getAllRequest.onerror = (event) => {
        console.error(
          'Erreur lors de la récupération des données :',
          event.target.error
        )
        setIsLoading(false)
      }
      request.onerror = (event) => {
        console.error(
          "Erreur lors de l'ouverture de la base de données :",
          event.target.error
        )
        setIsLoading(false)
      }
    }
  }, [bool])
  useEffect(() => {
    if (listPlan && listPlan.length > 0) {
      listPlan.map((el) => {
        if (el.nomMedia == nomPlanSelected) {
          setIdPlanSelectedIndexedDB(el.id)
          setMarkers(el.pins)
          setAllType(el.pins)
          setIdPlanSelected(el._id)
          setShowOnePlan(true)
          setImagePlan(el.image)
        }
      })
    }
  }, [listPlan, nomPlanSelected])
  useEffect(() => {
    const handleOnline = () => {
      setIsOnLine(true)
      alert(
        'Vous êtes de retour en ligne ! Vous pouvez enregistrer vos modifications au serveur.'
      )
    }
    const handleOffline = () => {
      setIsOnLine(false)
      alert(
        'La connexion a été coupée ! Vos modifications seront stockées localement.'
      )
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={false} offline={true} />
        <Header bool={true} offline={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="container-fluid">
              <div
                class="page-title-box"
                style={{
                  padding: '16px 5px',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  margin: '16px 0px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  marginBottom: '15px',
                  marginTop: '20px',
                }}>
                <div
                  class="float-end"
                  style={{
                    padding: '10px 30px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <button
                    className="btn"
                    style={
                      isOnLine
                        ? {
                            color: '#0D9488',
                            backgroundColor: 'rgba(45, 212, 191, 0.15)',
                            display: 'flex',
                            padding: '1px 10px',
                            alignItems: 'center',
                          }
                        : {
                            color: '#FB7185',
                            backgroundColor: '#FFF1F2',
                            display: 'flex',
                            padding: '1px 10px',
                            alignItems: 'center',
                          }
                    }>
                    {isOnLine ? (
                      <i class="fa far fa-check-circle"></i>
                    ) : (
                      <i class="fa far fa-times-circle"></i>
                    )}
                    <span style={{ marginLeft: '3px', marginTop: '2px' }}>
                      {isOnLine ? 'Connecté' : 'Non connecté'}
                    </span>
                  </button>
                  <button
                    className="btn"
                    style={{
                      backgroundColor: '#4F46E5',
                      color: '#F8FAFC',
                      marginLeft: '12px',
                    }}
                    onClick={async (ev) => {
                      if (navigator.onLine) {
                        try {
                          setIsLoading(true)
                          const dbName = 'myDataBase'
                          const tableName = 'plans'
                          const request = window.indexedDB.open(dbName)

                          request.onsuccess = async (event) => {
                            const db = event.target.result
                            const transaction = db.transaction(
                              [tableName],
                              'readonly'
                            )
                            const objectStore =
                              transaction.objectStore(tableName)
                            const getAllRequest = objectStore.getAll()

                            getAllRequest.onsuccess = async (event) => {
                              const data = event.target.result
                              if (data && data.length > 0) {
                                for (const el of data) {
                                  if (el.pins && el.pins.length > 0) {
                                    for (const pin of el.pins) {
                                      if (pin.isModified) {
                                        try {
                                          await axios.patch(
                                            `${process.env.REACT_APP_URL}/api/v1/ressources/${el._id}?planning=${infoChantier.planning}`,
                                            {
                                              base64Files: JSON.stringify(
                                                pin.base64 ? [pin.base64] : []
                                              ),
                                              archive: pin.archive,
                                              top: pin.top,
                                              left: pin.left,
                                              description: pin.description,
                                              phase: pin.phase,
                                              itemNumber: pin.itemNumber,
                                              aLeverLe: pin.aLeverLe
                                                ? pin.aLeverLe
                                                : new Date(Date.now()),
                                              statut: pin.statut,
                                              tag: JSON.stringify(
                                                pin.tag ? pin.tag : []
                                              ),
                                              observation: pin.observation,
                                              position: pin.position,
                                              destinataire: JSON.stringify(
                                                pin.destinataire
                                                  ? pin.destinataire
                                                  : []
                                              ),
                                              avancementPrevu:
                                                pin.avancementPrevu,
                                              avancementActuel:
                                                pin.avancementActuel,
                                              uuid: pin.uuid,
                                              _id: pin._id,
                                              taches: JSON.stringify(
                                                pin.taches ? pin.taches : []
                                              ),
                                              photo: JSON.stringify(
                                                pin.photo ? pin.photo : []
                                              ),
                                              type: pin.type,
                                            },
                                            {
                                              headers: {
                                                Authorization: `Bearer ${infoUser.token}`,
                                              },
                                            }
                                          )
                                        } catch (e) {
                                          console.error(e)
                                        }
                                      }
                                    }
                                  }
                                }
                                navigate('/Observation_plan')
                              } else {
                                navigate('/Observation_plan')
                              }
                            }

                            getAllRequest.onerror = (event) => {
                              alert("Une erreur s'est produite")
                              console.error(
                                'Erreur lors de la récupération des données :',
                                event.target.error
                              )
                              setIsLoading(false)
                            }
                          }

                          request.onerror = (event) => {
                            alert("Une erreur s'est produite")
                            console.error(
                              "Erreur lors de l'ouverture de la base de données :",
                              event.target.error
                            )
                            setIsLoading(false)
                          }
                        } catch (err) {
                          console.log(err)
                          setIsLoading(false)
                        }
                      } else {
                        alert(
                          'Veuillez vous connecter pour enregistrer les données sur le serveur en ligne ! '
                        )
                      }
                    }}>
                    Enregistrer
                  </button>
                </div>
                <span style={{ marginLeft: '10px' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#94A3B8"
                    style={{ width: '16px', height: '16px' }}>
                    <path
                      fillRule="evenodd"
                      d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                      clipRule="evenodd"
                    />
                  </svg>{' '}
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    / Observations / Mode Tablette
                  </span>
                </span>{' '}
                <br />
                <span
                  style={{
                    marginLeft: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <span
                    style={{
                      backgroundColor: '#E2E8F0',
                      padding: '2px',
                      borderRadius: '6px',
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#6366F1"
                      style={{
                        width: '20px',
                        height: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        if (navigator.onLine) {
                          alert(
                            "Cliquez sur le bouton 'Enregistrer' pour quitter la page"
                          )
                        } else {
                          alert(
                            "Vous n'êtes pas connecté à Internet ! Veuillez vous connecter !"
                          )
                        }
                      }}>
                      <path
                        fillRule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '20px',
                      fontWeight: '600',
                      marginLeft: '10px',
                    }}>
                    {' '}
                    Mode Tablette
                  </span>
                </span>
              </div>
            </div>
            <div
              className="d-flex"
              style={{
                border: '1px solid #E2E8F0',
                boxShadow:
                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                backgroundColor: '#FFFFFF',
                borderRadius: '8px',
                position: 'relative',
              }}>
              {showOnePlan && (
                <>
                  <div
                    className="btn-group dropleft"
                    style={{
                      position: 'absolute',
                      top: '20px',
                      right: '32px',
                      zIndex: '999',
                      display: 'flex',
                    }}>
                    <span
                      data-bs-toggle="dropdown"
                      style={{
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #CBD5E1',
                        boxShadow:
                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                        borderRadius: '8px',
                        padding: '6px 8px',
                        height: '36px',
                        width: '40px',
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#E11D48"
                        style={{ width: '24px', height: '24px' }}>
                        <path
                          fillRule="evenodd"
                          d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1">
                      <li
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => {
                          e.preventDefault()
                          setItemType(1)
                          setMarkers(allType)
                        }}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Tous
                        </a>
                      </li>
                      <li
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => {
                          e.preventDefault()
                          setItemType(2)
                          setMarkers(allType.filter((el) => el.type == 1))
                        }}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Visites
                        </a>
                        <GeoAltFill color="#6366F1" size={24} />
                      </li>
                      <li
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => {
                          e.preventDefault()
                          setItemType(3)
                          setMarkers(allType.filter((el) => el.type == 2))
                        }}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Réserves
                        </a>
                        <GeoAltFill color="red" size={24} />
                      </li>
                      <li
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => {
                          e.preventDefault()
                          setItemType(4)
                          setMarkers(allType.filter((el) => el.type == 3))
                        }}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Réception de supports
                        </a>
                        <GeoAltFill color="#2DD4BF" size={24} />
                      </li>
                      <li
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => {
                          e.preventDefault()
                          setItemType(5)
                          setMarkers(allType.filter((el) => el.type == 4))
                        }}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Avancements
                        </a>
                        <GeoAltFill color="orange" size={24} />
                      </li>
                      <li
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => {
                          e.preventDefault()
                          setItemType(6)
                          setMarkers(allType.filter((el) => el.type == 5))
                        }}>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}>
                          Bons à fermer
                        </a>
                        <GeoAltFill color="#02A0FC" size={24} />
                      </li>
                    </ul>
                    <span
                      style={{
                        backgroundColor: '#F8FAFC',
                        border: '1px solid #CBD5E1',
                        boxShadow:
                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)',
                        borderRadius: '8px',
                        padding: '6px 12px',
                        height: '36px',
                        width: '77px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginLeft: '16px',
                        color: '#475569',
                        fontSize: '24px',
                        fontWeight: '600',
                      }}>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(ev) => {
                          ev.preventDefault()
                          if (widthPlan < 300) {
                            setWidthPlan(widthPlan + 50)
                          }
                        }}>
                        +
                      </span>
                      <span
                        style={{
                          fontSize: '16px',
                          color: '#CBD5E1',
                          fontWeight: '400',
                        }}>
                        |
                      </span>
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={(ev) => {
                          ev.preventDefault()
                          if (widthPlan > 50) {
                            setWidthPlan(widthPlan - 50)
                          }
                        }}>
                        -
                      </span>
                    </span>
                  </div>
                </>
              )}
              <div
                style={
                  showListPlan
                    ? {
                        width: '326px',
                        borderRight: '1px solid #CBD5E1',
                        padding: '12px 12px',
                        overflow: 'hidden',
                        position: 'relative',
                      }
                    : {
                        width: '20px',
                        borderRight: '1px solid #CBD5E1',
                        padding: '12px 12px',
                        overflow: 'hidden',
                        position: 'relative',
                      }
                }>
                {showListPlan ? (
                  <>
                    <form
                      id="champ"
                      onSubmit={(e) => {
                        e.preventDefault()
                        e.target.reset()
                      }}>
                      <div
                        style={{
                          backgroundColor: 'rgba(226, 232, 240, 0.6)',
                          padding: '8px 0px 10px 10px',
                          width: '90%',
                          borderRadius: '8px',
                          display: 'flex',
                        }}>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#64748B"
                            style={{ width: '24px', height: '24px' }}>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                          </svg>
                        </span>
                        <span>
                          <input
                            type="text"
                            id=""
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                              marginLeft: '10px',
                              height: '100%',
                            }}
                            placeholder="Recherche"
                            defaultValue={recherche}
                            onChange={(e) => {
                              setRecherche(recherche)
                              const newData = searchOptimizedData(
                                e.target.value,
                                listPlan
                              )
                              setListPlan(newData)
                            }}
                          />
                        </span>
                      </div>
                    </form>{' '}
                    <div
                      class="table-responsive"
                      style={{ maxHeight: '68vh', overflowY: 'scroll' }}>
                      <table class="table" id="myTable">
                        <tbody>
                          {listPlan.length > 0 && (
                            <>
                              {listPlan.map((e) => {
                                return (
                                  <>
                                    <tr
                                      style={{ cursor: 'pointer' }}
                                      onClick={(a) => {
                                        setNomPlanSelected(e.nomMedia)
                                      }}>
                                      <td
                                        style={{
                                          backgroundColor: 'white',
                                          border: 'none',
                                          borderRadius: '8px',
                                          padding: '5px 0px',
                                        }}>
                                        <div
                                          style={{
                                            border: '1px solid  #CBD5E1',
                                            borderRadius: '8px',
                                            padding: '10px 10px',
                                            backgroundColor: `${
                                              nomPlanSelected == e.nomMedia
                                                ? 'rgba(226, 232, 240, 0.6)'
                                                : 'white'
                                            }`,
                                          }}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              flexWrap: 'wrap',
                                            }}>
                                            <span>
                                              <a
                                                href="#"
                                                className="nav-item nav-link active activeLocal"
                                                style={{
                                                  color: `${
                                                    nomPlanSelected ==
                                                    e.nomMedia
                                                      ? '#1E293B'
                                                      : '#1E293B'
                                                  }`,
                                                  fontSize: '18px',
                                                  fontWeight: '600',
                                                }}>
                                                {e.description
                                                  ? e.description
                                                  : '---'}
                                              </a>
                                            </span>
                                            <span
                                              style={{
                                                padding: '4px 8px',
                                                backgroundColor: '#FFF1F2',
                                                borderRadius: '8px',
                                                color: '#E11D48',
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                              }}>
                                              {' '}
                                              <span>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 20 20"
                                                  fill=" #E11D48"
                                                  style={{
                                                    width: '20px',
                                                    height: '20px',
                                                  }}>
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </span>
                                              <span>
                                                {e.pins?.length > 0
                                                  ? nombrePins(e.pins).length
                                                  : 0}{' '}
                                                épingle(s)
                                              </span>
                                            </span>
                                          </div>
                                          <span
                                            style={{
                                              color: '#64748B',
                                              fontSize: '14px',
                                              fontWeight: '400',
                                              marginLeft: '8px',
                                            }}>
                                            Modifié il y a -- heures
                                          </span>
                                          <hr
                                            style={{
                                              width: '95%',
                                              margin: 'auto',
                                              marginTop: '8px',
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                style={{
                  width: `${showListPlan ? '90%' : '100%'}`,
                  overflow: 'auto',
                  position: 'relative',
                }}>
                {showListPlan ? (
                  <>
                    <button
                      style={{
                        backgroundColor: '#6366F1',
                        position: 'absolute',
                        left: '-5px',
                        top: '10px',
                        zIndex: '10',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '3px 3px',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setShowListPlan(false)
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="white"
                        style={{ width: '20px', height: '20px' }}>
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      style={{
                        backgroundColor: '#6366F1',
                        position: 'absolute',
                        left: '-5px',
                        top: '10px',
                        zIndex: '10',
                        border: 'none',
                        borderRadius: '4px',
                        padding: '3px 3px',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setShowListPlan(true)
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="white"
                        style={{ width: '20px', height: '20px' }}>
                        <path
                          fillRule="evenodd"
                          d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </>
                )}
                {showOnePlan ? (
                  <>
                    <div
                      className="container-fluid"
                      style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '72vh',
                        overflowX: 'auto',
                      }}>
                      <div
                        className="container-fluid planCible"
                        style={{ width: `${widthPlan}%` }}>
                        <ImageMarker
                          src={`${imagePlan}`}
                          markers={markers}
                          onAddMarker={(marker) =>
                            setMarkers([...markers, marker])
                          }
                          markerComponent={(e) => (
                            <PinOffLine
                              boolPlan={bool}
                              setBoolPlan={setBool}
                              props={e}
                              lot={allLot}
                              allPins={markers.filter(
                                (el) => el.itemNumber || el.itemNumber == 0
                              )}
                              setAllPins={setMarkers}
                              idPlan={idPlanSelected}
                              idPlanSelectedIndexedDB={idPlanSelectedIndexedDB}
                            />
                          )}
                        />
                      </div>
                    </div>{' '}
                  </>
                ) : (
                  <>
                    <h1
                      className="text-center text-danger"
                      style={{ marginTop: '12px' }}>
                      {' '}
                      <u>Sélectionner un plan</u>{' '}
                    </h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LoaderFixed />}
    </>
  )
}

export default OffLineWithPlan
