import axios from "axios";
export const updateFavori = async (data,id,token,planning) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    console.log(data);
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${id}?planning=${planning}`,{isCritical:data},config).then((e)=>{
            return e
        }).catch((e)=>{
            return e
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}
export const getFavori = async (planning,page,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/taches/simple?planning=${planning}&sort="responsableEngage"&isCritical=true&page=${page}&limit=10`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e
        })
        return result
    }catch(err){
        return {message:"erreur"}
    }
}
export const updateOnTask = async (cible,data,id,token,planning) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
        try{
        switch(cible){
            case "start_date":
                const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${id}?planning=${planning}`,{start_date:data.start_date,duration:data.duration},config).then((e)=>{
                    return e
                }).catch((e)=>{
                    return e
                })
                return result
            case "end_date":
                const results = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${id}?planning=${planning}`,{end_date:data.end_date,duration:data.duration},config).then((e)=>{
                    return e
                }).catch((e)=>{
                    return e
                })
                return results
            case "observation":
                    const resulta = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${id}?planning=${planning}`,{observation:data.observation},config).then((e)=>{
                        return e
                    }).catch((e)=>{
                        return e
                    })
                    return resulta
            case "lot":
                    const resultas = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/taches/${id}?planning=${planning}`,{lot:data.lot._id},config).then((e)=>{
                         return e
                    }).catch((e)=>{
                    return e
                   })
                    return resultas
            default : 
                return {data: []}
        }
    }catch(err){
        return {message:"erreur"}
    }
}