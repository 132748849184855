import React, { useEffect, useState } from 'react'
import Menu from '../../components/navBar/Menu'
import Header from '../../components/navBar/Header'
import photoUser from '../../assets/images/sooda_logo/default.png'
import { useToasts } from 'react-toast-notifications'
import {
  getOneUser,
  updateUserInfo,
  updateUserSociete,
  getChantierUser,
  updateChantierUser,
  updatePasswordUser,
} from '../../helpers/user/user'
import axios from 'axios'
import { Navigate, useNavigate } from 'react-router-dom'
import LoaderFixed from '../../components/loader/LoaderFixed'
//page profil utilisateur
const Profil = () => {
  const { addToast } = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  const [date, setDate] = useState()
  const [data, setData] = useState({})
  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [email, setEmail] = useState('')
  const [oldPassword, setOldPassword] = useState('') // Etat ancien mot de passe
  const [newPassword, setNewPassword] = useState('') // Etat nouveau mot de passe
  const [passwordConfirm, setPasswordConfirm] = useState('') // Etat passwordConfirm mot de passe
  const [tel, setTel] = useState('')
  const [societe, setSociete] = useState('')
  const [bool, setBool] = useState(false)
  const InfoUsers = localStorage.getItem('users')
  const users = JSON.parse(InfoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [listChantier, setListChantier] = useState([])
  const [item, setItem] = useState('edit')
  const [wait, setWait] = useState(false)
  useEffect(() => {
    setWait(true)
    getChantierUser(users?.user?._id, users.token,infoChantier.planning).then((e) => {
      if (e.data) {
        setListChantier(e.data)
      }
    })
  }, [bool])
  useEffect(() => {
    setWait(true)
    getOneUser(users?.user?._id, users.token,infoChantier.planning)
      .then((e) => {
        if (e.data) {
          setData(e.data)
          setPrenom(e.data?.lastname)
          setNom(e.data.name)
          setEmail(e.data.email)
          setTel(e.data.telephone)
          setSociete(e.data.entrepriseName)
          setWait(false)
        }
      })
      .catch((err) => {
        console.log(err)
      }).finally((res)=>{
        setWait(false)
      })
  }, [bool])
  //enregistrement des informations personnelles
  const savePerso = () => {
    setWait(true)
    updateUserInfo(users.user?._id, nom, prenom, email, tel, users.token).then(
      (e) => {
        setBool(!bool)
        setWait(false)
        if (e.name == 'AxiosError') {
          addToast(
            e.response?.data?.message
              ? e.response.data.message
              : "Une erreur s'est produit",
            {
              appearance: 'error',
              autoDismiss: true,
            }
          )
        }
      }
    )
  }

  //Mise à jour du mot de passe
  const savePassword = () => {
    setWait(true)
    if(newPassword && passwordConfirm && newPassword == passwordConfirm){
      let dataToSend = {password: newPassword, passwordConfirm: passwordConfirm, planning: infoChantier.planning}
      updatePasswordUser(dataToSend, users.token, users.user._id, infoChantier.planning).then(
        (e) => {
          setBool(!bool)
          setWait(false)
          if (e.name == 'AxiosError') {
            addToast(
              e.response?.data?.message
                ? e.response.data.message
                : "Une erreur s'est produit",
              {
                appearance: 'error',
                autoDismiss: true,
              }
            )
          }else{
            addToast(
              "Votre mot de passe est mis à jour.",
              {
                appearance: 'success',
                autoDismiss: true,
              }
            )

          }
        }
      ).catch((err)=>{
        console.log(err);
      })
    }else{
      alert('Les mots de passe saisis ne sont pas identiques.')
      setBool(!bool)
      setWait(false)
    }
  }
  const saveSociete = () => {
    setWait(true)
    updateUserSociete(users.user?._id, societe, users.token).then((e) => {
      setBool(!bool)
      setWait(false)
      if (e.name == 'AxiosError') {
        addToast(
          e.response?.data?.message
            ? e.response.data.message
            : "Une erreur s'est produit",
          {
            appearance: 'error',
            autoDismiss: true,
          }
        )
      }
    })
  }
  return (
    <>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={false} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="contaier-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate(-2)
                    }}
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}
                >
                  {' '}
                  Profil utilisateur
                </span>
              </span>
            </div>

            {/* debut profil 
            
            "@syncfusion/ej2-base": "^20.3.50",
            "@syncfusion/ej2-react-schedule": "^20.3.58",
            */}
            <div className="container-fluid" style={{ padding: '10px 20px' }}>
              <div className="row">
                <div
                  className="col-lg-3"
                  style={{
                    backgroundColor: 'white',
                    padding: '24px 16px ',
                    boxShadow:
                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                    borderRadius: '8px',
                    border: ' 1px solid #E2E8F0',
                    marginTop: '10px',
                  }}
                >
                  <span
                    style={
                      item == 'edit'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('edit')
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'edit' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}
                    >
                      Editer mon profil
                    </span>
                  </span>
                  {(users?.user?.role?.roleType == 'admin' ||
                    users?.user?.role.roleType == 'opc') && (
                    <>
                      <span
                        style={
                          item == 'reglage'
                            ? {
                                display: 'flex',
                                alignItems: 'center',
                                color: '#334155',
                                padding: '10px  10px 10px',
                                backgroundColor: 'rgba(226, 232, 240, 0.5)',
                                fontSize: '16px',
                                fontWeight: '600',
                                borderRadius: '6px',
                              }
                            : {
                                display: 'flex',
                                alignItems: 'center',
                                color: '#64748B',
                                padding: '10px  10px 10px',
                                fontSize: '16px',
                                fontWeight: '400',
                                cursor: 'pointer',
                              }
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          setItem('reglage')
                        }}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill={item == 'reglage' ? '#6366F1' : 'none'}
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#64748B"
                            style={{ width: '24px', height: '24px' }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </span>
                        <span
                          style={{
                            marginLeft: '12px',
                          }}
                        >
                          Réglage du compte
                        </span>
                      </span>
                    </>
                  )}

                  <span
                    style={
                      item == 'securite'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('securite')
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'securite' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}
                    >
                      Mot de passe & sécurité
                    </span>
                  </span>
                  <span
                    style={
                      item == 'notification'
                        ? {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#334155',
                            padding: '10px  10px 10px',
                            backgroundColor: 'rgba(226, 232, 240, 0.5)',
                            fontSize: '16px',
                            fontWeight: '600',
                            borderRadius: '6px',
                          }
                        : {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#64748B',
                            padding: '10px  10px 10px',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      setItem('notification')
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill={item == 'notification' ? '#6366F1' : 'none'}
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#64748B"
                        style={{ width: '24px', height: '24px' }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        marginLeft: '12px',
                      }}
                    >
                      Notifications
                    </span>
                  </span>
                </div>
                <div className="col-lg-9" style={{ marginTop: '10px' }}>
                  {item == 'edit' && (
                    <>
                      <div
                        className="container-fluid"
                        style={{
                          display: 'flex',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                          marginLeft: '10px',
                          padding: '20px 20px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>
                            {' '}
                            <div style={{width:'93px',height:'93px',borderRadius:'8px',overflow:'hidden',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'white'}}>
                              <img
                                src={
                                  users?.user?.photo
                                    ? `${process.env.REACT_APP_URL}/api/v1/users/photo/${users.user.photo}`
                                    : photoUser
                                }
                                alt=""
                                style={{
                                  width: 'auto',
                                  height: '100%',
                                }}
                              />
                            </div>{' '}
                          </span>
                          <span style={{ marginLeft: '10px' }}>
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: '500',
                                color: ' #334155',
                              }}
                            >
                              Rôle :{' '}
                              {users?.user?.role?.roleType
                                ? users?.user?.role?.roleType.toUpperCase()
                                : '---'}
                            </span>{' '}
                            <br />
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#6366F1',
                              }}
                            >
                              Entreprise :{' '}
                              {data?.entrepriseName
                                ? data.entrepriseName
                                : '---'}
                            </span>
                          </span>
                        </div>
                        <div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '0px',
                              alignItems: 'flex-start',
                              marginTop: '24px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="#818CF8"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                  }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: '#1E293B',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                  }}
                                >
                                  {data?.telephone ? data.telephone : '---'}
                                </span>{' '}
                                <br />
                                <span
                                  style={{
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}
                                >
                                  Téléphone
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '12px',
                              }}
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="#818CF8"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                  }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: '#1E293B',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                  }}
                                >
                                  {data?.email ? data.email : '---'}
                                </span>{' '}
                                <br />
                                <span
                                  style={{
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}
                                >
                                  Email
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="container-fluid"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                          marginLeft: '10px',
                          marginTop: '20px',
                          padding: '20px 20px',
                        }}
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            savePerso()
                          }}
                        >
                          <span
                            style={{
                              fontSize: '18px',
                              fontWeight: '600',
                              color: '#334155',
                            }}
                          >
                            Information personnelles
                          </span>
                          <div class="row" style={{ marginTop: '16px' }}>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                Nom{' '}
                              </span>
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="Nom"
                                onChange={(e) => {
                                  e.preventDefault()
                                  setNom(e.target.value)
                                }}
                                defaultValue={data?.name ? data.name : ''}
                                required
                              />
                            </div>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                Prénom
                              </span>
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="Prénom"
                                defaultValue={
                                  data?.lastname ? data.lastname : ''
                                }
                                onChange={(e) => {
                                  e.preventDefault()
                                  setPrenom(e.target.value)
                                }}
                                required
                              />
                            </div>
                          </div>
                          <div class="row" style={{ marginTop: '10px' }}>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                Email{' '}
                              </span>
                              <span
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  border: '1px solid #E2E8F0',
                                  borderRadius: '6px',
                                  backgroundColor: 'white',
                                  padding: '9px 5px',
                                }}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#94A3B8"
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: 'white',
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                                    />
                                  </svg>
                                </span>
                                <span style={{}}>
                                  <input
                                    type="text"
                                    style={{
                                      border: 'none',
                                      backgroundColor: 'transparent',
                                      marginLeft: '10px',
                                    }}
                                    placeholder="Email"
                                    defaultValue={data?.email ? data.email : ''}
                                    onChange={(e) => {
                                      e.preventDefault()
                                      setEmail(e.target.value)
                                    }}
                                    required
                                  />
                                </span>
                              </span>
                            </div>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                Téléphone{' '}
                              </span>{' '}
                              <br />
                              <span
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  border: '1px solid #E2E8F0',
                                  borderRadius: '6px',
                                  backgroundColor: 'white',
                                  padding: '9px 5px',
                                }}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#94A3B8"
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: 'white',
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                    />
                                  </svg>
                                </span>
                                <span style={{}}>
                                  <input
                                    type="text"
                                    style={{
                                      border: 'none',
                                      backgroundColor: 'transparent',
                                      marginLeft: '10px',
                                    }}
                                    placeholder="Téléphone"
                                    defaultValue={
                                      data?.telephone ? data.telephone : ''
                                    }
                                    onChange={(e) => {
                                      e.preventDefault()
                                      setTel(e.target.value)
                                    }}
                                    required
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: '10px' }}>
                            <div
                              className="col"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <button
                                className="btn"
                                style={{ color: '#64748B' }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setBool(!bool)
                                }}
                              >
                                Annuler
                              </button>
                              <button
                                className="btn"
                                type="submit"
                                style={{
                                  marginLeft: '5px',
                                  backgroundColor: '#4F46E5',
                                  color: 'white',
                                }}
                              >
                                Sauvegarder
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div
                        className="container-fluid"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                          marginLeft: '10px',
                          marginTop: '20px',
                          padding: '20px 20px',
                        }}
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            saveSociete()
                          }}
                        >
                          <span
                            style={{
                              fontSize: '18px',
                              fontWeight: '600',
                              color: '#334155',
                            }}
                          >
                            Informations entreprise
                          </span>
                          <div class="row" style={{ marginTop: '16px' }}>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                Nom société
                              </span>
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="Nom société"
                                defaultValue={
                                  data?.entrepriseName
                                    ? data.entrepriseName
                                    : ''
                                }
                                onChange={(e) => {
                                  e.preventDefault()
                                  setSociete(e.target.value)
                                }}
                                required
                              />
                            </div>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                SIRET
                              </span>
                              <input
                                type="text"
                                class="form-control form-control-lg"
                                placeholder="SIRET"
                                required
                              />
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: '10px' }}>
                            <div
                              className="col"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <button
                                className="btn"
                                style={{ color: '#64748B' }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setBool(!bool)
                                }}
                              >
                                Annuler
                              </button>
                              <button
                                className="btn"
                                type="submit"
                                style={{
                                  marginLeft: '5px',
                                  backgroundColor: '#4F46E5',
                                  color: 'white',
                                }}
                              >
                                Sauvegarder
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                  {item == 'reglage' && (
                    <>
                      <div
                        className="container-fluid"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                          marginLeft: '10px',
                          padding: '20px 20px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#334155',
                          }}
                        >
                          Envoi automatique
                        </span>{' '}
                        <br />
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px 0px 12px 16px',
                            backgroundColor: ' rgba(226, 232, 240, 0.6)',
                            borderRadius: '6px',
                            marginTop: '12px',
                          }}
                        >
                          <div className="form-check form-switch mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              defaultChecked={false}
                            />
                          </div>
                          <span
                            style={{ marginTop: '7px', marginLeft: '12px' }}
                          >
                            Activer tous les envois
                          </span>
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px 0px 12px 16px',
                          }}
                        >
                          <div className="form-check form-switch mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onChange={(e) => {
                                e.preventDefault()
                                axios
                                  .patch(
                                    `${process.env.REACT_APP_URL}/api/v1/users/updateMe`,
                                    {
                                      envoyerEmailRappelEtRelance:
                                        e.target.checked,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${users.token}`,
                                      },
                                    }
                                  )
                                  .then((el) => {
                                    setBool(!bool)
                                  })
                                  .catch((el) => {
                                    console.log(el)
                                  })
                              }}
                              checked={
                                data?.envoyerEmailRappelEtRelance
                                  ? data.envoyerEmailRappelEtRelance
                                  : false
                              }
                            />
                          </div>
                          <span
                            style={{ marginTop: '7px', marginLeft: '12px' }}
                          >
                            Envoi rappel + relance automatique
                          </span>
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px 0px 12px 16px',
                          }}
                        >
                          <div className="form-check form-switch mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onChange={(e) => {
                                e.preventDefault()
                                axios
                                  .patch(
                                    `${process.env.REACT_APP_URL}/api/v1/users/updateMe`,
                                    {
                                      envoyerEmailRapport: e.target.checked,
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${users.token}`,
                                      },
                                    }
                                  )
                                  .then((el) => {
                                    setBool(!bool)
                                  })
                                  .catch((el) => {
                                    console.log(el)
                                  })
                              }}
                              checked={
                                data?.envoyerEmailRapport
                                  ? data.envoyerEmailRapport
                                  : false
                              }
                            />
                          </div>
                          <span
                            style={{ marginTop: '7px', marginLeft: '12px' }}
                          >
                            Envoi automatique des rapports
                          </span>
                        </span>
                      </div>
                      <div
                        className="container-fluid"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                          marginLeft: '10px',
                          marginTop: '20px',
                          padding: '20px 20px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#334155',
                          }}
                        >
                          Liste des chantiers
                        </span>{' '}
                        <br />
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '12px 0px 12px 16px',
                            backgroundColor: ' rgba(226, 232, 240, 0.6)',
                            borderRadius: '6px',
                            marginTop: '12px',
                          }}
                        >
                          <div className="form-check form-switch mt-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              defaultChecked={false}
                            />
                          </div>
                          <span
                            style={{ marginTop: '7px', marginLeft: '12px' }}
                          >
                            Activer tous les chantiers
                          </span>
                        </span>
                        <span style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {listChantier &&
                            listChantier.map((c) => {
                              return (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      padding: '12px 0px 12px 16px',
                                      width: '50%',
                                    }}
                                  >
                                    <div className="form-check form-switch mt-2">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            updateChantierUser(
                                              c._id,
                                              true,
                                              users.token,
                                              infoChantier.planning
                                            ).then((res) => {
                                            })
                                          } else {
                                            updateChantierUser(
                                              c._id,
                                              false,
                                              users.token,
                                              infoChantier.planning
                                            ).then((res) => {
                                            })
                                          }
                                        }}
                                        defaultChecked={
                                          c?.envoieEmail ? c.envoieEmail : false
                                        }
                                      />
                                    </div>
                                    <span
                                      style={{
                                        marginTop: '7px',
                                        marginLeft: '12px',
                                      }}
                                    >
                                      {c?.titre ? c.titre : '---'}
                                    </span>
                                  </span>
                                </>
                              )
                            })}
                        </span>
                      </div>
                      <div
                        className="container-fluid"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                          marginLeft: '10px',
                          marginTop: '20px',
                          padding: '20px 20px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#334155',
                          }}
                        >
                          Date des taches à rappeler
                        </span>
                        <div class="row" style={{ marginTop: '16px' }}>
                          <div class="col">
                            <input
                              type="date"
                              class="form-control form-control-lg"
                              placeholder="Nom"
                            />
                          </div>
                        </div>
                        <div className="row" style={{ marginTop: '10px' }}>
                          <div
                            className="col"
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <button
                              className="btn"
                              style={{ color: '#64748B' }}
                            >
                              Annuler
                            </button>
                            <button
                              className="btn"
                              style={{
                                marginLeft: '5px',
                                backgroundColor: '#4F46E5',
                                color: 'white',
                              }}
                            >
                              Sauvegarder
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {item == 'securite' && (
                    <>
                      <div
                        className="container-fluid"
                        style={{
                          display: 'flex',
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                          marginLeft: '10px',
                          padding: '20px 20px',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>
                            {' '}
                            <img
                                src={
                                  users?.user?.photo
                                    ? `${process.env.REACT_APP_URL}/api/v1/users/photo/${users.user.photo}`
                                    : photoUser
                                }
                              alt=""
                              style={{
                                width: '96px',
                                height: '96px',
                                borderRadius: '8px',
                              }}
                            />{' '}
                          </span>
                          <span style={{ marginLeft: '10px' }}>
                            <span
                              style={{
                                fontSize: '20px',
                                fontWeight: '500',
                                color: ' #334155',
                              }}
                            >
                              Rôle :{' '}
                              {users?.user?.role?.roleType
                                ? users?.user?.role?.roleType.toUpperCase()
                                : '---'}
                            </span>{' '}
                            <br />
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#6366F1',
                              }}
                            >
                              Entreprise :{' '}
                              {data?.entrepriseName
                                ? data.entrepriseName
                                : '---'}
                            </span>
                          </span>
                        </div>
                        <div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '0px',
                              alignItems: 'flex-start',
                              marginTop: '24px',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="#818CF8"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                  }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: '#1E293B',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                  }}
                                >
                                  {data?.telephone ? data.telephone : '---'}
                                </span>{' '}
                                <br />
                                <span
                                  style={{
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}
                                >
                                  Téléphone
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '12px',
                              }}
                            >
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="#818CF8"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                  }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: '#1E293B',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                  }}
                                >
                                  {data?.email ? data.email : '---'}
                                </span>{' '}
                                <br />
                                <span
                                  style={{
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}
                                >
                                  Email
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="container-fluid"
                        style={{
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          border: '1px solid #E2E8F0',
                          borderRadius: '8px',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.01), 0px 6.15px 6.15px rgba(0, 0, 0, 0.02)',
                          marginLeft: '10px',
                          marginTop: '20px',
                          padding: '20px 20px',
                        }}
                      >
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            savePassword()
                          }}
                        >
                          <span
                            style={{
                              fontSize: '18px',
                              fontWeight: '600',
                              color: '#334155',
                            }}
                          >
                            Modifier mot de passe
                          </span>
                          <div class="row" style={{ marginTop: '16px' }}>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                Ancien mot de passe{' '}
                              </span>
                              <input
                                type="password"
                                onChange={(e)=> {
                                  e.preventDefault()
                                  setOldPassword(e.target.value)
                                }}
                                class="form-control form-control-lg"
                                placeholder="ancien mot de passe"
                                required
                              />
                            </div>
                          </div>
                          <div class="row" style={{ marginTop: '20px' }}>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                Nouveau mot de passe{' '}
                              </span>
                              <span
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  border: '1px solid #E2E8F0',
                                  borderRadius: '6px',
                                  backgroundColor: 'white',
                                  padding: '9px 5px',
                                }}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#94A3B8"
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: 'white',
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                  </svg>
                                </span>
                                <span style={{}}>
                                  <input
                                    type="password"
                                    style={{
                                      border: 'none',
                                      backgroundColor: 'transparent',
                                      marginLeft: '10px',
                                    }}
                                    placeholder="Nouveau mot de passe"
                                    onChange={(e)=>{
                                      e.preventDefault()
                                      setNewPassword(e.target.value)
                                    }}
                                    required
                                  />
                                </span>
                              </span>
                            </div>
                            <div class="col">
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: '500',
                                  color: '#334155',
                                }}
                              >
                                Confirmer le nouveau mot de passe{' '}
                              </span>{' '}
                              <br />
                              <span
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  border: '1px solid #E2E8F0',
                                  borderRadius: '6px',
                                  backgroundColor: 'white',
                                  padding: '9px 5px',
                                }}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="#94A3B8"
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: 'white',
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                    />
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                  </svg>
                                </span>
                                <span style={{}}>
                                  <input
                                    type="password"
                                    style={{
                                      border: 'none',
                                      backgroundColor: 'transparent',
                                      marginLeft: '10px',
                                    }}
                                    placeholder="Confirmer"
                                    onChange={(e)=>{
                                      e.preventDefault()
                                      setPasswordConfirm(e.target.value)
                                    }}
                                    required
                                  />
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="row" style={{ marginTop: '10px' }}>
                            <div
                              className="col"
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <button
                                className="btn"
                                style={{ color: '#64748B' }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  setBool(!bool)
                                }}
                              >
                                Annuler
                              </button>
                              <button
                                className="btn"
                                type="submit"
                                style={{
                                  marginLeft: '5px',
                                  backgroundColor: '#4F46E5',
                                  color: 'white',
                                }}
                              >
                                Sauvegarder
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* fin profil */}
        </div>
      </div>
    </>
  )
}

export default Profil
