import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { ReactTabulator } from 'react-tabulator'
import { useToasts } from 'react-toast-notifications'
import { getISOWeek, getISOWeekYear } from 'date-fns'
import { Button, Upload } from 'antd'
import ReactApexChart from 'react-apexcharts'
import trash from '../../../assets/images/Kolos_logo/trash.png'
import Chart from 'react-apexcharts'
import {
  getAllData,
  getData,
  addData,
  updateData,
  delData,
  getForfait,
} from '../../../helpers/suivi/suivi'
import ModalDocument from './component/ModalDocument'
import ModalPenalite from './component/ModalPenalite'
import PageNotFound from '../../../components/404/PageNotFound'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { searchOptimizedData } from '../../../helpers/recherche/recherche'
import Header_small_screen from '../../../components/navBar/Header_small_screen'
//Profil de chaque entreprise
const ProfilEntreprise = () => {
  const { addToast } = useToasts()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  //const _id = localStorage.getItem('_id')
  //const titreLot = localStorage.getItem('titreLot')
  const [_id, setId] = useState(
    localStorage.getItem('_id') ? localStorage.getItem('_id') : ''
  )
  const [_idLot, setIdLot] = useState(
    localStorage.getItem('_idLot') ? localStorage.getItem('_idLot') : ''
  )
  const [titreLot, setTitreLot] = useState(
    localStorage.getItem('titreLot') ? localStorage.getItem('titreLot') : ''
  )
  const [dataEngagement, setDataEngagement] = useState([])
  const [dataAdministratif, setDataAdministratif] = useState([])
  const [lot, setLot] = useState({})
  const [forfait, setForfait] = useState({})
  const [dataDocument, setDataDocument] = useState([])
  const [dataSecurite, setDataSecurite] = useState([])
  const [dataCT, setDataCT] = useState([])
  const [dataInstallation, setDataInstallation] = useState([])
  const [dataEffectif, setDataEffectif] = useState([])
  const [dataPlanningSo, setDataPlanningSo] = useState([])
  const [dataModalAction, setDataModalAction] = useState({})
  const [cibleAction, setCibleAction] = useState('')
  const [imageAction, setImageAction] = useState([])
  const [dataModalPlanification, setDataModalPlanification] = useState({})
  const [dataModalEngagement, setDataModalEngagement] = useState({})
  const [dataModalAdministration, setDataModalAdministration] = useState({})
  const [dataModalDocument, setDataModalDocument] = useState({})
  const [dataModalSecurite, setDataModalSecurite] = useState({})
  const [dataModalCT, setDataModalCT] = useState({})
  const [dataModalInstallation, setDataModalInstallation] = useState({})
  const [dataModalEffectif, setDataModalEffectif] = useState({})
  const [dataModalSuiviDoc, setDataModalSuiviDoc] = useState([])
  const [entreprise, setEntreprise] = useState()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [pourcentageGlobal, setPourcentageGlobal] = useState(0)
  const [listNote, setListNote] = useState({})
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState(false)
  const [boolPenalite,setBoolPenalite] = useState(false)
  const [dataMaj, setDataMaj] = useState()
  const [colorNote, setColorNote] = useState('')
  const indiceDoc = [
    { value: 'A' },
    { value: 'B' },
    { value: 'C' },
    { value: 'D' },
    { value: 'E' },
    { value: 'F' },
    { value: 'G' },
    { value: 'H' },
    { value: 'I' },
    { value: 'J' },
    { value: 'K' },
    { value: 'L' },
    { value: 'M' },
    { value: 'N' },
    { value: 'O' },
    { value: 'P' },
    { value: 'Q' },
    { value: 'R' },
    { value: 'S' },
    { value: 'T' },
    { value: 'U' },
    { value: 'V' },
    { value: 'W' },
    { value: 'X' },
    { value: 'Y' },
    { value: 'Z' },
  ]
  const [item, setItem] = useState('penalite')
  const [carousel, setCarousel] = useState(true)
  const [listItem, setListItem] = useState([])
  const [itemPlanningSo, setItemPlanningSo] = useState(false)
  const [itemEng, setItemEng] = useState(false)
  const [itemAdm, setItemAdm] = useState(false)
  const [itemSec, setItemSec] = useState(false)
  const [itemCT, setItemCT] = useState(false)
  const [itemIc, setItemIc] = useState(false)
  const [itemEff, setItemEff] = useState(false)
  const [itemDoc, setItemDoc] = useState(false)
  const [itemObservation, setItemObservation] = useState(true)
  //fonction qui verifier le filtre des items
  const filtreVerification = (tableFilter, item) => {
    return tableFilter.includes(item)
  }
  const conversionNomFiltre = (item) => {
    switch (item) {
      case 'planningSo':
        return 'plannification'
        break
      case 'engagement':
        return 'engagement'
        break
      case 'administratif':
        return 'administratif'
        break
      case 'document':
        return 'document'
        break
      case 'securite':
        return 'securite'
        break
      case 'controleurTechniques':
        return 'controleurTechniques'
        break
      case 'ic':
        return 'installation'
        break
      case 'effectif':
        return 'effectif'
        break
      default:
        return ''
    }
  }
  const modificationFiltre = (tableFIltre, value, item) => {
    if (value) {
      if (tableFIltre.includes(item)) {
        return tableFIltre
      } else {
        return [...tableFIltre, item]
      }
    } else {
      return tableFIltre.filter((e) => e !== item)
    }
  }
  useEffect(() => {
    getForfait(infoChantier.planning, infoUser.token).then((e) => {
      setForfait(e.data[0])
    })
  }, [boolPenalite])

  //Recuperattion information lot
  useEffect(() => {
    setWait(true)
    getAllData(
      `planning=${infoChantier.planning}`,
      `lots/${localStorage.getItem('_idLot')}`,
      infoUser.token
    )
      .then((res) => {
        setLot(res.data)
        if (res.data.filter) {
          setListItem(res.data.filter)
          setItem(conversionNomFiltre(res.data.filter[0]))
          setItemPlanningSo(filtreVerification(res.data.filter, 'planningSo'))
          setItemAdm(filtreVerification(res.data.filter, 'administratif'))
          setItemEff(filtreVerification(res.data.filter, 'effectif'))
          setItemEng(filtreVerification(res.data.filter, 'engagement'))
          setItemIc(filtreVerification(res.data.filter, 'ic'))
          setItemSec(filtreVerification(res.data.filter, 'securite'))
          setItemCT(filtreVerification(res.data.filter, 'controleurTechnique'))
          setItemDoc(filtreVerification(res.data.filter, 'document'))
        }
        setWait(false)
      })
      .catch((err) => {
        console.log(err)
        setWait(false)
      })
  }, [bool])
  useEffect(() => {
    getAllData(
      `entId=${_id}&idLot=${_idLot}&planning=${infoChantier.planning}`,
      'lots/lotprogress',
      infoUser.token
    ).then((e) => {
      e.data?.pourcentage && setListNote(e.data.pourcentage)
      e.data?.pourcentageGlobal &&
        setPourcentageGlobal(e.data.pourcentageGlobal)
      e.data?.color && setColorNote(e.data.color)
    })
  }, [])
  //Recupération information entreprise
  useEffect(() => {
    setId(localStorage.getItem('_id'))
    setTitreLot(localStorage.getItem('titreLot'))

    getData(_id, 'entreprises', infoUser.token, infoChantier.planning).then(
      (e) => {
        setEntreprise(e)
      }
    )
  }, [_id, titreLot])
  useEffect(() => {
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&lot=${_idLot}`,
      'planningSo',
      infoUser.token
    )
      .then((e) => {
        setDataPlanningSo(e.data)
      })
      .catch((err) => {
        console.log(err)
      })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&lot=${_idLot}`,
      'engagements',
      infoUser.token
    ).then((e) => {
      setDataEngagement(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&lot=${_idLot}`,
      'securites',
      infoUser.token
    ).then((e) => {
      setDataSecurite(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&lot=${_idLot}`,
      'installationchantiers',
      infoUser.token
    ).then((e) => {
      setDataInstallation(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&lot=${_idLot}`,
      'controleurTechniques',
      infoUser.token
    ).then((e) => {
      setDataCT(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&lot=${_idLot}`,
      'administratifs',
      infoUser.token
    ).then((e) => {
      setDataAdministratif(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&lot=${_idLot}`,
      'documents',
      infoUser.token
    ).then((e) => {
      setDataDocument(e.data)
    })
    getAllData(
      `planning=${infoChantier.planning}&entreprise=${_id}&lot=${_idLot}`,
      'effectifs',
      infoUser.token
    ).then((e) => {
      setDataEffectif(e.data)
    })
  }, [itemObservation, _id, titreLot])

  const delPlanning = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'planningSo',
        infoUser.token,
        infoChantier.planning
      )
        .then((res) => {
          cell.getRow().delete()
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delEngagements = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'engagements',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delAdministratifs = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'administratifs',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delDocuments = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'documents',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delSecurites = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'securites',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  const delCT = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'controleurTechniques',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delInstallationChantier = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'installationchantiers',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  const delEffectif = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'effectifs',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  //Boutton de suppression
  const button = (delFunction) => (cell) => {
    const delIcons = document.createElement('img')
    delIcons.src = trash
    delIcons.style = 'width:24px;height:24px'

    //create delete button
    var delBt = document.createElement('button')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.type = 'button'
    delBt.appendChild(delIcons)
    delBt.classList.add('btn')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)
    return buttonHolder
  }
  //input semaine
  const semaine = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    const week = document.createElement('input')
    week.classList = 'form-control'
    week.type = 'week'
    week.defaultValue = data.formattedSemaine ? data.formattedSemaine : ''
    week.addEventListener('change', function (e) {
      updateData(
        { ...data, formattedSemaine: e.target.value },
        `effectifs`,
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    div.appendChild(week)
    return div
  }
  //Effectif pers
  const pers = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    div.style = 'display:flex;align-items:center'
    const inputPers = document.createElement('input')
    inputPers.type = 'number'
    inputPers.className = 'form-control'
    inputPers.style = 'width:70px;text-align:rigth'
    inputPers.defaultValue = data.prevu ? data.prevu : 0
    inputPers.addEventListener('change', function (e) {
      updateData(
        { ...data, prevu: e.target.value },
        `effectifs`,
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    const unite = document.createElement('sapan')
    unite.innerText = 'per(s)'
    div.appendChild(inputPers)
    div.appendChild(unite)
    return div
  }
  //Effectif pers
  const pers2 = (cell) => {
    const data = cell.getData()
    const div = document.createElement('div')
    div.style = 'display:flex;align-items:center'
    const inputPers = document.createElement('input')
    inputPers.type = 'number'
    inputPers.className = 'form-control'
    inputPers.style = 'width:70px;text-align:rigth'
    inputPers.defaultValue = data.reel?.present ? data.reel.present : 0
    inputPers.addEventListener('change', function (e) {
      updateData(
        { ...data, reel: { ...data.reel, present: e.target.value } },
        `effectifs`,
        infoUser.token,
        infoChantier.planning
      ).then((e) => {
        e.data
          ? setDataEffectif(
              dataEffectif.map((el) => {
                if (el._id == e.data._id) {
                  return e.data
                } else {
                  return el
                }
              })
            )
          : console.log(e)
      })
    })
    const unite = document.createElement('sapan')
    unite.innerHTML = `<span>per(s) <span style='color:${
      data.reel?.color ? data.reel.color : 'blue'
    };font-weight:bold'>(${
      data.reel?.diff ? data.reel.diff : 0
    })</span> </span>`
    div.appendChild(inputPers)
    div.appendChild(unite)
    return div
  }
  //Assigenment nouvelle donner action
  const assignDataAction = (item, data) => {
    switch (item) {
      case 'planningSo':
        setDataPlanningSo(
          dataPlanningSo.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'installationchantiers':
        setDataInstallation(
          dataInstallation.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'securites':
        setDataSecurite(
          dataSecurite.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'controleurTechniques':
        setDataCT(
          dataCT.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
      case 'engagements':
        setDataEngagement(
          dataEngagement.map((el) => {
            if (el._id == data._id) {
              return data
            } else {
              return el
            }
          })
        )
    }
  }
  //Modal document
  const modalAction = (value) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-download')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAction')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setCibleAction(value)
      setDataMaj(cell)
      setDataModalAction(cell.getData())
      setImageAction(null)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }

  const modalDocument = (dellFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalDoc')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalDocument(cell.getData())
      setDataModalSuiviDoc(
        cell.getData().suiviDocs ? cell.getData().suiviDocs : []
      )
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  const maj = (cell, info) => {
    cell.setValue(info)
  }
  const majPhoto = (cell, info) => {
    cell.getRow().update(info)
  }
  //Modal Plannification
  const modalPlannification = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalPlannification')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalPlanification(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Engagement
  const modalEngagement = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEngagement')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalEngagement(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Administratif
  const modalAdministratif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAdministratif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Securite
  const modalSecurite = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalSecurite')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalSecurite(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Controle technique CT
  const modalCT = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalCT')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalCT(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Installation
  const modalInstallation = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalInstallation')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalInstallation(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Effectif
  const modalEffectif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEffectif')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalEffectif(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }

  //Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  //Create date picker
  const datePM = (cell) => {
    const data = cell.getData()
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    if (data.pm) {
      view.disabled = true
    }
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }

  const inputUrgence = (cell) => {
    const ul = document.createElement('ul')
    ul.className = 'dropdown-menu'
    const option1 = document.createElement('li')
    option1.className = 'dropdown-item'
    option1.innerText = 'Faible'
    option1.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(1)
    })
    const option2 = document.createElement('li')
    option2.className = 'dropdown-item'
    option2.innerText = 'Important'
    option2.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(2)
    })
    const option3 = document.createElement('li')
    option3.className = 'dropdown-item'
    option3.innerText = 'Primordial'
    option3.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(3)
    })
    ul.appendChild(option1)
    ul.appendChild(option2)
    ul.appendChild(option3)
    const btn = document.createElement('button')
    btn.className = 'btn'
    btn.type = 'button'
    btn.addEventListener('click', function (e) {
      e.preventDefault()
    })
    btn.setAttribute('data-bs-toggle', 'dropdown')
    const span = document.createElement('span')
    const icon = document.createElement('i')
    icon.className = 'ti ti-selector'
    if (cell.getValue() == 1) {
      btn.style =
        'color:#0D9488;background-color:rgba(45, 212, 191, 0.15);width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Faible'
    }
    if (cell.getValue() == 2) {
      btn.style =
        'color:#F59E0B;background-color:#FEF3C7;width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Important'
    }
    if (cell.getValue() == 3) {
      btn.style =
        'color:#FB7185;background-color:#FFF1F2;width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Primordial'
    }
    if (!cell.getValue()) {
      btn.style =
        'color:#0D9488;background-color:rgba(45, 212, 191, 0.15);width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Faible'
    }
    btn.appendChild(span)
    btn.appendChild(icon)
    const container = document.createElement('div')
    container.className = 'dropdown dropend'
    container.style = 'position:absolute;z-index:999'
    container.appendChild(btn)
    container.appendChild(ul)
    return container
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.disabled = cell.getField()=="visa" ? true : false
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
        if (cell.getField() !== 'dqp' && cell.getField() !== 'pm') {
          if (
            (data.prevuLe == undefined) |
            (data.prevuLe == '') |
            (data.prevuLe == null)
          ) {
            data.prevuLe = new Date(Date.now()).toISOString()
            cell.getRow().update(data)
          }
          if (
            (data.faitLe == undefined) |
            (data.faitLe == '') |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            cell.getRow().update(data)
          }
        }
      } else {
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }
  const getItem = (value) => {
    return value
  }

  const colonneEngagement = [
    { title: 'Engagements', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: datePM },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalEngagement(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('engagements')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEngagements),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
  ]
  const colonneAdministratif = [
    { title: 'Titre', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalAdministratif(),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delAdministratifs),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
  ]
  const colonneDocument = [
    { title: 'Titre', field: 'titre', formatter: simpleInput },
    { title: 'Phase', field: 'phase', formatter: simpleInput },
    { title: 'Type', field: 'type', formatter: simpleInput },
    { title: 'Niveau', field: 'niveau', formatter: simpleInput },
    { title: 'Zone', field: 'zone', formatter: simpleInput },
    { title: 'Indice', field: 'indice', formatter: simpleInput },
    { title: 'Date BPE', field: 'dateBPE', formatter: date },
    { title: 'Prévu Le', field: 'prevision', formatter: date },
    { title: 'Diffusé Le', field: 'diffusion', formatter: date },
    { title: 'Visa', field: 'visa', formatter: simpleInput },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'functionButton',
      formatter: modalDocument(),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delDocuments),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
  ]
  const colonnePlanning = [
    { title: 'Tâches', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Planifié Le', field: 'prevuLe', formatter: datePM },
    { title: 'Réalisé Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalPlannification(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('planningSo')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delPlanning),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
  ]
  const colonneSecurite = [
    { title: 'Remarques', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: datePM },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalSecurite(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('securites')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delSecurites),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
  ]
  const colonneCT = [
    { title: 'Remarques', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: datePM },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalCT(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('controleurTechniques')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delCT),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
  ]
  const colonneInstallation = [
    { title: 'Remarques', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: datePM },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalInstallation(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(getItem('installationchantiers')),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delInstallationChantier),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
  ]
  const colonneEffectif = [
    { title: 'Semaine(s)', field: '', formatter: semaine },
    { title: 'Noté le', field: 'noteLe', formatter: date },
    { title: 'Prévu', field: 'prevu', formatter: pers },
    { title: 'Réel (delta)', field: 'reel.present', formatter: pers2 },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalEffectif(),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEffectif),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
      },
    },
  ]

  const rowDataCandidate = {
    titre: 'New',
    noteLe: Date.now(),
    entreprise: _id,
    planning: infoChantier.planning,
  }
  const planningEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(data, `planningSo`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `planningSo`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `planningSo`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(data, `planningSo`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `planningSo`, infoUser.token, infoChantier.planning)
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
  const engagementEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(data, `engagements`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `engagements`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `engagements`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(data, `engagements`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `engagements`, infoUser.token, infoChantier.planning)
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const administratifEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(
          data,
          `administratifs`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(
          data,
          `administratifs`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (!data.prevuLe) {
            data.prevuLe = new Date(Date.now()).toISOString()
          }
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `administratifs`,
              infoUser.token,
              infoChantier.planning
            ).then((res) => {
            })
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(
            data,
            `administratifs`,
            infoUser.token,
            infoChantier.planning
          )
          cell.getRow().update(data)
        }
      } else {
        updateData(
          data,
          'administratifs',
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    }
  }
  const documentEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'diffusion') {
      if (
        (data.diffusion == '') |
        (data.diffusion == undefined) |
        (data.diffusion == null)
      ) {
        data.statut = false
        updateData(
          data,
          `documents`,
          infoUser.token,
          infoChantier.planning
        ).then((e) => {})
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `documents`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.diffusion == '') |
            (data.diffusion == undefined) |
            (data.diffusion == null)
          ) {
            data.diffusion = new Date(Date.now()).toISOString()
            updateData(data, `documents`, infoUser.token, infoChantier.planning)
            cell.getRow().update(data)
          }
        } else {
          data.diffusion = ''
          updateData(data, `documents`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `documents`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    }
  }
  const securiteEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(data, `securites`, infoUser.token, infoChantier.planning)
      } else {
        data.statut = true
        updateData(data, `securites`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(data, `securites`, infoUser.token, infoChantier.planning)
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(data, `securites`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `securites`, infoUser.token, infoChantier.planning)
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
  const ctEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(
          data,
          `controleurTechniques`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(
          data,
          `controleurTechniques`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `controleurTechniques`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(
            data,
            `controleurTechniques`,
            infoUser.token,
            infoChantier.planning
          )
          cell.getRow().update(data)
        }
      } else {
        updateData(
          data,
          `controleurTechniques`,
          infoUser.token,
          infoChantier.planning
        )
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
  const installationEdited = async (cell) => {
    const data = cell.getData()
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(
          data,
          `installationchantiers`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(
          data,
          `installationchantiers`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `installationchantiers`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(
            data,
            `installationchantiers`,
            infoUser.token,
            infoChantier.planning
          )
          cell.getRow().update(data)
        }
      } else {
        updateData(
          data,
          `installationchantiers`,
          infoUser.token,
          infoChantier.planning
        )
          .then((res) => {
            if (res.data.data) {
              cell.getRow().update(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
  const effectifEdited = async (cell) => {
    const data = cell.getData()
    updateData(data, `effectifs`, infoUser.token, infoChantier.planning).then(
      (e) => {
      }
    )
  }

  const addPlanning = async (e) => {
    e.preventDefault()
    const result = await addData(
      rowDataCandidate,
      'planningSo',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setItemObservation(!itemObservation)
  }
  const addEngagementRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'engagements',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setItemObservation(!itemObservation)
  }
  const addAdministratifRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'administratifs',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setItemObservation(!itemObservation)
  }
  const addDocumentRow = async (e) => {
    e.preventDefault()
    setWait(true)
    await addData({...rowDataCandidate,typeDoc:1}, 'documents', _idLot, infoUser.token).then(
      (e) => {
        if (e.name == 'AxiosError' || e.message == 'erreur') {
          setWait(false)
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          setWait(false)
          setItemObservation(!itemObservation)
        }
      }
    )
  }
  const addSecuriteRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'securites',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setItemObservation(!itemObservation)
  }
  const addCTRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'controleurTechniques',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setItemObservation(!itemObservation)
  }
  const addInstallationRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      rowDataCandidate,
      'installationchantiers',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setItemObservation(!itemObservation)
  }
  const addEffectifRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      {
        formattedSemaine: `${getISOWeekYear(Date.now())}-W${getISOWeek(
          new Date()
        )}`,
        noteLe: Date.now(),
        prevu: 0,
        entreprise: _id,
        planning: infoChantier.planning,
      },
      'effectifs',
      _idLot,
      infoUser.token
    ).then((e) => {
      setWait(false)
      return e
    })
    setItemObservation(!itemObservation)
  }

  //fonction pour le nav bar scroll horizontal
  const right = () => {
    const element = document.querySelector('.wrapper')
    const item = document.querySelector('.item')
    element.scrollLeft = element.scrollLeft + item.clientWidth + 10
  }

  const left = () => {
    const element = document.querySelector('.wrapper')
    const item = document.querySelector('.item')
    element.scrollLeft = element.scrollLeft - item.clientWidth + 10
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="suivi" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
                    <Header_small_screen />
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#94A3B8',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                  / Suivi opérationnel
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  {' '}
                  / Profil
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/SuiviOperationnel')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Profil
                </span>
              </span>
            </div>
            {/*debut profil */}
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                <div className="row">
                  <div className="col-lg-5" style={{ marginTop: '20px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '20px 20px',
                        backgroundColor: '#FFFFFF',
                        boxShadow:
                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                        borderRadius: '8px',
                        height: '166px',
                        border: '1px solid #E2E8F0',
                      }}>
                      <span
                        style={{
                          color: ' #334155',
                          fontSize: '20px',
                          fontWeight: '600',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <span
                          style={{
                            width: '70%',
                            display: 'flex',
                            flexWrap: 'nowrap',
                            overflow: 'hidden',
                          }}>
                          {localStorage.getItem('titreLot') !=
                            'Généralités' && <>Lot : </>}{' '}
                          {titreLot ? titreLot : '---'}
                        </span>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            width: '250px',
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#818CF8"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                            }}>
                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                          </svg>
                          <span
                            style={{
                              color: '#64748B',
                              fontSize: '14px',
                              fontWeight: '600',
                            }}>
                            Envoi courrier (Bientôt)
                          </span>
                        </span>
                      </span>

                      {localStorage.getItem('titreLot') != 'Généralités' && (
                        <>
                          <span
                            style={{
                              color: '#6366F1',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}>
                            Entreprise :{' '}
                            {entreprise?.nom ? entreprise.nom : '---'}
                          </span>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              padding: '0px',
                              alignItems: 'flex-start',
                              marginTop: '24px',
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="#818CF8"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                  }}>
                                  <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                </svg>
                              </span>
                              <div>
                                <span
                                  style={{
                                    color: '#1E293B',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                  }}>
                                  {entreprise?.responsable?.name
                                    ? entreprise.responsable.name
                                    : ''}{' '}
                                  {entreprise?.responsable?.lastname
                                    ? entreprise.responsable.lastname
                                    : ''}
                                </span>{' '}
                                <br />
                                <span
                                  style={{
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}>
                                  Responsable
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="#818CF8"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                  }}>
                                  <path
                                    fillRule="evenodd"
                                    d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: '#1E293B',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                  }}>
                                  {entreprise?.responsable?.telephone
                                    ? entreprise.responsable.telephone
                                    : '---'}
                                </span>{' '}
                                <br />
                                <span
                                  style={{
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}>
                                  Téléphone
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="#818CF8"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                  }}>
                                  <path
                                    fillRule="evenodd"
                                    d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <div>
                                <span
                                  style={{
                                    color: '#1E293B',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                  }}>
                                  {entreprise?.responsable?.email
                                    ? entreprise.responsable.email
                                    : '---'}
                                </span>{' '}
                                <br />
                                <span
                                  style={{
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                  }}>
                                  Email
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2" style={{ marginTop: '20px' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: '1px solid #E2E8F0',
                        boxShadow:
                          '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                        borderRadius: '8px',
                        padding: '3px 10px',
                        backgroundColor: '#FFFFFF',
                      }}>
                      <div>
                        <ReactApexChart
                          series={[
                            pourcentageGlobal
                              ? pourcentageGlobal.toFixed(0)
                              : 0,
                          ]}
                          colors={['red']}
                          options={{
                            plotOptions: {
                              radialBar: {
                                hollow: {
                                  margin: 0,
                                  size: '60%',
                                  background: '#E0E7FF',
                                },
                                dataLabels: {
                                  value: {
                                    show: true,
                                    fontSize: '18px',
                                    offsetY: 7.5,
                                    offsetX: 0,
                                    fontWeight: 600,
                                    formatter: function (val) {
                                      return val + '%'
                                    },
                                    style: {
                                      color: '#E0E7FF',
                                    },
                                  },
                                  total: {
                                    show: false,
                                  },
                                  name: {
                                    show: false,
                                  },
                                },
                                track: {
                                  background: '#E0E7FF',
                                },
                              },
                            },
                            labels: [],
                            fill: {
                              type: 'pattern',
                              colors: '#6366F1',
                              pattern: {
                                style: 'verticalLines',
                                width: 1,
                                height: 1,
                                strokeWidth: 2,
                              },
                            },
                            /* fill:{
                              opacity:2,
                              colors:'red',
                              type:'gradient',
                              gradient: {
                                shade: 'light',
                                type: "vertical",
                                shadeIntensity: 0.5,
                                gradientToColors: 'green',
                                inverseColors: false,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0,100],
                                colorStops: []
                            }
                            } */
                          }}
                          type="radialBar"
                          height={153}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '5px',
                          }}>
                          {' '}
                          <div
                            style={{
                              width: '12px',
                              height: '12px',
                              borderRadius: '50%',
                              backgroundColor: '#6366F1',
                            }}></div>{' '}
                          <span
                            style={{
                              marginLeft: '5px',
                              color: '#64748B',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}>
                            Fiabilité
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5" style={{ marginTop: '20px' }}>
                    {carousel ? (
                      <div
                        style={{
                          width: '100%',
                          border: '1px solid #E2E8F0',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                          borderRadius: '8px',
                          height: '166px',
                          backgroundColor: '#FFFFFF',
                          padding: '19px 42px 19px 24px ',
                          display: 'flex',
                          position: 'relative',
                        }}>
                        <span
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '10px',
                            width: '28px',
                            height: '25px',
                            border: 'solid lightgray 1px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#myModalCarousel">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#818CF8"
                            style={{ width: '16px', height: '16px' }}>
                            <path
                              fillRule="evenodd"
                              d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                          <span style={{ width: '45%' }}>
                            {lot.filter &&
                              filtreVerification(lot.filter, 'planningSo') && (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#1E293B',
                                      }}>
                                      Planification
                                    </span>
                                    <span style={{ color: '#4F46E5' }}>
                                      {listNote?.planningSo
                                        ? listNote.planningSo.toFixed(0)
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                  <div
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      height: '7px',
                                      borderRadius: '4px',
                                      backgroundColor: '#C7D2FE',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        backgroundColor: '#4F46E5',
                                        width: `${
                                          listNote?.planningSo
                                            ? listNote.planningSo
                                            : 0
                                        }%`,
                                        borderRadius: '4px',
                                      }}></span>
                                  </div>
                                </>
                              )}
                            {lot.filter &&
                              filtreVerification(lot.filter, 'engagement') && (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: '10px',
                                    }}>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#1E293B',
                                      }}>
                                      Engagements
                                    </span>
                                    <span style={{ color: '#E11D48' }}>
                                      {listNote?.engagement
                                        ? listNote.engagement.toFixed(0)
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                  <div
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      height: '7px',
                                      borderRadius: '4px',
                                      backgroundColor:
                                        'rgba(251, 113, 133, 0.3)',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        backgroundColor: '#E11D48',
                                        width: `${
                                          listNote?.engagement
                                            ? listNote.engagement
                                            : 0
                                        }%`,
                                        borderRadius: '4px',
                                      }}></span>
                                  </div>
                                </>
                              )}
                            {lot.filter &&
                              filtreVerification(
                                lot.filter,
                                'administratif'
                              ) && (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: '10px',
                                    }}>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#1E293B',
                                      }}>
                                      Administratifs
                                    </span>
                                    <span style={{ color: '#F59E0B' }}>
                                      {listNote?.administratif
                                        ? listNote.administratif.toFixed(0)
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                  <div
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      height: '7px',
                                      borderRadius: '4px',
                                      backgroundColor: '#FEF3C7',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        backgroundColor: '#F59E0B',
                                        width: `${
                                          listNote?.administratif
                                            ? listNote.administratif
                                            : 0
                                        }%`,
                                        borderRadius: '4px',
                                      }}></span>
                                  </div>
                                </>
                              )}
                          </span>
                          <span style={{ width: '45%' }}>
                            {lot.filter &&
                              filtreVerification(lot.filter, 'document') && (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#1E293B',
                                      }}>
                                      Documents
                                    </span>
                                    <span style={{ color: '#0D9488' }}>
                                      {listNote?.document
                                        ? listNote.document.toFixed(0)
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                  <div
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      height: '7px',
                                      borderRadius: '4px',
                                      backgroundColor:
                                        'rgba(45, 212, 191, 0.2)',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        backgroundColor: '#2DD4BF',
                                        width: `${
                                          listNote?.document
                                            ? listNote.document
                                            : 0
                                        }%`,
                                        borderRadius: '4px',
                                      }}></span>
                                  </div>
                                </>
                              )}
                            {lot.filter &&
                              filtreVerification(lot.filter, 'securite') && (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: '10px',
                                    }}>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#1E293B',
                                      }}>
                                      Sécurités
                                    </span>
                                    <span style={{ color: '#4F46E5' }}>
                                      {listNote?.securite
                                        ? listNote.securite.toFixed(0)
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                  <div
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      height: '7px',
                                      borderRadius: '4px',
                                      backgroundColor: '#C7D2FE',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        backgroundColor: '#4F46E5',
                                        width: `${
                                          listNote?.securite
                                            ? listNote.securite
                                            : 0
                                        }%`,
                                        borderRadius: '4px',
                                      }}></span>
                                  </div>
                                </>
                              )}
                            {lot.filter &&
                              filtreVerification(lot.filter, 'ic') && (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: '10px',
                                    }}>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#1E293B',
                                      }}>
                                      Installation chantier
                                    </span>
                                    <span style={{ color: '#02A0FC' }}>
                                      {listNote?.ic
                                        ? listNote.ic.toFixed(0)
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                  <div
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      height: '7px',
                                      borderRadius: '4px',
                                      backgroundColor: '#CCF8FE',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        backgroundColor: '#02A0FC',
                                        width: `${
                                          listNote?.ic ? listNote.ic : 0
                                        }%`,
                                        borderRadius: '4px',
                                      }}></span>
                                  </div>
                                </>
                              )}
                          </span>
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '0',
                              left: '0',
                              right: '0',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '5px 5px',
                            }}>
                            <div
                              style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#4F46E5',
                                cursor: 'pointer',
                              }}></div>
                            <div
                              style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#C7D2FE',
                                marginLeft: '10px',
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                e.preventDefault()
                                setCarousel(false)
                              }}></div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          border: '1px solid #E2E8F0',
                          boxShadow:
                            '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                          borderRadius: '8px',
                          height: '166px',
                          backgroundColor: '#FFFFFF',
                          padding: '19px 42px 19px 24px',
                          display: 'flex',
                          position: 'relative',
                        }}>
                        <span
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '10px',
                            width: '28px',
                            height: '25px',
                            border: 'solid lightgray 1px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#818CF8"
                            style={{ width: '16px', height: '16px' }}>
                            <path
                              fillRule="evenodd"
                              d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}>
                          <span style={{ width: '45%' }}>
                            {lot.filter &&
                              filtreVerification(lot.filter, 'effectif') && (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#1E293B',
                                      }}>
                                      Effectifs
                                    </span>
                                    <span style={{ color: '#4F46E5' }}>
                                      {listNote?.effectif
                                        ? listNote.effectif.toFixed(0)
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                  <div
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      height: '7px',
                                      borderRadius: '4px',
                                      backgroundColor: '#C7D2FE',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        backgroundColor: '#4F46E5',
                                        width: `${
                                          listNote?.effectif
                                            ? listNote.effectif
                                            : 0
                                        }%`,
                                        borderRadius: '4px',
                                      }}></span>
                                  </div>
                                </>
                              )}
                            {lot.filter &&
                              filtreVerification(
                                lot.filter,
                                'controleurTechnique'
                              ) && (
                                <>
                                  <span
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      marginTop: '10px',
                                    }}>
                                    <span
                                      style={{
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#1E293B',
                                      }}>
                                      Contrôleur technique
                                    </span>
                                    <span style={{ color: '#E11D48' }}>
                                      {listNote?.controleurTechnique
                                        ? listNote.controleurTechnique.toFixed(
                                            0
                                          )
                                        : 0}
                                      %
                                    </span>
                                  </span>
                                  <div
                                    style={{
                                      width: '100%',
                                      position: 'relative',
                                      height: '7px',
                                      borderRadius: '4px',
                                      backgroundColor:
                                        'rgba(251, 113, 133, 0.3)',
                                    }}>
                                    <span
                                      style={{
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                        bottom: '0',
                                        backgroundColor: '#E11D48',
                                        width: `${
                                          listNote?.controleurTechnique
                                            ? listNote.controleurTechnique
                                            : 0
                                        }%`,
                                        borderRadius: '4px',
                                      }}></span>
                                  </div>
                                </>
                              )}
                          </span>
                          <span style={{ width: '45%' }}></span>
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '0',
                              left: '0',
                              right: '0',
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '5px 5px',
                            }}>
                            <div
                              style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#C7D2FE',
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                e.preventDefault()
                                setCarousel(true)
                              }}></div>
                            <div
                              style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#4F46E5',
                                marginLeft: '10px',
                                cursor: 'pointer',
                              }}></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/**Modal Carousel */}
                    <div class="modal" id="myModalCarousel">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div
                            class="modal-header"
                            style={{ backgroundColor: '#EBEFF5' }}>
                            <h4
                              class="modal-title"
                              style={{ color: '#334155' }}>
                              Filtrer les items
                            </h4>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"></button>
                          </div>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault()
                              axios
                                .patch(
                                  `${process.env.REACT_APP_URL}/api/v1/lots/${_idLot}?planning=${infoChantier.planning}`,
                                  {
                                    filter: listItem,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${infoUser.token}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  location.reload()
                                })
                                .catch((err) => {
                                  console.log(err)
                                  addToast("Une erreur s'est produit", {
                                    appearance: 'error',
                                    autoDismiss: true,
                                  })
                                })
                            }}>
                            <div
                              class="modal-body"
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                              <span
                                style={{
                                  marginTop: '5px',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                <input
                                  type="checkbox"
                                  onChange={(ev) => {
                                    setItemPlanningSo(!itemPlanningSo)
                                    setListItem(
                                      modificationFiltre(
                                        listItem,
                                        !itemPlanningSo,
                                        'planningSo'
                                      )
                                    )
                                  }}
                                  checked={itemPlanningSo}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  Planification
                                </span>
                              </span>
                              <span
                                style={{
                                  marginTop: '5px',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                <input
                                  type="checkbox"
                                  checked={itemEng}
                                  onChange={(ev) => {
                                    setItemEng(!itemEng)
                                    setListItem(
                                      modificationFiltre(
                                        listItem,
                                        !itemEng,
                                        'engagement'
                                      )
                                    )
                                  }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  Engagements
                                </span>
                              </span>
                              <span
                                style={{
                                  marginTop: '5px',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                <input
                                  type="checkbox"
                                  checked={itemAdm}
                                  onChange={(ev) => {
                                    setItemAdm(!itemAdm)
                                    setListItem(
                                      modificationFiltre(
                                        listItem,
                                        !itemAdm,
                                        'administratif'
                                      )
                                    )
                                  }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  Administratifs
                                </span>
                              </span>
                              <span
                                style={{
                                  marginTop: '5px',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                <input
                                  type="checkbox"
                                  checked={itemDoc}
                                  onChange={(ev) => {
                                    setItemDoc(!itemDoc)
                                    setListItem(
                                      modificationFiltre(
                                        listItem,
                                        !itemDoc,
                                        'document'
                                      )
                                    )
                                  }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  Documents
                                </span>
                              </span>
                              <span
                                style={{
                                  marginTop: '5px',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                <input
                                  type="checkbox"
                                  checked={itemSec}
                                  onChange={(ev) => {
                                    setItemSec(!itemSec)
                                    setListItem(
                                      modificationFiltre(
                                        listItem,
                                        !itemSec,
                                        'securite'
                                      )
                                    )
                                  }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  Sécurités
                                </span>
                              </span>
                              <span
                                style={{
                                  marginTop: '5px',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                <input
                                  type="checkbox"
                                  checked={itemIc}
                                  onChange={(ev) => {
                                    setItemIc(!itemIc)
                                    setListItem(
                                      modificationFiltre(
                                        listItem,
                                        !itemIc,
                                        'ic'
                                      )
                                    )
                                  }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  Installation chantier
                                </span>
                              </span>
                              <span
                                style={{
                                  marginTop: '5px',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                <input
                                  type="checkbox"
                                  checked={itemEff}
                                  onChange={(ev) => {
                                    setItemEff(!itemEff)
                                    setListItem(
                                      modificationFiltre(
                                        listItem,
                                        !itemEff,
                                        'effectif'
                                      )
                                    )
                                  }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  Effectif
                                </span>
                              </span>
                              <span
                                style={{
                                  marginTop: '5px',
                                  fontSize: '16px',
                                  fontWeight: '600',
                                }}>
                                <input
                                  type="checkbox"
                                  checked={itemCT}
                                  onChange={(ev) => {
                                    setItemCT(!itemCT)
                                    setListItem(
                                      modificationFiltre(
                                        listItem,
                                        !itemCT,
                                        'controleurTechnique'
                                      )
                                    )
                                  }}
                                />
                                <span style={{ marginLeft: '5px' }}>
                                  Contrôleur technique
                                </span>
                              </span>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="submit"
                                class="btn"
                                style={{
                                  color: 'white',
                                  backgroundColor: '#4F46E5',
                                  borderRadius: '8px',
                                }}
                                //data-bs-dismiss="modal"
                              >
                                Enregistrer
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div className="col-12" style={{ marginTop: '32px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    width: '100%',
                  }}>
                  <button
                    className="btn"
                    style={{
                      width: '10px',
                      height: '52px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '0px',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                      borderTop: '1px solid #CBD5E1',
                      borderBottom: '1px solid #CBD5E1',
                      borderLeft: '1px solid #CBD5E1',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      left()
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{ width: '20px', height: '20px' }}>
                      <path
                        fillRule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <div className="wrapper">
                    {lot.filter &&
                      filtreVerification(lot.filter, 'planningSo') && (
                        <>
                          <div
                            className="item"
                            onClick={(e) => {
                              e.preventDefault()
                              setItem('plannification')
                            }}>
                            <span
                              style={
                                item == 'plannification'
                                  ? {
                                      color: '#F8FAFC',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      backgroundColor: '#6366F1',
                                      padding: '8px 24px',
                                      borderRadius: '8px',
                                    }
                                  : {
                                      color: '#334155',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      cursor: 'pointer',
                                    }
                              }>
                              Planification
                            </span>
                          </div>
                        </>
                      )}
                    {lot.filter &&
                      filtreVerification(lot.filter, 'engagement') && (
                        <>
                          <div
                            className="item"
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setItem('engagement')
                            }}>
                            <span
                              style={
                                item == 'engagement'
                                  ? {
                                      color: '#F8FAFC',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      backgroundColor: '#6366F1',
                                      padding: '8px 24px',
                                      borderRadius: '8px',
                                    }
                                  : {
                                      color: '#334155',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      cursor: 'pointer',
                                    }
                              }>
                              Engagements
                            </span>
                          </div>
                        </>
                      )}
                    {lot.filter &&
                      filtreVerification(lot.filter, 'administratif') && (
                        <>
                          <div
                            className="item"
                            onClick={(e) => {
                              e.preventDefault()
                              setItem('administratif')
                            }}>
                            <span
                              style={
                                item == 'administratif'
                                  ? {
                                      color: '#F8FAFC',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      backgroundColor: '#6366F1',
                                      padding: '8px 24px',
                                      borderRadius: '8px',
                                    }
                                  : {
                                      color: '#334155',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      cursor: 'pointer',
                                    }
                              }>
                              Administratifs
                            </span>
                          </div>
                        </>
                      )}
                    {lot.filter &&
                      filtreVerification(lot.filter, 'document') && (
                        <>
                          <div
                            className="item"
                            onClick={(e) => {
                              e.preventDefault()
                              setItem('document')
                            }}>
                            <span
                              style={
                                item == 'document'
                                  ? {
                                      color: '#F8FAFC',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      backgroundColor: '#6366F1',
                                      padding: '8px 24px',
                                      borderRadius: '8px',
                                    }
                                  : {
                                      color: '#334155',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      cursor: 'pointer',
                                    }
                              }>
                              Documents
                            </span>
                          </div>
                        </>
                      )}
                    {lot.filter &&
                      filtreVerification(lot.filter, 'securite') && (
                        <>
                          <div
                            className="item"
                            onClick={(e) => {
                              e.preventDefault()
                              setItem('securite')
                            }}>
                            <span
                              style={
                                item == 'securite'
                                  ? {
                                      color: '#F8FAFC',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      backgroundColor: '#6366F1',
                                      padding: '8px 24px',
                                      borderRadius: '8px',
                                    }
                                  : {
                                      color: '#334155',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      cursor: 'pointer',
                                    }
                              }>
                              Sécurités
                            </span>
                          </div>
                        </>
                      )}
                    {lot.filter && filtreVerification(lot.filter, 'ic') && (
                      <>
                        <div
                          className="item"
                          onClick={(e) => {
                            e.preventDefault()
                            setItem('installation')
                          }}>
                          <span
                            style={
                              item == 'installation'
                                ? {
                                    color: '#F8FAFC',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    backgroundColor: '#6366F1',
                                    padding: '8px 14px',
                                    borderRadius: '8px',
                                  }
                                : {
                                    color: '#334155',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                  }
                            }>
                            Installation de chantier
                          </span>
                        </div>
                      </>
                    )}
                    {lot.filter &&
                      filtreVerification(lot.filter, 'effectif') && (
                        <>
                          <div
                            className="item"
                            onClick={(e) => {
                              e.preventDefault()
                              setItem('effectif')
                            }}>
                            <span
                              style={
                                item == 'effectif'
                                  ? {
                                      color: '#F8FAFC',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      backgroundColor: '#6366F1',
                                      padding: '8px 24px',
                                      borderRadius: '8px',
                                    }
                                  : {
                                      color: '#334155',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      cursor: 'pointer',
                                    }
                              }>
                              Effectifs
                            </span>
                          </div>
                        </>
                      )}
                    {lot.filter &&
                      filtreVerification(lot.filter, 'controleurTechnique') && (
                        <>
                          <div
                            className="item"
                            onClick={(e) => {
                              e.preventDefault()
                              setItem('CT')
                            }}>
                            <span
                              style={
                                item == 'CT'
                                  ? {
                                      color: '#F8FAFC',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      backgroundColor: '#6366F1',
                                      padding: '8px 24px',
                                      borderRadius: '8px',
                                    }
                                  : {
                                      color: '#334155',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      cursor: 'pointer',
                                    }
                              }>
                              Contôleur technique
                            </span>
                          </div>
                        </>
                      )}
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault()
                        setItem('penalite')
                      }}>
                      <span
                        style={
                          item == 'penalite'
                            ? {
                                color: '#F8FAFC',
                                fontSize: '16px',
                                fontWeight: '400',
                                backgroundColor: '#6366F1',
                                padding: '8px 24px',
                                borderRadius: '8px',
                              }
                            : {
                                color: '#334155',
                                fontSize: '16px',
                                fontWeight: '400',
                                cursor: 'pointer',
                              }
                        }>
                        Pénalités
                      </span>
                    </div>
                  </div>

                  <button
                    className="btn"
                    style={{
                      width: '10px',
                      height: '52px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '0px',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                      textAlign: 'center',
                      position: 'relative',
                      borderTop: '1px solid #CBD5E1',
                      borderBottom: '1px solid #CBD5E1',
                      borderRight: '1px solid #CBD5E1',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      right()
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        left: '0',
                        top: '17',
                      }}>
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: '10px 10px' }}>
              <div
                className="col-12"
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  padding: '16px 0px 24px',
                }}>
                {/** Tableau plannification */}
                {item == 'plannification' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Planification
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataPlanningSo.length > 0
                            ? dataPlanningSo.length
                            : '0'}{' '}
                          tâches
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataPlanningSo)
                              setDataPlanningSo(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addPlanning(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      className="cibleTabulatorCr"
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataPlanningSo}
                        columns={colonnePlanning}
                        layout={'fitColumns'}
                        events={{ cellEdited: planningEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Engagement */}
                {item == 'engagement' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Engagements
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',  
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataEngagement.length > 0
                            ? dataEngagement.length
                            : '0'}{' '}
                          engagements
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              const newData = searchOptimizedData(ev.target.value,dataEngagement)
                              setDataEngagement(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addEngagementRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      className="cibleTabulatorCr"
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataEngagement}
                        columns={colonneEngagement}
                        layout={'fitColumns'}
                        events={{ cellEdited: engagementEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Administratif */}
                {item == 'administratif' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Administratifs
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataAdministratif.length > 0
                            ? dataAdministratif.length
                            : '0'}{' '}
                          Administratifs
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataAdministratif)
                              setDataAdministratif(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addAdministratifRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataAdministratif}
                        columns={colonneAdministratif}
                        layout={'fitColumns'}
                        events={{ cellEdited: administratifEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Documents */}
                {item == 'document' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Documents
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataDocument.length > 0 ? dataDocument.length : '0'}{' '}
                          Documents
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataDocument)
                              setDataDocument(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addDocumentRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataDocument}
                        columns={colonneDocument}
                        layout={'fitColumns'}
                        events={{ cellEdited: documentEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Securites */}
                {item == 'securite' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Sécurités
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataSecurite.length > 0 ? dataSecurite.length : '0'}{' '}
                          Sécurités
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataSecurite)
                              setDataSecurite(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addSecuriteRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      className="cibleTabulatorCr"
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataSecurite}
                        columns={colonneSecurite}
                        layout={'fitColumns'}
                        events={{ cellEdited: securiteEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau controle technique ct */}
                {item == 'CT' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Contrôleur technique
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataCT.length > 0 ? dataCT.length : '0'} Contrôleur
                          technique(s)
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataCT)
                              setDataCT(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addCTRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      className="cibleTabulatorCr"
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataCT}
                        columns={colonneCT}
                        layout={'fitColumns'}
                        events={{ cellEdited: ctEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Effectifs */}
                {item == 'effectif' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Effectifs
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataEffectif.length > 0 ? dataEffectif.length : '0'}{' '}
                          Effectifs
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataEffectif)
                              setDataEffectif(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addEffectifRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataEffectif}
                        columns={colonneEffectif}
                        layout={'fitColumns'}
                        events={{ cellEdited: effectifEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Installation de chantier */}
                {item == 'installation' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Installation de chantier
                        </span>
                        <span
                          style={{
                            backgroundColor: '#E0E7FF',
                            borderRadius: '24px',
                            padding: '6px 12px',
                            color: '#64748B',
                            fontSize: '14px',
                            fontWeight: '600',
                            marginLeft: '8px',
                            maxHeight: '32px',
                            width: 'auto',
                          }}>
                          {dataInstallation.length > 0
                            ? dataInstallation.length
                            : '0'}{' '}
                          Installations
                        </span>
                      </div>
                      <div>
                        <span
                          style={{
                            padding: '8px 12px 8px 12px',
                            backgroundColor: 'rgba(226, 232, 240, 0.6)',
                            borderRadius: '8px',
                            height: '40px',
                          }}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#818CF8"
                              style={{ width: '18px', height: '18px' }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </span>
                          <input
                            type="text"
                            placeholder="Recherche"
                            style={{
                              border: 'none',
                              backgroundColor: 'transparent',
                            }}
                            onChange={(ev)=>{
                              ev.preventDefault()
                              ev.stopPropagation()
                              const newData = searchOptimizedData(ev.target.value,dataInstallation)
                              setDataInstallation(newData)
                            }}
                          />
                        </span>
                        <button
                          className="btn"
                          style={{
                            backgroundColor: '#4F46E5',
                            color: '#F8FAFC',
                            marginLeft: '24px',
                          }}
                          onClick={(e) => addInstallationRow(e)}>
                          + Nouvelle tâche
                        </button>
                      </div>
                    </div>
                    <div
                      className="cibleTabulatorCr"
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      <ReactTabulator
                        data={dataInstallation}
                        columns={colonneInstallation}
                        layout={'fitColumns'}
                        events={{ cellEdited: installationEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </>
                )}
                {/** Tableau Pénalités */}
                {item == 'penalite' && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px 0px 0px 40px',
                      }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            color: '#334155',
                            fontSize: '20px',
                            fontWeight: '600',
                          }}>
                          Pénalités
                        </span>
                      </div>
                      <div></div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        borderTop: 'solid #E2E8F0 1px',
                        marginTop: '16px',
                      }}>
                      {lot && (
                        <ModalPenalite
                          lot={lot}
                          forfait={forfait}
                          bool={boolPenalite}
                          setBool={setBoolPenalite}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Fin profil */}
          </div>
        </div>
      </div>
      {/* Modal Action */}
      <div class="modal fade" id="myModalAction">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Importer des photos
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.target.reset()
                addToast('Enregistrement en cours.', {
                  appearance: 'success',
                  autoDismiss: true,
                })
                const formData = new FormData()
                imageAction.map((item) => {
                  formData.append('item', item.originFileObj)
                })
                axios
                  .patch(
                    `${process.env.REACT_APP_URL}/api/v1/${cibleAction}/${dataModalAction._id}?planning=${infoChantier.planning}`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${infoUser.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    addToast('Enregistrement avec succès.', {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    if (res.data.data.data) {
                      majPhoto(dataMaj,res.data.data.data)
                      //assignDataAction(cibleAction, res.data.data.data)
                      //setItemObservation(!itemObservation)
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  })
              }}>
              {' '}
              <div class="modal-body">
                {dataModalAction?.actionPhoto?.length < 4 ? (
                  <>
                    <div class="mb-3 mt-3">
                      <label for="comment">
                        (4 images max, Taille 1Mo max, type:".png,.jpeg,.jpg"):
                      </label>
                      <Upload.Dragger
                        multiple={true}
                        maxCount={4}
                        beforeUpload={(files) => {
                          return false
                        }}
                        showUploadList={{ showRemoveIcon: true }}
                        accept=".png,.jpeg,.jpg"
                        listType="picture"
                        onChange={(ev) => {
                          setImageAction(ev.fileList)
                        }}>
                        <Button>Parcourir Fichier</Button>
                      </Upload.Dragger>
                    </div>
                  </>
                ) : (
                  <>
                    <h4
                      class="modal-title"
                      style={{ color: '#334155', textAlign: 'center' }}>
                      Vous avez atteint la limite des photos à importer (4
                      images)
                    </h4>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                  disabled={
                    dataModalAction?.actionPhoto?.length < 4 ? false : true
                  }>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Planification */}
      <div class="modal fade" id="myModalPlannification">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Planification
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalPlanification').value
                updateData(
                  {
                    observation:
                      document.getElementById('modalPlanification').value,
                    _id: dataModalPlanification._id,
                  },
                  'planningSo',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              {' '}
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    id="modalPlanification"
                    value={
                      dataModalPlanification.observation
                        ? dataModalPlanification.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalPlanification({
                        ...dataModalPlanification,
                        observation: e.target.value,
                      })
                    }}
                    class="form-control"
                    rows="7"
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalPlanification?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalPlanification.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image =
                                      dataModalPlanification.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalPlanification._id,
                                      },
                                      'planningSo',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Engagement */}
      <div class="modal fade" id="myModalEngagement">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Engagement
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalEngagement').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalEngagement._id,
                  },
                  'engagements',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    value={
                      dataModalEngagement.observation
                        ? dataModalEngagement.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEngagement({
                        ...dataModalEngagement,
                        observation: e.target.value,
                      })
                    }}
                    id="modalEngagement"
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalEngagement?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalEngagement.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image =
                                      dataModalEngagement.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalEngagement._id,
                                      },
                                      'engagements',
                                      infoUser.token,
                                      infoChantier.planning
                                    )
                                      .then((res) => {
                                        if (res.data.data) {
                                          dataMaj.getRow().update(res.data.data)
                                        } else {
                                          addToast("Une erreur s'est produit", {
                                            appearance: 'error',
                                            autoDismiss: true,
                                          })
                                        }
                                      })
                                      .catch((err) => console.log(err))
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Administratif */}
      <div class="modal fade" id="myModalAdministratif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Administratif
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalAdministratif').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalAdministration._id,
                  },
                  'administratifs',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalAdministratif"
                    value={
                      dataModalAdministration.observation
                        ? dataModalAdministration.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalAdministration({
                        ...dataModalAdministration,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Securite */}
      <div class="modal fade" id="myModalSecurite">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Sécurité
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalSecurite').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalSecurite._id,
                  },
                  'securites',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalSecurite"
                    value={
                      dataModalSecurite.observation
                        ? dataModalSecurite.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalSecurite({
                        ...dataModalSecurite,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalSecurite?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalSecurite.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image = dataModalSecurite.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalSecurite._id,
                                      },
                                      'securites',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Controle technique */}
      <div class="modal fade" id="myModalCT">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Contrôleur technique
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalCT').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalCT._id,
                  },
                  'controleurTechniques',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalCT"
                    value={
                      dataModalCT.observation ? dataModalCT.observation : ''
                    }
                    onChange={(e) => {
                      setDataModalCT({
                        ...dataModalCT,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalCT?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalCT.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}>
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image = dataModalCT.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalCT._id,
                                      },
                                      'controleurTechniques',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Installation */}
      <div class="modal fade" id="myModalInstallation">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Installation chantier
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalInstallation').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalInstallation._id,
                  },
                  'installationchantiers',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data.data) {
                    maj(dataMaj, info)
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalInstallation"
                    value={
                      dataModalInstallation.observation
                        ? dataModalInstallation.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalInstallation({
                        ...dataModalInstallation,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalInstallation?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalInstallation.actionPhoto.map((el) => {
                          return (
                            <>
                              <div
                                className="container-fluid"
                                style={{ position: 'relative' }}
                                id={`${el._id}`}
                                >
                                <img
                                  src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    marginTop: '5px',
                                  }}
                                />
                                <button
                                  className="btn btn-danger"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    const cible = document.getElementById(
                                      `${el._id}`
                                    )
                                    cible.style = 'display:none'
                                    const image =
                                      dataModalInstallation.actionPhoto
                                    updateData(
                                      {
                                        actionPhoto: image.filter(
                                          (img) => img._id != el._id
                                        ),
                                        _id: dataModalInstallation._id,
                                      },
                                      'installationchantiers',
                                      infoUser.token,
                                      infoChantier.planning
                                    ).then((res) => {
                                      if (res.data.data) {
                                        dataMaj.getRow().update(res.data.data)
                                      } else {
                                        addToast("Une erreur s'est produit", {
                                          appearance: 'error',
                                          autoDismiss: true,
                                        })
                                      }
                                    })
                                  }}
                                  style={{
                                    position: 'absolute',
                                    top: '3px',
                                    right: '15px',
                                  }}>
                                  <i className="ti ti-trash"></i>
                                </button>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Effectif */}
      <div class="modal fade" id="myModalEffectif">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Effectifs
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                const info = document.getElementById('modalEffectif').value
                updateData(
                  {
                    observation: info,
                    _id: dataModalEffectif._id,
                  },
                  'effectifs',
                  infoUser.token,
                  infoChantier.planning
                ).then((res) => {
                  if (res.data) {
                    maj(dataMaj, info)
                    /* setDataEffectif(
                      dataEffectif.map((el) => {
                        if (el._id == res.data._id) {
                          return res.data
                        } else {
                          return el
                        }
                      })
                    ) */
                  } else {
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  }
                })
              }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalEffectif"
                    value={
                      dataModalEffectif.observation
                        ? dataModalEffectif.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEffectif({
                        ...dataModalEffectif,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ul class="dropdown-menu">
        <li>
          <a class="dropdown-item" href="#">
            Normal
          </a>
        </li>
        <li>
          <a class="dropdown-item active" href="#">
            Active
          </a>
        </li>
        <li>
          <a class="dropdown-item disabled" href="#">
            Disabled
          </a>
        </li>
      </ul>

      {/* Modal document */}
      <ModalDocument
        dataModalDocument={dataModalDocument}
        dataModalSuiviDoc={dataModalSuiviDoc}
        indiceDoc={indiceDoc}
        setDataDocument={setDataDocument}
        setDataModalSuiviDoc={setDataModalSuiviDoc}
        dataDocument={dataDocument}
        cell={dataMaj}
        typeDoc={1}
      />

      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default ProfilEntreprise
