import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import Connexion from './pages/connexion/Connexion'
import PassOublier from './pages/connexion/PassOublier'
import Reintialisation_MDP from './pages/connexion/Reintialisation_MDP'
import Home from './pages/Home'
import ChantierTemplate from './pages/template/ChantierTemplate'
import Acceuil from './pages/acceuil/Acceuil'
import PageNotFound from './components/404/PageNotFound'
import ServerNotWork from './components/404/ServerNotWork'
import TableauDeBord from './pages/chantier/planning/TableauDeBord'
import Dashboard from './pages/chantier/planning/Dashboard'
import GestionTache from './pages/chantier/planning/GestionTache'
import Pointage from './pages/chantier/planning/Pointage'
import Favori from './pages/chantier/planning/Favori'
import Retard from './pages/chantier/planning/Retard'
import Observation from './pages/chantier/observation/Observation'
import Media from './pages/chantier/observation/Media'
import ListeEntreprise from './pages/chantier/suivi/ListeEntreprise'
import Contacte from './pages/chantier/contacte/Contacte'
import Rapport from './pages/chantier/rapport/Rapport'
import Penalite from './pages/chantier/penalite/Penalite'
import CarteAdminEtGen from './pages/chantier/suivi/CarteAdminEtGen'
import ProfilEntreprise from './pages/chantier/suivi/ProfilEntreprise'
import ProfilIntervenant from './pages/chantier/suivi/ProfilIntervenant'
import NewCr from './pages/chantier/rapport/NewCr'
import NewFiche from './pages/chantier/rapport/NewFiche'
import NewEvenement from './pages/chantier/rapport/NewEvenement'
import Newpassation from './pages/chantier/rapport/Newpassation'
import NewNoteChantier from './pages/chantier/rapport/NewNoteChantier'
import NewAvancement from './pages/chantier/rapport/NewAvancement'
import DGPF from './pages/chantier/planning/DGPF'
import LastPlanner from './pages/chantier/planning/LastPlanner'
import Profil from './pages/profil/Profil'
import FormatPDF1 from './pages/formatPDF/FormatPDF1'
import OffLine from './pages/chantier/observation/OffLine'
import AddLot from './pages/acceuil/AddLot'
import Courrier from './pages/chantier/Courriers/Courrier'
import Agenda from './pages/chantier/agenda/Agenda'
import OffLineWithPlan from './pages/chantier/observation/OffLineWithPlan'
import OfflineLastPlanner from './pages/chantier/planning/OfflineLastPlanner'
import { store } from './redux'
import {TaostProvider, ToastProvider} from 'react-toast-notifications'
function App() {
  return (
    <ToastProvider>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Connexion />} />
          <Route path="/AddLot" element={<AddLot />} />
          <Route path="/Recuperation_MDP" element={<PassOublier />} />
          <Route path="/Reinitialisation_MDP" element={<Reintialisation_MDP />} />
          <Route path="/chantier" element={<ChantierTemplate />} />
          <Route path="/accueil" element={<Acceuil />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/ServerNotWork" element={<ServerNotWork />} />
          <Route path="/ChantierTemplate" element={<ChantierTemplate />} />
          <Route path="/Planning_Dashboard" element={<Dashboard />} />
          <Route path="/Tableau_de_bord" element={<TableauDeBord />} />
          <Route path="/Planning_GestionTache" element={<GestionTache />} />
          <Route
            path="/Planning_GestionTache_LastPlanner"
            element={<LastPlanner />}
          />
          <Route path="/offline_planning" element={<OfflineLastPlanner />} />
          <Route path="/Planning_Pointage" element={<Pointage />} />
          <Route path="/Planning_Pointage_DGPF" element={<DGPF />} />
          <Route path="/Planning_Favori" element={<Favori />} />
          <Route path="/Planning_Retard" element={<Retard />} />
          <Route path="/Observation_Plan" element={<Observation />} />
          <Route path="/Observation_OffLine" element={<OffLine />} />
          <Route path="/Observation_horsLigne" element={<OffLineWithPlan />} />
          <Route path="/Observation_Media" element={<Media />} />
          <Route path="/SuiviOperationnel" element={<ListeEntreprise />} />
          <Route
            path="/SuiviOperationnel_Profil"
            element={<ProfilEntreprise />}
          />
          <Route
            path="/SuiviOperationnel_Profil_Intervenant"
            element={<ProfilIntervenant />}
          />
          <Route path="/Information_generale" element={<CarteAdminEtGen />} />
          <Route path="/Contact" element={<Contacte />} />
          <Route path="/Rapport_CR" element={<Rapport />} />
          <Route path="/Rapport_NewCR" element={<NewCr />} />
          <Route path="/Rapport_NewFiche" element={<NewFiche />} />
          <Route path="/Rapport_avancement" element={<NewAvancement />} />
          <Route path="/Fiche_de_passation" element={<Newpassation />} />
          <Route path="/Note_de_chantier" element={<NewNoteChantier />} />
          <Route path="/Evenement_de_chantier" element={<NewEvenement />} />
          <Route path="/Penalite" element={<Penalite />} />
          <Route path="/Courrier" element={<Courrier />} />
          <Route path="/Agenda" element={<Agenda />} />
          <Route path="/Format" element={<FormatPDF1 />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
    </ToastProvider>
  )
}

export default App
