import React, { useState, useEffect } from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'
import Multiselect from 'multiselect-react-dropdown'
import { useNavigate } from 'react-router-dom'
import { Button, Upload } from 'antd'
import { db } from '../db/db'
import { v4 as uuidv4 } from 'uuid';
import imageNotFound from '../db/image/image-not-found-scaled.png'
const PinOffLine = ({
  props,
  idPlanSelectedIndexedDB,
  idPlan,
  lot,
  boolPlan,
  setBoolPlan,
  allPins,
  setAllPins,
}) => {
  const navigate = useNavigate()
  //fonction pour formater les dates
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [infoObservation, setInfoObservation] = useState({})
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [bool2, setBool2] = useState(false)
  const [bool, setBool] = useState(true)
  const [top, setTop] = useState(props.top)
  const [left, setLeft] = useState(props.left)
  const [del, setDel] = useState(true)
  const [imageBase64, setImageBase64] = useState(
    props.base64 ? props.base64 : null
  )
  const [description, setDescription] = useState(
    props.description ? props.description : ''
  )
  const [phase, setPhase] = useState(props.phase ? props.phase : '')
  const [status, setStatus] = useState(props.statut ? props.statut : 'designe')
  const [position, setPosition] = useState(props.position ? props.position : '')
  const [avancementActuel, setAvancementActuel] = useState(
    props.avancementActuel ? props.avancementActuel : 0
  )
  const [avancementPrevu, setAvancementPrevu] = useState(
    props.avancementPrevu ? props.avancementPrevu : 0
  )
  const [alever, setAlever] = useState(
    props.aLeverLe ? formatDate(props.aLeverLe) : null
  )
  const [destinataire, setDestinataire] = useState(
    props.destinataire ? props.destinataire : []
  )
  const [observation, setObservation] = useState(
    props.observation ? props.observation : ''
  )
  const [image, setImage] = useState(imageNotFound)
  const [uuid, setUuid] = useState(props.uuid ? props.uuid : '')
  const [tag, setTag] = useState(props.tag ? props.tag : [])
  const [photo, setPhoto] = useState(props.photo ? props.photo : [])
  const [allLot, setAllLot] = useState(lot ? lot : [])
  const [typePin, setTypePin] = useState(props.type ? props.type : 1)
  const [itemStatus, setItemStatus] = useState('Désignée')
  const dataTag = [
    {
      value: 'planification',
    },
    {
      value: 'engagement',
    },
    {
      value: 'securite',
    },
    {
      value: 'installation',
    },
  ]
  const [_id, set_Id] = useState(props._id ? props._id : null)
  const [positionInitialX, setPositionInitialX] = useState('')
  const [positionInitialY, setPositionInitialY] = useState('')
  const save = () => {
    const itemNumber = props.itemNumber
    const uuidHorsLigne = props.uuidHorsLigne
    const dbName = 'myDataBase'
    const tableName = 'plans'
    const request = window.indexedDB.open(dbName)
    request.onsuccess = (event) => {
      const db = event.target.result
      const transaction = db.transaction([tableName], 'readwrite')
      const objectStore = transaction.objectStore(tableName)
      const getRequest = objectStore.get(idPlanSelectedIndexedDB)
      getRequest.onsuccess = async (event) => {
        const data = event.target.result
        const updatedData = { ...data }
        let newMarkers
        let pinToUpdate
        if (_id) {
          pinToUpdate = allPins.find((el) => el._id === _id)
        } else {
          if(uuidHorsLigne){
            pinToUpdate = allPins.find((el) => el.uuidHorsLigne === uuidHorsLigne)
          }else{
            pinToUpdate = null
          }
        }
        if (pinToUpdate) {
          pinToUpdate.isModified = true
          pinToUpdate.archive = false
          pinToUpdate.top = top
          pinToUpdate.left = left
          pinToUpdate.description = description
          pinToUpdate.aLeverLe = alever
          pinToUpdate.statut = status
          pinToUpdate.tag = tag
          pinToUpdate.position = position
          pinToUpdate.destinataire = destinataire
          pinToUpdate.observation = observation
          pinToUpdate.type = typePin
          pinToUpdate.avancementActuel = avancementActuel
          pinToUpdate.avancementPrevu = avancementPrevu
          pinToUpdate.base64 = imageBase64
          pinToUpdate.photo = photo
          if (_id) {
            pinToUpdate._id = _id
          }
          if (_id) {
            newMarkers = allPins.map((el) => {
              if (el._id === _id) {
                return pinToUpdate
              } else {
                return el
              }
            })
          } else {
            newMarkers = allPins.map((el) => {
              if (el.uuidHorsLigne === uuidHorsLigne) {
                return pinToUpdate
              } else {
                return el
              }
            })
          }
          updatedData.pins = newMarkers
          const updateRequest = await objectStore.put(updatedData)
          updateRequest.onsuccess = (event) => {
            setBoolPlan(!boolPlan)
            setBool2(false)
          }
          updateRequest.onerror = (event) => {
            console.error('Erreur lors de la mise à jour :', event.target.error)
            setBoolPlan(!boolPlan)
            setBool2(false)
          }
        } else {
          const pinToAdd = {
            isModified: true,
            itemNumber: itemNumber,
            archive: false,
            top: top,
            left: left,
            description: description,
            aLeverLe: alever,
            statut: status,
            tag: tag,
            position: position,
            destinataire: destinataire,
            observation: observation,
            type: typePin,
            avancementActuel: avancementActuel,
            avancementPrevu: avancementPrevu,
            photo: photo,
            base64: imageBase64,
            uuidHorsLigne:uuidv4()
          }
          newMarkers = [...allPins, pinToAdd]
          updatedData.pins = newMarkers
          const updateRequest = await objectStore.put(updatedData)
          updateRequest.onsuccess = (event) => {
            setBoolPlan(!boolPlan)
            setBool2(false)
          }
          updateRequest.onerror = (event) => {
            console.error('Erreur lors de la mise à jour :', event.target.error)
            setBoolPlan(!boolPlan)
            setBool2(false)
          }
        }
      }

      getRequest.onerror = (event) => {
        console.error(
          'Erreur lors de la récupération des données :',
          event.target.error
        )
        setBoolPlan(!boolPlan)
        setBool2(false)
      }
    }
  }
  const delPin = () => {
    const itemNumber = props.itemNumber
    const uuidHorsLigne = props.uuidHorsLigne
    const dbName = 'myDataBase'
    const tableName = 'plans'
    const request = window.indexedDB.open(dbName)
    request.onsuccess = (event) => {
      const db = event.target.result
      const transaction = db.transaction([tableName], 'readwrite')
      const objectStore = transaction.objectStore(tableName)
      const getRequest = objectStore.get(idPlanSelectedIndexedDB)
      getRequest.onsuccess = (event) => {
        const data = event.target.result
        const updatedData = { ...data }
        const markers = updatedData.pins
        let newMarkers
        let pinToUpdate
        if (_id) {
          pinToUpdate = markers.find((el) => el._id === _id)
        } else {
          if(uuidHorsLigne){
            pinToUpdate = markers.find((el) => el.uuidHorsLigne === uuidHorsLigne)
          }else{
            pinToUpdate = null
          }
        }
        if (pinToUpdate) {
          pinToUpdate.isModified = true
          pinToUpdate.archive = true
          pinToUpdate.top = top
          pinToUpdate.left = left
          pinToUpdate.description = description
          pinToUpdate.aLeverLe = alever
          pinToUpdate.statut = status
          pinToUpdate.tag = tag
          pinToUpdate.position = position
          pinToUpdate.destinataire = destinataire
          pinToUpdate.observation = observation
          pinToUpdate.type = typePin
          pinToUpdate.avancementActuel = avancementActuel
          pinToUpdate.avancementPrevu = avancementPrevu
          pinToUpdate.photo = photo
          if (_id) {
            pinToUpdate._id = _id
          }
          if (_id) {
            newMarkers = markers.map((el) => {
              if (el._id === _id) {
                return pinToUpdate
              } else {
                return el
              }
            })
          } else {
            newMarkers = markers.map((el) => {
              if (el.uuidHorsLigne === uuidHorsLigne) {
                return pinToUpdate
              } else {
                return el
              }
            })
          }
          updatedData.pins = newMarkers
          const updateRequest = objectStore.put(updatedData)
          updateRequest.onsuccess = (event) => {
            setBoolPlan(!boolPlan)
            setBool2(false)
          }
          updateRequest.onerror = (event) => {
            console.error('Erreur lors de la mise à jour :', event.target.error)
            setBoolPlan(!boolPlan)
            setBool2(false)
          }
        } else {
          //setAllPins(markers)
          setBoolPlan(!boolPlan)
          setBool2(false)
        }
      }

      getRequest.onerror = (event) => {
        console.error(
          'Erreur lors de la récupération des données :',
          event.target.error
        )
        setBoolPlan(!boolPlan)
        setBool2(false)
      }
    }
  }
  useEffect(() => {
    if (props.base64) {
      setImage(props.base64.buffer)
    }
  }, [])
  return (
    <>
      {del && !props.archive && (
        <div
          className="d-flex"
          data-toggle="tooltip"
          data-placement="top"
          title={`# ${props.uniqueId ? +props.uniqueId : 'Non défini'}`}
          style={{
            alignItems: 'center',
            cursor: 'pointer',
            padding: '5px 5px',
          }}
          draggable="true"
          onDragStart={(ev) => {
            setPositionInitialX(ev.clientX)
            setPositionInitialY(ev.clientY)
          }}
          onDragEnd={(ev) => {
            const plan = document.querySelector('.planCible')
            const planWidth = plan.offsetWidth
            const planHeight = plan.offsetHeight
            const grandEcranWidth = window.innerWidth
            const info = {
              grandEcranWidth: grandEcranWidth,
              planWidth: planWidth,
              planHeight: planHeight,
              posLeftInitPinGrandEcranPx: positionInitialX,
              posTopInitPinGrandEcranPx: positionInitialY,
              nouvPosLeftPinGrandEcranPx: ev.clientX,
              nouvPosTopPinGrandEcranPx: ev.clientY,
              leftPosPlanPct: props.left,
              topPosPlanPct: props.top,
            }
            const newValue = calculNewPosition(info)
            //save(newValue.newTop, newValue.newLeft)
          }}
          onClick={(e) => {
            setBool2(true)
          }}>
          <GeoAltFill
            color={`${
              props.type == 1
                ? '#6366F1'
                : props.type == 2
                ? 'red'
                : props.type == 3
                ? '#2DD4BF'
                : props.type == 4
                ? 'orange'
                : props.type == 5
                ? '#02A0FC'
                : '#6366F1'
            }`} /* color="red" */
            size={28}
          />
        </div>
      )}
      {bool && bool2 ? (
        <>
          <div
            className="docPopup"
            style={{
              zIndex: '20000',
              right: `${props.left > 50 ? '0' : '1'}`,
            }}>
            <div
              className="container-fluid mb-2"
              style={{ position: 'relative' }}>
              <div className="btn-group dropright">
                <span
                  data-bs-toggle="dropdown"
                  style={{
                    height: '32px',
                    minWidth: '210px',
                    padding: '6px',
                    position: 'absolute',
                    display: 'flex',
                    left: '0',
                    backgroundColor: `${
                      typePin == 1
                        ? '#c2e5ff'
                        : typePin == 2
                        ? '#FFF1F2'
                        : typePin == 3
                        ? '#f0fff0'
                        : typePin == 4
                        ? '#ffe7c2'
                        : typePin == 5
                        ? '#CCF8FE'
                        : '#c2e5ff'
                    }`,
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  title="Déplacer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill={`${
                      typePin == 1
                        ? '#6366F1'
                        : typePin == 2
                        ? 'red'
                        : typePin == 3
                        ? '#2DD4BF'
                        : typePin == 4
                        ? 'orange'
                        : typePin == 5
                        ? '#02A0FC'
                        : '#6366F1'
                    }`}
                    //fill="red"
                    style={{ width: '20px', height: '20px' }}>
                    <path
                      fillRule="evenodd"
                      d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                      marginLeft: '10px',
                    }}>
                    {`${
                      typePin == 1
                        ? 'Visites'
                        : typePin == 2
                        ? 'Réserves'
                        : typePin == 3
                        ? 'Réception de supports'
                        : typePin == 4
                        ? 'Avancements'
                        : typePin == 5
                        ? 'Bons à fermer'
                        : 'Visites'
                    }`}{' '}
                    # {props.uniqueId}
                  </span>
                </span>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li
                    style={{
                      backgroundColor: `${typePin == 1 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(1)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Visites
                    </a>
                  </li>
                  <li
                    style={{
                      backgroundColor: `${typePin == 2 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(2)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Réserves
                    </a>
                  </li>
                  <li
                    style={{
                      backgroundColor: `${typePin == 3 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(3)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Réception de supports
                    </a>
                  </li>
                  <li
                    style={{
                      backgroundColor: `${typePin == 4 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(4)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Avancements
                    </a>
                  </li>
                  <li
                    style={{
                      backgroundColor: `${typePin == 5 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(5)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Bons à fermer
                    </a>
                  </li>
                </ul>
              </div>
              <i
                class="ti ti-x"
                style={{
                  position: 'absolute',
                  right: '0',
                  cursor: 'pointer',
                  color: 'gray',
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
                onClick={(e) => {
                  setBool2(false)
                }}></i>
            </div>
            <form>
              <div
                class="mb-3"
                style={{
                  marginTop: '50px',
                  width: '100%',
                }}>
                <span>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Statut:
                  </label>
                  <button
                    data-bs-toggle="dropdown"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent',
                      color: '#475569',
                      width: '100%',
                      height: '38px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: 'solid  #AFBACA 1px',
                    }}>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#475569',
                      }}>
                      {status == 'designe'
                        ? 'désignée'
                        : status == 'traite'
                        ? 'Traitée'
                        : status == 'annule'
                        ? 'Annulée'
                        : itemStatus}
                    </span>
                    <i className="ti ti-selector"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(ev) => {
                          ev.preventDefault()
                          setStatus('designe')
                        }}>
                        Désignée
                      </a>
                    </li>
                    <li
                      onClick={(ev) => {
                        ev.preventDefault()
                        setStatus('traite')
                      }}>
                      <a className="dropdown-item">Traitée</a>
                    </li>
                    <li
                      onClick={(ev) => {
                        ev.preventDefault()
                        setStatus('annule')
                      }}>
                      <a className="dropdown-item">Annulée</a>
                    </li>
                  </ul>
                </span>
              </div>
              <div className="mb-3">
                <span style={{ marginLeft: '8px' }}>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Observation:
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    defaultValue={observation ? observation : ''}
                    onChange={(ev) => {
                      setObservation(ev.target.value)
                    }}></textarea>
                  {/* <input
                    type="text"
                    class="form-control"
                    defaultValue={observation ? observation : ''}
                    style={{ width: '100%', border: 'solid #AFBACA 1px' }}
                    onChange={(e) => {
                      setObservation(e.target.value)
                    }}
                  /> */}
                </span>
              </div>
              <div
                class="mb-3"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ width: '220px' }}>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Tag:
                  </label>
                  <Multiselect
                    displayValue="value"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck(e) {
                      setTag(e)
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck(e) {
                      setTag(e)
                    }}
                    options={dataTag}
                    selectedValues={tag}
                    placeholder="Tag"
                  />
                </span>
                <span style={{ marginLeft: '10px' }}>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Position:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    defaultValue={position ? position : ''}
                    style={{ width: '220px', border: 'solid #AFBACA 1px' }}
                    onChange={(e) => {
                      setPosition(e.target.value)
                    }}
                  />
                </span>
              </div>
              <div
                class="mb-3"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ width: '220px' }}>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Destinataire(s):
                  </label>
                  <Multiselect
                    displayValue="titre"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck(e) {
                      setDestinataire(e)
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck(e) {
                      setDestinataire(e)
                    }}
                    options={allLot}
                    selectedValues={destinataire}
                    placeholder="Destinataire(s)"
                  />
                </span>
                <span>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    A lever le:
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    style={{ width: '220px', border: 'solid #AFBACA 1px' }}
                    defaultValue={alever}
                    onChange={(e) => {
                      setAlever(e.target.value)
                    }}
                  />
                </span>
              </div>
              {typePin == 4 && (
                <>
                  <div
                    class="mb-3"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <span style={{ width: '220px' }}>
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        style={{
                          color: '#475569',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}>
                        Avancement prévu (%):
                      </label>
                      <input
                        type="number"
                        min={0}
                        max={100}
                        className="form-control"
                        style={{ width: '220px', border: 'solid #AFBACA 1px' }}
                        defaultValue={avancementPrevu}
                        onChange={(e) => {
                          setAvancementPrevu(e.target.value)
                        }}
                      />
                    </span>
                    <span>
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        style={{
                          color: '#475569',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}>
                        Avancement actuel (%):
                      </label>
                      <input
                        type="number"
                        min={0}
                        max={100}
                        className="form-control"
                        style={{ width: '220px', border: 'solid #AFBACA 1px' }}
                        defaultValue={avancementActuel}
                        onChange={(e) => {
                          setAvancementActuel(e.target.value)
                        }}
                      />
                    </span>
                  </div>
                </>
              )}
              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  {props.photo
                    ? `${props.photo.length} image(s) enregistrée(s) en ligne`
                    : '0 image enregistrée en ligne'}
                </label>
              </div>
              {!props.base64 && (
                <>
                  <div className="mb-3">
                    <span>
                      <label htmlFor="" className="form-label">
                        Image:
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        accept="image/png,image/jpg,image/jpeg"
                        onChange={(ev) => {
                          const file = ev.target.files[0]
                          const reader = new FileReader()
                          reader.onload = function (event) {
                            const base64String = event.target.result
                            setImageBase64({
                              name: file.name,
                              lastModified: file.lastModified,
                              size: file.size,
                              type: file.type,
                              buffer: base64String,
                            })
                          }
                          reader.readAsDataURL(file)
                        }}
                      />
                    </span>
                  </div>
                </>
              )}
              {props.base64 && (
                <>
                  <div
                    className="mb-3 imageCible"
                    style={{ position: 'relative' }}>
                    <a href={image} target="_blank" style={{ width: '100%' }}>
                      <img
                        src={image}
                        alt=""
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </a>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        e.preventDefault()
                        const imageCible = document.querySelector('.imageCible')
                        imageCible.style = 'display:none'
                        setImageBase64(null)
                      }}
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '15px',
                      }}>
                      <i className="ti ti-trash"></i>
                    </button>
                  </div>
                </>
              )}
              <div
                className="d-flex"
                style={{ marginTop: '12px', justifyContent: 'flex-end' }}>
                <button
                  className="btn"
                  style={{
                    padding: '8px 16px',
                    border: '1px solid #E11D48',
                    backgroundColor: 'transparent',
                    borderRadius: '8px',
                    color: '#E11D48',
                  }}
                  onClick={(e) => {
                    if (
                      confirm(
                        "Êtes-vous certain(e) de vouloir supprimer l'observation ? "
                      )
                    ) {
                      setBool2(false)
                      setDel(false)
                      delPin()
                    } else {
                      e.preventDefault()
                    }
                  }}>
                  Supprimer
                </button>
                <button
                  className="btn"
                  style={{
                    backgroundColor: '#4F46E5',
                    padding: '8px 16px',
                    color: 'white',
                    borderRadius: '8px',
                    marginLeft: '12px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setBool2(false)
                    save()
                  }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default PinOffLine
