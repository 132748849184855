import React, { useEffect, useState } from 'react'
import { ReactTabulator } from 'react-tabulator'
import {
  updateData,
  addDataCarte,
  delData,
} from '../../../../helpers/suivi/suivi'
import ModalDocument from '../../suivi/component/ModalDocument'
import { useToasts } from 'react-toast-notifications'
import trash from '../../../../assets/images/Kolos_logo/trash.png'
import { Button, Upload } from 'antd'
import axios from 'axios'
import { searchOptimizedData } from '../../../../helpers/recherche/recherche'
const TableIntervenants = ({
  item5,
  setItem5,
  classement,
  itemInt,
  setItemInt,
}) => {
  const { addToast } = useToasts()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [item, setItem] = useState('Engagements')
  const [item1, setItem1] = useState('')
  const [imageAction, setImageAction] = useState()
  const [dataEngagement, setDataEngagement] = useState([])
  const [dataAdministratif, setDataAdministratif] = useState([])
  const [dataDocument, setDataDocument] = useState([])
  const [dataModalEngagement, setDataModalEngagement] = useState({})
  const [dataModalAdministration, setDataModalAdministration] = useState({})
  const [dataModalDocument, setDataModalDocument] = useState({})
  const [dataModalSuiviDoc, setDataModalSuiviDoc] = useState([])
  const [dataModalAction, setDataModalAction] = useState({})
  const [dataMaj, setDataMaj] = useState()
  //date Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  //Boutton de suppression
  const button = (delFunction) => (cell) => {
    const delIcons = document.createElement('img')
    delIcons.src = trash
    delIcons.style = 'width:24px;height:24px'
    //create delete button
    var delBt = document.createElement('button')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.type = 'button'
    delBt.appendChild(delIcons)
    delBt.classList.add('btn')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)
    return buttonHolder
  }
  //urgence
  const inputUrgence = (cell) => {
    const ul = document.createElement('ul')
    ul.className = 'dropdown-menu'
    const option1 = document.createElement('li')
    option1.className = 'dropdown-item'
    option1.innerText = 'Faible'
    option1.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(1)
    })
    const option2 = document.createElement('li')
    option2.className = 'dropdown-item'
    option2.innerText = 'Important'
    option2.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(2)
    })
    const option3 = document.createElement('li')
    option3.className = 'dropdown-item'
    option3.innerText = 'Primordial'
    option3.addEventListener('click', function (e) {
      e.preventDefault()
      cell.setValue(3)
    })
    ul.appendChild(option1)
    ul.appendChild(option2)
    ul.appendChild(option3)
    const btn = document.createElement('button')
    btn.className = 'btn'
    btn.type = 'button'
    btn.addEventListener('click', function (e) {
      e.preventDefault()
    })
    btn.setAttribute('data-bs-toggle', 'dropdown')
    const span = document.createElement('span')
    const icon = document.createElement('i')
    icon.className = 'ti ti-selector'
    if (cell.getValue() == 1) {
      btn.style =
        'color:#0D9488;background-color:rgba(45, 212, 191, 0.15);width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Faible'
    }
    if (cell.getValue() == 2) {
      btn.style =
        'color:#F59E0B;background-color:#FEF3C7;width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Important'
    }
    if (cell.getValue() == 3) {
      btn.style =
        'color:#FB7185;background-color:#FFF1F2;width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Primordial'
    }
    if (!cell.getValue()) {
      btn.style =
        'color:#0D9488;background-color:rgba(45, 212, 191, 0.15);width:150px;display:flex;justify-content:space-between'
      span.innerText = 'Faible'
    }
    btn.appendChild(span)
    btn.appendChild(icon)
    const container = document.createElement('div')
    container.className = 'dropdown dropend'
    container.style = 'position:absolute;z-index:999'
    container.appendChild(btn)
    container.appendChild(ul)
    return container
  }
  //input simple texte
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  //checkbox
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
      if (cell.getValue()) {
        console.log('ok')
        if (
          (data.prevuLe == undefined) |
          (data.prevuLe == '') |
          (data.prevuLe == null)
        ) {
          data.prevuLe = new Date(Date.now()).toISOString()
        }
        if (
          (data.faitLe == undefined) |
          (data.faitLe == '') |
          (data.faitLe == null)
        ) {
          data.faitLe = new Date(Date.now()).toISOString()
        }
        console.log(data)
      } else {
      }
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }
  //Modal Action
  const modalAction = (value) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-download')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalActions')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      setDataMaj(cell)
      setDataModalAction(cell.getData())
      setImageAction(null)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  const delEngagements = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'carteEngagement',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delAdministratifs = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'carteAdmin',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const delDocuments = async (cell) => {
    try {
      await delData(
        cell.getData(),
        'carteDoc',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
    } catch (err) {
      addToast(err, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  const engagementEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(
          data,
          `carteEngagement`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(
          data,
          `carteEngagement`,
          infoUser.token,
          infoChantier.planning
        )
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `carteEngagement`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(
            data,
            `carteEngagement`,
            infoUser.token,
            infoChantier.planning
          )
          cell.getRow().update(data)
        }
      } else {
        updateData(
          data,
          `carteEngagement`,
          infoUser.token,
          infoChantier.planning
        )
          .then((res) => {
            console.log(res)
            if (res.data) {
              cell.getRow().update(res.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const administratifEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'faitLe') {
      if (
        (data.faitLe == '') |
        (data.faitLe == undefined) |
        (data.faitLe == null)
      ) {
        data.statut = false
        updateData(data, `carteAdmin`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `carteAdmin`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.faitLe == '') |
            (data.faitLe == undefined) |
            (data.faitLe == null)
          ) {
            data.faitLe = new Date(Date.now()).toISOString()
            updateData(
              data,
              `carteAdmin`,
              infoUser.token,
              infoChantier.planning
            )
            cell.getRow().update(data)
          }
        } else {
          data.faitLe = ''
          updateData(data, `carteAdmin`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, 'carteAdmin', infoUser.token, infoChantier.planning)
      }
    }
  }
  const documentEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    if (cell.getField() == 'diffusion') {
      if (
        (data.diffusion == '') |
        (data.diffusion == undefined) |
        (data.diffusion == null)
      ) {
        data.statut = false
        updateData(
          data,
          `carteDoc`,
          infoUser.token,
          infoChantier.planning
        ).then((e) => {})
        cell.getRow().update(data)
      } else {
        data.statut = true
        updateData(data, `carteDoc`, infoUser.token, infoChantier.planning)
        cell.getRow().update(data)
      }
    } else {
      if (cell.getField() == 'statut') {
        if (data.statut) {
          if (
            (data.diffusion == '') |
            (data.diffusion == undefined) |
            (data.diffusion == null)
          ) {
            data.diffusion = new Date(Date.now()).toISOString()
            updateData(data, `carteDoc`, infoUser.token, infoChantier.planning)
            cell.getRow().update(data)
          }
        } else {
          data.diffusion = ''
          updateData(data, `carteDoc`, infoUser.token, infoChantier.planning)
          cell.getRow().update(data)
        }
      } else {
        updateData(data, `carteDoc`, infoUser.token, infoChantier.planning)
      }
    }
  }

  const addEngagementRow = async (id) => {
    //setWait(true)
    const result = await addDataCarte(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: id,
        planning: infoChantier.planning,
      },
      'carteEngagement',
      id,
      infoUser.token
    ).then((res) => {
      return res
    })
    setDataEngagement([{ ...result }, ...dataEngagement])
  }
  const addAdministratifRow = async (id) => {
    const result = await addDataCarte(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: id,
        planning: infoChantier.planning,
      },
      'carteAdmin',
      id,
      infoUser.token
    ).then((e) => {
      console.log(e)
      return e
    })
    setDataAdministratif([{ ...result }, ...dataAdministratif])
  }
  const addDocumentRow = async (id) => {
    await addDataCarte(
      {
        titre: 'New',
        noteLe: Date.now(),
        entreprise: id,
        planning: infoChantier.planning,
      },
      'carteDoc',
      id,
      infoUser.token
    ).then((e) => {
      console.log(e)
      if (e.name == 'AxiosError' || e.message == 'erreur') {
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
      } else {
        setDataDocument([{ ...e }, ...dataDocument])
      }
    })
  }
  const maj = (cell, info) => {
    console.log(cell.getValue())
    cell.setValue(info)
  }
  //Modal Engagement
  const modalEngagement = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEngagements')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalEngagement(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal Administratif
  const modalAdministratif = (delFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')

    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAdministratifs')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      setDataMaj(cell)
      setDataModalAdministration(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)

    return buttonHolder
  }
  //Modal document
  const modalDocument = (dellFunction) => (cell) => {
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-plus')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalDoc')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      setDataModalDocument(cell.getData())
      setDataModalSuiviDoc(
        cell.getData().suiviDocs ? cell.getData().suiviDocs : []
      )
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }
  const colonneEngagement = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Engagements', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    {
      title: 'Urgence',
      minWidth: 170,
      field: 'urgence',
      formatter: inputUrgence,
    },
    { title: 'PM', field: 'pm', width: 100, formatter: checkbox },
    { title: 'DQP', field: 'dqp', width: 100, formatter: checkbox },
    { title: 'Tenue', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalEngagement(),
    },
    {
      title: 'Action',
      width: 100,
      formatter: modalAction(),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delEngagements),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneAdministratif = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Titre', field: 'titre', formatter: simpleInput },
    { title: 'Noté Le', field: 'noteLe', formatter: date },
    { title: 'Prévu Le', field: 'prevuLe', formatter: date },
    { title: 'Fait Le', field: 'faitLe', formatter: date },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'observation',
      width: 100,
      formatter: modalAdministratif(),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delAdministratifs),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const colonneDocument = [
    {
      title: 'Pour mémoire',
      field: 'pourMemoire',
      width: 100,
      formatter: checkbox,
    },
    { title: 'Titre', field: 'titre', formatter: simpleInput },
    { title: 'Phase', field: 'phase', formatter: simpleInput },
    { title: 'Type', field: 'type', formatter: simpleInput },
    { title: 'Niveau', field: 'niveau', formatter: simpleInput },
    { title: 'Zone', field: 'zone', formatter: simpleInput },
    { title: 'Indice', field: 'indice', formatter: simpleInput },
    { title: 'Date BPE', field: 'dateBPE', formatter: date },
    { title: 'Prévu Le', field: 'prevision', formatter: date },
    { title: 'Diffusé Le', field: 'diffusion', formatter: date },
    { title: 'Visa', field: 'visa', formatter: simpleInput },
    { title: 'Statut', field: 'statut', width: 100, formatter: checkbox },
    {
      title: 'Details',
      field: 'functionButton',
      formatter: modalDocument(),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      formatter: button(delDocuments),
      width: 100,
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const indiceDoc = [
    { value: 'A' },
    { value: 'B' },
    { value: 'C' },
    { value: 'D' },
    { value: 'E' },
    { value: 'F' },
    { value: 'G' },
    { value: 'H' },
    { value: 'I' },
    { value: 'J' },
    { value: 'K' },
    { value: 'L' },
    { value: 'M' },
    { value: 'N' },
    { value: 'O' },
    { value: 'P' },
    { value: 'Q' },
    { value: 'R' },
    { value: 'S' },
    { value: 'T' },
    { value: 'U' },
    { value: 'V' },
    { value: 'W' },
    { value: 'X' },
    { value: 'Y' },
    { value: 'Z' },
  ]
  //fonction pour le nav bar scroll horizontal
  const right = () => {
    const element = document.querySelector('.wrapper3')
    console.log(element.scrollLeft)
    const item = document.querySelector('.item3')
    element.scrollLeft = element.scrollLeft + item.clientWidth + 10
  }

  const left = () => {
    const element = document.querySelector('.wrapper3')
    const item = document.querySelector('.item3')
    element.scrollLeft = element.scrollLeft - item.clientWidth + 10
  }
  useEffect(() => {
    if (classement.length > 0) {
      const selectedData = classement[0]
      setItem1(selectedData._id)
      if (selectedData.profile.cEngagement) {
        setDataEngagement(selectedData.profile.cEngagement)
      }
      if (selectedData.profile.cAdmin) {
        setDataAdministratif(selectedData.profile.cAdmin)
      }
      if (selectedData.profile.cDocuments) {
        setDataDocument(selectedData.profile.cDocuments)
      }
    }
  }, [])
  return (
    <>
      <div class="row">
        <div class="col-12">
          <div
            class="card"
            style={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}>
            <div class="card-body" style={{ padding: '0px' }}>
              <form action="" method="post" id="custom-step">
                <nav>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '32px',
                    }}>
                    <div
                      style={{
                        width: '60%',
                        display: 'flex',
                        flexWrap: 'nowrap',
                      }}>
                      <button
                        className="btn"
                        style={{
                          width: '10px',
                          height: '52px',
                          backgroundColor: 'rgba(226, 232, 240, 0.6)',
                          borderRadius: '0px',
                          borderTopLeftRadius: '8px',
                          borderBottomLeftRadius: '8px',
                          borderTop: '1px solid #CBD5E1',
                          borderBottom: '1px solid #CBD5E1',
                          borderLeft: '1px solid #CBD5E1',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          left()
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          style={{
                            width: '20px',
                            height: '20px',
                          }}>
                          <path
                            fillRule="evenodd"
                            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      <div className="wrapper3">
                        {classement &&
                          classement.map((e) => {
                            return (
                              <>
                                <div
                                  className="item3"
                                  onClick={(el) => {
                                    el.preventDefault()
                                    setItem1(e._id)
                                    if (e.profile.cEngagement) {
                                      setDataEngagement(e.profile.cEngagement)
                                    }
                                    if (e.profile.cAdmin) {
                                      setDataAdministratif(e.profile.cAdmin)
                                    }
                                    if (e.profile.cDocuments) {
                                      setDataDocument(e.profile.cDocuments)
                                    }
                                    //assigner les data engagement, admin, doc
                                    //modifier item1 en engagement
                                    setItemInt(!itemInt)
                                  }}>
                                  <span
                                    style={
                                      item1 == e._id
                                        ? {
                                            color: '#F8FAFC',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            backgroundColor: '#6366F1',
                                            padding: '8px 16px',
                                            borderRadius: '8px',
                                          }
                                        : {
                                            color: '#334155',
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            cursor: 'pointer',
                                          }
                                    }>
                                    {e.titre ? e.titre : '---'}
                                  </span>
                                </div>
                              </>
                            )
                          })}
                      </div>
                      <button
                        className="btn"
                        style={{
                          width: '10px',
                          height: '52px',
                          backgroundColor: 'rgba(226, 232, 240, 0.6)',
                          borderRadius: '0px',
                          borderTopRightRadius: '8px',
                          borderBottomRightRadius: '8px',
                          textAlign: 'center',
                          position: 'relative',
                          borderTop: '1px solid #CBD5E1',
                          borderBottom: '1px solid #CBD5E1',
                          borderRight: '1px solid #CBD5E1',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          right()
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          style={{
                            width: '20px',
                            height: '20px',
                            position: 'absolute',
                            left: '0',
                            top: '17',
                          }}>
                          <path
                            fillRule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      style={{
                        width: '30%',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                      }}>
                      <button
                        style={{
                          border: '1px solid #CBD5E1',
                          borderRadius: '8px',
                          padding: '8px',
                          width: '45px',
                          height: '45px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          backgroundColor: 'transparent',
                          marginRight: '8px',
                        }}
                        id="dropdownMenuButton5"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#6366F1"
                          style={{
                            width: '20px',
                            height: '20px',
                          }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
                          />
                        </svg>
                      </button>

                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton5"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}>
                        <li
                          onClick={(ev) => {
                            ev.preventDefault()
                            setItem5('entreprise')
                          }}>
                          <a href="#" class="dropdown-item">
                            Entreprises
                          </a>
                        </li>
                        <li
                          style={
                            item5 == 'intervenant'
                              ? {
                                  backgroundColor: 'lightblue',
                                }
                              : {}
                          }>
                          <a href="#" class="dropdown-item">
                            Intervenants
                          </a>
                        </li>
                      </ul>
                      <span
                        style={{
                          display: 'flex',
                          backgroundColor: '#EBEFF5',
                          borderRadius: '8px',
                          padding: '8px 10px',
                          alignItems: 'center',
                        }}>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#6366F1"
                            style={{
                              height: '24px',
                              width: '24px',
                            }}>
                            <path
                              fillRule="evenodd"
                              d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zM21 9.375A.375.375 0 0020.625 9h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 00.375-.375v-1.5zM10.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h7.5zM3.375 15h7.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-7.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h7.5a.375.375 0 00.375-.375v-1.5A.375.375 0 0010.875 9h-7.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                        <select
                          className="form-select"
                          style={{
                            backgroundColor: 'transparent',
                          }}
                          onChange={(ev) => {
                            ev.preventDefault()
                            setItem(ev.target.value)
                          }}>
                          <option value="Engagements">Engagements</option>
                          <option value="Administratifs">Administratifs</option>
                          <option value="Documents">Documents</option>
                        </select>
                      </span>
                    </div>
                  </div>
                </nav>
                <div>
                  {classement &&
                    classement.map((e, i) => {
                      return (
                        <>
                          {item == 'Engagements' && item1 == e._id && (
                            <div
                              style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                boxShadow:
                                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                padding: '16px 0px 24px',
                              }}>
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  padding: '0px 0px 0px 40px',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <span
                                    style={{
                                      color: '#334155',
                                      fontSize: '20px',
                                      fontWeight: '600',
                                    }}>
                                    Engagements
                                  </span>
                                  <span
                                    style={{
                                      backgroundColor: '#E0E7FF',
                                      borderRadius: '24px',
                                      padding: '6px 12px',
                                      color: '#64748B',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      marginLeft: '8px',
                                      maxHeight: '32px',
                                      width: 'auto',
                                    }}>
                                    {dataEngagement.length > 0
                                      ? dataEngagement.length
                                      : '0'}{' '}
                                    tâches
                                  </span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      padding: '8px 12px 8px 12px',
                                      backgroundColor:
                                        'rgba(226, 232, 240, 0.6)',
                                      borderRadius: '8px',
                                      height: '40px',
                                    }}>
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="#818CF8"
                                        style={{
                                          width: '18px',
                                          height: '18px',
                                        }}>
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Recherche"
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                      }}
                                      onChange={(ev)=>{
                                        ev.preventDefault()
                                        ev.stopPropagation()
                                        const newData = searchOptimizedData(ev.target.value,dataEngagement)
                                        setDataEngagement(newData)
                                      }}
                                    />
                                  </span>
                                  <button
                                    className="btn"
                                    style={{
                                      backgroundColor: '#4F46E5',
                                      color: '#F8FAFC',
                                      marginLeft: '24px',
                                    }}
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      addEngagementRow(e._id)
                                    }}>
                                    + Nouvelle tâche
                                  </button>
                                </div>
                              </div>
                              <div
                                className="cibleTabulatorCr"
                                style={{
                                  width: '100%',
                                  borderTop: 'solid #E2E8F0 1px',
                                  marginTop: '16px',
                                }}>
                                <ReactTabulator
                                  data={dataEngagement ? dataEngagement : []}
                                  columns={colonneEngagement}
                                  layout={'fitColumns'}
                                  events={{
                                    cellEdited: engagementEdited,
                                  }}
                                  options={{
                                    locale: true,
                                    pagination: 'local',
                                    paginationSize: 5,
                                    langs: {
                                      fr: {
                                        pagination: {
                                          first: 'Premier',
                                          first_title: 'Première',
                                          last: 'Dernier',
                                          last_title: 'Dernière',
                                          prev: 'Précédent',
                                          prev_title: 'Précédente',
                                          next: 'Suivant',
                                          next_title: 'Page suivante',
                                          page_size: 'Taille de page',
                                          page_title: 'Afficher la page',
                                          all: 'Tous',
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {item == 'Administratifs' && item1 == e._id && (
                            <div
                              style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                boxShadow:
                                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                padding: '16px 0px 24px',
                              }}>
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  padding: '0px 0px 0px 40px',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <span
                                    style={{
                                      color: '#334155',
                                      fontSize: '20px',
                                      fontWeight: '600',
                                    }}>
                                    Administratifs
                                  </span>
                                  <span
                                    style={{
                                      backgroundColor: '#E0E7FF',
                                      borderRadius: '24px',
                                      padding: '6px 12px',
                                      color: '#64748B',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      marginLeft: '8px',
                                      maxHeight: '32px',
                                      width: 'auto',
                                    }}>
                                    {dataAdministratif.length > 0
                                      ? dataAdministratif.length
                                      : '0'}{' '}
                                    tâches
                                  </span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      padding: '8px 12px 8px 12px',
                                      backgroundColor:
                                        'rgba(226, 232, 240, 0.6)',
                                      borderRadius: '8px',
                                      height: '40px',
                                    }}>
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="#818CF8"
                                        style={{
                                          width: '18px',
                                          height: '18px',
                                        }}>
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Recherche"
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                      }}
                                      onChange={(ev)=>{
                                        ev.preventDefault()
                                        ev.stopPropagation()
                                        const newData = searchOptimizedData(ev.target.value,dataAdministratif)
                                        setDataAdministratif(newData)
                                      }}
                                    />
                                  </span>
                                  <button
                                    className="btn"
                                    style={{
                                      backgroundColor: '#4F46E5',
                                      color: '#F8FAFC',
                                      marginLeft: '24px',
                                    }}
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      addAdministratifRow(e._id)
                                    }}>
                                    + Nouvelle tâche
                                  </button>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: '100%',
                                  borderTop: 'solid #E2E8F0 1px',
                                  marginTop: '16px',
                                }}>
                                <ReactTabulator
                                  data={
                                    dataAdministratif ? dataAdministratif : []
                                  }
                                  columns={colonneAdministratif}
                                  layout={'fitColumns'}
                                  events={{
                                    cellEdited: administratifEdited,
                                  }}
                                  options={{
                                    locale: true,
                                    pagination: 'local',
                                    paginationSize: 5,
                                    langs: {
                                      fr: {
                                        pagination: {
                                          first: 'Premier',
                                          first_title: 'Première',
                                          last: 'Dernier',
                                          last_title: 'Dernière',
                                          prev: 'Précédent',
                                          prev_title: 'Précédente',
                                          next: 'Suivant',
                                          next_title: 'Page suivante',
                                          page_size: 'Taille de page',
                                          page_title: 'Afficher la page',
                                          all: 'Tous',
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {item == 'Documents' && item1 == e._id && (
                            <div
                              style={{
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                boxShadow:
                                  '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                                padding: '16px 0px 24px',
                              }}>
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  padding: '0px 0px 0px 40px',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}>
                                  <span
                                    style={{
                                      color: '#334155',
                                      fontSize: '20px',
                                      fontWeight: '600',
                                    }}>
                                    Documents
                                  </span>
                                  <span
                                    style={{
                                      backgroundColor: '#E0E7FF',
                                      borderRadius: '24px',
                                      padding: '6px 12px',
                                      color: '#64748B',
                                      fontSize: '14px',
                                      fontWeight: '600',
                                      marginLeft: '8px',
                                      maxHeight: '32px',
                                      width: 'auto',
                                    }}>
                                    {dataDocument.length > 0
                                      ? dataDocument.length
                                      : '0'}{' '}
                                    tâches
                                  </span>
                                </div>
                                <div>
                                  <span
                                    style={{
                                      padding: '8px 12px 8px 12px',
                                      backgroundColor:
                                        'rgba(226, 232, 240, 0.6)',
                                      borderRadius: '8px',
                                      height: '40px',
                                    }}>
                                    <span>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="#818CF8"
                                        style={{
                                          width: '18px',
                                          height: '18px',
                                        }}>
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="Recherche"
                                      style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                      }}
                                      onChange={(ev)=>{
                                        ev.preventDefault()
                                        ev.stopPropagation()
                                        const newData = searchOptimizedData(ev.target.value,dataDocument)
                                        setDataDocument(newData)
                                      }}
                                    />
                                  </span>
                                  <button
                                    className="btn"
                                    style={{
                                      backgroundColor: '#4F46E5',
                                      color: '#F8FAFC',
                                      marginLeft: '24px',
                                    }}
                                    onClick={(ev) => {
                                      ev.preventDefault()
                                      addDocumentRow(e._id)
                                    }}>
                                    + Nouvelle tâche
                                  </button>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: '100%',
                                  borderTop: 'solid #E2E8F0 1px',
                                  marginTop: '16px',
                                }}>
                                <ReactTabulator
                                  data={dataDocument ? dataDocument : []}
                                  columns={colonneDocument}
                                  layout={'fitColumns'}
                                  events={{
                                    cellEdited: documentEdited,
                                  }}
                                  options={{
                                    locale: true,
                                    pagination: 'local',
                                    paginationSize: 5,
                                    langs: {
                                      fr: {
                                        pagination: {
                                          first: 'Premier',
                                          first_title: 'Première',
                                          last: 'Dernier',
                                          last_title: 'Dernière',
                                          prev: 'Précédent',
                                          prev_title: 'Précédente',
                                          next: 'Suivant',
                                          next_title: 'Page suivante',
                                          page_size: 'Taille de page',
                                          page_title: 'Afficher la page',
                                          all: 'Tous',
                                        },
                                      },
                                    },
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )
                    })}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Action */}
      <div class="modal fade" id="myModalActions">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Importer une photo
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form>
              {' '}
              <div class="modal-body">
                {dataModalAction?.actionPhoto?.length < 4 ? (
                  <>
                    <div class="mb-3 mt-3">
                      <label for="comment">
                        (4 images max, Taille 10Mo max, type:".png,.jpeg,.jpg"):
                      </label>
                      <Upload.Dragger
                        multiple={true}
                        maxCount={4}
                        beforeUpload={(files) => {
                          return false
                        }}
                        showUploadList={{ showRemoveIcon: true }}
                        accept=".png,.jpeg,.jpg"
                        listType="picture"
                        onChange={(ev) => {
                          console.log(ev.fileList)
                          setImageAction(ev.fileList)
                        }}>
                        <Button>Parcourir Fichier</Button>
                      </Upload.Dragger>
                    </div>
                  </>
                ) : (
                  <>
                    <h4
                      class="modal-title"
                      style={{ color: '#334155', textAlign: 'center' }}>
                      Vous avez atteint la limite des photos à importer (4
                      images)
                    </h4>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                  disabled={
                    dataModalAction?.actionPhoto?.length < 4 ? false : true
                  }
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    addToast('Enregistrement en cours.', {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    const formData = new FormData()
                    imageAction.map((item) => {
                      formData.append('item', item.originFileObj)
                    })
                    axios
                      .patch(
                        `${process.env.REACT_APP_URL}/api/v1/carteEngagement/${dataModalAction._id}?planning=${infoChantier.planning}`,
                        formData,
                        {
                          headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${infoUser.token}`,
                          },
                        }
                      )
                      .then((res) => {
                        addToast('Enregistrement avec succès.', {
                          appearance: 'success',
                          autoDismiss: true,
                        })
                        console.log(res)
                        if (res.data.data) {
                          console.log(res.data.data)
                          dataMaj.getRow().update(res.data.data)
                        }
                      })
                      .catch((err) => {
                        console.log(err)
                        addToast("Une erreur s'est produit.", {
                          appearance: 'error',
                          autoDismiss: true,
                        })
                      })
                  }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Engagement */}
      <div class="modal fade" id="myModalEngagements">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Engagement
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    value={
                      dataModalEngagement.observation
                        ? dataModalEngagement.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalEngagement({
                        ...dataModalEngagement,
                        observation: e.target.value,
                      })
                    }}
                    id="modalEngagement"
                    name="text"></textarea>
                </div>
                <div className="mb-3 mt-3">
                  {dataModalEngagement?.actionPhoto?.length > 0 && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          padding: '12px 12px',
                          height: '300px',
                          overflowY: 'scroll',
                        }}>
                        {dataModalEngagement.actionPhoto.map((el) => {
                          return (
                            <>
                              <img
                                src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                alt=""
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  marginTop: '5px',
                                }}
                              />
                            </>
                          )
                        })}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    const info =
                      document.getElementById('modalEngagement').value
                    updateData(
                      {
                        observation: info ? info : '',
                        _id: dataModalEngagement._id,
                      },
                      'carteEngagement',
                      infoUser.token,
                      infoChantier.planning
                    ).then((res) => {
                      console.log(res)
                      if (res.data) {
                        maj(dataMaj, info ? info : '')
                      } else {
                        addToast("Une erreur s'est produit", {
                          autoDismiss: true,
                        })
                      }
                    })
                  }}
                  data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Modal Administratif */}
      <div class="modal fade" id="myModalAdministratifs">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Administratif
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label for="comment">Observations:</label>
                  <textarea
                    class="form-control"
                    rows="7"
                    id="modalAdministratif"
                    value={
                      dataModalAdministration.observation
                        ? dataModalAdministration.observation
                        : ''
                    }
                    onChange={(e) => {
                      setDataModalAdministration({
                        ...dataModalAdministration,
                        observation: e.target.value,
                      })
                    }}
                    name="text"></textarea>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    const info =
                      document.getElementById('modalAdministratif').value
                    updateData(
                      {
                        observation: info ? info : '',
                        _id: dataModalAdministration._id,
                      },
                      'carteAdmin',
                      infoUser.token,
                      infoChantier.planning
                    ).then((res) => {
                      console.log(res)
                      if (res.data) {
                        maj(dataMaj, info ? info : '')
                      } else {
                        addToast("Une erreur s'est produit", {
                          appearance: 'error',
                          autoDismiss: true,
                        })
                      }
                    })
                  }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ul class="dropdown-menu">
        <li>
          <a class="dropdown-item" href="#">
            Normal
          </a>
        </li>
        <li>
          <a class="dropdown-item active" href="#">
            Active
          </a>
        </li>
        <li>
          <a class="dropdown-item disabled" href="#">
            Disabled
          </a>
        </li>
      </ul>
      {/* Modal document */}
      <ModalDocument
        dataModalDocument={dataModalDocument}
        dataModalSuiviDoc={dataModalSuiviDoc}
        indiceDoc={indiceDoc}
        setDataDocument={setDataDocument}
        setDataModalSuiviDoc={setDataModalSuiviDoc}
        dataDocument={dataDocument}
      />
    </>
  )
}

export default TableIntervenants
