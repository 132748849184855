import React from 'react'
import { PulseLoader } from 'react-spinners'
const Loader = ({top,left}) => {
  return (
   <>
     <div style={{position:'absolute',top:`${top}%`,left:`${left}%`}}>
     <PulseLoader color='#2DD4BF' loading={true} size={32}/>
     </div>
   </>
  )
}

export default Loader