import React, { useState } from 'react'
import { Button, Upload } from 'antd'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'
const ImportPlanning = ({bool,setBool,setTacheNonAssigner,setAssignement,setWait}) => {
  const { addToast } = useToasts()
  const [file, setFile] = useState()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const verificationExtension = () => {
    if(infoChantier && infoChantier.extension ){
      return `.${infoChantier.extension}`
    }else{
      return ".mpp,.xer,.xml,.xls"
    }
  }
  
  const savePlanning = () => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('planning',infoChantier.planning)
      axios
        .patch(`${process.env.REACT_APP_URL}/api/v1/taches/updatePlanning?planning=${infoChantier.planning}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        })
        .then((res) => {
          setWait(false)
          addToast("Le planning à été importer avec succès.", {
            appearance: 'success',
            autoDismiss: true,
          })
          if(res.data.data.data.data){
            setTacheNonAssigner(res.data.data.data.data)
            setAssignement(true)
          }
          setBool(!bool)
          setFile(null)
        })
        .catch((err) => {
          setWait(false)
          console.log(err)
          addToast("Une erreur s'est produit.", {
            appearance: 'error',
            autoDismiss: true,
          })
          setFile(null)
        })
    }else{
      setWait(false)
        addToast("Veuillez glisser un planning.", {
            appearance: 'error',
            autoDismiss: true,
          })
    }
  }
  return (
    <>
      <div class="modal" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header"  style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>Importer un nouveau planning</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <form
              onSubmit={(ev) => {
                setWait(true)
                ev.preventDefault()
                addToast("Enregistrement en cours ...", {
                  appearance: 'success',
                  autoDismiss: true,
                  autoDismissTimeout:6000,
                })
                savePlanning()
              }}
            >
              <div class="modal-body">
                <Upload.Dragger
                  multiple={false}
                  maxCount={1}
                  beforeUpload={(files) => {
                    setFile(files)
                    return false
                  }}
                  showUploadList={{ showRemoveIcon: false }}
                  accept={verificationExtension()}
                  listType="picture"
                >
                  Glisser le fichier ({verificationExtension()}) <br />
                  <Button>Parcourir Fichier</Button>
                </Upload.Dragger>
              </div>

              <div class="modal-footer">
              <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImportPlanning
