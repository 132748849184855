import React, { useState, useEffect } from 'react';
import Menu from '../../../components/navBar/Menu';
import Header from '../../../components/navBar/Header';
import { useNavigate } from 'react-router-dom';
import LastPlanner2 from './LastPlanner2';
import PageNotFound from '../../../components/404/PageNotFound';
import { openDB } from 'idb';

const OfflineLastPlanner = () => {
  const navigate = useNavigate();
  const infoUsers = localStorage.getItem('users');
  const infoUser = JSON.parse(infoUsers);
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType !== 'admin' &&
      infoUser?.user?.role?.roleType !== 'opc' &&
      infoUser?.user?.role?.roleType !== 'moex')
  ) {
    return <PageNotFound />;
  }

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [tasks, setTasks] = useState([]);
  const [syncPending, setSyncPending] = useState(false);

  useEffect(() => {
    const handleOnline = async () => {
      setIsOnline(true);
      const db = await openLocalDB();
      const tx = db.transaction('tasks', 'readonly');
      const store = tx.objectStore('tasks');
      const localTasks = await store.getAll();

      if (localTasks.length > 0) {
        setSyncPending(true);
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const openLocalDB = async () => {
    return openDB('planningDB', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('tasks')) {
          db.createObjectStore('tasks', { keyPath: 'id' });
        }
      },
    });
  };

  const saveTaskToLocalDB = async (task) => {
    const db = await openLocalDB();
    const tx = db.transaction('tasks', 'readwrite');
    const store = tx.objectStore('tasks');
    await store.put(task);
    await tx.done;

    setSyncPending(true);
  };

  const handleSync = async () => {
    if (navigator.onLine) {
      const db = await openLocalDB();
      const tx = db.transaction('tasks', 'readonly');
      const store = tx.objectStore('tasks');
      const localTasks = await store.getAll();

      for (const task of localTasks) {
        await fetch('/api/sync-tasks', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(task),
        });
      }

      const clearTx = db.transaction('tasks', 'readwrite');
      const clearStore = clearTx.objectStore('tasks');
      await clearStore.clear();

      alert('Synchronisation réussie avec le serveur !');
      setSyncPending(false);
    } else {
      alert('Vous devez être connecté à Internet pour synchroniser.');
    }
  };

  return (
    <div>
      <Menu bool={true} active="offline-planning" />
      <Header bool={true} />
      <div className="page-wrapper">
        <div className="page-content-tab">
          {syncPending && (
            <button onClick={handleSync} className="btn btn-success">
              Synchroniser avec le serveur
            </button>
          )}
          <LastPlanner2 syncPending={syncPending} />
        </div>
      </div>
    </div>
  );
};

export default OfflineLastPlanner;