import React, { useEffect, useState } from 'react'
import Board from 'react-trello'
import { useSelector, useDispatch } from 'react-redux'
import {
  DeleteZone,
  UpdateZone,
} from '../../../../helpers/planning/gestionTache/dataScheduler'
import axios from 'axios'
const Card = (data) => {
  const dispatch = useDispatch()
  const bool = useSelector((state) => state.bool.bool)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100px',
          padding: '8px 8px',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '20px',
        }}>
        <button
          className="btn"
          style={{ marginLeft: '2px' }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}>
          <i className="ti ti-arrows-down-up"></i>
        </button>
        <input
          className="form-control"
          type="text"
          defaultValue={data.label}
          onBlur={(e) => {
            e.preventDefault()
            UpdateZone(
              data._id,
              e.target.value,
              infoUser.token,
              infoChantier.planning
            ).then((res) => {
              dispatch({
                type: 'bool/modifier',
                payload: !bool,
              })
            })
          }}
        />
        <button
          className="btn btn-danger"
          data-bs-dismiss="modal"
          style={{ marginLeft: '2px' }}
          onClick={(e) => {
            e.preventDefault()
            DeleteZone(data._id, infoUser.token, infoChantier.planning).then(
              (e) => {
                dispatch({
                  type: 'bool/modifier',
                  payload: !bool,
                })
              }
            )
          }}>
          <i className="ti ti-trash"></i>
        </button>
      </div>
    </>
  )
}
const AddCardLink = (data) => {
  return (
    <>
      <div></div>
    </>
  )
}
const UpdateZoneLtp = ({
  zone,
  setZone,
  bool,
  dispatch,
  UpdateZone,
  DeleteZone,
}) => {
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [datas, setDatas] = useState({
    lanes: [
      {
        id: 'lane1',
        title: 'Listes des zones',
        cards: [],
      },
    ],
  })
  /* const datas = {
    lanes: [
      {
        id: 'lane1',
        title: 'Listes des zones',
        cards: zone,
      },
    ],
  } */
  useEffect(() => {
    setDatas({
      lanes: [
        {
          id: 'lane1',
          title: 'Listes des zones',
          cards: zone,
        },
      ],
    })
  }, [zone])
  const reorganizeArray = (array, fromIIndex, toIndex) => {
    if (!Array.isArray(array)) {
      throw new Error('le paramètre array doit être un tableau')
    }
    if (
      fromIIndex < 0 ||
      fromIIndex >= array.length ||
      toIndex < 0 ||
      toIndex >= array.length
    ) {
      throw new Error(
        'Les index "fromIndex" et "toIndex" doivent être valides.'
      )
    }
    const movedElement = array[fromIIndex]
    array.splice(fromIIndex, 1)
    array.splice(toIndex, 0, movedElement)
    array.forEach((element, index) => {
      element.index = index
    })
    return array
  }
  return (
    <>
      <div class="modal" id="myModal1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Modification des zones
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body" style={{ maxHeight: '90vh' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <Board
                  disallowAddingCard={true}
                  editable
                  draggable
                  data={datas}
                  style={{
                    backgroundColor: '#F8FAFC',
                    maxHeight: '80vh',
                    borderRadius: '8px',
                    overflow: 'auto',
                  }}
                  laneStyle={{
                    backgroundColor: '#E2E8F0',
                    height: '90%',
                    overflow: 'auto',
                  }}
                  /*  handleDragStart={handleDragStart}
                  laneDraggable={false}
                    */
                  components={{ Card: Card, AddCardLink: AddCardLink }}
                  /* onDataChange={onDataChange}  */
                  handleDragEnd={(
                    cardId,
                    sourceLaneId,
                    targetLaneId,
                    position,
                    cardDetails
                  ) => {
                    const newData = reorganizeArray(
                      zone,
                      cardDetails.index,
                      position
                    )
                    axios
                      .patch(
                        `${process.env.REACT_APP_URL}/api/v1/zones?planning=${infoChantier.planning}`,
                        {
                          zones: newData,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${infoUser.token}`,
                          },
                        }
                      )
                      .then((resp) => {
                        dispatch({
                          type: 'bool/modifier',
                          payload: !bool,
                        })
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateZoneLtp
