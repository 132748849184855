import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/Kolos_logo/1_big.png'
import logoLight from '../../assets/images/Kolos_logo/1_typo.png'
import logoDark from '../../assets/images/Kolos_logo/1_big.png'
// import logo from '../../assets/images/Kolos_logo/1_big.png'
import { useNavigate } from 'react-router-dom'
import OverlayProVersion from '../../helpers/fond_pro_version/OverlayProVersion'
const Menu = ({ bool, active, offline }) => {
  const navigate = useNavigate()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  // Gestion de la taille d'écran pour masquer la liste
  const [isMobile, setIsMobile] = useState(false);

  // Défintion de "isDevice"
  let isDevice

  // On déclare les états des résultats (affichage)
  useEffect(() => {
    // Détection si nous sommes sur un device / Appareil mobile
    isDevice = /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile/.test(navigator.userAgent);
    const isTabletOrMobileScreen = window.innerWidth <= 1080;

    // Vérification combinée avec la taille de l'écran
    if (isDevice || isTabletOrMobileScreen) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

  }, [])

  // Fond pour les version LTE et les affichages sur tablette
  if (infoUser?.user?.formule === 'opclte' && isMobile) {
    return <OverlayProVersion />; // Affiche l'overlay pour les utilisateurs non Pro
  }


  return (
    // Masquer la liste si l'écran est un device
    !isMobile && (
      <div
        className="left-sidebar"
        style={{ backgroundColor: '#F8FAFC', borderRight: 'solid #D9D9D9 1px' }}>
        <div
          class="brand"
          style={{
            backgroundColor: '#F8FAFC',
            borderBottom: 'solid #D9D9D9 1.5px',
          }}
          onClick={(e) => {
            if (offline) {
            } else {
              navigate('/accueil')
            }
          }}>
          <a href="#" class="logo">
            <span>
              <img
                src={logo}
                alt="logo-big"
                class="logo-sm"
                style={{ width: '45px', height: '45px' }}
              />
            </span>
            <span>
              <img
                src={logoLight}
                alt="logo-large"
                class="logo-lg logo-light"
                style={{ width: '47%', height: '100%' }}
              />
              <img
                src={logoLight}
                alt="logo-large"
                class="logo-lg logo-dark"
                style={{ width: '118px', height: 'auto', marginTop: '12px' }}
              />
            </span>

            {/*   <span>
              <img src={logo} alt="logo-small" class="logo-sm logo-dark" />
            </span>
            <span>
              <img src={logoLight} alt="logo-small" class="logo-lg logo-dark" />
            </span> */}
          </a>
        </div>
        <div className="menu-content h-100" data-simplebar>
          <div className="menu-body navbar-vertical" style={{ padding: '0 0' }}>
            <div
              className="collapse navbar-collapse tab-content"
              id="sidebarCollapse">
              {bool && (
                <>
                  <ul
                    className="navbar-nav tab-pane active"
                    id="Main"
                    role="tabpanel">
                    {(
                      infoUser?.user?.role?.roleType == 'admin'||
                      infoUser?.user?.role?.roleType == 'opc' ||
                      infoUser?.user?.role?.roleType == 'moex') && (
                      <>
                        <li
                          className="nav-item"
                          onClick={(e) => {
                            if (infoUser?.user?.formule !== 'opclte') {
                              e.preventDefault()
                              navigate('/Tableau_de_bord')
                            }
                          }}
                          style={{ width: '90%', margin: 'auto', padding: '5px' }}>
                          <a
                            className="nav-link"
                            href={infoUser?.user?.formule !== 'opclte' ? '#' : undefined} // Conditionne href ici
                            style={
                              active == 'dashboard'
                                ? {
                                    backgroundColor: '#C7D2FE',
                                    width: '100%',
                                    color: '#6366F1',
                                  }
                                : { width: '100%' }
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill={active == 'dashboard' ? '#4F46E5' : 'none'}
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke={active == 'dashboard' ? '#6366F1' : '#94A3B8'}
                              style={{
                                width: '24px',
                                height: '24px',
                                marginLeft: '10px',
                              }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
                              />
                            </svg>

                            
                            <span
                              style={
                                infoUser?.user?.formule === 'opclte'
                                  ? {
                                      marginLeft: '20px',
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      lineHeight: '24px',
                                      color: '#64748B',
                                      fontStyle: 'italic',
                                    }
                                  : {
                                      marginLeft: '20px',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      lineHeight: '24px',
                                    }
                              }
                            >
                              Tableau de bord
                              {infoUser?.user?.formule === 'opclte' ? ' - PRO' : ''}
                            </span>
                          </a>
                        </li>
                      </>
                    )}
                    <li
                      className="nav-item"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/planning_GestionTache')
                      }}
                      style={{ width: '90%', margin: 'auto', padding: '5px' }}>
                      <a
                        className="nav-link"
                        href="#"
                        style={
                          active == 'planning'
                            ? {
                                backgroundColor: '#C7D2FE',
                                width: '100%',
                                color: '#6366F1',
                              }
                            : { width: '100%' }
                        }>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill={active == 'planning' ? '#4F46E5' : 'none'}
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke={active == 'planning' ? '#6366F1' : '#94A3B8'}
                          style={{
                            width: '24px',
                            height: '24px',
                            marginLeft: '10px',
                          }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                          />
                        </svg>

                        <span
                          style={{
                            marginLeft: '20px',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                          }}>
                          Planning
                        </span>
                      </a>
                    </li>
                    {/*                   <li
                      className="nav-item"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/Planning_Dashboard')
                      }}
                      style={{ width: '90%', margin: 'auto', padding: '5px' }}
                    >
                      <a
                        className="nav-link"
                        href="#"
                        style={
                          active == 'planning'
                            ? {
                                backgroundColor: '#C7D2FE',
                                width: '100%',
                                color: '#6366F1',
                              }
                            : { width: '100%' }
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill={active == 'planning' ? '#4F46E5' : 'none'}
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke={active == 'planning' ? '#6366F1' : '#94A3B8'}
                          style={{
                            width: '24px',
                            height: '24px',
                            marginLeft: '10px',
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                          />
                        </svg>

                        <span
                          style={{
                            marginLeft: '20px',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                          }}
                        >
                          Planning
                        </span>
                      </a>
                    </li>
  */}{' '}
                    <li
                      className="nav-item"
                      onClick={(e) => {
                        navigate('/SuiviOperationnel')
                      }}
                      style={{ width: '90%', margin: 'auto', padding: '5px' }}>
                      <a
                        className="nav-link"
                        href="#"
                        style={
                          active == 'suivi'
                            ? {
                                backgroundColor: '#C7D2FE',
                                width: '100%',
                                color: '#6366F1',
                              }
                            : { width: '100%' }
                        }>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill={active == 'suivi' ? ' #4F46E5' : 'none'}
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke={active == 'suivi' ? '#6366F1' : '#94A3B8'}
                          style={{
                            width: '24px',
                            height: '24px',
                            marginLeft: '10px',
                          }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>

                        <span
                          style={{
                            marginLeft: '20px',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                          }}>
                          Suivi Opérationnel
                        </span>
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={(e) => {
                        localStorage.setItem('typeRapport', 'cr')
                        navigate('/Rapport_CR')
                      }}
                      style={{ width: '90%', margin: 'auto', padding: '5px' }}>
                      <a
                        className="nav-link"
                        href="#"
                        style={
                          active == 'rapport'
                            ? {
                                backgroundColor: '#C7D2FE',
                                width: '100%',
                                color: '#6366F1',
                              }
                            : { width: '100%' }
                        }>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill={active == 'rapport' ? '#4F46E5' : 'none'}
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke={active == 'rapport' ? '#6366F1' : '#94A3B8'}
                          style={{
                            width: '24px',
                            height: '24px',
                            marginLeft: '10px',
                          }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                          />
                        </svg>

                        <span
                          style={{
                            marginLeft: '20px',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                          }}>
                          Rapports
                        </span>
                      </a>
                    </li>

                    {(
                      infoUser?.user?.role?.roleType == 'admin' ||
                      infoUser?.user?.role?.roleType == 'opc' ||
                      infoUser?.user?.role?.roleType == 'moex') && (
                      <>
                        <li
                          className="nav-item"
                          onClick={(e) => {
                            if (infoUser?.user?.formule !== 'opclte') {
                              e.preventDefault()
                              navigate('/Observation_Plan')
                            }
                          }}
                          style={{
                            width: '90%',
                            margin: 'auto',
                            padding: '5px',
                          }}>
                          <a
                            className="nav-link"
                            href={infoUser?.user?.formule !== 'opclte' ? '#' : undefined} // Conditionne href ici
                            style={
                              active == 'observation'
                                ? {
                                    backgroundColor: '#C7D2FE',
                                    width: '100%',
                                    color: '#6366F1',
                                  }
                                : { width: '100%' }
                            }>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill={active == 'observation' ? '#4F46E5' : 'none'}
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke={
                                active == 'observation' ? '#6366F1' : '#94A3B8'
                              }
                              style={{
                                width: '24px',
                                height: '24px',
                                marginLeft: '10px',
                              }}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                              />
                            </svg>
                            
                            
                            <span
                              style={
                                infoUser?.user?.formule === 'opclte'
                                  ? {
                                      marginLeft: '20px',
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      lineHeight: '24px',
                                      color: '#64748B',
                                      fontStyle: 'italic',
                                    }
                                  : {
                                      marginLeft: '20px',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      lineHeight: '24px',
                                    }
                              }
                            >
                              Observations
                              {infoUser?.user?.formule === 'opclte' ? ' - PRO' : ''}
                            </span>
                          </a>
                        </li>
                      </>
                    )}
                    <li
                      className="nav-item"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/Contact')
                      }}
                      style={{ width: '90%', margin: 'auto', padding: '5px' }}>
                      <a
                        className="nav-link"
                        href="#"
                        style={
                          active == 'contact'
                            ? {
                                backgroundColor: '#C7D2FE',
                                width: '100%',
                                color: '#6366F1',
                              }
                            : { width: '100%' }
                        }>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill={active == 'contact' ? '#4F46E5' : 'none'}
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke={active == 'contact' ? '#6366F1' : '#94A3B8'}
                          style={{
                            width: '24px',
                            height: '24px',
                            marginLeft: '10px',
                          }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                          />
                        </svg>

                        <span
                          style={{
                            marginLeft: '20px',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                          }}>
                          Contacts
                        </span>
                      </a>
                    </li>
                    {/* <li
                      className="nav-item"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/Courrier')
                      }}
                      style={{ width: '90%', margin: 'auto', padding: '5px' }}
                    >
                      <a
                        className="nav-link"
                        href="#"
                        style={
                          active == 'courrier'
                            ? {
                                backgroundColor: '#C7D2FE',
                                width: '100%',
                                color: '#6366F1',
                              }
                            : { width: '100%' }
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill={active == 'courrier' ? '#4F46E5' : 'none'}
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke={active == 'courrier' ? '#6366F1' : '#94A3B8'}
                          style={{
                            width: '24px',
                            height: '24px',
                            marginLeft: '10px',
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                          />
                        </svg>
                        <span
                          style={{
                            marginLeft: '20px',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                          }}
                        >
                          Courriers
                        </span>
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/Agenda')
                      }}
                      style={{ width: '90%', margin: 'auto', padding: '5px' }}
                    >
                      <a
                        className="nav-link"
                        href="#"
                        style={
                          active == 'agenda'
                            ? {
                                backgroundColor: '#C7D2FE',
                                width: '100%',
                                color: '#6366F1',
                              }
                            : { width: '100%' }
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill={active == 'agenda' ? '#4F46E5' : 'none'}
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke={active == 'agenda' ? '#6366F1' : '#94A3B8'}
                          style={{
                            width: '24px',
                            height: '24px',
                            marginLeft: '10px',
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                          />
                        </svg>
                        <span
                          style={{
                            marginLeft: '20px',
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '24px',
                          }}
                        >
                          Agenda
                        </span>
                      </a>
                    </li> */}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default Menu
