import React from 'react';
import './OverlayProVersion.scss'; // Import the CSS for styling
import logo from '../../assets/images/Kolos_logo/newLogoLogin.png'

const OverlayProVersion = () => {
  return (
    <div className="overlay">
      <div className="overlay-content card"> 
        <div class="text-center p-3 mt-2">
        <a href='#' class="logo logo-admin">
            <img
            src={logo}
            height="40"
            alt="logo"
            class="auth-logo"
            style={{marginBottom:'10px'}}
            />
        </a>
        <p style={{fontSize:'18px',fontWeight:'600',color:'white'}}>Accès réservé à la version PRO</p>
        </div>
        <p style={{fontSize:'16px',fontWeight:'600',color:'white'}}>Le mode tablette est disponible uniquement pour les utilisateurs de la version PRO.</p><div class="form-group mb-0 row">
            <div class="col-12">
                <div class="d-grid mt-3">
                    <a 
                        href="https://calendly.com/palata_sooda/45min"
                        class="btn" 
                        style={{backgroundColor:'#4F46E5',color:'white'}}
                        target="_blank"
                    >
                    Prendre rendez-vous{' '}
                    <i class="fas fa-sign-in-alt ms-1"></i>
                    </a>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayProVersion;