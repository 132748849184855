import React, { useEffect, useState } from 'react'
import { updateForfait } from '../../../../helpers/suivi/suivi'
import Modal from '../../penalite/Modal'
const Penalite = ({ entreprise, forfait, bool, setBool, setForfait,itemPenalite }) => {
  //fonction pour le nav bar scroll horizontal
  const right = () => {
    const element = document.querySelector('.wrapper2')
    console.log(element.scrollLeft)
    const item = document.querySelector('.item2')
    element.scrollLeft = element.scrollLeft + item.clientWidth + 10
  }

  const left = () => {
    const element = document.querySelector('.wrapper2')
    const item = document.querySelector('.item2')
    element.scrollLeft = element.scrollLeft - item.clientWidth + 10
  }
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [item, setItem] = useState(itemPenalite)
  useEffect(()=>{
    setItem(itemPenalite ? itemPenalite : "")
  },[itemPenalite,entreprise])
  return (
    <>
      <div className="conatainer-fluid">
        {/* wizard pour compte rendu et fiche de visite */}
        <div class="row">
          <div class="col-12">
            <div
              class="card"
              style={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                border: 'none',
              }}
            >
              <div
                class="card-body"
                style={{
                  width: '100%',
                  padding: '0px',
                }}
              >
                <div
                  style={{
                    width: '60%',
                    display: 'flex',
                    marginTop: '20px',
                    flexWrap: 'nowrap',
                  }}
                >
                  <button
                    className="btn"
                    style={{
                      width: '10px',
                      height: '52px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '0px',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                      borderTop: '1px solid #CBD5E1',
                      borderBottom: '1px solid #CBD5E1',
                      borderLeft: '1px solid #CBD5E1',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      left()
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <div className="wrapper2">
                    {entreprise?.map((e) => {
                      return (
                        <>
                          <div
                            className="item2"
                            onClick={(ev) => {
                              ev.preventDefault()
                              console.log(e._id);
                              setItem(e._id)
                            }}
                          >
                            <span
                              style={
                                item == e._id
                                  ? {
                                      color: '#F8FAFC',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      backgroundColor: '#6366F1',
                                      padding: '8px 16px',
                                      borderRadius: '8px',
                                    }
                                  : {
                                      color: '#334155',
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      cursor: 'pointer',
                                    }
                              }
                            >
                              {e.titre}
                            </span>
                          </div>
                        </>
                      )
                    })}
                  </div>
                  <button
                    className="btn"
                    style={{
                      width: '10px',
                      height: '52px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '0px',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                      textAlign: 'center',
                      position: 'relative',
                      borderTop: '1px solid #CBD5E1',
                      borderBottom: '1px solid #CBD5E1',
                      borderRight: '1px solid #CBD5E1',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      right()
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        left: '0',
                        top: '17',
                      }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  style={{
                    border: 'none',
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E2E8F0',
                    borderRadius: '8px',
                    boxShadow:
                      '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                    marginTop: '12px',
                  }}
                >
                  {entreprise &&
                    entreprise.map((e, i) => {
                      return (
                        <>
                          {item == e._id && (
                            <>
                              <div
                                class={`${
                                  i == 0 ? 'tab-pane active' : 'tab-pane '
                                }`}
                                id={`step22${e._id}`}
                              >
                                <div style={{ width: '100%' }}>
                                  <table
                                    class="table"
                                    style={{ marginTop: '1vw' }}
                                  >
                                    <thead
                                      style={{
                                        borderBottom: '1px solid #CBD5E1',
                                      }}
                                    >
                                      <tr>
                                        <th
                                          style={{
                                            color: '#334155',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                          }}
                                        >
                                          Type
                                        </th>
                                        <th
                                          style={{
                                            color: '#334155',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                          }}
                                        >
                                          Forfait
                                        </th>
                                        <th
                                          style={{
                                            color: '#334155',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                          }}
                                        >
                                          Retard consigné
                                        </th>
                                        <th
                                          style={{
                                            color: '#334155',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                          }}
                                        >
                                          Pénalités applicables
                                        </th>
                                        <th
                                          style={{
                                            color: '#334155',
                                            fontSize: '16px',
                                            fontWeight: '600',
                                          }}
                                        >
                                          Détails
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Planning</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitPlanning
                                                ? forfait.forfaitPlanning
                                                : 0
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitPlanning:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                console.log(e.data);
                                                setForfait(e.data)
                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails.planning
                                            ? e.penaliteDetails.planning.toFixed(
                                                0
                                              )
                                            : 0}{' '}
                                          jour(s)
                                        </td>
                                        <td>
                                          {e.penaliteDetails?.planning
                                            ? (
                                                e.penaliteDetails.planning *
                                                forfait.forfaitPlanning
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard${e._id}`}
                                            data={e}
                                            type="Planning"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Planifications</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitPlanningSo
                                                ? forfait.forfaitPlanningSo
                                                : 0
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitPlanningSo:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)
                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails.planningSo
                                            ? e.penaliteDetails.planningSo.toFixed(
                                                0
                                              )
                                            : 0}{' '}
                                          jour(s)
                                        </td>
                                        <td>
                                          {e.penaliteDetails?.planningSo
                                            ? (
                                                e.penaliteDetails.planningSo *
                                                forfait.forfaitPlanningSo
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard0${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard0${e._id}`}
                                            data={e}
                                            type="PlanningSo"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Engagements</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitEngagement
                                                ? forfait.forfaitEngagement
                                                : 0
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitEngagement:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)
                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails.engagement
                                            ? e.penaliteDetails.engagement.toFixed(
                                                0
                                              )
                                            : 0}{' '}
                                          jour(s)
                                        </td>
                                        <td>
                                          {e.penaliteDetails?.engagement
                                            ? (
                                                e.penaliteDetails.engagement *
                                                forfait.forfaitEngagement
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard1${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard1${e._id}`}
                                            data={e}
                                            type="Engagement"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Administratifs</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitAdmin
                                                ? forfait.forfaitAdmin
                                                : 0
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitAdmin: el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)
                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails.administratif
                                            ? e.penaliteDetails.administratif.toFixed(
                                                0
                                              )
                                            : 0}{' '}
                                          jour(s)
                                        </td>
                                        <td>
                                          {e.penaliteDetails?.administratif
                                            ? (
                                                e.penaliteDetails
                                                  .administratif *
                                                forfait.forfaitAdmin
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard2${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard2${e._id}`}
                                            data={e}
                                            type="Administratifs"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Documents</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={forfait.forfaitDocument || 0}
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitDocument:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)

                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails?.document
                                            ? e.penaliteDetails.document.toFixed(
                                                0
                                              )
                                            : 0}{' '}
                                          jour(s)
                                        </td>
                                        <td>
                                          {e.penaliteDetails?.document
                                            ? (
                                                e.penaliteDetails.document *
                                                forfait.forfaitDocument
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard3${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>

                                          <Modal
                                            id={`retard3${e._id}`}
                                            data={e}
                                            type="Documents"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Sécurités</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitSecurite
                                                ? forfait.forfaitSecurite
                                                : 0
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitSecurite:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)
                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails.securite
                                            ? e.penaliteDetails.securite.toFixed(
                                                0
                                              )
                                            : 0}{' '}
                                          jour(s)
                                        </td>
                                        <td>
                                          {e.penaliteDetails?.securite
                                            ? (
                                                e.penaliteDetails.securite *
                                                forfait.forfaitSecurite
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard4${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard4${e._id}`}
                                            data={e}
                                            type="Sécurités"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Installation de Chantier</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitInstallation
                                                ? forfait.forfaitInstallation
                                                : 0
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitInstallation:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)
                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails
                                            .installationChantier
                                            ? e.penaliteDetails.installationChantier.toFixed(
                                                0
                                              )
                                            : 0}{' '}
                                          jour(s)
                                        </td>
                                        <td>
                                          {e.penaliteDetails
                                            ?.installationChantier
                                            ? (
                                                e.penaliteDetails
                                                  .installationChantier *
                                                forfait.forfaitInstallation
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard5${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard5${e._id}`}
                                            data={e}
                                            type="Installations"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Contrôleur technique</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitControleurTechnique
                                                ? forfait.forfaitControleurTechnique
                                                : 0
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitControleurTechnique:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)

                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails
                                            .controleurTechnique
                                            ? e.penaliteDetails.controleurTechnique.toFixed(
                                                0
                                              )
                                            : 0}{' '}
                                          jour(s)
                                        </td>
                                        <td>
                                          {e.penaliteDetails
                                            ?.controleurTechnique
                                            ? (
                                                e.penaliteDetails
                                                  .controleurTechnique *
                                                forfait.forfaitControleurTechnique
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard7${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard7${e._id}`}
                                            data={e}
                                            type="controleurTechnique"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Retards réunions</td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitRetard
                                                ? forfait.forfaitRetard
                                                : 1
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitRetard:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)

                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails.intervenantEnRetard
                                            ? e.penaliteDetails.intervenantEnRetard.toFixed(
                                                0
                                              )
                                            : 0}
                                        </td>
                                        <td>
                                          {e.penaliteDetails
                                            ?.intervenantEnRetard
                                            ? (
                                                e.penaliteDetails
                                                  .intervenantEnRetard *
                                                forfait.forfaitRetard
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard6${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard6${e._id}`}
                                            data={e}
                                            type="Retards"
                                          />
                                        </td>
                                      </tr>
                                      <tr
                                        style={{
                                          borderBottom: '1px solid #CBD5E1',
                                        }}
                                      >
                                        <td>Absences réunions </td>
                                        <td>
                                          <input
                                            type="number"
                                            className="form-control"
                                            defaultValue={
                                              forfait.forfaitAbsence
                                                ? forfait.forfaitAbsence
                                                : 1
                                            }
                                            onBlur={(el) => {
                                              el.preventDefault()
                                              updateForfait(
                                                forfait._id,
                                                {
                                                  forfaitAbsence:
                                                    el.target.value,
                                                },
                                                infoUser.token,
                                                infoChantier.planning
                                              ).then((e) => {
                                                setForfait(e.data)

                                                setBool(!bool)
                                              })
                                            }}
                                          />
                                        </td>
                                        <td>
                                          {e.penaliteDetails.intervenantAbsent
                                            ? e.penaliteDetails.intervenantAbsent.toFixed(
                                                0
                                              )
                                            : 0}
                                        </td>
                                        <td>
                                          {e.penaliteDetails?.intervenantAbsent
                                            ? (
                                                e.penaliteDetails
                                                  .intervenantAbsent *
                                                forfait.forfaitAbsence
                                              ).toFixed(0)
                                            : 0}{' '}
                                          €
                                        </td>
                                        <td>
                                          <button
                                            style={{
                                              border: '1px solid #CBD5E1',
                                              borderRadius: '8px',
                                              color: '#2DD4BF',
                                              padding: '10px 14px',
                                              backgroundColor: 'transparent',
                                            }}
                                            data-bs-toggle="modal"
                                            data-bs-target={`#retard7${e._id}`}
                                            onClick={(ev) => {
                                              ev.preventDefault()
                                            }}
                                          >
                                            <i
                                              type="icon"
                                              className="ti ti-plus"
                                            ></i>
                                          </button>
                                          <Modal
                                            id={`retard7${e._id}`}
                                            data={e}
                                            type="Absences"
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* fin wizard */}
      </div>
    </>
  )
}

export default Penalite
