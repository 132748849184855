import axios from 'axios'

export const UpdateTask = async (planning, taskUpdate, links, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const result = await axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/taches`,
        { planning: planning, data: taskUpdate, links: links },
        config
      )
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e.response
      })
    return result
  } catch (err) {
    return { message: 'erreur' }
  }
}

export const getAllTask = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const resultat = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/taches?planning=${id}`, config)
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        return err.response.data.data
      })
    return resultat
  } catch (err) {
    alert('Le serveur ne répond pas')
    return { message: 'erreur' }
  }
}
export const getDataLastPlannerMere = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const resultat = await axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/taches/filter/?open=true&planning=${id}&limit=1000`,
        config
      )
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        return err.response.data.data
      })
    return resultat
  } catch (err) {
    alert('Le serveur ne répond pas')
    return { message: 'erreur' }
  }
}
export const getDataLastPlannerFille = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  try {
    const resultat = await axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/taches/filter/?&planning=${id}&limit=1000&start_date[gte]=2020-09-01&end_date[lte]=2020-09-30`,
        config
      )
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        return err.response.data.data
      })
    return resultat
  } catch (err) {
    alert('Le serveur ne répond pas')
    return { message: 'erreur' }
  }
}
export const getDataLastPlanner = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const resultat = await axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/taches/filter/?&planning=${id}&limit=1000`,
        config
      )
      .then((res) => {
        return res.data.data
      })
      .catch((err) => {
        return err.response.data.data
      })
    return resultat
  } catch (err) {
    alert('Le serveur ne répond pas')
    return { message: 'erreur' }
  }
}
