import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import Favori2 from './Favori/Favori2'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { getFavori } from '../../../helpers/planning/favori/favori'
import PageNotFound from '../../../components/404/PageNotFound'
import { useSelector } from 'react-redux'
import { getAllData } from '../../../helpers/suivi/suivi'
import Header_small_screen from '../../../components/navBar/Header_small_screen'
//Liste des taches noté favori
const Favori = () => {
  const navigate = useNavigate()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }
  const bools = useSelector((state) => state.bool.bool)
  const [page, setPage] = useState(1)
  const [nbPage, setNbPage] = useState(0)
  const [bool, setBool] = useState(false)
  const [allTask, setAllTask] = useState([])
  const [allLot, setAllLot] = useState([])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [wait, setWait] = useState(false)
  useEffect(() => {
    setWait(true)
    getAllData(
      `planning=${infoChantier.planning}`,
      'lots',
      infoUser.token
    ).then((e) => {
      setAllLot(e.data)
      setWait(false)
    })
    getFavori(infoChantier.planning, page, infoUser.token).then((el) => {
      setNbPage((el.length / 10).toFixed(0))
      setAllTask(el.data)
    })
  }, [bool, page, bools])

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="planning" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                    <Header_small_screen />
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#94A3B8"
                      style={{ width: '16px', height: '16px' }}
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                        clipRule="evenodd"
                      />
                    </svg>{' '}
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      / Planification
                    </span>
                  </span>{' '}
                  <br />
                  <span
                    style={{
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: '#E2E8F0',
                        padding: '2px',
                        borderRadius: '6px',
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#6366F1"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/accueil')
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                        marginLeft: '10px',
                      }}
                    >
                      {' '}
                      Planification
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/** Début navbar */}
            <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
              <div className="wrapper">
                {/* <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/planning_Dashboard')
                  }}
                >
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Tableau de bord
                  </span>
                </div> */}
                <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate('/planning_GestionTache')
                  }}
                >
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Gestion des tâches
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/Planning_GestionTache_LastPlanner')
                  }}
                >
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Glissant
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/planning_Favori')
                  }}
                >
                  <span
                    style={{
                      color: '#F8FAFC',
                      fontSize: '16px',
                      fontWeight: '400',
                      backgroundColor: '#6366F1',
                      padding: '8px 24px',
                      borderRadius: '8px',
                    }}
                  >
                    Jalons Critiques
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/planning_Retard')
                  }}
                  style={{
                    borderRight: '1px solid  #CBD5E1',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}
                >
                  <span
                    style={{
                      color: '#334155',
                      fontSize: '16px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Retards
                  </span>
                </div>
              </div>
            </div>
            <Favori2 allTask={allTask} allLot={allLot} bool={bool} setBool={setBool}/>
            {wait && (
              <>
                <LoaderFixed />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Favori
