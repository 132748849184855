import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { ReactTabulator } from 'react-tabulator'
import PageNotFound from '../../../components/404/PageNotFound'
import Presence from './component/Presence'
import {
  updateData,
  getAllData,
  delData,
  addData,
  getDataPresence,
} from '../../../helpers/suivi/suivi'
import trash from '../../../assets/images/Kolos_logo/trash.png'
import Multiselect from 'multiselect-react-dropdown'
import { getAllEntreprise } from '../../../helpers/rapport/rapport'
import { Button, Upload } from 'antd'
import axios from 'axios'
import LoaderFixed from '../../../components/loader/LoaderFixed'
const NewNoteChantier = () => {
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  const [dataNote, setDataNote] = useState([])
  const [dataModalEntreprise, setDataModalEntreprise] = useState({})
  const [dataPresence, setDataPresence] = useState([])
  const [dataLot, setDataLot] = useState([])
  const [entSelected, setEntSelected] = useState([])
  const [dataModalAction, setDataModalAction] = useState({})
  const [imageAction, setImageAction] = useState([])
  const [wait, setWait] = useState(false)
  const [bool, setBool] = useState(false)
  const [item, setItem] = useState('presence')
  const [dataMaj, setDataMaj] = useState()
  const [preambule, setPreambule] = useState('')
  const maj = (cell, info) => {
    console.log(cell.getValue())
    cell.setValue(info)
  }
  //Boutton de suppression
  const button = (delFunction) => (cell) => {
    const delIcons = document.createElement('img')
    delIcons.src = trash
    delIcons.style = 'width:24px;height:24px'

    //create delete button
    var delBt = document.createElement('button')
    delBt.style = 'padding:8px 10px; border:1px solid #CBD5E1 '
    delBt.type = 'button'
    delBt.appendChild(delIcons)
    delBt.classList.add('btn')
    delBt.addEventListener('click', function () {
      delFunction(cell)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(delBt)
    return buttonHolder
  }
  //Create date picker
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.addEventListener('change', function (e) {
      cell.setValue(e.target.value)
    })
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  const noteEdited = async (cell) => {
    const data = cell.getData()
    console.log(cell.getData())
    updateData(data, `notesChantier`, infoUser.token, infoChantier.planning)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }
  const delNote = async (cell) => {
    setWait(true)
    try {
      await delData(
        cell.getData(),
        'notesChantier',
        infoUser.token,
        infoChantier.planning
      )
      cell.getRow().delete()
      setWait(false)
    } catch (err) {
      addToast("Une erreur s'est produit.", {
        appearance: 'error',
        autoDismiss: true,
      })
      setWait(false)
    }
    //cell.getRow().delete()
  }
  const addNoteRow = async (e) => {
    e.preventDefault()
    setWait(true)
    const result = await addData(
      { planning: infoChantier.planning },
      `notesChantier`,
      'rien',
      infoUser.token
    )
      .then((e) => {
        setBool(!bool)
        return e
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
        setBool(!bool)
        return
      })
  }
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  //Modal Photo
  const modalAction = (value) => (cell) => {
    const data = cell.getData()
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-download')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalAction')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(data)
      setDataMaj(cell)
      setDataModalAction(data)
      setImageAction(null)
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }

  //Modal document
  const modalEntreprise = (value) => (cell) => {
    const data = cell.getData()
    const delIcon = document.createElement('i')
    delIcon.type = 'icon'
    delIcon.classList.add('ti')
    delIcon.classList.add('ti-list')
    //create delete button
    var modalBt = document.createElement('button')
    modalBt.type = 'button'
    modalBt.setAttribute('data-bs-toggle', 'modal')
    modalBt.setAttribute('data-bs-target', '#myModalEntreprise')
    modalBt.appendChild(delIcon)
    modalBt.classList.add('btn')
    modalBt.style =
      'border:1px solid #CBD5E1;border-radius: 8px;color:#2DD4BF;padding:10px 14px'
    modalBt.addEventListener('click', function () {
      console.log(cell.getData())
      console.log(dataLot)
      //setEntSelected([])
      setEntSelected(
        data.lots?.length > 0 ? data.lots : [{ titre: 'Sélectionner des lots' }]
      )
      setDataMaj(cell)
      setDataModalEntreprise(cell.getData())
    })
    var buttonHolder = document.createElement('span')
    buttonHolder.style = 'margin-left:12px'
    buttonHolder.appendChild(modalBt)
    return buttonHolder
  }

  const colonneNote = [
    {
      title: 'Lot concerné(s)',
      field: 'lots',
      width: 110,
      formatter: modalEntreprise(),
    },
    {
      title: 'Remarques',
      field: 'remarques',
      formatter: simpleInput,
    },
    { title: 'À lever', field: 'aLever', formatter: date },
    {
      title: 'PM',
      field: 'pm',
      width: 110,
      formatter: checkbox,
    },
    {
      title: 'Images',
      width: 110,
      field: 'actionPhoto',
      formatter: modalAction(),
    },
    {
      title: 'Supprimer',
      field: 'functionButton',
      width: 100,
      formatter: button(delNote),
      align: 'centrer',
      cellClick: (e, cell) => {
        console.log(cell.getData())
      },
    },
  ]
  const verifierChantier = (acces) => {
    const rolePourChantier = acces.find((el)=>el.chantierId._id==infoChantier._id)
    if(rolePourChantier){
      return {
        role:rolePourChantier.roles.roleType,
        roleName:rolePourChantier.roles.roleName
      }
    }else{
      return {
        role:'Non défini',
        roleName:'Non défini'
      }
    }
  }
  useEffect(() => {
    setWait(true)
    getAllData(
      `planning=${infoChantier.planning}`,
      'notesChantier',
      infoUser.token
    )
      .then((res) => {
        console.log(res.data)
        if (res.data) {
          setDataNote(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally((res) => {
        setWait(false)
      })
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/lots/simple?planning=${infoChantier.planning}&sort=titre`,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          console.log(res.data.data)
          setDataLot(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [bool])
  useEffect(() => {
    getDataPresence(infoChantier.planning, infoUser.token, '')
      .then((e) => {
        if (e.responsables) {
          /* getnew table */
          const contacts = e.responsables.reduce((accumulator, responsable) => {
            //console.log(responsable);
            const user = {}
            user._id = responsable._id ? responsable._id : '---'
            user.name = responsable.name ? responsable.name : 'Non défini'
            user.lastname = responsable.lastname
              ? responsable.lastname
              : 'Non défini'
            user.email = responsable.email ? responsable.email : 'Non défini'
            user.telephone = responsable.telephone
              ? responsable.telephone
              : 'Non défini'
            user.present = true
            user.lot = responsable.lot ? responsable.lot : ''
            user.retard = false
            user.absent = false
            user.convoque =
              responsable.role?.roleType === 'ent'
                ? responsable.statut
                  ? responsable.statut.convoque
                  : true
                : responsable.statut
                ? responsable.statut.convoque
                : false
            user.diffusionPresence = responsable.statut
              ? responsable.statut.diffusionPresence
              : true
            user.intervenant = responsable._id ? responsable._id : 'Non défini'
            user.role = verifierChantier(responsable.acces).role

            /* user.role = responsable.role?.roleType
              ? responsable.role.roleType
              : 'Non défini' */
              verifierChantier(responsable.acces)
            user.entreprise = responsable.entrepriseName
              ? responsable.entrepriseName
              : responsable.entreprise
              ? responsable.entreprise
              : 'Non défini'
           /*  user.roleName = responsable.role?.roleName
              ? responsable.role.roleName
              : 'Non défini' */
              user.roleName = verifierChantier(responsable.acces).roleName
            const exist = accumulator.find((res) => res._id === responsable._id)
            if (!exist) {
              accumulator.push(user)
            }
            return accumulator
          }, [])
          setDataPresence(contacts)
        }
      })
      .catch((err) => {
        console.log(err)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }, [])
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="rapport" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#94A3B8',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                  / Rapports
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  {' '}
                  / Nouvelle note de chantier
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Rapport_CR')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Nouvelle note de chantier
                </span>
              </span>
            </div>
          </div>
          <div className="container-fluid">
            <div
              className="wrapper"
              style={{ marginTop: '20px', marginBottom: '10px' }}>
              <div
                className="item"
                onClick={(ev) => {
                  ev.preventDefault()
                  setItem('presence')
                }}
                style={{
                  borderLeft: '1px solid #CBD5E1',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                }}>
                <span
                  style={
                    item == 'presence'
                      ? {
                          color: '#F8FAFC',
                          fontSize: '16px',
                          fontWeight: '400',
                          backgroundColor: '#6366F1',
                          padding: '8px 24px',
                          borderRadius: '8px',
                          marginLeft: '10px',
                        }
                      : {
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }
                  }>
                  Présence
                </span>
              </div>
              <div
                className="item"
                onClick={(ev) => {
                  ev.preventDefault()
                  setItem('preambule')
                }}>
                <span
                  style={
                    item == 'preambule'
                      ? {
                          color: '#F8FAFC',
                          fontSize: '16px',
                          fontWeight: '400',
                          backgroundColor: '#6366F1',
                          padding: '8px 24px',
                          borderRadius: '8px',
                        }
                      : {
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }
                  }>
                  Préambule
                </span>
              </div>
              <div
                className="item"
                onClick={(ev) => {
                  ev.preventDefault()
                  setItem('remarque')
                }}
                style={{
                  borderRight: '1px solid #CBD5E1',
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                }}>
                <span
                  style={
                    item == 'remarque'
                      ? {
                          color: '#F8FAFC',
                          fontSize: '16px',
                          fontWeight: '400',
                          backgroundColor: '#6366F1',
                          padding: '8px 24px',
                          borderRadius: '8px',
                          marginRight: '10px',
                        }
                      : {
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }
                  }>
                  Remarques
                </span>
              </div>
            </div>
          </div>
          {item == 'remarque' && (
            <>
              <div
                className="container-fluid"
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  padding: '16px 0px 24px',
                }}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 0px 0px 40px',
                  }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}>
                      Remarques
                    </span>
                    <span
                      style={{
                        backgroundColor: '#E0E7FF',
                        borderRadius: '24px',
                        padding: '6px 12px',
                        color: '#64748B',
                        fontSize: '14px',
                        fontWeight: '600',
                        marginLeft: '8px',
                        maxHeight: '32px',
                        width: 'auto',
                      }}>
                      {dataNote.length > 0 ? dataNote.length : '0'} tâches
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        padding: '8px 12px 8px 12px',
                        backgroundColor: 'rgba(226, 232, 240, 0.6)',
                        borderRadius: '8px',
                        height: '40px',
                      }}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#818CF8"
                          style={{ width: '18px', height: '18px' }}>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        placeholder="Recherche"
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                      />
                    </span>
                    <button
                      className="btn"
                      style={{
                        backgroundColor: '#2DD4BF',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                        marginRight: '20px',
                      }}
                      onClick={(e) => addNoteRow(e)}>
                      + Nouvelle tâche
                    </button>
                    <button
                      className="btn"
                      style={{
                        backgroundColor: '#4F46E5',
                        color: '#F8FAFC',
                        marginLeft: '24px',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setWait(true)
                        axios
                          .post(
                            `${process.env.REACT_APP_URL}/api/v1/fiches/noteChantierToPDF?planning=${infoChantier.planning}`,
                            { preambule: preambule, presences: dataPresence },
                            {
                              headers: {
                                Authorization: `Bearer ${infoUser.token}`,
                              },
                            }
                          )
                          .then((res) => {
                            addToast(
                              'Fiche de note de chantier créé avec succès.',
                              {
                                appearance: 'success',
                                autoDismiss: true,
                              }
                            )
                            localStorage.setItem('typeRapport','note')
                            navigate('/Rapport_CR')
                          })
                          .catch((err) => {
                            console.log(err)
                            addToast("Une erreur s'est produit.", {
                              appearance: 'error',
                              autoDismiss: true,
                            })
                          })
                          .finally((res) => {
                            setWait(false)
                          })
                      }}>
                      Valider
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    width: '100%',
                    borderTop: 'solid #E2E8F0 1px',
                    marginTop: '16px',
                  }}>
                  <ReactTabulator
                    data={dataNote}
                    columns={colonneNote}
                    layout={'fitColumns'}
                    events={{ cellEdited: noteEdited }}
                    options={{
                      locale: true,
                      pagination: 'local',
                      paginationSize: 5,
                      langs: {
                        fr: {
                          pagination: {
                            first: 'Premier',
                            first_title: 'Première',
                            last: 'Dernier',
                            last_title: 'Dernière',
                            prev: 'Précédent',
                            prev_title: 'Précédente',
                            next: 'Suivant',
                            next_title: 'Page suivante',
                            page_size: 'Taille de page',
                            page_title: 'Afficher la page',
                            all: 'Tous',
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {item == 'presence' && (
            <>
              <div className="container-fluid">
                <Presence data={dataPresence} updateData={setDataPresence} />
              </div>
            </>
          )}
          {item == 'preambule' && (
            <>
              <div
                className="container-fluid"
                style={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  padding: '20px 24px',
                }}>
                <span style={{ color: '#334155', fontSize: '16px' }}>
                  Préambule:
                </span>{' '}
                <br />
                <textarea
                  class="form-control"
                  rows="10"
                  style={{ width: '100%' }}
                  defaultValue={preambule}
                  onChange={(e) => {
                    e.preventDefault()
                    setPreambule(e.target.value)
                  }}></textarea>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Modal multiselect entreprise */}
      <div class="modal" id="myModalEntreprise">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Lot concerné(s)
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>

            <div class="modal-body">
              <Multiselect
                displayValue="titre"
                options={dataLot}
                placeholder=""
                onRemove={function noRefCheck(e) {
                  console.log(e)
                  setEntSelected(
                    e.filter((el) => el.titre !== 'Sélectionner des lots')
                  )
                  //setEntSelected(entSelected.filter((el)=>el.nom!=="Sélectionner des entreprises"))
                }}
                onSelect={function noRefCheck(e) {
                  console.log(e)
                  setEntSelected(
                    e.filter((el) => el.titre !== 'Sélectionner des lots')
                  )
                }}
                selectedValues={entSelected}
              />
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                style={{
                  color: 'white',
                  backgroundColor: '#4F46E5',
                  borderRadius: '8px',
                }}
                data-bs-dismiss="modal"
                onClick={(ev) => {
                  ev.preventDefault()
                  axios
                    .patch(
                      `${process.env.REACT_APP_URL}/api/v1/notesChantier/${dataModalEntreprise._id}?planning=${infoChantier.planning}`,
                      { lots: entSelected },
                      {
                        headers: {
                          Authorization: `Bearer ${infoUser.token}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log(res)
                      maj(dataMaj, entSelected)
                    })
                    .catch((err) => {
                      console.log(err)
                      addToast("Une erreur s'est produit.", {
                        appearance: 'error',
                        autoDismiss: true,
                      })
                    })
                }}>
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Action */}
      <div class="modal fade" id="myModalAction">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Importer des photos
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.target.reset()
                addToast('Enregistrement en cours.', {
                  appearance: 'success',
                  autoDismiss: true,
                })
                const formData = new FormData()
                imageAction.map((item) => {
                  formData.append('item', item.originFileObj)
                })
                axios
                  .patch(
                    `${process.env.REACT_APP_URL}/api/v1/notesChantier/${dataModalAction._id}?planning=${infoChantier.planning}`,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${infoUser.token}`,
                      },
                    }
                  )
                  .then((res) => {
                    addToast('Enregistrement avec succès.', {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    console.log(res)
                    if (res.data.data) {
                      console.log(res.data.data)
                      const result = res.data.data
                      maj(dataMaj, result.actionPhoto)
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                    addToast("Une erreur s'est produit.", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                  })
              }}>
              {' '}
              <div class="modal-body">
                {dataModalAction?.actionPhoto?.length < 4 ? (
                  <>
                    <div class="mb-3 mt-3">
                      <label for="comment">
                        (4 images max, Taille 1Mo max, type:".png,.jpeg,.jpg"):
                      </label>
                      <Upload.Dragger
                        multiple={true}
                        maxCount={4}
                        beforeUpload={(files) => {
                          return false
                        }}
                        showUploadList={{ showRemoveIcon: true }}
                        accept=".png,.jpeg,.jpg"
                        listType="picture"
                        onChange={(ev) => {
                          console.log(ev.fileList)
                          setImageAction(ev.fileList)
                        }}>
                        <Button>Parcourir Fichier</Button>
                      </Upload.Dragger>
                    </div>
                  </>
                ) : (
                  <>
                    <h4
                      class="modal-title"
                      style={{ color: '#334155', textAlign: 'center' }}>
                      Vous avez atteint la limite des photos à importer (4
                      images)
                    </h4>
                  </>
                )}
                {dataModalAction?.actionPhoto?.length > 0 && (
                  <>
                    <div
                      style={{
                        width: '100%',
                        padding: '12px 12px',
                        height: '300px',
                        overflowY: 'scroll',
                      }}>
                      {dataModalAction.actionPhoto.map((el) => {
                        return (
                          <>
                            <div
                              className="container-fluid"
                              id={`${el._id}`}
                              style={{ position: 'relative' }}>
                              <img
                                src={`${process.env.REACT_APP_URL}/api/v1/ressources/signedFile/${el.accessToken}`}
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                  marginTop: '5px',
                                }}
                              />
                              <button
                                className="btn btn-danger"
                                onClick={(e) => {
                                  e.preventDefault()
                                  const cible = document.getElementById(
                                    `${el._id}`
                                  )
                                  cible.style = 'display:none'
                                  const image = dataModalAction.actionPhoto
                                  updateData(
                                    {
                                      actionPhoto: image.filter(
                                        (img) => img._id != el._id
                                      ),
                                      _id: dataModalAction._id,
                                    },
                                    'notesChantier',
                                    infoUser.token,
                                    infoChantier.planning
                                  ).then((res) => {
                                    console.log(res)
                                    if (res.data) {
                                      dataMaj.getRow().update(res.data)
                                    } else {
                                      addToast("Une erreur s'est produit", {
                                        appearance: 'error',
                                        autoDismiss: true,
                                      })
                                    }
                                  })
                                }}
                                style={{
                                  position: 'absolute',
                                  top: '3px',
                                  right: '15px',
                                }}>
                                <i className="ti ti-trash"></i>
                              </button>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}
                  data-bs-dismiss="modal"
                  disabled={
                    dataModalAction?.actionPhoto?.length < 4 ? false : true
                  }>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default NewNoteChantier
