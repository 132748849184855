import axios from 'axios'

export const addRole = async (data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    await axios
      .post(`${process.env.REACT_APP_URL}/api/v1/roles`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:`Bearer ${token}`
        },
      })
      .then((res) => {
        console.log(res)
        return res.data
      })
      .catch((err) => {
        console.log(err)
        return err
      })
  } catch (err) {
    console.log(err)
    return { message: 'erreur' }
  }
}
export const updateRole = async (data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/roles/${data._id}`, data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getAllRole = async (query,token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/roles?${query}&planning=${planning}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const delRole = async (data,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .delete(`${process.env.REACT_APP_URL}/api/v1/roles/${data._id}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}
