import React, { useState, useRef, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import PageNotFound from '../../../components/404/PageNotFound'
import { getAllData, updateData } from '../../../helpers/suivi/suivi'
import { getAllPlan } from '../../../helpers/observation/observation'
import Avancement from './component/Avancement'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import Modal from '../observation/component/Modal'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'
const NewAvancement = () => {
  const { addToast } = useToasts()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [dateRendu, setDateRendu] = useState(new Date(Date.now()))
  const [wait, setWait] = useState(false)
  const [lot, setLot] = useState([])
  const [idPlan, setIdPlan] = useState()
  const [nomPlan, setNomPlan] = useState('---')
  const [idLot, setIdLot] = useState('')
  const [item, setItem] = useState('')
  const [allPlan, setAllPlan] = useState([])
  const [planAnnoter, setPlanAnnoter] = useState()
  const [listPlanAfficher, setLisPlanAffiche] = useState([])
  const [originalListPlan, setOriginalListPlan] = useState([])
  const [listPlanFromLot, setListPlanFromLot] = useState([])
  const [nomPlanPartager, setNomPlanPartage] = useState({})
  const [planApartage,setPlanApartage] = useState([])
  const [bool, setBool] = useState(false)
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const navigate = useNavigate()
  const verificationPlan = (plan, id) => {
    for (const objet of plan) {
      if (objet._id === id) {
        return true // Un objet avec l'_id égal à id a été trouvé
      }
    }
    return false // Aucun objet avec l'_id égal à id n'a été trouvé
  }
  useEffect(() => {
    setWait(true)
    getAllData(`planning=${infoChantier.planning}`, 'lots', infoUser.token)
      .then((result) => {
        if (result.data) {
          console.log(result.data)
          const lots = result.data.filter((el) => el.generalite !== true)
          if (idLot) {
            lots.map((el1) => {
              if (el1._id == idLot) {
                setIdLot(el1._id)
                setItem(el1._id)
                setLot(lots)
                setListPlanFromLot(el1.plans)
                getAllPlan(infoChantier.planning, infoUser.token).then((e) => {
                  if (e.name == 'AxiosError') {
                    setData([])
                    setWait(false)
                  } else {
                    const plans = e.data[0]
                    setPlanAnnoter(plans)
                    setIdPlan(plans._id)
                    setNomPlan(plans.description)
                    setAllPlan(e.data)
                    setLisPlanAffiche(el1.plans)
                    setOriginalListPlan(e.data)
                  }
                })
              }
            })
          } else {
            setIdLot(lots[0]._id)
            setItem(lots[0]._id)
            setLot(lots)
            setListPlanFromLot(lots[0].plans)
            getAllPlan(infoChantier.planning, infoUser.token).then((e) => {
              if (e.name == 'AxiosError') {
                setData([])
                setWait(false)
              } else {
                const plans = e.data[0]
                setPlanAnnoter(plans)
                setIdPlan(plans._id)
                setNomPlan(plans.description)
                setAllPlan(e.data)
                setLisPlanAffiche(lots[0].plans)
                setOriginalListPlan(e.data)
              }
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally((res) => {
        setWait(false)
      })
  }, [bool])
  //fonction pour le nav bar scroll horizontal
  const rights = () => {
    const element = document.querySelector('.wrappers')
    const item = document.querySelector('.items')
    element.scrollLeft = element.scrollLeft + item.clientWidth + 10
  }

  const lefts = () => {
    const element = document.querySelector('.wrappers')
    const item = document.querySelector('.items')
    element.scrollLeft = element.scrollLeft - item.clientWidth + 10
  }

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="rapport" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="page-title-box">
              <div class="float-end"></div>
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#94A3B8',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                  / Rapports
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  {' '}
                  / Rapport d'avancement
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Rapport_CR')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Rapport d'avancement
                </span>
                <div
                  class="form-floating"
                  style={{ width: '200px', marginLeft: '1vw' }}>
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    defaultValue={dateRendu.toISOString().substring(0, 10)}
                    onChange={(e) => {
                      if (e.target.value) {
                        setDateRendu(new Date(e.target.value))
                      }
                    }}
                  />
                  <label for="floatingInput">Date du rapport</label>
                </div>
              </span>
            </div>
          </div>
          <div className="container-fluid">
            <nav>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '32px',
                }}>
                <div
                  style={{
                    width: '70%',
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}>
                  <button
                    className="btn"
                    style={{
                      width: '10px',
                      height: '52px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '0px',
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px',
                      borderTop: '1px solid #CBD5E1',
                      borderBottom: '1px solid #CBD5E1',
                      borderLeft: '1px solid #CBD5E1',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      lefts()
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{
                        width: '20px',
                        height: '20px',
                      }}>
                      <path
                        fillRule="evenodd"
                        d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                  <div className="wrappers">
                    {lot.map((el) => {
                      return (
                        <div
                          className="items"
                          onClick={(ev) => {
                            ev.preventDefault()
                            setIdLot(el._id)
                            setItem(el._id)
                            setBool(!bool)
                            /*  setIdLot(el._id)
                            setItem(el._id)
                            setLisPlanAffiche(el.plans) */
                          }}>
                          <span
                            style={
                              item == el._id
                                ? {
                                    color: '#F8FAFC',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    backgroundColor: '#6366F1',
                                    padding: '8px 16px',
                                    borderRadius: '8px',
                                  }
                                : {
                                    color: '#334155',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    cursor: 'pointer',
                                  }
                            }>
                            {el.titre ? el.titre : '---'}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                  <button
                    className="btn"
                    style={{
                      width: '10px',
                      height: '52px',
                      backgroundColor: 'rgba(226, 232, 240, 0.6)',
                      borderRadius: '0px',
                      borderTopRightRadius: '8px',
                      borderBottomRightRadius: '8px',
                      textAlign: 'center',
                      position: 'relative',
                      borderTop: '1px solid #CBD5E1',
                      borderBottom: '1px solid #CBD5E1',
                      borderRight: '1px solid #CBD5E1',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      rights()
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      style={{
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        left: '0',
                        top: '17',
                      }}>
                      <path
                        fillRule="evenodd"
                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </nav>
          </div>
          <div
            className="container-fluid"
            style={{
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '15px',
            }}>
            <div>
              <button
                className="btn"
                data-bs-toggle="dropdown"
                style={{
                  backgroundColor: '#EBEFF5',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <i className="ti ti-list"></i>{' '}
                <span style={{ marginLeft: '20px' }}>{nomPlan}</span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li style={{ display: 'flex' }}>
                  <a className="dropdown-item" href="#">
                    tout sélectionner
                  </a>
                  <input
                    type="checkbox"
                    style={{ marginRight: '40px' }}
                    onChange={(ev) => {
                      if (ev.target.checked) {
                        console.log(originalListPlan)
                        setLisPlanAffiche(originalListPlan)
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/lots/${idLot}`,
                            {
                              plans: originalListPlan,
                              planning: infoChantier.planning,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${infoUser.token}`,
                              },
                            }
                          )
                          .then((res) => {
                            console.log(res)
                          })
                          .catch((err) => console.log(err))
                      } else {
                        axios
                          .patch(
                            `${process.env.REACT_APP_URL}/api/v1/lots/${idLot}`,
                            {
                              plans: [],
                              planning: infoChantier.planning,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${infoUser.token}`,
                              },
                            }
                          )
                          .then((res) => {
                            console.log(res)
                          })
                          .catch((err) => console.log(err))
                        setLisPlanAffiche([])
                      }
                    }}
                  />
                </li>
                {allPlan.map((el) => {
                  return (
                    <>
                      <li
                        style={
                          idPlan == el._id
                            ? {
                                backgroundColor: 'rgba(226, 232, 240, 0.6)',
                                display: 'flex',
                                alignItems: 'center',
                              }
                            : { display: 'flex', alignItems: 'center' }
                        }>
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1vw' }}
                          onClick={(e) => {
                            e.preventDefault()
                            setPlanAnnoter(el)
                            setIdPlan(el._id)
                            setNomPlan(el.description)
                          }}>
                          {el.description ? el.description : '---'}
                        </a>
                        <input
                          data-toggle="tooltip"
                          data-placement="top"
                          title={`${
                            verificationPlan(listPlanAfficher, el._id)
                              ? 'Masquer dans le rapport'
                              : 'Afficher dans le rapport'
                          }`}
                          type="checkbox"
                          style={{ marginRight: '10px', cursor: 'pointer' }}
                          checked={verificationPlan(listPlanAfficher, el._id)}
                          onChange={(ev) => {
                            if (ev.target.checked) {
                              axios
                                .patch(
                                  `${process.env.REACT_APP_URL}/api/v1/lots/${idLot}`,
                                  {
                                    plans: [...listPlanAfficher, el],
                                    planning: infoChantier.planning,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${infoUser.token}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  console.log(res)
                                })
                                .catch((err) => console.log(err))
                              setLisPlanAffiche([...listPlanAfficher, el])
                            } else {
                              const newList = listPlanAfficher.filter(
                                (e) => e._id !== el._id
                              )
                              axios
                                .patch(
                                  `${process.env.REACT_APP_URL}/api/v1/lots/${idLot}`,
                                  {
                                    plans: newList,
                                    planning: infoChantier.planning,
                                  },
                                  {
                                    headers: {
                                      Authorization: `Bearer ${infoUser.token}`,
                                    },
                                  }
                                )
                                .then((res) => {
                                  console.log(res)
                                })
                                .catch((err) => console.log(err))
                              setLisPlanAffiche(newList)
                            }
                          }}
                        />
                        <span
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Partager les données">
                          <i
                            className="ti ti-file-export"
                            data-bs-toggle="modal"
                            data-bs-target="#myModal"
                            onClick={(ev) => {
                              ev.preventDefault()
                              setNomPlanPartage(el)
                              setPlanApartage([])
                            }}
                            style={{
                              cursor: 'pointer',
                              color: 'grey',
                              fontSize: '22px',
                              marginLeft: '5px',
                              marginRight: '5px',
                            }}></i>
                        </span>
                      </li>
                    </>
                  )
                })}
              </ul>
            </div>
            <button
              className="btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              style={{
                color: 'white',
                backgroundColor: '#4F46E5',
                borderRadius: '8px',
              }}>
              <i className="ti ti-plus"></i> Nouveau plan
            </button>
            <Modal
              id={'exampleModal'}
              setWait={setWait}
              data={allPlan}
              setData={setAllPlan}
            />
          </div>
          <div className="container-fluid">
            <Avancement
              idLot={idLot}
              idPlan={idPlan}
              setWait={setWait}
              dateExport={dateRendu}
              plan={planAnnoter}
              listPlanAfficher={listPlanAfficher}
            />
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
      <div class="modal" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Ajouter les critères dans "{nomPlanPartager.description}" vers:
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>

            <div class="modal-body">
              {allPlan &&
                allPlan.map((el) => {
                  if(el.description!=nomPlanPartager.description){
                    return (
                      <>
                        <div style={{ display: 'flex',width:'100%',alignItems:'center',justifyContent:'space-between'}}>
                          <span>{el.description}</span>{' '}
                          <input
                            type="checkbox"
                            checked={verificationPlan(planApartage,el._id)}
                            onChange={(ev) => {
                              if(ev.target.checked){
                                setPlanApartage([...planApartage,el])
                              }else{
                                setPlanApartage(planApartage.filter((e)=>e._id!=el._id))
                              }
                            }}
                          />
                        </div>
                      </>
                    )
                  }
                })}
            </div>  

            <div class="modal-footer">
              <button
                type="button"
                class="btn"
                style={{
                  color: 'white',
                  backgroundColor: '#4F46E5',
                  borderRadius: '8px',
                }}
                onClick={(ev)=>{
                  ev.preventDefault()
                  console.log(planApartage);
                  axios.post(`${process.env.REACT_APP_URL}/api/v1/avancements/makeAsTemplate`,{
                    planning: infoChantier.planning,
                    lot: idLot,
                    planSource:nomPlanPartager._id,
                    planDest:planApartage
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${infoUser.token}`,
                    },
                  }).then((res)=>{
                    addToast('Transfert des données réussi.', {
                      appearance: 'success',
                      autoDismiss: true,
                    })
                    console.log(res);
                  }).catch((err)=>{
                    addToast("Une erreur s'est produit", {
                      appearance: 'error',
                      autoDismiss: true,
                    })
                    console.log(err);
                  })
                }}
                data-bs-dismiss="modal">
                Valider
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewAvancement
