import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import UploadFile from './upload/UploadFile'
import InfoDashboard from './InfoDashboard'
import { FullPage, Slide } from 'react-full-page'
import GaugeChart from 'react-gauge-chart'
import { getProgLot } from '../../../helpers/planning/dashBoard/upload'
import PageNotFound from '../../../components/404/PageNotFound'
import './dashboard.css'
//Tableau de bord de l'application
const Dashboard = () => {
  const navigate = useNavigate()
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const [havePlanning, setHavePlaning] = useState(false)
  sessionStorage.setItem('media', 'media')
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [progLot, setProgLot] = useState([])
  const [item, setItem] = useState('dashboard')
  const colorProgress = (a, b) => {
    if (a < b) {
      return '#EA4228'
    } else {
      return '#5cb85c'
    }
  }
  useEffect(() => {
    if (infoChantier.planning) {
      setHavePlaning(true)
      getProgLot(infoChantier.planning, infoUser.token).then((e) => {
        if (e.error) {
          setProgLot([])
        } else {
          setProgLot(e)
        }
      })
    } else {
      setHavePlaning(false)
    }
  }, [havePlanning])
  const options = {}
  //fonction pour le nav bar scroll horizontal
  const right = () => {
    const element = document.querySelector('.wrapper')
    const item = document.querySelector('.item')
    element.scrollLeft = element.scrollLeft + item.clientWidth + 10
  }

  const left = () => {
    const element = document.querySelector('.wrapper')
    const item = document.querySelector('.item')
    element.scrollLeft = element.scrollLeft - item.clientWidth + 10
  }
  return (
    <>
      <Menu bool={true} active="planning" />
      <div className="d-flex flex-column">
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <span style={{ marginLeft: '10px' }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="#94A3B8"
                      style={{ width: '16px', height: '16px' }}>
                      <path
                        fillRule="evenodd"
                        d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                        clipRule="evenodd"
                      />
                    </svg>{' '}
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}>
                      / Planification
                    </span>
                  </span>{' '}
                  <br />
                  <span
                    style={{
                      marginLeft: '15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        backgroundColor: '#E2E8F0',
                        padding: '2px',
                        borderRadius: '6px',
                      }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="#6366F1"
                        style={{
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/accueil')
                        }}>
                        <path
                          fillRule="evenodd"
                          d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                        marginLeft: '10px',
                      }}>
                      {' '}
                      Planification
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
              <div className="wrapper">
                <div
                  className="item"
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}>
                  <span
                    style={{
                      color: '#F8FAFC',
                      fontSize: '16px',
                      fontWeight: '400',
                      backgroundColor: '#6366F1',
                      padding: '8px 24px',
                      borderRadius: '8px',
                    }}>
                    Tableau de bord
                  </span>
                </div>
                {(infoUser?.user?.role?.roleType == 'admin' ||
                  infoUser?.user?.role?.roleType == 'opc' ||
                  infoUser?.user?.role?.roleType == 'moex') && (
                  <>
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        navigate('/planning_GestionTache')
                      }}>
                      <span
                        style={{
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }}>
                        Gestion des tâches
                      </span>
                    </div>
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/Planning_GestionTache_LastPlanner')
                      }}>
                      <span
                        style={{
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }}>
                        Glissant
                      </span>
                    </div>
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/planning_Favori')
                      }}>
                      <span
                        style={{
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }}>
                        Jalons Critiques
                      </span>
                    </div>
                    <div
                      className="item"
                      onClick={(e) => {
                        e.preventDefault()
                        navigate('/planning_Retard')
                      }}
                      style={{
                        borderRight: '1px solid  #CBD5E1',
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                      }}>
                      <span
                        style={{
                          color: '#334155',
                          fontSize: '16px',
                          fontWeight: '400',
                          cursor: 'pointer',
                        }}>
                        Retards
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {havePlanning && infoChantier?.planningDiffuse ? (
              <>
                <div className="container-fluid">
                  <InfoDashboard />
                </div>
              </>
            ) : (
              <>
                <div className="container mt-2">
                  <h5>Importer un nouveau planning </h5>
                  <UploadFile />
                </div>
              </>
            )}
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                right: '0',
              }}>
              <h1 className="text-center">
                {' '}
                <i class="ti ti-arrow-big-down"></i>
              </h1>
            </div>
          </div>
        </div>
      </div>
      {havePlanning && infoChantier?.planningDiffuse && (
        <>
          <div className="page-wrapper">
            <div className="page-content-tab" style={{ paddingTop: '15px' }}>
              <h5 style={{ marginLeft: '20px' }}>Avancement lot par lot</h5>
              <div
                className="container-fluid p-3"
                style={{
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}>
                <div className="row">
                  {progLot &&
                    progLot.map((e) => {
                      return (
                        <>
                          <div className="col-sm-4">
                            <div className="card">
                              <div className="card-header">
                                <div className="row align-items-center">
                                  <h5 className="text-center">
                                    {e.titre ? e.titre.toUpperCase() : ' '}
                                  </h5>
                                </div>
                              </div>
                              <div className="card-body">
                                <p className="text-center">
                                  {' '}
                                  {/* <u>Prévisionnel</u> : 40 %{' '} 
                                  primary : #0275d8
                                  */}
                                </p>
                                <GaugeChart
                                  id="gauge-chart5"
                                  animate={true}
                                  nrOfLevels={10}
                                  arcsLength={[0.2, 0.3, 0.3, 0.2]}
                                  colors={[
                                    '#0275d8',
                                    '#0275d8',
                                    '#0275d8',
                                    '#0275d8',
                                  ]}
                                  percent={e.progress ? e.progress / 100 : 0}
                                  arcPadding={0.02}
                                  textColor={colorProgress(
                                    e.progress,
                                    e.plannedProgress
                                  )}
                                  fontSize="2vw"
                                  style={{ fontWeight: 'bold' }}
                                  needleColor=" #0275d8"
                                  needleBaseColor=" #0275d8"
                                  arcWidth={0.09}
                                  marginInPercent={0.03}
                                />
                              </div>
                              <div className="card-footer">
                                <h5 className="text-center">
                                  {' '}
                                  Avancement prévisionnel :{' '}
                                  {e.plannedProgress ? e.plannedProgress : 0}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Dashboard
