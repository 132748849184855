import React from 'react'
import { PulseLoader } from 'react-spinners'
const LoaderFixed = () => {
  return (
    <>
     <div style={{position:'fixed',top:'0',left:'0',right:'0',bottom:'0',display:'flex',justifyContent:'center',alignItems:'center',zIndex:'22222222222'}}>
        <PulseLoader color='#2DD4BF' loading={true} size={32}/>
     </div>
    </>
  )
}

export default LoaderFixed