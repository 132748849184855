import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

const ModifLot = ({ id, data }) => {
  const  infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const dispatch = useDispatch()
  const bool = useSelector((state) => state.bool.bool)
  const [ttht, setTtht] = useState(data.ttht)
  const [tva, setTva] = useState(data.tva)
  const [tttc, setTttc] = useState(data.tttc)
  const handleSubmit = () => {
    axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/lots/${data._id}`, {
        tva: tva,
      },{
        headers: {
          Authorization: `Bearer ${infoUser.token}`
        },
      })
      .then((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <div
      class="modal fade modal-dialog-scrollable"
      id={id}
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {data.titre}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <div class="modal-body">
              {/* <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Total Travaux en Euros HT - BASE :
                </label>
                <input
                  type="number"
                  defaultValue={data.ttht}
                  class="form-control"
                  placeholder="Unité"
                  onChange={(e) => {
                    setTtht(e.target.value)
                  }}
                />
              </div> */}
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  TVA:
                </label>
                <select class="form-select" aria-label="Default select example" onChange={(e)=>{
                  e.preventDefault()
                  setTva(e.target.value)
                }}>
                  <option selected>Choisir le TVA</option>
                  <option value="20">Normal : 20%</option>
                  <option value="10">Intermédiaire : 10%</option>
                  <option value="5.5">Réduit  5,5%</option>
                </select>
                {/* <input
                  type="number"
                  class="form-control"
                  defaultValue={data.tva}
                  placeholder="TVA"
                  onChange={(e) => {
                    setTva(e.target.value)
                  }}
                /> */}
              </div>
              {/* <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Total Travaux en Euros TTC - BASE :
                </label>
                <input
                  type="number"
                  class="form-control"
                  defaultValue={data.tttc}
                  placeholder="Unité"
                  onChange={(e) => {
                    setTttc(e.target.value)
                  }}
                />
              </div> */}
            </div>
            <div class="modal-footer">
              <button
                type="submit"
                class="btn btn-success"
                data-bs-dismiss="modal"
              >
                Enregistrer
              </button>
              <button
                type="button"
                class="btn btn-dark"
                data-bs-dismiss="modal"
              >
                Fermer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModifLot
