import React from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
const Courrier = () => {
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="courrier" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab" >
          <div
            className="container-fuild"
            style={{
              padding: '16px 5px',
              backgroundColor: '#FFFFFF',
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              margin: '20px 20px',
              boxShadow:
                '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
              height:'80vh',
              display:'flex',
              alignItems:'center',
              justifyContent:'center'
            }}> 
            <span style={{fontSize:'20px',fontWeight:'600'}}>En phase de développement</span>
            </div>
        </div>
      </div>
    </>
  )
}

export default Courrier
