import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/Kolos_logo/Logo_Kolos_Blanc.png'
// import logo from '../../assets/images/Kolos_logo/1_carre_blanc.png'
import { useNavigate, useLocation } from 'react-router-dom'
import { resetMDP } from '../../helpers/connexion/connexion'
import BeatLoader from 'react-spinners/BeatLoader'
import { useForm } from 'react-hook-form'
//Page de connexion à SOODA
const Reintialisation_MDP = () => {
  const location = useLocation()

  const navigate = useNavigate()
  const [param, setParam] = useState('')
  const [loading, setLoading] = useState(false)
  const [identique, setIdentique] = useState(false)
  const [err, setErr] = useState(false)
  const [message, setMessage] = useState('')
  const [messageDb,setMessageDb] = useState('')
  const [erreur,setErreur] = useState(false)
  const [success,setSuccess] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      password: '',
      passwordConfirm: null,
    },
    mode: 'onChange',
  })
  const password = watch('password')
  const passwordConfirm = watch('passwordConfirm')
  //fonction pour la connexion de l'utilisateur
  const onSubmit = (data) => {
    setLoading(true)
    console.log(data)
    console.log(param)
    resetMDP(data, param).then((e) => {
      console.log(e)
      if (e.data?.data?.status == 'success') {
        setMessageDb("Votre mot de passe à été réinitialisé avec succès.")
        setSuccess(true)
        setLoading(false)
      } else {
        setMessageDb("Votre clé n'est pas valide ou à expiré")
        setErreur(true)
        setLoading(false)
      }
    })
  }
  const customValidator = (value) => {
    const hasNumber = /\d/.test(value)
    const hasLowercase = /[a-z]/.test(value)
    const hasUppercase = /[A-Z]/.test(value)
    const hasSpecial = /[!@#\%^&]/.test(value)
    const longeur = value.split("").length
    console.log(longeur);
    if (longeur <1) {
      setErr(false)
      setMessage('-Minimum 8')
    } else {
      if (!hasNumber) {
        setErr(true)
        setMessage('-Au moins 1 chiffre')
      } else {
        if (!hasUppercase) {
          setErr(true)
          setMessage('-Au moins 1 Majuscule')
        } else {
          if (!hasSpecial) {
            setErr(true)
            setMessage('-Au moins 1 caractère spécial (!@/#%^&)')
          } else {
            if (!hasLowercase) {
              setErr(true)
              setMessage('-Au moins 1 minuscule')
            } else {
              setErr(false)
              setMessage('')
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const locationParam = searchParams.get('token')
    setParam(locationParam)
  }, [])

  useEffect(() => {
    customValidator(password)
    if (passwordConfirm) {
      if (password == passwordConfirm) {
        setIdentique(false)
      } else {
        setIdentique(true)
      }
    }
  }, [password, passwordConfirm])
  return (
    <>
      {loading && (
        <>
          <div
            className="container-fluid d-flex"
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              zIndex: '2000',
              backgroundColor: 'rgba(104, 93, 119, 0.7)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={50} />
          </div>
        </>
      )}
      <div class="container-md">
        <div class="row vh-100 d-flex justify-content-center">
          <div class="col-12 align-self-center">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 mx-auto">
                  <div class="card" style={{borderRadius:'8px',overflow:'hidden',border:'1px solid #E2E8F0',boxShadow:'0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)'}}>
                    <div class="card-body p-0 auth-header-box" style={{backgroundColor:'#4F46E5'}}>
                      <div class="text-center p-4 mt-2">
                        <a href="#" class="logo logo-admin">
                          <img
                            src={logo}
                            height="70"
                            alt="logo"
                            class="auth-logo"
                          />
                        </a>
                      </div>
                      <div class="text-center">
                        <p style={{fontSize:'18px',fontWeight:'600',color:'#ffffff'}}>
                          Veuillez entrer le nouveau Mot de passe
                        </p>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <form class="my-4" onSubmit={handleSubmit(onSubmit)}>
                      {erreur && (
                          <>
                            <div
                              class="alert alert-danger border-0 text-center"
                              role="alert"
                            >
                              <strong>
                                {' '}
                                {messageDb} <br />
                                <span className='text-center' style={{color:"blue",cursor:"pointer"}} onClick={(e)=>{
                                  e.preventDefault()
                                  navigate('/Recuperation_MDP')
                                }}> <u>réessayer?</u> </span>
                              </strong>
                            </div>
                          </>
                        )}
                        {success && (
                          <>
                            <div
                              class="alert alert-success border-0 text-center"
                              role="alert"
                            >
                              <strong>
                                {' '}
                                {messageDb}<br />
                                <span className='text-center' style={{color:"blue",cursor:"pointer"}} onClick={(e)=>{
                                  e.preventDefault()
                                  navigate('/')
                                }}> <u>Se connecter ?</u> </span>
                              </strong>
                            </div>
                          </>
                        )}
                        <div class="form-group mb-2">
                          <label class="form-label" for="username" style={{fontSize:'14px',fontWeight:'500',color:'#334155'}}>
                            Nouveau mot de passe
                          </label>
                          <div className="input-group">
                            <input
                              type="password"
                              id="password"
                              class="form-control"
                              placeholder="********"
                              {...register('password', {
                                required: true,
                                minLength: 8,
                                validate: customValidator,
                              })}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                style={{ height: '100%', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  const passwordInput =
                                    document.getElementById('password')
                                  if (passwordInput.type === 'password') {
                                    passwordInput.type = 'text'
                                  } else {
                                    passwordInput.type = 'password'
                                  }
                                }}
                              >
                                <i className="fas fa-eye"></i>
                              </span>
                            </div>
                          </div>
                          {err && (
                            <>
                              <span className="text-danger"> {message} </span> <br />
                            </>
                          )}
                          {errors.password?.type == 'minLength' && (
                            <>
                              <span className="text-danger"> -Minimum 8 </span> <br />
                            </>
                          )}
                          {errors.password?.type == 'required' && (
                            <>
                              <span className="text-danger">
                                -Champ requise
                              </span>
                            </>
                          )}
                        </div>
                        <div class="form-group mb-2">
                          <label class="form-label" for="username" style={{fontSize:'14px',fontWeight:'500',color:'#334155'}}>
                            Confirmer le nouveau mot de passe
                          </label>
                          <div className="input-group">
                            <input
                              type="password"
                              id="passwordConfirm"
                              class="form-control"
                              placeholder="********"
                              {...register('passwordConfirm', {
                                required: true,
                                minLength: 8,
                                pattern:
                                  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\%]).{8,}$/,
                              })}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                style={{ height: '100%', cursor: 'pointer' }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  const passwordInput =
                                    document.getElementById('passwordConfirm')
                                  if (passwordInput.type === 'password') {
                                    passwordInput.type = 'text'
                                  } else {
                                    passwordInput.type = 'password'
                                  }
                                }}
                              >
                                <i className="fas fa-eye"></i>
                              </span>
                            </div>
                          </div>

                          {identique && (
                            <>
                              <span className="text-danger">
                                {' '}
                                Mot de passe non identique
                              </span>
                            </>
                          )}
                        </div>
                        <div class="form-group mb-0 row">
                          <div class="col-12">
                            <div class="d-grid mt-3">
                              <button class="btn" type="submit" style={{backgroundColor:'#4F46E5',color:'white'}}>
                                Enregistrer{' '}
                                <i class="fas fa-sign-in-alt ms-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div class="m-3 text-center text-muted" style={{cursor:"pointer"}} onClick={(e)=>{
                        navigate('/')
                      }}>
                        Se connecter ? 
                      </div>
                      <hr class="hr-dashed mt-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reintialisation_MDP
