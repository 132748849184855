import React, { useEffect, useState } from 'react'
import { ReactTabulator } from 'react-tabulator'
import { getUser } from '../../../../helpers/contact/contact'
import PageNotFound from '../../../../components/404/PageNotFound'
const Presence = ({ data, updateData }) => {
  if (!localStorage.getItem('isLogin')) {
    return <PageNotFound />
  }
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const checkbox = (cell) => {
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2 '
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }

  const edited = (cell) => {
    const rowData = cell.getData()
    if (cell.getField() === 'retard' && cell.getValue() === true) {
      rowData.absent = false
      rowData.present = false
      rowData.retard = true
      cell.getRow().update(rowData)
      //updateData((prevData) => [...prevData])
    }
    if (cell.getField() === 'present' && cell.getValue() === true) {
      rowData.absent = false
      rowData.retard = false
      rowData.present = true
      cell.getRow().update(rowData)
      //updateData((prevData) => [...prevData])
    }
    if (cell.getField() === 'absent' && cell.getValue() === true) {
      rowData.retard = false
      rowData.present = false
      rowData.absent = true
      cell.getRow().update(rowData)
      //updateData((prevData) => [...prevData])
    }
  }
  const simpleInput = (cell) => {
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = cell.getValue()
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  const colonnePresence = [
    {
      title: 'Rôle',
      field: 'roleName',
      formatter: simpleInput,
    },
    {
      title: 'Société',
      field: 'entreprise',
      formatter: simpleInput,
    },
    {
      title: 'Nom',
      field: 'name',
      formatter: simpleInput,
    },
    { title: 'Prénom', field: 'lastname', formatter: simpleInput },
    { title: 'Email', field: 'email', formatter: simpleInput },
    { title: 'Téléphone', field: 'telephone', formatter: simpleInput },
    {
      title: 'Présent',
      field: 'present',
      width: 100,
      editor: true,
      formatter: checkbox,
    },
    {
      title: 'Retard',
      field: 'retard',
      editor: true,
      width: 100,
      formatter: checkbox,
    },
    {
      title: 'Absent',
      field: 'absent',
      editor: true,
      width: 100,
      formatter: checkbox,
    },
    {
      title: 'Convoqué',
      field: 'convoque',
      width: 110,
      editor: true,
      formatter: checkbox,
    },
    {
      title: 'Diffusion',
      width: 100,
      field: 'diffusionPresence',
      editor: true,
      formatter: checkbox,
    },
  ]
  const colonneEntreprise = [
    {
      title: 'Société',
      field: 'entreprise',
      formatter: simpleInput,
    },
    {
      title: 'Nom',
      field: 'name',
      formatter: simpleInput,
    },
    { title: 'Prénom', field: 'lastname', formatter: simpleInput },
    { title: 'Email', field: 'email', formatter: simpleInput },
    { title: 'Téléphone', field: 'telephone', formatter: simpleInput },
    {
      title: 'Présent',
      field: 'present',
      width: 100,
      editor: true,
      formatter: checkbox,
    },
    {
      title: 'Retard',
      field: 'retard',
      editor: true,
      width: 100,
      formatter: checkbox,
    },
    {
      title: 'Absent',
      field: 'absent',
      editor: true,
      width: 100,
      formatter: checkbox,
    },
    {
      title: 'Convoqué',
      field: 'convoque',
      width: 110,
      editor: true,
      formatter: checkbox,
    },
    {
      title: 'Diffusion',
      width: 100,
      field: 'diffusionPresence',
      editor: true,
      formatter: checkbox,
    },
  ]
  return (
    <>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          border: '1px solid #E2E8F0',
          borderRadius: '8px',
          boxShadow:
            '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
          marginTop: '24px',
          padding: '12px 0px',
        }}>
        <div
          className="d-flex flex-row-reverse mt-2"
          style={{ justifyContent: 'space-between', padding: '12px 20px' }}>
          <button
            type="button"
            class="btn"
            onClick={(e) => {
              e.preventDefault()
              updateData([
                ...data,
                {
                  name: 'new',
                  lastname: 'user',
                  email: 'client@gmail.com',
                  telephone: '00 00 00 00 00',
                  present: true,
                  retard: false,
                  absent: false,
                  role: 'autre',
                  convoque: false,
                  diffusionPresence: true,
                  entreprise: 'aucune entreprise',
                },
              ])
            }}
            style={{
              color: 'white',
              backgroundColor: '#4F46E5',
              borderRadius: '8px',
            }}>
            <i class="ti ti-plus"></i> Ajouter
          </button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: '#334155',
                fontSize: '20px',
                fontWeight: '600',
              }}>
              Intervenants
            </span>
          </div>
        </div>
        <ReactTabulator
          data={data.filter((el) => el.role != 'ent'&&el.role!='admin')}
          columns={colonnePresence}
          layout={'fitColumns'}
          events={{
            cellEdited: edited,
          }}
          options={{
            locale: true,
            pagination: 'local',
            paginationSize: 5,
            langs: {
              fr: {
                pagination: {
                  first: 'Premier',
                  first_title: 'Première page',
                  last: 'Dernier',
                  last_title: 'Dernière page',
                  prev: 'Précédent',
                  prev_title: 'Page précédente',
                  next: 'Suivant',
                  next_title: 'Page suivante',
                  page_size: 'Taille de page',
                  page_title: 'Afficher la page',
                  all: 'Tous',
                },
              },
            },
          }}
        />
      </div>
      <div
        style={{
          backgroundColor: '#FFFFFF',
          border: '1px solid #E2E8F0',
          borderRadius: '8px',
          boxShadow:
            '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
          marginTop: '24px',
          padding: '12px 0px',
        }}>
        <div
          className="d-flex flex-row-reverse mt-2"
          style={{ justifyContent: 'space-between', padding: '12px 20px' }}>
          <div></div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                color: '#334155',
                fontSize: '20px',
                fontWeight: '600',
              }}>
              Entreprises
            </span>
          </div>
        </div>
        <ReactTabulator
          data={data.filter((el) => el.role == 'ent')}
          columns={colonneEntreprise}
          layout={'fitColumns'}
          events={{
            cellEdited: edited,
          }}
          options={{
            locale: true,
            pagination: 'local',
            paginationSize: 5,
            langs: {
              fr: {
                pagination: {
                  first: 'Premier',
                  first_title: 'Première page',
                  last: 'Dernier',
                  last_title: 'Dernière page',
                  prev: 'Précédent',
                  prev_title: 'Page précédente',
                  next: 'Suivant',
                  next_title: 'Page suivante',
                  page_size: 'Taille de page',
                  page_title: 'Afficher la page',
                  all: 'Tous',
                },
              },
            },
          }}
        />
      </div>
    </>
  )
}

export default Presence
