import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { getAllObservation, saveFiche } from '../../../helpers/rapport/rapport'
import LoaderFixed from '../../../components/loader/LoaderFixed'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReactTabulator } from 'react-tabulator'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'
import PageNotFound from '../../../components/404/PageNotFound'
//page pour la créaction d'un nouveau fiche de visite
const NewFiche = () => {
  const [bool, setBool] = useState(false)
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [fiche, setFiche] = useState([])
  const [wait, setWait] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [etape, setEtape] = useState(1)
  const [type, setType] = useState(1)
  const [style, setStyle] = useState(1)
  const [filtre, setFiltre] = useState(1)
  const [select, setSelect] = useState(false)
  const [filtrePlan, setFiltrePlan] = useState(1)
  const [dateFiche, setDateFiche] = useState(new Date(Date.now()))
  const [isSelectAll, setIsSelectAll] = useState(false)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }
  const selectAll = (value, type) => {
    var checkboxes = document.getElementsByTagName('input')
    if (value) {
      setFiche(data.filter((el) => el.type == type))
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type === 'checkbox') {
          checkboxes[i].checked = true
        }
      }
    } else {
      setFiche([])
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type === 'checkbox') {
          checkboxes[i].checked = false
        }
      }
    }
  }
  useEffect(() => {
    setWait(true)
    getAllObservation(infoChantier.planning, infoUser.token).then((e) => {
      const allObservation = e.data
      const observationSortByUpdatedAt = allObservation.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      )
      setData(observationSortByUpdatedAt)
      setWait(false)
    })
  }, [bool])

  const validerFiche = (type, style, filtre, filtrePlan) => {
    const newData = data.filter((el) => el.archive == true)
    const dataToSend = newData.map((el) => {
      el.archive = false
      return el
    })
    saveFiche(
      infoChantier.planning,
      dataToSend,
      type,
      style,
      filtre,
      filtrePlan,
      dateFiche,
      infoUser.token
    )
      .then((e) => {
        addToast('Rapport des visites créé avec succès.', {
          appearance: 'success',
          autoDismiss: true,
        })
        setWait(false)
        setBool(!bool)
        localStorage.setItem('typeRapport', 'fiche')
        navigate('/Rapport_CR')
      })
      .catch((err) => {
        console.log(err)
        setBool(!bool)
        addToast("Une erreur s'est produit.", {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }
  const date = (cell) => {
    const view = document.createElement('input')
    view.type = 'date'
    view.disabled = true
    view.style = 'background-color:rgba(226, 232, 240, 0.4);padding:11px 22px'
    view.defaultValue = cell.getValue()?.split('T')[0]
    view.classList.add('form-control')
    const container = document.createElement('span')
    container.appendChild(view)
    return container
  }
  const valueInput = (value, data) => {
    switch (value) {
      case 'statut':
        if (data == 'designe') {
          return 'désignée'
        } else {
          if (data == 'annule') {
            return 'annulée'
          } else {
            if (data == 'traite') {
              return 'traitée'
            } else {
              return 'désignée'
            }
          }
        }
      case 'tag':
        if ((data.length < 1) | !data) {
          return '---'
        }
        const newData = []
        data.map((el) => newData.push(el.value))
        return newData.join(',')
      case 'position':
        if (data) {
          return data
        } else {
          return '---'
        }
      case 'destinataire':
        if (data.length < 1 || data == undefined || data == 'undefined') {
          return '---'
        } else {
          const newDatas = []
          data.map((el) => newDatas.push(el.titre))
          return newDatas.join(',')
        }
      case 'observation':
        if (data) {
          return data
        } else {
          return '---'
        }
      case 'uniqueId':
        if (data) {
          return data
        } else {
          return 'Non défini'
        }
      default:
        return '---'
    }
  }
  const simpleInput = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'text'
    input.style =
      'width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155'
    input.defaultValue = valueInput(cell.getField(), cell.getValue())
    input.disabled = true
    input.addEventListener('blur', function (ev) {
      ev.preventDefault()
      cell.setValue(ev.target.value)
    })
    const container = document.createElement('div')
    container.style = 'display:flex;align-items:center;width:100%'
    container.appendChild(input)
    return container
  }
  const checkbox = (cell) => {
    const data = cell.getData()
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = cell.getValue()
    input.addEventListener('change', function (e) {
      e.preventDefault()
      e.stopPropagation()
      data.archive = e.target.checked
      cell.getRow().update(data)
      //cell.setValue(!cell.getValue())
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch mt-2'
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    return view
  }
  const selectAllElement = () => {
    const input = document.createElement('input')
    input.type = 'checkbox'
    input.className = 'form-check-input'
    input.defaultChecked = isSelectAll
    input.addEventListener('change', function (e) {
      e.preventDefault()
      e.stopPropagation()
      const value = e.target.checked
      if (value) {
        setData(
          data.map((el) => {
            if (el.type == type) {
              el.archive = true
              return el
            } else {
              el.archive = false
              return el
            }
          })
        )
      } else {
        setData(
          data.map((el) => {
            el.archive = false
            return el
          })
        )
      }
      setIsSelectAll(!isSelectAll)
    })
    const view = document.createElement('div')
    view.className = 'form-check form-switch '
    view.style = 'color:#2DD4BF;margin-left:12px'
    view.appendChild(input)
    const element = document.createElement('div')
    element.style = 'display:flex;align-items:center'
    const span = document.createElement('span')
    span.innerText = 'Tout'
    element.appendChild(span)
    element.appendChild(view)
    return element
  }
  const colonneObservation = [
    { title: 'ID', field: 'uniqueId', formatter: simpleInput },
    { title: 'Status', field: 'statut', formatter: simpleInput },
    { title: 'Date de création', field: 'createdAt', formatter: date },
    { title: 'Position', field: 'position', formatter: simpleInput },
    { title: 'Destinataire', field: 'destinataire', formatter: simpleInput },
    { title: 'Observation', field: 'observation', formatter: simpleInput },
    { title: selectAllElement(), field: 'archive', formatter: checkbox },
  ]

  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} active="rapport" />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fuild">
            <div class="page-title-box">
              <div class="float-end"></div>
              <span style={{ marginLeft: '10px' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#94A3B8"
                  style={{ width: '16px', height: '16px' }}>
                  <path
                    fillRule="evenodd"
                    d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                <span
                  style={{
                    color: '#94A3B8',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}>
                  / Rapports
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}>
                  {' '}
                  / Nouvel rapport des visites
                </span>
              </span>{' '}
              <br />
              <span
                style={{
                  marginLeft: '15px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    backgroundColor: '#E2E8F0',
                    padding: '2px',
                    borderRadius: '6px',
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="#6366F1"
                    style={{
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/Rapport_CR')
                    }}>
                    <path
                      fillRule="evenodd"
                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span
                  style={{
                    color: '#334155',
                    fontSize: '20px',
                    fontWeight: '600',
                    marginLeft: '10px',
                  }}>
                  {' '}
                  Nouvel rapport des visites
                </span>
                <div
                  class="form-floating"
                  style={{ width: '200px', marginLeft: '1vw' }}>
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    defaultValue={dateFiche.toISOString().substring(0, 10)}
                    onChange={(e) => {
                      if (e.target.value) {
                        setDateFiche(new Date(e.target.value))
                      }
                    }}
                  />
                  <label for="floatingInput">Date des visites</label>
                </div>
              </span>
            </div>
          </div>
          {/* wizard pour compte rendu et fiche de visite */}
          <div class="row">
            <div className="container-fluid">
              <div
                className="wrapper"
                style={{ marginTop: '20px', marginBottom: '10px' }}>
                <div
                  className="item"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setType(1)
                    setIsSelectAll(false)
                    setSelect(false)
                    setData(
                      data.map((el) => {
                        el.archive = false
                        return el
                      })
                    )
                    setFiche([])
                  }}
                  style={{
                    borderLeft: '1px solid #CBD5E1',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                  }}>
                  <span
                    style={
                      type == 1
                        ? {
                            color: '#F8FAFC',
                            fontSize: '16px',
                            fontWeight: '400',
                            backgroundColor: '#6366F1',
                            padding: '8px 24px',
                            borderRadius: '8px',
                            marginLeft: '10px',
                          }
                        : {
                            color: '#334155',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }>
                    Visites
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setType(2)
                    setIsSelectAll(false)
                    setSelect(false)
                    setData(
                      data.map((el) => {
                        el.archive = false
                        return el
                      })
                    )
                    setFiche([])
                  }}>
                  <span
                    style={
                      type == 2
                        ? {
                            color: '#F8FAFC',
                            fontSize: '16px',
                            fontWeight: '400',
                            backgroundColor: '#6366F1',
                            padding: '8px 24px',
                            borderRadius: '8px',
                          }
                        : {
                            color: '#334155',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }>
                    Réserves
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setType(3)
                    setSelect(false)
                    setIsSelectAll(false)
                    setData(
                      data.map((el) => {
                        el.archive = false
                        return el
                      })
                    )
                    setFiche([])
                  }}>
                  <span
                    style={
                      type == 3
                        ? {
                            color: '#F8FAFC',
                            fontSize: '16px',
                            fontWeight: '400',
                            backgroundColor: '#6366F1',
                            padding: '8px 24px',
                            borderRadius: '8px',
                          }
                        : {
                            color: '#334155',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }>
                    Réception de supports
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setType(4)
                    setIsSelectAll(false)
                    setSelect(false)
                    setData(
                      data.map((el) => {
                        el.archive = false
                        return el
                      })
                    )
                    setFiche([])
                  }}>
                  <span
                    style={
                      type == 4
                        ? {
                            color: '#F8FAFC',
                            fontSize: '16px',
                            fontWeight: '400',
                            backgroundColor: '#6366F1',
                            padding: '8px 24px',
                            borderRadius: '8px',
                            marginRight: '10px',
                          }
                        : {
                            color: '#334155',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }>
                    Avancements
                  </span>
                </div>
                <div
                  className="item"
                  onClick={(ev) => {
                    ev.preventDefault()
                    setType(5)
                    setIsSelectAll(false)
                    setSelect(false)
                    setData(
                      data.map((el) => {
                        el.archive = false
                        return el
                      })
                    )
                    setFiche([])
                  }}
                  style={{
                    borderRight: '1px solid #CBD5E1',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                  }}>
                  <span
                    style={
                      type == 5
                        ? {
                            color: '#F8FAFC',
                            fontSize: '16px',
                            fontWeight: '400',
                            backgroundColor: '#6366F1',
                            padding: '8px 24px',
                            borderRadius: '8px',
                            marginRight: '10px',
                          }
                        : {
                            color: '#334155',
                            fontSize: '16px',
                            fontWeight: '400',
                            cursor: 'pointer',
                          }
                    }>
                    Bons à fermer
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12" style={{ padding: '12px 12px' }}>
              <div
                class="card"
                style={{
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 6.15px 12.31px rgba(0, 0, 0, 0.06), 0px 6.15px 6.15px rgba(0, 0, 0, 0.08)',
                  marginTop: '12px',
                  padding: '12px 0px',
                }}>
                <div class="card-body" style={{ padding: '0px' }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '12px 24px',
                      alignItems: 'center',
                    }}>
                    <span
                      style={{
                        color: '#334155',
                        fontSize: '20px',
                        fontWeight: '600',
                      }}>
                      Liste des observations
                    </span>
                    <button
                      className="btn"
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                      onClick={(ev) => {
                        setEtape(1)
                      }}
                      style={{ backgroundColor: '#4F46E5', color: 'white' }}>
                      Valider
                    </button>
                  </div>
                  {/*  <div className='container-fluid' style={{position:'relative'}}>
                    <span style={{position:'absolute',bottom:0,right:0}}>
                      test
                    </span>
                  </div> */}
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane active" id="step1">
                      <ReactTabulator
                        data={data.filter((el) => el.type == type)}
                        columns={colonneObservation}
                        layout={'fitColumns'}
                        //events={{ cellEdited: planningEdited }}
                        options={{
                          locale: true,
                          pagination: 'local',
                          paginationSize: 10,
                          langs: {
                            fr: {
                              pagination: {
                                first: 'Premier',
                                first_title: 'Première',
                                last: 'Dernier',
                                last_title: 'Dernière',
                                prev: 'Précédent',
                                prev_title: 'Précédente',
                                next: 'Suivant',
                                next_title: 'Page suivante',
                                page_size: 'Taille de page',
                                page_title: 'Afficher la page',
                                all: 'Tous',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* fin wizard */}
          {/* modal pour le choix du format fiche de visite */}
          <div class="modal" id="myModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <div
                  class="modal-header"
                  style={{ backgroundColor: '#EBEFF5' }}>
                  <h4 class="modal-title" style={{ color: '#334155' }}>
                    Choix du format du fiche de visite
                  </h4>
                  <button
                    type="button"
                    class="btn-close"
                    onClick={(ev) => {
                      ev.preventDefault()
                      setEtape(1)
                    }}
                    data-bs-dismiss="modal"></button>
                </div>

                <div class="modal-body">
                  {etape == 1 && (
                    <>
                      {' '}
                      <button
                        className="btn"
                        onClick={(e) => {
                          e.preventDefault()
                          setEtape(2)
                          setStyle(1)
                        }}
                        style={{
                          backgroundColor: '#4F46E5',
                          color: 'white',
                          width: '100%',
                        }}>
                        Sans photo
                      </button>{' '}
                      <br />{' '}
                      <button
                        className="btn mt-3"
                        style={{
                          backgroundColor: '#4F46E5',
                          color: 'white',
                          width: '100%',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          setEtape(2)
                          setStyle(2)
                        }}>
                        Simples
                      </button>
                      <br />
                      <button
                        className="btn mt-3"
                        style={{
                          backgroundColor: '#4F46E5',
                          color: 'white',
                          width: '100%',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          setEtape(2)
                          setStyle(3)
                        }}>
                        Détaillées
                      </button>
                    </>
                  )}
                  {etape == 2 && (
                    <>
                      <button
                        className="btn"
                        onClick={(e) => {
                          e.preventDefault()
                          setEtape(3)
                          setFiltre(1)
                          //validerFiche(type, style, 1)
                        }}
                        style={{
                          backgroundColor: '#4F46E5',
                          color: 'white',
                          width: '100%',
                        }}>
                        Tous lots compilés
                      </button>{' '}
                      <br />{' '}
                      <button
                        className="btn mt-3"
                        style={{
                          backgroundColor: '#4F46E5',
                          color: 'white',
                          width: '100%',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          setEtape(3)
                          setFiltre(2)
                          // validerFiche(type, style, 2)
                        }}>
                        Filtrer par lot
                      </button>
                    </>
                  )}
                  {etape == 3 && (
                    <>
                      <button
                        className="btn"
                        data-bs-dismiss="modal"
                        onClick={(e) => {
                          e.preventDefault()
                          setWait(true)
                          setEtape(1)
                          setFiltrePlan(1)
                          validerFiche(type, style, filtre, 1)
                        }}
                        style={{
                          backgroundColor: '#4F46E5',
                          color: 'white',
                          width: '100%',
                        }}>
                        Tous les plans compilés
                      </button>{' '}
                      <br />{' '}
                      <button
                        className="btn mt-3"
                        data-bs-dismiss="modal"
                        style={{
                          backgroundColor: '#4F46E5',
                          color: 'white',
                          width: '100%',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          setWait(true)
                          setEtape(1)
                          setFiltrePlan(2)
                          validerFiche(type, style, filtre, 2)
                        }}>
                        Filtrer par plan
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {wait && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default NewFiche
