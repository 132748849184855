import axios from 'axios'
import { CardText } from 'react-bootstrap-icons'

export const getAllUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/users`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}
export const getForfait = async (planning,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/forfaits?planning=${planning}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    alert('le serveur ne repond pas')
    return err
  }
}
export const updateForfait = async (id, data,token,planning) => {
  console.log(planning);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/forfaits/${id}?planning=${planning}`,data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}


/*export const getEngagement =  async (id,planning) => {
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/engagements?entreprise=${id}&chantier=${planning}`).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAdministratif =  async (id,planning) => {
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/administratifs?entreprise=${id}&chantier=${planning}`).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}

export const getDocument =  async (id,planning) => {
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/documents?entreprise=${id}&chantier=${planning}`).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getSecurite =  async (id,planning) => {
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/securites?entreprise=${id}&chantier=${planning}`).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}

export const getInstallation =  async (id,planning) => {
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/installationchantiers?entreprise=${id}&chantier=${planning}`).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
} */

export const addData = async (candidate, path,id,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_URL}/api/v1/${path}`,{
       ...candidate,
        lot:id
      },
      config
    )
    return data.data
  } catch (err) {
    console.log(err)
    return { message: 'erreur' }
  }
}
export const addDataCarte = async (candidate, path,id,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_URL}/api/v1/${path}`,{
       ...candidate,
        carte:id
      },
      config
    )
    return data.data
  } catch (err) {
    console.log(err)
    return { message: 'erreur' }
  }
}

export const updateData = async (data, path,token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    switch(path){
      case 'entreprises':
        const result = await axios
         .patch(`${process.env.REACT_APP_URL}/api/v1/${path}/${data._id}`,{nom:data.nom,planning:planning},config)
         .then((e) => {
          return e.data
         })
          .catch((e) => {
          return e
         })
         return result
      case 'lots':
         console.log(data);
          const resultt = await axios
           .patch(`${process.env.REACT_APP_URL}/api/v1/${path}/${data._id}`,{titre:data.lot,planning:planning},config)
           .then((e) => {
            return e.data
           })
            .catch((e) => {
            return e
           })
           return resultt
      case 'lotsInfoGenerale':
        console.log(data);
        const formData = new FormData()
        if(data.ICDatePrevisionnel)formData.append('ICDatePrevisionnel',data.ICDatePrevisionnel)
        if(data.ICDateExecution)formData.append('ICDateExecution',data.ICDateExecution)
        if(data.MOADatePrevisionnel)formData.append('MOADatePrevisionnel',data.MOADatePrevisionnel)
        if(data.MOADateExecution)formData.append('MOADateExecution',data.MOADateExecution)
        if(data.MOAemisPar)formData.append('MOAemisPar',data.MOAemisPar._id)
          const resultts = await axios
          .patch(`${process.env.REACT_APP_URL}/api/v1/lots/${data._id}?planning=${planning}`,
           formData
          ,config)
          .then((e) => {
            return e.data
          })
          .catch((e) => {
            return e
          })
            return resultts
      case 'suiviIntemperies':
        console.log(data);
        const formDatas = new FormData()
        if(data.accordMOEOuOpc)formDatas.append('accordMOEOuOpc',data.accordMOEOuOpc)
        if(data.date)formDatas.append('date',data.date)
        if(data.evenement)formDatas.append('evenement',data.evenement)
        if(data.nombreJours)formDatas.append('nombreJours',data.nombreJours)
        if(data.ouvrageImpacte)formDatas.append('ouvrageImpacte',data.ouvrageImpacte)
        if(data.demandePar)formDatas.append('demandePar',data.demandePar._id)
        const resulttss = await axios
          .patch(`${process.env.REACT_APP_URL}/api/v1/suiviIntemperies/${data._id}?planning=${planning}`,
            formDatas
            ,config)
            .then((e) => {
              return e.data
            })
            .catch((e) => {
              return e
            })
            return resulttss
      default : 
        const results = await axios
        .patch(`${process.env.REACT_APP_URL}/api/v1/${path}/${data._id}?planning=${planning}`, data,config)
        .then((e) => {
          return e.data
        })
        .catch((e) => {
         return e
       })
         return results
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export const getAllData = async (query, path,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/${path}?${query}&archive[ne]=true&limit=1000`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}
export const getData = async (id, path,token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_URL}/api/v1/${path}/${id}?planning=${planning}&limit=1000`,config
    )
    return data.data
  } catch (err) {
    console.log(err)
    return err
  }
}
export const getDataPresence = async (id,token,cr) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_URL}/api/v1/users/userParChantier?planning=${id}&archive=false&cr=${cr}`,config
    )
    return data.data
  } catch (err) {
    console.log(err)
    return err
  }
}


export const delData = async (data, path,token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  
  try {
    //console.log(data._id)
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/${path}/${data._id}?planning=${planning}`,{archive:true, planning: planning},config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const updateDoc = async (id, data,token,planning) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .patch(`${process.env.REACT_APP_URL}/api/v1/suiviDoc/updateSingle?id=${id}&planning=${planning}`,data,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}
export const getEtatEntreprise = async (planning,user,token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    const result = await axios
      .get(`${process.env.REACT_APP_URL}/api/v1/presenceStatuts?planning=${planning}&user=${user}`,config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}

export const addDoc = async (id, data,token,typeDoc) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  try {
    //console.log(data._id)
    const result = await axios
      .post(`${process.env.REACT_APP_URL}/api/v1/suiviDoc`,{
        document:id,
        planning:data,
        carteSuividocument:id,
        typeDoc:typeDoc
      },config)
      .then((e) => {
        return e.data
      })
      .catch((e) => {
        return e
      })
    return result
  } catch (err) {
    console.log(err)
    return err
  }
}


