import React, { useEffect, useState } from 'react'
import {
  getProgPrev,
  getProgGeneral,
} from '../../../helpers/planning/dashBoard/upload'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js'
import Loader from '../../../components/loader/Loader'
import { Line } from 'react-chartjs-2'
import { useToasts } from 'react-toast-notifications'
import { getAllData } from '../../../helpers/suivi/suivi'
import { ReactTabulator } from 'react-tabulator'
const InfoDashboard = () => {
  const { addToast } = useToasts()
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [dataIndice, setDataIndice] = useState()
  const [progressAnnuel, setProgressAnnuelle] = useState([])
  const [general, setGeneral] = useState({})
  const [datas, setDatas] = useState({})
  const [wait, setWait] = useState(true)
  const [date, setDate] = useState('')
  const [currentYear, setCurrenYear] = useState({})
  const dates = new Date()
  const anneeEnCours = dates.getFullYear()
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Filler,
    Title,
    Tooltip,
    Legend
  )
  const incrementRgpPerMonth = (progress) => {
    let pivotReel = 0
    let pivotPrev = 0
    for (let i = 0; i < progress.length; i++) {
      for (let j = 0; j < progress[i].rgpPerMonth.length; j++) {
        if (parseFloat(progress[i].gpPerMonth[j]) >= 0) {
          pivotPrev = pivotPrev + parseFloat(progress[i].gpPerMonth[j])
          progress[i].gpPerMonth[j] = pivotPrev
        } else {
          progress[i].gpPerMonth[j] = pivotPrev
        }
        if (parseFloat(progress[i].rgpPerMonth[j]) >= 0) {
          pivotReel = pivotReel + parseFloat(progress[i].rgpPerMonth[j])
          progress[i].rgpPerMonth[j] = pivotReel
        } else {
          progress[i].rgpPerMonth[j] = pivotReel
        }
      }
    }
    console.log(progress);
  }
  useEffect(() => {
    setWait(true)
    getProgGeneral(infoChantier.planning, 2020, infoUser.token).then((e) => {
      if (e.message == 'erreur') {
        setGeneral([])
      } else {
        setGeneral(e)
      }
    })
    getProgPrev(infoChantier.planning, infoUser.token)
      .then((e) => {
        if (e.status == 'error') {
          console.log('il y a erreur')
          setProgressAnnuelle([])
          setCurrenYear({})
          setWait(false)
        } else {
          setDatas(e)
          const newData = e.progressionDuChantier
          incrementRgpPerMonth([...newData])
          setProgressAnnuelle(e.progressionDuChantier)
          const value = e?.progressionDuChantier?.find(
            (el) => el.year == anneeEnCours
          )
          if (value) {
            setCurrenYear(value)
          } else {
            setCurrenYear(e.progressionDuChantier[0])
          }
          setWait(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
      .finally((res) => {
        setWait(false)
      })
    /*  getAllData(
      `planning=${infoChantier.planning}`,
      `profile/indicePerformance`,
      infoUser.token
    )
      .then((res) => {
        console.log(res)
        if (res.data) {
          setDataIndice(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      }) */
  }, [])
  useEffect(() => {
    if (progressAnnuel) {
      progressAnnuel?.map((e) => {
        if (e.year == date) {
          setCurrenYear(e)
        }
      })
    }
  }, [date])

  const options2 = {
    scales: {
      y: {
        max: 100,
        min: 0,
        ticks: {
          stepSize: 5,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: '',
      },
    },
  }
  const labels = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ]
  /* const data = {
    labels,
    datasets: [
      {
        label: 'Avancement réelle',
        data: currentYear?.rgpPerMonth
          ? currentYear.rgpPerMonth.map((e) => (e ? e : 0))
          : labels.map(() => 0),
        backgroundColor: '#007bff',
        borderRadius: 50,
        borderWidth: 1,
        indexAxis: 'x',
        barThickness: 15,
        grouped: true,
        maxBarThickness: 20,
        barPercentage: 50,
      },
      {
        label: 'Avancement prévisionnnelle',
        data: currentYear?.gpPerMonth
          ? currentYear.gpPerMonth.map((e) => (e ? e : 0))
          : labels.map(() => 0),
        backgroundColor: '#6c757d',
        borderRadius: 50,
        borderWidth: 1,
        indexAxis: 'x',
        barThickness: 25,
        grouped: true,
        maxBarThickness: 15,
        barPercentage: 50,
      },
    ],
  } */
  const data = {
    labels,
    datasets: [
      {
        label: 'Avancement réelle',
        pointStyle: false,
        fill: true,
        data: currentYear?.rgpPerMonth
          ? currentYear.rgpPerMonth.map((e) => (e ? e : 0))
          : labels.map(() => 0),
        backgroundColor: 'rgba(0,123,255,0.3)',
        borderColor: '#007bff',
        tension: 0.5,
      },
      {
        label: 'Avancement prévisionnnelle',
        pointStyle: false,
        fill: true,
        data: currentYear?.gpPerMonth
          ? currentYear.gpPerMonth.map((e) => (e ? e : 0))
          : labels.map(() => 0),
        backgroundColor: 'rgba(108,117,125,0.3)',
        borderColor: '#6c757d',
        tension: 0.5,
      },
    ],
  }
  return (
    <>
      <div className="row">
        <div className="col-sm-4 mt-5">
          <div class="card report-card">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col">
                  <p class="text-dark mb-1 fw-semibold">Avancement général</p>
                  <div
                    style={{
                      width: '80%',
                      color: 'white',
                      borderRadius: '12px',
                      backgroundColor: '#6366F1',
                      padding: '5px 0px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}>
                    {datas?.progress?.gpPerMonth
                      ? datas.progress.gpPerMonth.toFixed(0)
                      : 0}
                    {'% '}
                    Planifié
                  </div>
                  <div
                    style={{
                      width: '80%',
                      color: 'white',
                      borderRadius: '12px',
                      backgroundColor: 'orange',
                      padding: '5px 0px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}>
                    {datas?.progress?.rgpPerMonth
                      ? datas.progress.rgpPerMonth.toFixed(0)
                      : 0}
                    {'% '}
                    Réel
                  </div>
                  <div
                    style={{
                      width: '80%',
                      color: 'white',
                      borderRadius: '12px',
                      backgroundColor: '#E11D48',
                      padding: '5px 0px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      marginBottom: '0px',
                    }}>
                    {datas?.progress?.rgpPerMonth && datas.progress.gpPerMonth
                      ? Math.abs(
                          (
                            datas.progress.rgpPerMonth -
                            datas.progress.gpPerMonth
                          ).toFixed(0)
                        )
                      : 0}
                    % Écart
                  </div>
                  {/* <h5 class="fw-bold">
                    {datas?.progress?.rgpPerMonth
                      ? datas.progress.rgpPerMonth.toFixed(0)
                      : 0}{' '}
                    %
                  </h5>
                  <p class="mb-0 text-truncate text-muted">
                    {datas?.progress?.gpPerMonth
                      ? datas.progress.gpPerMonth.toFixed(0)
                      : 0}{' '}
                    % planifié
                  </p> */}
                </div>
                <div class="col-auto align-self-center">
                  <div class="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
                    <i class="ti ti-stack"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card report-card">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col">
                  <p class="text-dark mb-1 fw-semibold">Dernier pointage</p>
                  <h5 class="fw-bold">
                    {datas?.dernierPointage
                      ? datas.dernierPointage
                      : 'JJ/MM/AAAA'}{' '}
                  </h5>
                </div>
                <div class="col-auto align-self-center">
                  <div class="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
                    <i class="ti ti-checkbox"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card report-card">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col">
                  <p class="text-dark mb-1 fw-semibold">Delta planning</p>
                  <h5 class="fw-bold">
                    {general.penalite?.retardConsigne
                      ? general.penalite.retardConsigne.toFixed(0)
                      : '0'}{' '}
                    jour(s) de retard
                  </h5>
                  <p class="mb-0 text-truncate text-muted">
                    {general.penalite?.somme
                      ? general.penalite.somme.toFixed(0)
                      : '0'}{' '}
                    € pénalité applicable(s)
                  </p>
                </div>
                <div class="col-auto align-self-center">
                  <div class="bg-light-alt d-flex justify-content-center align-items-center thumb-md  rounded-circle">
                    <i className="ti ti-currency-euro"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  <div class="card report-card">
            <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col">
                  <p class="text-dark mb-1 fw-semibold">
                    Indicateurs de performances (K.P.I)
                  </p>

                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ padding: '5px 0px', textAlign: 'center' }}>
                          Indice de Performance Coût
                        </th>
                        <th style={{ padding: '5px 0px', textAlign: 'center' }}>
                          {' '}
                          Tendance M-1
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: '5px 0px',
                            textAlign: 'center',
                            backgroundColor: '#EBEFF5 ',
                          }}>
                          {dataIndice?.ipc ? dataIndice.ipc : 0}
                        </td>
                        <td
                          style={{
                            padding: '5px 0px',
                            textAlign: 'center',
                            backgroundColor: '#EBEFF5',
                          }}>
                          {dataIndice?.ipc > 1 ? (
                            <i
                              className="ti ti-arrow-up-circle"
                              style={{
                                color: 'green',
                                fontSize: '22px',
                              }}></i>
                          ) : (
                            <i
                              className="ti ti-arrow-down-circle"
                              style={{
                                color: 'red',
                                fontSize: '22px',
                              }}></i>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ padding: '5px 0px', textAlign: 'center' }}>
                          Indice de Performance Délais
                        </th>
                        <th style={{ padding: '5px 0px', textAlign: 'center' }}>
                          {' '}
                          Tendance M-1
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: '5px 0px',
                            textAlign: 'center',
                            backgroundColor: '#EBEFF5 ',
                          }}>
                          {dataIndice?.ipd ? dataIndice.ipd : 0}
                        </td>
                        <td
                          style={{
                            padding: '5px 0px',
                            textAlign: 'center',
                            backgroundColor: '#EBEFF5 ',
                          }}>
                          {dataIndice?.ipd > 1 ? (
                            <i
                              className="ti ti-arrow-up-circle"
                              style={{
                                color: 'green',
                                fontSize: '22px',
                              }}></i>
                          ) : (
                            <i
                              className="ti ti-arrow-down-circle"
                              style={{
                                color: 'red',
                                fontSize: '22px',
                              }}></i>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="col-sm-8">
          <div className="row d-flex" style={{ justifyContent: 'center' }}>
            <h6 className="text-center mt-3">Avancement </h6>{' '}
            <select
              class="form-select text-center"
              aria-label="Default select example"
              style={{ width: '50%' }}
              onChange={(e) => {
                setDate(e.target.value)
              }}>
              <option selected>Sélectionner l'année à afficher</option>
              {progressAnnuel &&
                progressAnnuel.map((e) => {
                  return (
                    <>
                      <option
                        value={e.year}
                        selected={e.year == anneeEnCours ? true : false}>
                        {e.year}
                      </option>
                    </>
                  )
                })}
            </select>
          </div>
          {/* <Bar options={options} data={data} /> */}
          <Line options={options2} data={data} />
        </div>
      </div>
      {wait && (
        <>
          <Loader top={40} left={42} />
        </>
      )}
    </>
  )
}

export default InfoDashboard
