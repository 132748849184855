import React, { useEffect, useState } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import PageNotFound from '../../../components/404/PageNotFound'
import { GeoAltFill } from 'react-bootstrap-icons'
import { db } from './db/db'
import axios from 'axios'
const OffLine = () => {

  const [dataPlanOffLine, setDataPlanOffline] = useState()
  const [imagePlan, setImagePlan] = useState()
  useEffect(() => {
    const dbName = 'myDataBase'
    const tableName = 'images'
    const request = window.indexedDB.open(dbName)
    request.onsuccess = (event) => {
      const db = event.target.result
      const transaction = db.transaction([tableName], 'readonly')
      const objectStore = transaction.objectStore(tableName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = (event) => {
        const data = event.target.result
        setDataPlanOffline(data)
        setImagePlan(data[0].data)
      }

      getAllRequest.onerror = (event) => {
        console.error(
          'Erreur lors de la récupération des données :',
          event.target.error
        )
      }
      request.onerror = (event) => {
        console.error(
          "Erreur lors de l'ouverture de la base de données :",
          event.target.error
        )
      }
    }
  }, [])
  const idDataOffLine = localStorage.getItem('idDataOffline')
  //localStorage.setItem('allGroupe',null)
  const [online, setOnline] = useState(navigator.onLine)
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }
  const [allObservation, setAllObservation] = useState(
    localStorage.getItem('horsLigne')
      ? JSON.parse(localStorage.getItem('horsLigne'))
      : []
  )
  const [allLot, setAllLot] = useState(
    localStorage.getItem('allLot')
      ? JSON.parse(localStorage.getItem('allLot'))
      : []
  )
  const dataTag = [
    {
      value: 'planning',
    },
    {
      value: 'engagement',
    },
    {
      value: 'securite',
    },
    {
      value: 'installation',
    },
  ]

  const [bool, setBool] = useState(false)
  const [listPlan, setListPlan] = useState(
    localStorage.getItem('listPlan')
      ? JSON.parse(localStorage.getItem('listPlan'))
      : []
  )
  const [alever, setAlever] = useState()
  const [nomPlan, setNomPlan] = useState('Pas de plan précisé')
  const [status, setStatus] = useState('designe')
  const [position, setPosition] = useState('')
  const [destinataire, setDestinataire] = useState([])
  const [observation, setObservation] = useState('')
  const [tag, setTag] = useState([])
  const [groupe, setGroupe] = useState('')
  const [trueGroupe, setTrueGroupe] = useState('Pas de goupe précisé')
  const [allGroupe, setAllGroupe] = useState([])
  const [type, setType] = useState(1)
  const [avancementPrevu, setAvancementPrevu] = useState(0)
  const [avancementActuel, setAvancementActuel] = useState(0)
  //enregistrement du groupe
  const saveGroupe = () => {
    const newGroupe = [...allGroupe, groupe]
    setAllGroupe([...allGroupe, groupe])
    if (navigator.onLine) {
      axios
        .patch(
          `${process.env.REACT_APP_URL}/api/v1/offlinePlans/${idDataOffLine}?planning=${infoChantier.planning}`,
          {
            groups: newGroupe,
          },
          {
            headers: {
              Authorization: `Bearer ${infoUser.token}`,
            },
          }
        )
        .then((res) => {
        })
        .catch((err) => {
          console.log(err)
        })
      localStorage.setItem('allGroupe', JSON.stringify(newGroupe))
    } else {
      localStorage.setItem('allGroupe', JSON.stringify(newGroupe))
    }
  }
  //enregistrement de l'observation
  const handleSubmit = () => {
    allObservation.push({
      nomPlan: nomPlan,
      type: type,
      groupe: trueGroupe,
      avancementPrevu: avancementPrevu,
      avancementActuel: avancementActuel,
      status: status,
      observation: observation,
      position: position,
      destinataire: destinataire,
      aLeverLe: alever,
      tag: tag,
    })
    if (navigator.onLine) {
      localStorage.setItem('horsLigne', JSON.stringify(allObservation))
      axios
        .patch(
          `${process.env.REACT_APP_URL}/api/v1/offlinePlans/${idDataOffLine}?planning=${infoChantier.planning}`,
          {
            observationsData: allObservation,
          },
          {
            headers: {
              Authorization: `Bearer ${infoUser.token}`,
            },
          }
        )
        .then((res) => {
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      localStorage.setItem('horsLigne', JSON.stringify(allObservation))
    }
    setBool(!bool)
    setStatus('désignée')
    setAlever(null)
  }

  //obtenir les groupes
  useEffect(() => {
    if (localStorage.getItem('allGroupe') == null) {
      setAllGroupe([])
    } else {
      //setAllGroupe([])
      setAllGroupe(JSON.parse(localStorage.getItem('allGroupe')))
    }
  }, [bool])

  //obtenir les observation
  useEffect(() => {
    if (localStorage.getItem('horsLigne') == null) {
      setAllObservation([])
    } else {
      setAllObservation(JSON.parse(localStorage.getItem('horsLigne')))
    }
  }, [bool])
  useEffect(() => {
    const handleOnline = () => {
      setOnline(true)
      alert(
        'Vous êtes de retour en ligne ! Vos modifications seront enregistrées directement sur le serveur.'
      )
    }
    const handleOffline = () => {
      setOnline(false)
      alert(
        'La connexion a été coupée ! Vos modifications seront stockées localement.'
      )
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])
  const navigate = useNavigate()
  //fonction pour formater les dates
  const DateFr = (date) => {
    const result = date.split('-')
    return `${result[2]}/${result[1]}/${result[0]}`
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={false} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end">
                    <button
                      class="dropdown-toggle btn btn-success"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Mode
                      <i className="ti ti-selector"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1">
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          navigate('/Observation_OffLine')
                        }}>
                        <a
                          class="dropdown-item"
                          style={{ fontSize: '1.2vw', cursor: 'pointer' }}>
                          Hors ligne
                        </a>
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          if (navigator.onLine) {
                            axios
                              .patch(
                                `${process.env.REACT_APP_URL}/api/v1/offlinePlans/${idDataOffLine}?planning=${infoChantier.planning}`,
                                {
                                  observationsData: allObservation,
                                  groups:allGroupe
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${infoUser.token}`,
                                  },
                                }
                              )
                              .then((res) => {
                              })
                              .catch((err) => {
                                console.log(err)
                              })
                              localStorage.setItem('allGroupe', JSON.stringify([]))
                              localStorage.setItem('horsLigne', JSON.stringify([]))
                            navigate('/Observation_Plan')
                          } else {
                            alert(
                              "Connectez-vous afin d'envoyer les données au serveur."
                            )
                          }
                        }}>
                        <a
                          class="dropdown-item"
                          style={{ fontSize: '1.2vw', cursor: 'pointer' }}>
                          En ligne
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h4 class="page-title">Observation hors ligne</h4>
                </div>
              </div>
            </div>

            {/* Debut de l'observation hors ligne */}
            <div className="container-fluid">
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#myModal">
                {' '}
                Ajouter une nouvelle observation
                <i className="ti ti-plus" style={{ marginLeft: '2vw' }}></i>
              </button>
              <button
                className="btn btn-primary"
                style={{ marginLeft: '10px' }}
                data-bs-toggle="modal"
                data-bs-target="#myModal1">
                Ajouter un groupe{' '}
                <i className="ti ti-plus" style={{ marginLeft: '2vw' }}></i>
              </button>
              <button
                className="btn btn-primary"
                style={{ marginLeft: '10px' }}
                data-bs-toggle="modal"
                data-bs-target="#myModal2">
                {' '}
                Liste Groupe{' '}
                <i className="ti ti-list" style={{ marginLeft: '2vw' }}></i>
              </button>
              <div
                className="container-fluid d-flex p-3"
                style={{ flexWrap: 'wrap' }}>
                {allObservation &&
                  allObservation.map((e) => {
                    return (
                      <>
                        <div className="card m-2" style={{ width: '390px' }}>
                          <div className="card-header">
                            <div className="row align-items-center">
                              <h5 className="text-center">
                                {e.nomPlan ? e.nomPlan : '...'}
                              </h5>
                            </div>
                          </div>
                          <div className="card-body">
                            <u>Type</u> :{' '}
                            <GeoAltFill
                              color={`${
                                e.type === 1
                                  ? '#6366F1'
                                  : e.type == 2
                                  ? 'red'
                                  : e.type == 3
                                  ? '#2DD4BF'
                                  : e.type == 4
                                  ? 'orange'
                                  : type == 5
                                  ? '#02A0FC'
                                  : '#6366F1'
                              }`}
                              size={18}
                            />{' '}
                            {e.type === 1
                              ? 'Visites'
                              : e.type == 2
                              ? 'Réserves'
                              : e.type == 3
                              ? 'Réception de supports'
                              : e.type == 4
                              ? 'Avancements'
                              : type == 5
                              ? 'Bon à fermer'
                              : 'Visites'}{' '}
                            <br />
                            <u>Groupe</u> : {e.groupe ? e.groupe : '...'} <br />
                            <u>Statut</u> :{' '}
                            {e.status == 'designe'
                              ? 'Désignée'
                              : e.status == 'traite'
                              ? 'Traitée'
                              : e.status == 'annule'
                              ? 'Annulée'
                              : '...'}{' '}
                            <br />
                            <u>Position</u> : {e.position ? e.position : '...'}{' '}
                            <br />
                            <u>Observation</u> :
                            {e.observation ? e.observation : '...'} <br />
                            <u>A lever le</u> :
                            {e.aLeverLe ? DateFr(e.aLeverLe) : '...'}
                          </div>
                          <div className="card-footer">
                            <button
                              className="btn btn-danger"
                              onClick={(el1) => {
                                el1.preventDefault()
                                localStorage.setItem(
                                  'horsLigne',
                                  JSON.stringify(
                                    allObservation.filter((el) => el != e)
                                  )
                                )
                                setBool(!bool)
                              }}>
                              supprimer
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Liste Groupe */}
      <div class="modal" id="myModal2">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Lise des groupes
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>

            <div class="modal-body">
              {allGroupe &&
                allGroupe.map((e) => {
                  return (
                    <>
                      <div
                        className="d-flex mt-2"
                        style={{ justifyContent: 'space-between' }}>
                        <h5> {e}</h5>
                        <button
                          className="btn btn-danger"
                          data-bs-dismiss="modal"
                          onClick={(el) => {
                            el.preventDefault()
                            localStorage.setItem(
                              'allGroupe',
                              JSON.stringify(allGroupe.filter((gp) => gp != e))
                            )
                            setBool(!bool)
                          }}>
                          <i className="ti ti-archive"></i>
                        </button>
                      </div>
                    </>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      {/* Ajout d'un groupe */}
      <div class="modal" id="myModal1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Ajouter un groupe
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                saveGroupe()
                e.target.reset()
                setBool(!bool)
              }}>
              <div class="modal-body">
                <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}>
                    Nom du groupe:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="nom  du groupe"
                    onChange={(e) => {
                      setGroupe(e.target.value)
                    }}
                    required
                  />
                </div>
              </div>

              <div class="modal-footer">
                <button
                  className="btn"
                  type="submit"
                  class="btn btn-success"
                  data-bs-dismiss="modal"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal de création */}
      <div class="modal" id="myModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: '#EBEFF5' }}>
              <h4 class="modal-title" style={{ color: '#334155' }}>
                Observation
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                e.target.reset()
                handleSubmit()
              }}>
              <div class="modal-body">
                <div class="mb-3">
                  <label class="form-label" style={{ color: 'black' }}>
                    Nom du plan:
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setNomPlan(e.target.value)
                    }}>
                    <option selected value="Pas de plan préciser">
                      {' '}
                      Sélectionner un plan
                    </option>
                    {listPlan &&
                      listPlan.map((el) => {
                        return (
                          <>
                            <option value={el.description}>
                              {el.description}
                            </option>
                          </>
                        )
                      })}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label" style={{ color: 'black' }}>
                    Groupe :
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      e.preventDefault()
                      setTrueGroupe(e.target.value)
                    }}>
                    <option value="Pas de goupe préciser">
                      Sélectionner un groupe
                    </option>
                    {allGroupe &&
                      allGroupe.map((e) => {
                        return (
                          <>
                            <option value={e}>{e}</option>
                          </>
                        )
                      })}
                  </select>
                </div>
                <div class="mb-3">
                  <label class="form-label" style={{ color: 'black' }}>
                    <GeoAltFill
                      color={`${
                        type === 1
                          ? '#6366F1'
                          : type == 2
                          ? 'red'
                          : type == 3
                          ? '#2DD4BF'
                          : type == 4
                          ? 'orange'
                          : type == 5
                          ? '#02A0FC'
                          : '#6366F1'
                      }`}
                      size={18}
                    />{' '}
                    Type :
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setType(+e.target.value)
                    }}>
                    <option value={'1'}> Visites</option>
                    <option value="2"> Réserves</option>
                    <option value="3">Réception de supports</option>
                    <option value="4">Avancements</option>
                    <option value="5">Bon à fermer</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label class="form-label" style={{ color: 'black' }}>
                    Statut:
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}>
                    <option value={'designe'}>Désignée</option>
                    <option value="traite">Traitée</option>
                    <option value="annule">Annulée</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}>
                    <i class="ti ti-square-x" style={{ color: 'red' }}></i>{' '}
                    Observation:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="Observation"
                    onChange={(e) => {
                      setObservation(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}>
                    <i class="ti ti-square-x" style={{ color: 'red' }}></i> Tag:
                  </label>
                  <Multiselect
                    displayValue="value"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck(e) {
                      setTag(e)
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck(e) {
                      setTag(e)
                    }}
                    options={dataTag}
                    selectedValues={tag}
                    placeholder="Tag"
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}>
                    Position:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    style={{ width: '98%' }}
                    placeholder="Position"
                    onChange={(e) => {
                      setPosition(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}>
                    <i class="ti ti-square-x" style={{ color: 'red' }}></i>{' '}
                    Destinataire:
                  </label>
                  <Multiselect
                    displayValue="titre"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck(e) {
                      setDestinataire(e)
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck(e) {
                      setDestinataire(e)
                    }}
                    options={[...allLot]}
                    selectedValues={destinataire}
                    placeholder="Destinataire(s)"
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{ color: 'black' }}>
                    <i class="ti ti-square-x" style={{ color: 'red' }}></i> A
                    lever le:
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    style={{ width: '98%' }}
                    onChange={(e) => {
                      setAlever(e.target.value)
                    }}
                  />
                </div>
                {type == 4 && (
                  <>
                    <div
                      class="mb-3"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <div>
                        <label
                          for="exampleInputEmail1"
                          class="form-label"
                          style={{ color: 'black' }}>
                          <i
                            class="ti ti-square-x"
                            style={{ color: 'red' }}></i>
                          Avancement prévu (%):
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          style={{ width: '98%' }}
                          min={0}
                          max={100}
                          defaultValue={avancementPrevu}
                          onChange={(e) => {
                            setAvancementPrevu(e.target.value)
                          }}
                        />
                      </div>
                      <div>
                        <label
                          for="exampleInputEmail1"
                          class="form-label"
                          style={{ color: 'black' }}>
                          <i
                            class="ti ti-square-x"
                            style={{ color: 'red' }}></i>
                          Avancement Actuel (%):
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          min={0}
                          max={100}
                          style={{ width: '98%' }}
                          defaultValue={avancementActuel}
                          onChange={(e) => {
                            setAvancementActuel(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn"
                  data-bs-dismiss="modal"
                  style={{
                    color: 'white',
                    backgroundColor: '#4F46E5',
                    borderRadius: '8px',
                  }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* {imagePlan && (
        <>
          <img src={imagePlan} alt="" />
        </>
      )} */}
    </>
  )
}

export default OffLine
