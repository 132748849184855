import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { getLot, saveLot } from '../../../helpers/planning/dgpf/dgpf'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateTacheDPGF } from '../../../helpers/planning/dgpf/dgpf'
import ModifTache from './article_taches/ModifTache'
import AddArticle from './article_taches/AddArticle'
import Model from './article_taches/Model'
import ModifLot from './article_taches/ModifLot'
import BeatLoader from 'react-spinners/BeatLoader'
import axios from 'axios'
import PageNotFound from '../../../components/404/PageNotFound'
//pointage format DGPF
const DGPF = () => {
  const navigate = useNavigate()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
      infoUser?.user?.role?.roleType != 'opc' &&
      infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }
  const bool = useSelector((state) => state.bool.bool)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [lot, setLot] = useState([])
  const [titreLot, setTitreLot] = useState('')
  const [bools, setBools] = useState(false)
  const [tache, setTache] = useState([])
  const [wait, setWait] = useState(false)
  useEffect(() => {
    setWait(true)
    axios
      .get(
        `${process.env.REACT_APP_URL}/api/v1/taches/simple?planning=${infoChantier.planning}&fille=true&sort=id,text&insideArticle=false&limit=2000000000000`,
        {
          headers: {
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((res) => {
        setTache(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
    getLot(infoChantier.planning, infoUser.token).then((e) => {
      if (e.data?.error?.statsCode == 500) {
        setLot([])
      }
      {
        setLot(e.data)
      }
      setWait(false)
    })
  }, [bools, bool])

  const saveLot = () => {
    saveLot(titreLot, infoChantier.planning, infoUser.token).then((e) => {
      setBools(!bools)
    })
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true} />
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-end"></div>
                <h4 class="page-title">Planification</h4>
              </div>
            </div>
          </div>
          <ul class="nav nav-pills">
            <li
              class="nav-item"
              onClick={(e) => navigate('/planning_Dashboard')}
            >
              <a class="nav-link" href="#">
                Tableau de bord
              </a>
            </li>
            <li
              class="nav-item"
              onClick={(e) => navigate('/planning_GestionTache')}
            >
              <a class="nav-link" href="#">
                Gestion des tâches
              </a>
            </li>
            <li
              class="nav-item"
              onClick={(e) => navigate('/planning_Pointage')}
            >
              <a class="nav-link active" href="#">
                Pointage
              </a>
            </li>
            <li class="nav-item" onClick={(e) => navigate('/planning_Favori')}>
              <a class="nav-link" href="#">
                Favories
              </a>
            </li>
            <li class="nav-item" onClick={(e) => navigate('/planning_Retard')}>
              <a class="nav-link" href="#">
                Retards
              </a>
            </li>
          </ul>
          <div style={{ marginTop: '1vw' }}>
            <button
              class="dropdown-toggle btn btn-primary"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Format
              <i className="ti ti-selector"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/Planning_Pointage')
                }}
              >
                <a class="dropdown-item" href="#" style={{ fontSize: '1.2vw' }}>
                  Pointage globale
                </a>
              </li>
              <li onClick={(e) => {}}>
                <a class="dropdown-item" href="#" style={{ fontSize: '1.2vw' }}>
                  DGPF
                </a>
              </li>
            </ul>

            <button
              class="dropdown-toggle btn btn-success"
              type="button"
              style={{ marginLeft: '1vw' }}
            >
              Enregistrer
            </button>
          </div>

          {/* dubut dgpf */}

          {/* wizard pour l'affichage DPGF */}
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-header">
                  <h6 class="card-title">Liste des artciles par lots</h6>
                </div>
                <div class="card-body">
                  <nav>
                    <div class="nav nav-tabs" id="nav-tab">
                      {lot &&
                        lot?.map((e, i) => {
                          return (
                            <>
                              <a
                                class={`${
                                  i == 0 ? 'nav-link active' : 'nav-link'
                                }`}
                                id={`step22-tab${e._id}`}
                                data-bs-toggle="tab"
                                href={`#step22${e._id}`}
                              >
                                {e.titre}
                              </a>
                            </>
                          )
                        })}
                    </div>
                  </nav>

                  {/* Liste articke */}
                  <div class="tab-content" id="nav-tabContent">
                    {lot &&
                      lot.map((e, i) => {
                        return (
                          <>
                            <div
                              class={`${
                                i == 0 ? 'tab-pane active' : 'tab-pane '
                              }`}
                              id={`step22${e._id}`}
                            >
                              <div className="container-fluid">
                                <table
                                  class="table table-stripped"
                                  style={{ marginTop: '1vw' }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        s
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        Art. N°
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        DESIGNATION DES OUVRAGES
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        UNITE
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        QUANTITE
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        Avancement (M-1)
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        Avancement (M)
                                      </th>
                                      <th
                                        scope="col"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        Avencement du mois
                                      </th>
                                      <th
                                        scope="col d-flex"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        UNITAIRE en Euros HT
                                      </th>
                                      <th
                                        scope="col d-flex"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        TOTAL en Euros HT
                                      </th>
                                      <th
                                        scope="col d-flex"
                                        style={{ fontWeight: 'bold' }}
                                      >
                                        <i
                                          class="ti ti-circle-plus"
                                          data-bs-toggle="modal"
                                          data-bs-target={`#article${e._id}`}
                                          style={{
                                            cursor: 'pointer',
                                            fontSize: '35px',
                                          }}
                                        ></i>
                                        <Model
                                          id={`article${e._id}`}
                                          lot={e._id}
                                          taches={tache}
                                        />
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {e.articles &&
                                      e.articles.map((e1) => {
                                        return (
                                          <>
                                            <tr>
                                              <td
                                                colSpan={10}
                                                data-bs-toggle="modal"
                                                data-bs-target={`#addArticle${e1._id}`}
                                                style={{ cursor: 'pointer' }}
                                              >
                                                {' '}
                                                <u>
                                                  <h5> {e1.titre}</h5>
                                                </u>{' '}
                                              </td>
                                            </tr>
                                            <AddArticle
                                              id={`addArticle${e1._id}`}
                                              article={e1}
                                            />

                                            {e1.taches.map((e2) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <th scope="row"></th>
                                                    <td>{e2.text}</td>
                                                    <td>
                                                      <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={e2.unite}
                                                        onBlur={(e) => {
                                                          e.preventDefault()
                                                          updateTacheDPGF(
                                                            e2._id,
                                                            e.target.value,
                                                            'unite',
                                                            (
                                                              (e2.prixUnitaire *
                                                                e2.quantite *
                                                                ((
                                                                  e2
                                                                    .lastProgress[
                                                                    e2
                                                                      .lastProgress
                                                                      .length -
                                                                      1
                                                                  ] * 100
                                                                ).toFixed(2) -
                                                                  (
                                                                    e2
                                                                      .lastProgress[
                                                                      e2
                                                                        .lastProgress
                                                                        .length -
                                                                        2
                                                                    ] * 100
                                                                  ).toFixed(
                                                                    2
                                                                  ))) /
                                                              100
                                                            ).toFixed(2),
                                                            infoUser.token
                                                          ).then((e) => {
                                                            setBools(!bools)
                                                          })
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        className="form-control"
                                                        defaultValue={
                                                          e2.quantite
                                                        }
                                                        onBlur={(e) => {
                                                          e.preventDefault()
                                                          updateTacheDPGF(
                                                            e2._id,
                                                            e.target.value,
                                                            'quantite',
                                                            (
                                                              (e2.prixUnitaire *
                                                                e2.quantite *
                                                                ((
                                                                  e2
                                                                    .lastProgress[
                                                                    e2
                                                                      .lastProgress
                                                                      .length -
                                                                      1
                                                                  ] * 100
                                                                ).toFixed(2) -
                                                                  (
                                                                    e2
                                                                      .lastProgress[
                                                                      e2
                                                                        .lastProgress
                                                                        .length -
                                                                        2
                                                                    ] * 100
                                                                  ).toFixed(
                                                                    2
                                                                  ))) /
                                                              100
                                                            ).toFixed(2),
                                                            infoUser.token
                                                          ).then((el) => {
                                                            setBools(!bools)
                                                          })
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      {(
                                                        e2.lastProgress[
                                                          e2.lastProgress
                                                            .length - 2
                                                        ] * 100
                                                      ).toFixed(2)}{' '}
                                                      %
                                                    </td>
                                                    <td>
                                                      {(
                                                        e2.lastProgress[
                                                          e2.lastProgress
                                                            .length - 1
                                                        ] * 100
                                                      ).toFixed(2)}{' '}
                                                      %
                                                    </td>
                                                    <td>
                                                      {(
                                                        (
                                                          e2.lastProgress[
                                                            e2.lastProgress
                                                              .length - 1
                                                          ] * 100
                                                        ).toFixed(2) -
                                                        (
                                                          e2.lastProgress[
                                                            e2.lastProgress
                                                              .length - 2
                                                          ] * 100
                                                        ).toFixed(2)
                                                      ).toFixed(2)}
                                                      %
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="number"
                                                        className="form-control"
                                                        defaultValue={
                                                          e2.prixUnitaire
                                                        }
                                                        onBlur={(el) => {
                                                          updateTacheDPGF(
                                                            e2._id,
                                                            el.target.value,
                                                            'prixUnitaire',
                                                            (
                                                              (e2.prixUnitaire *
                                                                e2.quantite *
                                                                ((
                                                                  e2
                                                                    .lastProgress[
                                                                    e2
                                                                      .lastProgress
                                                                      .length -
                                                                      1
                                                                  ] * 100
                                                                ).toFixed(2) -
                                                                  (
                                                                    e2
                                                                      .lastProgress[
                                                                      e2
                                                                        .lastProgress
                                                                        .length -
                                                                        2
                                                                    ] * 100
                                                                  ).toFixed(
                                                                    2
                                                                  ))) /
                                                              100
                                                            ).toFixed(2),
                                                            infoUser.token
                                                          ).then((el2) => {
                                                            setBools(!bools)
                                                          })
                                                        }}
                                                      />
                                                    </td>
                                                    <td>
                                                      {(
                                                        (e2.prixUnitaire *
                                                          e2.quantite *
                                                          ((
                                                            e2.lastProgress[
                                                              e2.lastProgress
                                                                .length - 1
                                                            ] * 100
                                                          ).toFixed(2) -
                                                            (
                                                              e2.lastProgress[
                                                                e2.lastProgress
                                                                  .length - 2
                                                              ] * 100
                                                            ).toFixed(2))) /
                                                        100
                                                      ).toFixed(2)}{' '}
                                                      €
                                                    </td>
                                                  </tr>
                                                  <ModifTache
                                                    id={`tache${e2._id}`}
                                                    data={e2}
                                                  />
                                                </>
                                              )
                                            })}
                                          </>
                                        )
                                      })}
                                    <tr>
                                      <td
                                        colSpan={8}
                                        style={{ textAlign: 'center' }}
                                      >
                                        Total Travaux en Euros HT - BASE
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        {e.total ? e.total : 0} €
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan={8}
                                        style={{ textAlign: 'center' }}
                                      >
                                        TVA {e.tva ? e.tva : 20} %
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        {(e.total * e.tva) / 100
                                          ? (e.total * e.tva) / 100
                                          : 0}
                                        €
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td
                                        colSpan={8}
                                        style={{ textAlign: 'center' }}
                                      >
                                        Total Travaux en Euros TTC - BASE
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        {(e.total * e.tva) / 100 + e.total
                                          ? (e.total * e.tva) / 100 + e.total
                                          : 0}{' '}
                                        €
                                      </td>
                                      <td>
                                        <i
                                          class="ti ti-edit"
                                          data-bs-toggle="modal"
                                          data-bs-target={`#lot${e._id}`}
                                          style={{
                                            cursor: 'pointer',
                                            fontSize: '32px',
                                          }}
                                        ></i>
                                      </td>
                                    </tr>
                                    <ModifLot id={`lot${e._id}`} data={e} />
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {wait && (
            <>
              <div style={{ position: 'absolute', top: '40%', left: '45%' }}>
                <BeatLoader color="yellowgreen" loading={true} size={25} />
              </div>
            </>
          )}
          {/* fin wizard */}

          {/* {affichage des lots } */}
        </div>
        <div
          class="modal fade"
          id="lot"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Créer un lot pour le pointage DPGF
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  e.target.reset()
                  saveLot()
                }}
              >
                <div class="modal-body">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">
                      Titre:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      onChange={(e) => {
                        setTitreLot(e.target.value)
                      }}
                      required
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Fermer
                  </button>
                  <button type="submit" class="btn btn-primary">
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* fin dgpf */}
        </div>
      </div>
    </>
  )
}

export default DGPF
