import React, { useState, useEffect } from 'react'
import Menu from '../../../components/navBar/Menu'
import Header from '../../../components/navBar/Header'
import { useNavigate } from 'react-router-dom'
import { pointages } from '../../../helpers/planning/gestionTache/gantt'
import { getAllTask } from '../../../helpers/planning/gestionTache/dataGantt'
import { gantt } from 'dhtmlx-gantt'
import BeatLoader from 'react-spinners/BeatLoader'
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css'
import axios from 'axios'
import PageNotFound from '../../../components/404/PageNotFound'
import { original } from '@reduxjs/toolkit'
const Pointage = () => {
  const navigate = useNavigate()
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  if (
    !localStorage.getItem('isLogin') ||
    (infoUser?.user?.role?.roleType != 'admin' &&
    infoUser?.user?.role?.roleType != 'opc' &&
    infoUser?.user?.role?.roleType != 'moex')
  ) {
    return <PageNotFound />
  }

  const [messagePointage,setMessagePointage] = useState("Patientez durant la calcul des avancements!")
  const [popPointage, setPopPointage] = useState(false)
  const [havePlanning, setHavePlaning] = useState(false)
  const [lastDate, setLastDate] = useState([])
  const [date, setDate] = useState()
  const [bool, setBool] = useState(false)
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const filterColonne = (tab1,tab2) =>{
    const result = []
    const names = tab2.map(t2 => t2.name);
    tab1.map(t1 =>{
     if(names.includes(t1.name)){
     result.push(t1)
     }
    });
    return result
  }
  //plugin de dhtmlx
  gantt.plugins({
    auto_scheduling: true,
    critical_path: true,
    marker: true,
  })
  gantt.config.show_errors = false
  //formatage date
  gantt.attachEvent('onTaskLoading', function (task) {
    task.planned_start = gantt.date.parseDate(task.planned_start, 'xml_date')
    task.planned_end = gantt.date.parseDate(task.planned_end, 'xml_date')
    return true
  })
  useEffect(() => {
    if (infoChantier.planning) {
      setHavePlaning(true)
      getAllTask(infoChantier.planning,infoUser.token).then((e) => {
        setLastDate(e.data.data[0].lastDate)
        pointages(gantt, 'gantt1', e)
        gantt.addTaskLayer(function draw_planned(task) {
          if (task.planned_start && task.planned_end) {
            var sizes = gantt.getTaskPosition(
              task,
              task.planned_start,
              task.planned_end
            )
            var el = document.createElement('div')
            el.className = 'baseline'
            el.style.left = sizes.left - 6 + 'px'
            el.style.width = sizes.width + 10 + 'px'
            el.style.height = sizes.height -13 + 'px'
            el.style.top = sizes.top + 1 + 'px'
            return el
          }
          return false
        })
        gantt.eachTask(function (task) {
          if (task.duration < 1) {
            task.type = gantt.config.types.milestone
          }
          if (+task.parent > 0) {
            var parent = gantt.getTask(task.parent)
            parent.type = gantt.config.types.project
            parent.color = "lightgray"
          }
        })
        if (sessionStorage.getItem('colonne')) {
          const newColonne = JSON.parse(sessionStorage.getItem('colonne'))
          gantt.config.columns = filterColonne(gantt.getGridColumns(),newColonne)
          gantt.render()
        }
      })
    } else {
      setHavePlaning(false)
    }
  }, [bool])
  useEffect(() => {
    lastDate.map((e, i) => {
      if (
        i == lastDate.length - 1 ||
        i == lastDate.length - 2 ||
        i == lastDate.length - 3
      ) {
        var dateToStr = gantt.date.date_to_str(gantt.config.task_date)
        var markerId = gantt.addMarker({
          start_date: new Date(e), //a Date object that sets the marker's date
          css: 'today', //a CSS class applied to the marker
          text: `${e.split('T')[0]}`, //the marker title
          title: dateToStr(new Date()), // the marker's tooltip
        })
      }
    })
  }, [lastDate])

  //debut baseline
  gantt.config.bar_height = 20
  gantt.config.row_height = 40

  //pointage globale
  const pointageGlobale = () => {
    setMessagePointage("Patientez durant la calcul des avancements!")
    setPopPointage(true)
    axios
      .post(
        `${process.env.REACT_APP_URL}/api/v1/pointages/vertical?planning=${infoChantier.planning}&dateDePointage=${date}`,{data:'rien'},{
          headers: {
            Authorization: `Bearer ${infoUser.token}`
          },
        }
      )
      .then((e) => {
        setMessagePointage("Succès!")
        setBool(!bool)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  return (
    <>
      <div className="d-flex flex-column">
        <Menu bool={true} />
        <Header bool={true}/>
      </div>
      <div className="page-wrapper">
        <div className="page-content-tab">
          <div className="container-fluid" style={{ paddingTop: '0.5vw' }}>
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-end"></div>
                  <h4 class="page-title">Planification</h4>
                </div>
              </div>
            </div>
            <ul class="nav nav-pills">
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Dashboard')}
              >
                <a class="nav-link" href="#">
                  Tableau de bord
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_GestionTache')}
              >
                <a class="nav-link" href="#">
                  Gestion des tâches
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Pointage')}
              >
                <a class="nav-link active" href="#">
                  Pointage
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Favori')}
              >
                <a class="nav-link" href="#">
                Pour mémoire
                </a>
              </li>
              <li
                class="nav-item"
                onClick={(e) => navigate('/planning_Retard')}
              >
                <a class="nav-link" href="#">
                  Retards
                </a>
              </li>
            </ul>

            {/* debut pointage */}
            {havePlanning ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'no-wrap',
                    justifyContent: 'space-between',
                    marginTop: '1vw',
                  }}
                >
                  <div style={{ width: '60%' }}>
                    <button
                      class="dropdown-toggle btn btn-primary"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Zoom
                      <i className="ti ti-selector"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          sessionStorage.setItem('zoomPlanning', 'jour')
                          gantt.config.scales = [
                            { unit: 'day', step: 1, format: '%j %D' },
                          ]
                          gantt.render()
                        }}
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1.2vw' }}
                        >
                          Jours
                        </a>
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          sessionStorage.setItem('zoomPlanning', 'semaine')
                          gantt.config.scales = [
                            { unit: 'month', step: 1, format: '%F %Y' },
                            { unit: 'week', step: 1, format: '%j %D' },
                          ]
                          gantt.render()
                        }}
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1.2vw' }}
                        >
                          Semaine
                        </a>
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          sessionStorage.setItem('zoomPlanning', 'mois')
                          gantt.config.scales = [
                            { unit: 'year', step: 1, format: '%Y' },
                            { unit: 'month', step: 1, format: '%j %F' },
                          ]
                          gantt.render()
                        }}
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1.2vw' }}
                        >
                          Mois
                        </a>
                      </li>
                    </ul>
                    <button
                      className="btn btn-primary"
                      style={{ marginLeft: '0.5vw' }}
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Format
                      <i className="ti ti-selector"></i>
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                          //navigate('/planning_Pointage_DGPF')
                        }}
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1.2vw' ,color:"gray"}}
                        >
                          DPGF
                        </a>
                      </li>
                      <li
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                      >
                        <a
                          class="dropdown-item"
                          href="#"
                          style={{ fontSize: '1.2vw' }}
                        >
                          globale
                        </a>
                      </li>
                    </ul>

                    <button
                      className="btn btn-success"
                      style={{ marginLeft: '0.5vw' }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Nouveau pointage
                    </button>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Inserer la date du pointage
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault()
                              e.target.reset()
                              pointageGlobale()
                            }}
                          >
                            <div class="modal-body">
                              <div class="mb-3">
                                <label class="form-label">
                                  Date du pointage
                                </label>
                                <input
                                  type="date"
                                  class="form-control"
                                  onChange={(e) => {
                                    setDate(e.target.value)
                                  }}
                                  required
                                />
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-bs-dismiss="modal"
                              >
                                Fermer
                              </button>
                              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                                valider
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="gantt1" style={{ width: '100%', height: '75vh' }}>
                  <div
                    className="container-fluid d-flex"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '75vh',
                      backgroundColor: 'white',
                    }}
                  >
                    <BeatLoader color="yellowgreen" loading={true} size={25} />
                  </div>{' '}
                </div>
              </>
            ) : (
              <>
                <h1>vous devez importer un planning</h1>
              </>
            )}
            {/* fin pointage directe */}
            {/* debut alert */}
       {
              popPointage && (
                <>
                <div
              className={messagePointage=="Succès!" ? ("alert custom-alert custom-alert-success icon-custom-alert shadow-sm fade show") : ("alert custom-alert custom-alert-primary icon-custom-alert shadow-sm fade show")}
              role="alert"
              style={{position:"absolute", top:"0",right:"0"}}
            >
              <div class="alert-text my-1">
                <h5 class="mb-1 fw-bold mt-0">Enregistrement</h5>
                <span>
                 {messagePointage}
                </span>
              </div>
              <div class="alert-close">
                <button
                  type="button"
                  class="btn-close"
                  onClick={(e)=>{
                    setPopPointage(false)
                  }}
                ></button>
              </div>
            </div>
                </>
              )
            }
            {/* fin alert */}
          </div>
        </div>
      </div>
       
    </>
  )
}

export default Pointage
