import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AfficherLargeurPage from './AfficherLargeurPage'; 

const Header_small_screen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Variable qui stocke le chemin de la page actuelle
  const lien_url = location.pathname;


  // Gestion de la taille d'écran pour masquer la liste
  const [isMobile, setIsMobile] = useState(false);
  // Ajoute l'état pour gérer le scroll
  const [isScrollable, setIsScrollable] = useState(false); 
  // On crée un variable pour les autres informations de la page
  const [isMasked, setIsMasked] = useState(false);

  // Détecter la largeur de l'écran et mettre à jour le state  
  useEffect(() => {
    // Détection si nous sommes sur un device / Appareil mobile
    const isDevice = /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile/.test(navigator.userAgent);
    const isTabletOrMobileScreen = window.innerWidth <= 1080;

    // Vérification combinée avec la taille de l'écran
    if (isDevice || isTabletOrMobileScreen) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    // Activer le scroll horizontal pour les écrans inférieurs à 768px
    setIsScrollable(window.innerWidth < 768);

    
    // Ajouter un écouteur pour mettre à jour lors du redimensionnement de la fenêtre
    // const handleResize = () => {
    //   if (isIpad) {
    //     setIsMobile(window.innerWidth <= 1080);
    //   } else {
    //     setIsMobile(window.innerWidth <= 1024);
    //   }

    //   Activer ou désactiver le scroll horizontal
    //   setIsScrollable(window.innerWidth < 768);
    // };

    // window.addEventListener('resize', handleResize);

    // Nettoyage de l'écouteur d'événement
    // return () => window.removeEventListener('resize', handleResize);
  }, [])

  // Style commun aux éléments actifs
  const activeStyle = {
    fontWeight: 'bold', // Texte en gras si actif
    textAlign: 'center', // Centrer le texte
    color: '#6366F1', // Couleur active
  };

  // Style commun aux éléments non-actifs
  const defaultStyle = {
    fontWeight: 'normal', // Texte normal si non-actif
    textAlign: 'center', // Centrer le texte
    color: '#94A3B8', // Couleur par défaut
  };

  // console.log(`La largeur de la page est : ${window.innerWidth}px`);

  return (
    // Masquer la liste si l'écran est un device
    isMobile && (
        <div
        style={{ overflowX: 'auto', whiteSpace: 'nowrap' }} // Activer le scroll horizontal quelle que soit la taille de l'écran
        >
        <ul style={{ display: 'flex', flexDirection: 'row', listStyleType: 'none', padding: 0 }}>
          
            {/* Tableau de bord */}
            <li
                className="nav-item"
                onClick={() => navigate('/Tableau_de_bord')}
                style={{ width: '90%', margin: 'auto', padding: '5px', textAlign: 'center' }}
            >
            <a 
                className="nav-link" 
                href="#" 
                style={lien_url === '/Tableau_de_bord' ? activeStyle : defaultStyle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={lien_url === '/Tableau_de_bord' ? '#6366F1' : 'none'}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke={lien_url === '/Tableau_de_bord' ? '#6366F1' : '#94A3B8'}
                style={{ width: '24px', height: '24px', marginLeft: '10px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
                />
              </svg>
              <span style={{ marginLeft: '20px', fontSize: '16px', lineHeight: '24px' }}>
                Tableau de bord
              </span>
            </a>
          </li>

          {/* Planning */}
          <li
            className="nav-item"
            onClick={() => navigate('/Planning_GestionTache_LastPlanner')}
            style={{ width: '90%', margin: 'auto', padding: '5px', textAlign: 'center' }}
          >
            <a
              className="nav-link"
              href="#"
              style={lien_url === '/planning_GestionTache' || lien_url === '/Planning_GestionTache_LastPlanner' || lien_url === '/planning_Favori' || lien_url === '/planning_Retard' ? activeStyle : defaultStyle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={lien_url === '/planning_GestionTache' || lien_url === '/Planning_GestionTache_LastPlanner' || lien_url === '/planning_Favori' || lien_url === '/planning_Retard' ? '#6366F1' : 'none'}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke={lien_url === '/planning_GestionTache' || lien_url === '/Planning_GestionTache_LastPlanner' || lien_url === '/planning_Favori' || lien_url === '/planning_Retard' ? '#6366F1' : '#94A3B8'}
                style={{ width: '24px', height: '24px', marginLeft: '10px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                />
              </svg>
              <span style={{ marginLeft: '20px', fontSize: '16px', lineHeight: '24px' }}>
                Planning Glissant
              </span>
            </a>
          </li>

          {/* Suivi Opérationnel */}
          {/* <li
            className="nav-item"
            onClick={() => navigate('/SuiviOperationnel')}
            style={{ width: '90%', margin: 'auto', padding: '5px', textAlign: 'center' }}
          >
            <a
              className="nav-link"
              href="#"
              style={lien_url === '/SuiviOperationnel' ? activeStyle : defaultStyle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={lien_url === '/SuiviOperationnel' ? '#6366F1' : 'none'}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke={lien_url === '/SuiviOperationnel' ? '#6366F1' : '#94A3B8'}
                style={{ width: '24px', height: '24px', marginLeft: '10px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </svg>
              <span style={{ marginLeft: '20px', fontSize: '16px', lineHeight: '24px' }}>
                Opérationnel
              </span>
            </a>
          </li> */}

          {/* Rapports */}
          {/* <li
            className="nav-item"
            onClick={() => {
              localStorage.setItem('typeRapport', 'cr');
              navigate('/Rapport_CR');
            }}
            style={{ width: '90%', margin: 'auto', padding: '5px', textAlign: 'center' }}
          >
            <a
              className="nav-link"
              href="#"
              style={lien_url === '/Rapport_CR' || lien_url === '/Rapport_NewCR' || lien_url === '/Rapport_NewFiche' || lien_url === '/Rapport_avancement' ? activeStyle : defaultStyle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={lien_url === '/Rapport_CR' || lien_url === '/Rapport_NewCR' || lien_url === '/Rapport_NewFiche' || lien_url === '/Rapport_avancement' ? '#6366F1' : 'none'}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke={lien_url === '/Rapport_CR' || lien_url === '/Rapport_NewCR' || lien_url === '/Rapport_NewFiche' || lien_url === '/Rapport_avancement' ? '#6366F1' : '#94A3B8'}
                style={{ width: '24px', height: '24px', marginLeft: '10px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25z"
                />
              </svg>
              <span style={{ marginLeft: '20px', fontSize: '16px', lineHeight: '24px' }}>
                Rapports
              </span>
            </a>
          </li> */}

          {/* Observations */}
          <li
            className="nav-item"
            onClick={() => navigate('/Observation_Plan')}
            style={{ width: '90%', margin: 'auto', padding: '5px', textAlign: 'center' }}
          >
            <a
              className="nav-link"
              href="#"
              style={lien_url === '/Observation_Plan' ? activeStyle : defaultStyle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={lien_url === '/Observation_Plan' ? '#6366F1' : 'none'}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke={lien_url === '/Observation_Plan' ? '#6366F1' : '#94A3B8'}
                style={{ width: '24px', height: '24px', marginLeft: '10px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                />
              </svg>
              <span style={{ marginLeft: '20px', fontSize: '16px', lineHeight: '24px' }}>
                Observations
              </span>
            </a>
          </li>

          {/* Contacts */}
          <li
            className="nav-item"
            onClick={() => navigate('/Contact')}
            style={{ width: '90%', margin: 'auto', padding: '5px', textAlign: 'center' }}
          >
            <a
              className="nav-link"
              href="#"
              style={lien_url === '/Contact' ? activeStyle : defaultStyle}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill={lien_url === '/Contact' ? '#6366F1' : 'none'}
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke={lien_url === '/Contact' ? '#6366F1' : '#94A3B8'}
                style={{ width: '24px', height: '24px', marginLeft: '10px' }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                />
              </svg>
              <span style={{ marginLeft: '20px', fontSize: '16px', lineHeight: '24px' }}>
                Contacts
              </span>
            </a>
          </li>

        </ul>
      </div>
    )
  );
};

export default Header_small_screen;