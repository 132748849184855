import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'
import { Button, Upload } from 'antd'
import LoaderFixed from '../../../../components/loader/LoaderFixed'
const Pin = ({ props, idt, idImage, lot, tacheSd, tacheSc, tacheSp,dataOffLine }) => {
  //formatage date
  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0')
  }
  //date en format fr
  const dateFr = (value) => {
    const dt = value.split('T')[0]
    const dt1 = dt.split('-')
    const result = `${dt1[2]}/${dt1[1]}/${dt1[0]}`
    return result
  }
  //fonction pour formater les dates
  const formatDate = (date) => {
    date = new Date(date)
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const bool = useSelector((state) => state.bool.bool)
  const [bool2, setBool2] = useState(idt)
  const [itemNumber, setItemNumber] = useState(props.itemNumber)
  const [top, setTop] = useState(props.top)
  const [left, setLeft] = useState(props.left)
  const [del, setDel] = useState(true)
  const [description, setDescription] = useState(
    props.description ? props.description : ''
  )
  const [phase, setPhase] = useState(props.phase ? props.phase : '')
  const [status, setStatus] = useState(props.statut ? props.statut : 'designe')
  const [position, setPosition] = useState(props.position ? props.position : '')
  const [avancementActuel, setAvancementActuel] = useState(
    props.avancementActuel ? props.avancementActuel : 0
  )
  const [avancementPrevu, setAvancementPrevu] = useState(
    props.avancementPrevu ? props.avancementPrevu : 0
  )
  const [destinataire, setDestinataire] = useState(
    props.destinataire ? props.destinataire : []
  )
  const [observation, setObservation] = useState(
    props.observation ? props.observation : ''
  )
  const [markers, setMarkers] = useState({})
  const [id, setId] = useState()
  const [img, setImg] = useState(true)
  const [image, setImage] = useState([])
  const [uuid, setUuid] = useState(props.uuid ? props.uuid : '')
  const [alever, setAlever] = useState(
    props.aLeverLe ? formatDate(props.aLeverLe) : null
  )
  const [tag, setTag] = useState(props.tag ? props.tag : [])
  const [photo, setPhoto] = useState(props.photo ? props.photo : [])
  const infoChantiers = localStorage.getItem('infoChantiers')
  const infoChantier = JSON.parse(infoChantiers)
  const [infoObservation, setInfoObservation] = useState({})
  const infoUsers = localStorage.getItem('users')
  const infoUser = JSON.parse(infoUsers)
  const [allLot, setAllLot] = useState(lot ? lot : [])
  const [itemStatus, setItemStatus] = useState('Désignée')
  const [typePin, setTypePin] = useState(props.type ? props.type : 1)
  const [allObservation, setAllObservation] = useState(dataOffLine?.observationsData ? dataOffLine.observationsData : [])
  const [allGroupe, setAllGroupe] = useState(dataOffLine?.groups ? dataOffLine.groups : [])
  const [allTacheAssigner, setAllTacheAssigner] = useState(
    props.taches ? props.taches : []
  )
  useEffect(() => {
    setMarkers(props)
    setId(props.itemNumber)
    if (props?.photo?.length > 0) {
      setImg(false)
    } else {
      setImg(true)
    }
  }, [])
  const delPin = () => {
    //setMarkers({ ...props, description: description });
    const uniqueIds = allTacheAssigner.reduce((acc, el) => {
      if (!acc.includes(el._id)) {
        acc.push(el._id)
      }
      return acc
    }, [])
    const formData = new FormData()
    formData.append('archive', true)
    formData.append('top', top)
    formData.append('left', left)
    formData.append('description', description)
    formData.append('phase', phase)
    formData.append('itemNumber', itemNumber)
    if (uuid) {
      formData.append('uuid', uuid)
    }
    formData.append('aLeverLe', alever ? alever : new Date(Date.now()))
    formData.append('statut', status)
    formData.append('tag', JSON.stringify([]))
    formData.append('position', position)
    formData.append('destinataire', JSON.stringify([]))
    formData.append('observation', observation)
    formData.append('avancementPrevu', avancementPrevu ? avancementPrevu : 0)
    formData.append('avancementActuel', avancementActuel ? avancementActuel : 0)
    image.map((element) => {
      formData.append('item', element.originFileObj)
    })
    formData.append('_id', props._id)
    formData.append('taches', JSON.stringify(uniqueIds))
    formData.append('photo', JSON.stringify(photo))
    formData.append('type', typePin)
    axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/ressources/${idImage}?planning=${infoChantier.planning}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
      .catch((e) => {
        console.log(e)
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
  }

  const save = (newTop, newLeft) => {
    setLoader(true)
    //setMarkers({ ...props, description: description });
    const uniqueIds = allTacheAssigner.reduce((acc, el) => {
      if (!acc.includes(el._id)) {
        acc.push(el._id)
      }
      return acc
    }, [])
    const formData = new FormData()
    formData.append('archive', false)
    formData.append('top', newTop ? newTop : top)
    formData.append('left', newLeft ? newLeft : left)
    formData.append('description', description)
    formData.append('phase', phase)
    formData.append('itemNumber', itemNumber)
    formData.append('aLeverLe', alever ? alever : new Date(Date.now()))
    formData.append('statut', status)
    formData.append('tag', JSON.stringify(tag))
    formData.append('position', position)
    formData.append('destinataire', JSON.stringify(destinataire))
    formData.append('observation', observation)
    formData.append('type', typePin)
    formData.append('avancementPrevu', avancementPrevu ? avancementPrevu : 0)
    formData.append('avancementActuel', avancementActuel ? avancementActuel : 0)
    image.map((element) => {
      formData.append('item', element.originFileObj)
    })
    if (uuid) {
      formData.append('uuid', uuid)
    }
    formData.append('_id', props._id)
    formData.append('taches', JSON.stringify(uniqueIds))
    formData.append('photo', JSON.stringify(photo))
    axios
      .patch(
        `${process.env.REACT_APP_URL}/api/v1/ressources/${idImage}?planning=${infoChantier.planning}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${infoUser.token}`,
          },
        }
      )
      .then((e) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
      .catch((e) => {
        console.log(e)
        dispatch({ type: 'bool/modifier', payload: !bool })
      })
      .finally((res) => {
        dispatch({ type: 'bool/modifier', payload: !bool })
        setLoader(false)
      })
  }
  useEffect(() => {}, [bool, bool2, del])

  const dataTag = [
    {
      value: 'planification',
    },
    {
      value: 'engagement',
    },
    {
      value: 'securite',
    },
    {
      value: 'installation',
    },
  ]
  const [positionInitialX, setPositionInitialX] = useState('')
  const [positionInitialY, setPositionInitialY] = useState('')

  const calculateNewPosition = (initPosPx, newPosPx, posPlanPct, planSize) => {
    const diffPx = newPosPx - initPosPx
    const posPlanPx = (posPlanPct * planSize) / 100
    const newPosPxWithOffset = posPlanPx + diffPx
    return (100 * newPosPxWithOffset) / planSize
  }

  const calculNewPosition = (info) => {
    const {
      planWidth,
      planHeight,
      posLeftInitPinGrandEcranPx,
      nouvPosLeftPinGrandEcranPx,
      leftPosPlanPct,
      posTopInitPinGrandEcranPx,
      nouvPosTopPinGrandEcranPx,
      topPosPlanPct,
    } = info

    const newLeft = calculateNewPosition(
      posLeftInitPinGrandEcranPx,
      nouvPosLeftPinGrandEcranPx,
      leftPosPlanPct,
      planWidth
    )

    const newTop = calculateNewPosition(
      posTopInitPinGrandEcranPx,
      nouvPosTopPinGrandEcranPx,
      topPosPlanPct,
      planHeight
    )

    return {
      newLeft,
      newTop,
    }
  }

  return (
    <>
      {del && !props.archive && (
        <div
          className="d-flex"
          data-toggle="tooltip"
          data-placement="top"
          title={`# ${props.uniqueId ? +props.uniqueId : 'Non défini'}`}
          style={{ alignItems: 'center', cursor: 'pointer' }}
          draggable="true"
          onDragStart={(ev) => {
            setPositionInitialX(ev.clientX)
            setPositionInitialY(ev.clientY)
          }}
          onDragEnd={(ev) => {
            const plan = document.querySelector('.planCible')
            const planWidth = plan.offsetWidth
            const planHeight = plan.offsetHeight
            const grandEcranWidth = window.innerWidth
            const info = {
              grandEcranWidth: grandEcranWidth,
              planWidth: planWidth,
              planHeight: planHeight,
              posLeftInitPinGrandEcranPx: positionInitialX,
              posTopInitPinGrandEcranPx: positionInitialY,
              nouvPosLeftPinGrandEcranPx: ev.clientX,
              nouvPosTopPinGrandEcranPx: ev.clientY,
              leftPosPlanPct: props.left,
              topPosPlanPct: props.top,
            }
            const newValue = calculNewPosition(info)
            save(newValue.newTop, newValue.newLeft)
          }}
          onClick={(e) => {
            setBool2(true)
          }}>
          <GeoAltFill
            color={`${
              props.type == 1
                ? '#6366F1'
                : props.type == 2
                ? 'red'
                : props.type == 3
                ? '#2DD4BF'
                : props.type == 4
                ? 'orange'
                : props.type == 5
                ? '#02A0FC'
                : '#6366F1'
            }`} /* color="red" */
            size={24}
          />
        </div>
      )}
      {bool && bool2 ? (
        <>
          <div
            className="docPopup"
            style={{
              zIndex: '20000',
              right: `${props.left > 50 ? '0' : '1'}`,
            }}>
            <div
              className="container-fluid mb-2"
              style={{ position: 'relative' }}>
              <div className="btn-group dropright">
                <span
                  data-bs-toggle="dropdown"
                  style={{
                    height: '32px',
                    minWidth: '210px',
                    padding: '6px',
                    position: 'absolute',
                    display: 'flex',
                    left: '0',
                    backgroundColor: `${
                      typePin == 1
                        ? '#c2e5ff'
                        : typePin == 2
                        ? '#FFF1F2'
                        : typePin == 3
                        ? '#f0fff0'
                        : typePin == 4
                        ? '#ffe7c2'
                        : typePin == 5
                        ? '#CCF8FE'
                        : '#c2e5ff'
                    }`,
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                  title="Déplacer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill={`${
                      typePin == 1
                        ? '#6366F1'
                        : typePin == 2
                        ? 'red'
                        : typePin == 3
                        ? '#2DD4BF'
                        : typePin == 4
                        ? 'orange'
                        : typePin == 5
                        ? '#02A0FC'
                        : '#6366F1'
                    }`}
                    //fill="red"
                    style={{ width: '20px', height: '20px' }}>
                    <path
                      fillRule="evenodd"
                      d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '500',
                      marginLeft: '10px',
                    }}>
                    {`${
                      typePin == 1
                        ? 'Visites'
                        : typePin == 2
                        ? 'Réserves'
                        : typePin == 3
                        ? 'Réception de supports'
                        : typePin == 4
                        ? 'Avancements'
                        : typePin == 5
                        ? 'Bons à fermer'
                        : 'Visites'
                    }`}{' '}
                    # {props.uniqueId}
                  </span>
                </span>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li
                    style={{
                      backgroundColor: `${typePin == 1 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(1)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Visites
                    </a>
                  </li>
                  <li
                    style={{
                      backgroundColor: `${typePin == 2 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(2)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Réserves
                    </a>
                  </li>
                  <li
                    style={{
                      backgroundColor: `${typePin == 3 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(3)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Réception de supports
                    </a>
                  </li>
                  <li
                    style={{
                      backgroundColor: `${typePin == 4 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(4)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Avancements
                    </a>
                  </li>
                  <li
                    style={{
                      backgroundColor: `${typePin == 5 ? '#E2E8F0' : ''}`,
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setTypePin(5)
                    }}>
                    <a
                      class="dropdown-item"
                      href="#"
                      style={{ fontSize: '1vw' }}>
                      Bons à fermer
                    </a>
                  </li>
                </ul>
              </div>
              <i
                class="ti ti-x"
                style={{
                  position: 'absolute',
                  right: '0',
                  cursor: 'pointer',
                  color: 'gray',
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
                onClick={(e) => {
                  setBool2(false)
                }}></i>
            </div>
            <form>
              <div
                class="mb-3"
                style={{
                  marginTop: '50px',
                  width:'100%'
                }}>
                <span>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Statut:
                  </label>
                  <button
                    data-bs-toggle="dropdown"
                    className="btn"
                    style={{
                      backgroundColor: 'transparent',
                      color: '#475569',
                      width: '100%',
                      height: '38px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: 'solid  #AFBACA 1px',
                    }}>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#475569',
                      }}>
                      {status == 'designe'
                        ? 'désignée'
                        : status == 'traite'
                        ? 'Traitée'
                        : status == 'annule'
                        ? 'Annulée'
                        : itemStatus}
                    </span>
                    <i className="ti ti-selector"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={(ev) => {
                          ev.preventDefault()
                          setStatus('designe')
                        }}>
                        Désignée
                      </a>
                    </li>
                    <li
                      onClick={(ev) => {
                        ev.preventDefault()
                        setStatus('traite')
                      }}>
                      <a className="dropdown-item">Traitée</a>
                    </li>
                    <li
                      onClick={(ev) => {
                        ev.preventDefault()
                        setStatus('annule')
                      }}>
                      <a className="dropdown-item">Annulée</a>
                    </li>
                  </ul>
                </span>
               
              </div>
              <div className="mb-3">
              <span style={{ marginLeft: '8px' }}>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Observation:
                  </label>
                  <textarea className='form-control' rows="3" defaultValue={observation ? observation : ''} onChange={(ev)=>{
                    setObservation(ev.target.value)
                  }}></textarea>
                 {/*  <input
                    type="text"
                    class="form-control"
                    defaultValue={observation ? observation : ''}
                    style={{ width: '100%', border: 'solid #AFBACA 1px' }}
                    onChange={(e) => {
                      setObservation(e.target.value)
                    }}
                  /> */}
                </span>
              </div>
              <div
                class="mb-3"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ width: '220px' }}>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Tag:
                  </label>
                  <Multiselect
                    displayValue="value"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck(e) {
                      setTag(e)
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck(e) {
                      setTag(e)
                    }}
                    options={dataTag}
                    selectedValues={tag}
                    placeholder="Tag"
                  />
                </span>
                <span style={{marginLeft:'10px'}}>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Position:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    defaultValue={position ? position : ''}
                    style={{ width: '220px', border: 'solid #AFBACA 1px' }}
                    onChange={(e) => {
                      setPosition(e.target.value)
                    }}
                  />
                </span>
              </div>
              <div
                class="mb-3"
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ width: '220px' }}>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    Destinataire(s):
                  </label>
                  <Multiselect
                    displayValue="titre"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck(e) {
                      setDestinataire(e)
                    }}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck(e) {
                      setDestinataire(e)
                    }}
                    options={allLot}
                    selectedValues={destinataire}
                    placeholder="Destinataire(s)"
                  />
                </span>
                <span>
                  <label
                    for="exampleInputEmail1"
                    class="form-label"
                    style={{
                      color: '#475569',
                      fontSize: '14px',
                      fontWeight: '600',
                    }}>
                    A lever le:
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    style={{ width: '220px', border: 'solid #AFBACA 1px' }}
                    defaultValue={alever}
                    onChange={(e) => {
                      setAlever(e.target.value)
                    }}
                  />
                </span>
              </div>
              {typePin == 4 && (
                <>
                  <div
                    class="mb-3"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}>
                    <span style={{ width: '220px' }}>
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        style={{
                          color: '#475569',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}>
                        Avancement prévu (%):
                      </label>
                      <input
                        type="number"
                        min={0}
                        max={100}
                        className="form-control"
                        style={{ width: '220px', border: 'solid #AFBACA 1px' }}
                        defaultValue={avancementPrevu}
                        onChange={(e) => {
                          setAvancementPrevu(e.target.value)
                        }}
                      />
                    </span>
                    <span>
                      <label
                        for="exampleInputEmail1"
                        class="form-label"
                        style={{
                          color: '#475569',
                          fontSize: '14px',
                          fontWeight: '600',
                        }}>
                        Avancement actuel (%):
                      </label>
                      <input
                        type="number"
                        min={0}
                        max={100}
                        className="form-control"
                        style={{ width: '220px', border: 'solid #AFBACA 1px' }}
                        defaultValue={avancementActuel}
                        onChange={(e) => {
                          setAvancementActuel(e.target.value)
                        }}
                      />
                    </span>
                  </div>
                </>
              )}
              {photo.length < 4 && (
                <>
                  <div class="mb-3">
                    <label for="comment">
                      (4 images max, Taille 1Mo max, type:".png,.jpeg,.jpg"):
                    </label>
                    <Upload.Dragger
                      multiple={true}
                      maxCount={4}
                      beforeUpload={(files) => {
                        return false
                      }}
                      showUploadList={{ showRemoveIcon: true }}
                      accept=".png,.jpeg,.jpg"
                      listType="picture"
                      onChange={(ev) => {
                        setImage(ev.fileList)
                      }}>
                      <Button>Parcourir Fichier</Button>
                    </Upload.Dragger>
                  </div>
                </>
              )}
              {photo.length > 0 && (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '250px',
                      overflowY: 'scroll',
                      padding: '0',
                    }}>
                    {photo.length > 0 &&
                      photo.map((el) => {
                        return (
                          <>
                            <div
                              id={`photo${el._id}`}
                              className="container-fluid"
                              style={{
                                cursor: 'pointer',
                                marginBottom: '20px',
                                position: 'relative',
                                marginTop: '10px',
                              }}>
                              <button
                                className="btn btn-danger"
                                onClick={(e) => {
                                  e.preventDefault()
                                  setPhoto(
                                    photo.filter((photo) => photo._id != el._id)
                                  )
                                }}
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  right: '15px',
                                }}>
                                <i className="ti ti-trash"></i>
                              </button>
                             <a href={`${process.env.REACT_APP_URL}/api/v1/ressources?filename=${el.nomMedia}&format=media&accessToken=${infoUser.token}&planning=${infoChantier.planning}`} target='_blank' style={{width:'100%'}}>
                             <img
                                src={`${process.env.REACT_APP_URL}/api/v1/ressources?filename=${el.nomMedia}&format=media&accessToken=${infoUser.token}&planning=${infoChantier.planning}`}
                                alt="image"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null // prevents looping
                                  setImg(true)
                                }}
                                style={{ width: '100%' }}
                                /* onClick={(e) => {
                                  navigate(
                                    `/Observation_Media?_id=${JSON.stringify(
                                      el._id
                                    )}`
                                  ) 
                                }} */
                              />
                             </a>
                            </div>
                          </>
                        )
                      })}
                  </div>
                </>
              )}
              <div
                class="mb-3"
                style={{
                  display: 'flex',
                  width: '100%',
                  margin: '0px',
                  padding: '0px',
                }}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    style={{ width: '22px', height: '22px' }}>
                    <path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" />
                    <path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" />
                  </svg>
                  <button
                    type="button"
                    style={{
                      fontSize: '14px',
                      fontWeight: '600',
                      color: '#475569',
                      border: 'none',
                      backgroundColor: 'white',
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#myModal1">
                    Assigner à une tâche
                  </button>
                  {/** Modal d'assignement des taches */}
                  <div
                    class="modal"
                    id="myModal1"
                    style={{ zIndex: '22222222222222222222222222' }}>
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div
                          class="modal-header"
                          style={{ backgroundColor: '#EBEFF5' }}>
                          <h4 class="modal-title" style={{ color: '#334155' }}>
                            Liste des tâches
                          </h4>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"></button>
                        </div>
                        <div class="modal-body">
                          <ul class="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a
                                class="nav-link active"
                                data-bs-toggle="tab"
                                href="#S-1"
                                role="tab"
                                aria-selected="true">
                                Semaine dernière
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                class="nav-link"
                                data-bs-toggle="tab"
                                href="#S"
                                role="tab"
                                aria-selected="true">
                                Semaine en cours
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                class="nav-link"
                                data-bs-toggle="tab"
                                href="#S1"
                                role="tab"
                                aria-selected="true">
                                Semaine prochaine
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                class="nav-link d-flex align-items-center"
                                data-bs-toggle="tab"
                                href="#Taches"
                                role="tab"
                                aria-selected="true">
                                Tâches assignées{' '}
                                <span
                                  className="justify-content-center d-flex align-items-center bg-dark "
                                  style={{
                                    marginLeft: '2px',
                                    color: 'white',
                                    width: '25px',
                                    height: '25px',
                                    borderRadius: '50%',
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                  }}>
                                  {allTacheAssigner.length}
                                </span>
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            <div
                              class="tab-pane p-3 active"
                              id="S-1"
                              role="tabpanel">
                              <div class="row">
                                <div className="container-fluid">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Id</th>
                                        <th>Nom du tâche</th>
                                        <th>date de début</th>
                                        <th>date de fin</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tacheSd &&
                                        tacheSd.map((el) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>
                                                  {el.id ? el.id : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.text
                                                    ? el.text
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.start_date
                                                    ? dateFr(el.start_date)
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.end_date
                                                    ? dateFr(el.end_date)
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {' '}
                                                  <button
                                                    type="button"
                                                    className={
                                                      allTacheAssigner.find(
                                                        (obj) =>
                                                          obj.id === el.id
                                                      )
                                                        ? 'btn btn-xs btn-dark'
                                                        : 'btn btn-xs btn-success'
                                                    }
                                                    style={{
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                    }}
                                                    onClick={(e) => {
                                                      e.preventDefault()
                                                      const objetExistant =
                                                        allTacheAssigner.find(
                                                          (obj) =>
                                                            obj.id === el.id
                                                        )
                                                      if (objetExistant) {
                                                      } else {
                                                        setAllTacheAssigner([
                                                          ...allTacheAssigner,
                                                          el,
                                                        ])
                                                      }
                                                    }}>
                                                    {allTacheAssigner.find(
                                                      (obj) => obj.id === el.id
                                                    )
                                                      ? 'Assigné'
                                                      : 'Assigner'}
                                                  </button>
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div class="tab-pane p-3" id="S" role="tabpanel">
                              <div class="row">
                                <div className="container-fluid">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Id</th>
                                        <th>Nom du tâche</th>
                                        <th>date de début</th>
                                        <th>date de fin</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tacheSc &&
                                        tacheSc.map((el) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>
                                                  {el.id ? el.id : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.text
                                                    ? el.text
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.start_date
                                                    ? dateFr(el.start_date)
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.end_date
                                                    ? dateFr(el.end_date)
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {' '}
                                                  <button
                                                    type="button"
                                                    className={
                                                      allTacheAssigner.find(
                                                        (obj) =>
                                                          obj.id === el.id
                                                      )
                                                        ? 'btn btn-xs btn-dark'
                                                        : 'btn btn-xs btn-success'
                                                    }
                                                    style={{
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                    }}
                                                    onClick={(e) => {
                                                      e.preventDefault()
                                                      const objetExistant =
                                                        allTacheAssigner.find(
                                                          (obj) =>
                                                            obj.id === el.id
                                                        )
                                                      if (objetExistant) {
                                                      } else {
                                                        setAllTacheAssigner([
                                                          ...allTacheAssigner,
                                                          el,
                                                        ])
                                                      }
                                                    }}>
                                                    {allTacheAssigner.find(
                                                      (obj) => obj.id === el.id
                                                    )
                                                      ? 'Assigné'
                                                      : 'Assigner'}
                                                  </button>
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div class="tab-pane p-3" id="S1" role="tabpanel">
                              <div class="row">
                                <div className="container-fluid">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Id</th>
                                        <th>Nom du tâche</th>
                                        <th>date de début</th>
                                        <th>date de fin</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tacheSp &&
                                        tacheSp.map((el) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>
                                                  {el.id ? el.id : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.text
                                                    ? el.text
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.start_date
                                                    ? dateFr(el.start_date)
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.end_date
                                                    ? dateFr(el.end_date)
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {' '}
                                                  <button
                                                    type="button"
                                                    className={
                                                      allTacheAssigner.find(
                                                        (obj) =>
                                                          obj.id === el.id
                                                      )
                                                        ? 'btn btn-xs btn-dark'
                                                        : 'btn btn-xs btn-success'
                                                    }
                                                    style={{
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                    }}
                                                    onClick={(e) => {
                                                      e.preventDefault()
                                                      const objetExistant =
                                                        allTacheAssigner.find(
                                                          (obj) =>
                                                            obj.id === el.id
                                                        )
                                                      if (objetExistant) {
                                                      } else {
                                                        setAllTacheAssigner([
                                                          ...allTacheAssigner,
                                                          el,
                                                        ])
                                                      }
                                                    }}>
                                                    {allTacheAssigner.find(
                                                      (obj) => obj.id === el.id
                                                    )
                                                      ? 'Assigné'
                                                      : 'Assigner'}
                                                  </button>
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div
                              class="tab-pane p-3"
                              id="Taches"
                              role="tabpanel">
                              <div class="row">
                                <div className="container-fluid">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Id</th>
                                        <th>Nom du tâche</th>
                                        <th>date de début</th>
                                        <th>date de fin</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {allTacheAssigner &&
                                        allTacheAssigner.map((el) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>
                                                  {el.id ? el.id : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.text
                                                    ? el.text
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.start_date
                                                    ? dateFr(el.start_date)
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  {el.end_date
                                                    ? dateFr(el.end_date)
                                                    : 'Non défini'}
                                                </td>
                                                <td>
                                                  <button
                                                    type="button"
                                                    className="btn btn-xs btn-danger"
                                                    style={{
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                    }}
                                                    onClick={(e) => {
                                                      e.preventDefault()
                                                      setAllTacheAssigner(
                                                        allTacheAssigner.filter(
                                                          (res) =>
                                                            res.id != el.id
                                                        )
                                                      )
                                                    }}>
                                                    retirer
                                                  </button>
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn"
                            data-bs-dismiss="modal"
                            style={{
                              color: 'white',
                              backgroundColor: '#4F46E5',
                              borderRadius: '8px',
                            }}>
                            Enregistrer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <button
                  type="button"
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#475569',
                    marginLeft: '12px',
                    border: 'none',
                    backgroundColor: 'white',
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#myModal">
                  + Ajouter depuis observation hors ligne
                </button>
                {/* Modal pour la selection de l'observation hors ligne */}
                <div
                  class="modal"
                  id="myModal"
                  style={{ zIndex: '9999999999999999999999999999999999999' }}>
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div
                        class="modal-header"
                        style={{ backgroundColor: '#EBEFF5' }}>
                        <h4 class="modal-title" style={{ color: '#334155' }}>
                          Ajout de l'observation
                        </h4>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"></button>
                      </div>
                      <div class="modal-body">
                        <div className="container">
                          <label className="form-label">
                            Liste des observations hors ligne
                          </label>
                          <select
                            className="form-select"
                            onChange={(e) => {
                              setInfoObservation(JSON.parse(e.target.value))
                            }}>
                            <option value="Sélectionner" selected>
                              {' '}
                              Sélectionner parmis les groupes
                            </option>
                            {allGroupe &&
                              allGroupe.map((e) => {
                                return (
                                  <>
                                    <optgroup label={e}>
                                      {allObservation &&
                                        allObservation.map((el) => {
                                          if (el.groupe == e) {
                                            return (
                                              <>
                                                <option
                                                  value={JSON.stringify(el)}>
                                                  {el.nomPlan} :{' '}
                                                  {el.observation}
                                                </option>
                                              </>
                                            )
                                          } else {
                                          }
                                        })}
                                    </optgroup>
                                  </>
                                )
                              })}
                            <optgroup label="Sans groupe">
                              {allObservation &&
                                allObservation.map((el) => {
                                  if (el.groupe == 'Pas de goupe précisé') {
                                    return (
                                      <>
                                        <option value={JSON.stringify(el)}>
                                          <span className="text-danger">
                                            {el.nomPlan} : {el.observation}
                                          </span>
                                        </option>
                                      </>
                                    )
                                  } else {
                                  }
                                })}
                            </optgroup>
                          </select>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          className="btn"
                          onClick={(e) => {
                            e.preventDefault()
                            setAlever(infoObservation.aLeverLe)
                            setDestinataire(infoObservation.destinataire)
                            setObservation(infoObservation.observation)
                            setTag(infoObservation.tag)
                            setPosition(infoObservation.position)
                            setStatus(infoObservation.status)
                            setTypePin(infoObservation.type)
                            setAvancementPrevu(infoObservation.avancementPrevu)
                            setAvancementActuel(infoObservation.avancementActuel)
                          }}
                          data-bs-dismiss="modal"
                          style={{
                            color: 'white',
                            backgroundColor: '#4F46E5',
                            borderRadius: '8px',
                          }}>
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex"
                style={{ marginTop: '12px', justifyContent: 'flex-end' }}>
                <button
                  className="btn"
                  style={{
                    padding: '8px 16px',
                    border: '1px solid #E11D48',
                    backgroundColor: 'transparent',
                    borderRadius: '8px',
                    color: '#E11D48',
                  }}
                  onClick={(e) => {
                    if (
                      confirm(
                        "Êtes-vous certain(e) de vouloir supprimer l'observation ? "
                      )
                    ) {
                      setBool2(false)
                      setDel(false)
                      dispatch({ type: 'bool/modifier', payload: !bool })
                      delPin()
                    } else {
                      e.preventDefault()
                    }
                  }}>
                  Supprimer
                </button>
                <button
                  className="btn"
                  style={{
                    backgroundColor: '#4F46E5',
                    padding: '8px 16px',
                    color: 'white',
                    borderRadius: '8px',
                    marginLeft: '12px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    save()
                  }}>
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <></>
      )}
      {loader && (
        <>
          <LoaderFixed />
        </>
      )}
    </>
  )
}

export default Pin
