import React from "react"; // PALATA : Je le laisse au cas où

// Redirection ses utilisateurs suivant la formule validée
let listFormule = ['opclte', 'detlte'] ;

const redirectionUser = (role, navigate) => {

    if (listFormule.includes(role)) {
        navigate('/planning_GestionTache') ;
    }else{
        // Pour le moment, on ne fait rien dans ces cas-là.
    }
};

export {redirectionUser} ;