import axios from "axios"
export const getPresence =  async (token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/presences`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const updateCr =  async (id,token,planning) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/comptesrendu/${id}?planning=${planning}`,{
            archive : true
        },config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const updatefiche =  async (id,token,planning) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/visites/${id}?planning=${planning}`,{
            archive : true
        },config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const updateEnementEtPassation =  async (id,token,planning) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.patch(`${process.env.REACT_APP_URL}/api/v1/fiches/${id}?planning=${planning}`,{
            archive : true
        },config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}

export const getAllCR =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/comptesrendu?planning=${planning}&sort=-idCR&limit=1000&archive=false`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAnnexe =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/annexes?planning=${planning}&sort=-idLastCr&limit=1000`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}


export const getAllFiche =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/visites?planning=${planning}&sort=-idVisite&limit=1000&archive=false`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAllPassation =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/fiches?planning=${planning}&sort=-createdAt&limit=1000&archive=false&typeFiche=0`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAllEvenement =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/fiches?planning=${planning}&sort=-createdAt&limit=1000&archive=false&typeFiche=1`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAllNote =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/fiches?planning=${planning}&sort=-createdAt&limit=1000&archive=false&typeFiche=2`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAllAvancement =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/fiches?planning=${planning}&sort=-createdAt&limit=1000&archive=false&typeFiche=3`,config).then((e)=>{
            return e.data
        }).catch((e)=>{return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}


export const getAllEntreprise =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/lots/lotProfile?planning=${planning}&archive[ne]=true`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const getAllIntervenant =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/cartes/carteProfile?planning=${planning}&archive[ne]=true`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}

export const getAllObservation =  async (planning,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.get(`${process.env.REACT_APP_URL}/api/v1/ressources/pins/?planning=${planning}&mediaType=pdf`,config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}
export const saveFiche =  async (planning,fiche,type,style,filtre,filtrePlan,dateFv,token) => {
    const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    
    try{
        const result = await axios.post(`${process.env.REACT_APP_URL}/api/v1/visites`,{type:type,style:style,filtre:filtre,visites:fiche,filtrePlan:filtrePlan,planning:planning,dateFv:dateFv},config).then((e)=>{
            return e.data
        }).catch((e)=>{
            return e})
        return result
    }catch(err){
        alert('le serveur ne repond pas')
        return err
    }
}