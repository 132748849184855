import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/Kolos_logo/1_carre_blanc.png'
import { useNavigate } from 'react-router-dom'
import { envoiMail } from '../../helpers/connexion/connexion'
import BeatLoader from 'react-spinners/BeatLoader'
//Page de connexion à SOODA
const PassOublier = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState(
    "il n'y a pas d'utilisateur associé à cette adresse email"
  )
  const [success, setSucces] = useState(
    'Un lien a été envoyé à votre email afin de réinitialiser votre mot de passe.'
  )
  const [erreur, setErreur] = useState(false)
  const [ok, setOk] = useState(false)
  //fonction pour la connexion de l'utilisateur
  const handleSubmit = () => {
    setLoading(true)
    envoiMail(email).then((e) => {
      setLoading(false)
      if (e.name == 'AxiosError') {
        setOk(false)
        setErreur(true)
      } else {
        setErreur(false)
        setOk(true)
      }
    })
  }

  return (
    <>
      {loading && (
        <>
          <div
            className="container-fluid d-flex"
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              zIndex: '2000',
              backgroundColor: 'rgba(104, 93, 119, 0.7)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BeatLoader color="yellowgreen" loading={true} size={50} />
          </div>
        </>
      )}
      <div class="container-md">
        <div class="row vh-100 d-flex justify-content-center">
          <div class="col-12 align-self-center">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 mx-auto">
                  <div class="card" style={{borderRadius:'8px',overflow:'hidden',border:'1px solid #E2E8F0',boxShadow:'0px 6.15px 12.31px rgba(0, 0, 0, 0.02), 0px 6.15px 6.15px rgba(0, 0, 0, 0.04)'}}>
                    <div class="card-body p-0 auth-header-box"  style={{backgroundColor:'#4F46E5'}}>
                      <div class="text-center p-4 mt-2">
                        <a
                          href="#"
                          class="logo logo-admin"
                          onClick={(e) => {
                            navigate('/')
                          }}
                        >
                          <img
                            src={logo}
                            height="70"
                            alt="logo"
                            class="auth-logo"
                          />
                        </a>
                        <p style={{fontSize:'18px',fontWeight:'600',color:'white'}}>
                          Veuillez entrer votre addresse email
                        </p>
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <form
                        class="my-4"
                        onSubmit={(e) => {
                          e.preventDefault()
                          handleSubmit()
                        }}
                      >
                        {erreur && (
                          <>
                            <div
                              class="alert alert-danger border-0 text-center"
                              role="alert"
                            >
                              <strong> {message}</strong>
                            </div>
                          </>
                        )}
                        {ok && (
                          <>
                            <div
                              class="alert alert-success border-0 text-center"
                              role="alert"
                            >
                              <strong> {success}</strong>
                            </div>
                          </>
                        )}
                        <div class="form-group mb-2">
                          <label class="form-label" for="username" style={{fontSize:'14px',fontWeight:'500',color:'#334155'}}>
                            Email
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            id="username"
                            name="username"
                            placeholder="exemple@gmail.com"
                            onChange={(e) => {
                              setEmail(e.target.value)
                            }}
                            required
                          />
                        </div>
                        <div class="form-group mb-0 row">
                          <div class="col-12">
                            <div class="d-grid mt-3">
                              <button class="btn" type="submit" style={{backgroundColor:'#4F46E5',color:'white'}}>
                                Envoyer le code{' '}
                                <i class="fas fa-sign-in-alt ms-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div class="m-3 text-center text-muted" style={{cursor:"pointer"}} onClick={(e)=>{
                        navigate("/")
                      }}>
                        Se connecter ?
                      </div>
                      <hr class="hr-dashed mt-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PassOublier
